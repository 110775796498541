import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { NO_RECORD_MSG } from "../../../shared/constants/shared.constants"
import NoRecord from "../../shared/noRecord"
import WorklistPatientListRow from "./worklistPatientListRow"
import { Scrollbars } from 'react-custom-scrollbars'
import { deletePatientFromWorklist } from "../../../actions/worklist.action";
import { WorklistInfo } from "./worklistInfo";
import { Link } from "react-router-dom"
import { setModalWithType } from "../../../actions/modal.action"
import { MODALS } from "../../../shared/constants/actions.constants"

export const WorklistDetailPanel = (props) => {
  const onLeftArrowClick = (patientId) => {
    if (props.activeWorklist && props.activeWorklist.id && patientId) {
      props.deletePatientFromWorklist(props.activeWorklist.id, patientId)
    }
  }

  const removeAllPatientsClickHandler = () => {
    if (props.activeWorklist && props.activeWorklist.id) {
      let modalState = { 
        worklistId: props.activeWorklist.id,
        actionType: MODALS.CONFIRMATION_MODAL_TO_REMOVE_ALL_PATIENTS_FROM_WORKLIST,
        header: "Worklist's All Patients Deletion",
        body: "Are you sure you want to delete all the patients from the worklist?"
      }

      props.setModalWithType(true, MODALS.CONFIRMATION_MODAL, modalState)
    }
  }

  return (
      <div className="content__right js-content__right">
        <div className="patient__list eadieWhite-bg patient__list--active">
          <WorklistInfo 
            worklist = { props.activeWorklist }
          />
          <div className="patient__tableList">
            <div className="patient__table">
              <div className="tableCntrl">
                <div className="table mg-b-0 table-hover">
                  {
                    props.activeWorklist && (
                      <div className="patient__editdetails">
                          <p className="cursor-pointer"></p>
                          <Link to = "#" onClick = { removeAllPatientsClickHandler } className="btn btn-light">Remove all Patients</Link> 
                      </div>
                    )
                  }
                  <div className="thead">
                    <div className="trow">
                      <div className="tcol w-5"></div>
                      <div className="tcol w-40">Patient Name</div>
                      <div className="tcol w-30">DOB</div>
                      <div className="tcol w-25">Patient ID</div>
                      <div className="tcol w-25"></div>
                    </div>
                  </div>
                  <div className="tbody mcustomscrollbar table-hover noheight" style={{ 'maxHeight': '180px !important' }}>
                    <Scrollbars style={{ 'position': 'relative', 'minHeight': 700 }}>
                      {
                        props.activeWorklist && props.activeWorklist && props.activeWorklist.patients.length ? (
                          props.activeWorklist.patients.map((patient, index) => (
                            <WorklistPatientListRow 
                              key = { index }
                              patient={patient}
                              onLeftArrowClick = { onLeftArrowClick }
                            />
                          ))
                        ) : (
                          <NoRecord msg = { NO_RECORD_MSG.NO_PATIENT_FOUND } />
                        )
                      }
                    </Scrollbars>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

const mapStateToProps = ({ worklists, ui, patients }) => ({
  activeWorklist: worklists.activeWorklist
})

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    deletePatientFromWorklist,
    setModalWithType
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(WorklistDetailPanel)
