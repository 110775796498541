import React from 'react'
import { Link } from "react-router-dom"

export const UserListRow = (props) => {
    return (
        <div className = { `trow ${ (props.activeUser && props.user.id === props.activeUser.id) ? "active" : ""}` } onClick = { () => props.onUserRowClick(props.user) }>
            <div className="tcol w-30">{ props.user.firstName } { props.user.lastName }</div>
            <div className="tcol w-30">{ props.user.email }</div>
            <div className="tcol w-15">{ props.user.role }</div>
            <div className="tcol w-25">
            <div className="dropdown dropdown-more text-right">
                <button className="btn btn-transparent" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="fabicon-more-vertical"></i>
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                    <Link to = { `/users/${props.user.id}` } className="dropdown-item">Edit</Link>
                </div>
            </div>
            </div>
        </div>
    )
}

export default UserListRow
