import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { setModalWithType } from "../actions/modal.action"
import AddEditPatientModal from "../components/shared/modals/addEditPatientModal"
import AddUserModal from "../components/shared/modals/addUserModal"
import AddEditWorklistModal from "../components/shared/modals/addEditWorklistModal"
import ConfirmationModal from "../components/shared/modals/confirmationModal"
import ChartViewModal from "../components/shared/modals/chartViewModal"
import ImportedTestViewModal from "../components/shared/modals/importedTestViewModal";
import ImportedTestSummaryImageViewModal from "../components/shared/modals/importedTestSummaryImageViewModal";
import ImportedTestSummaryTextDataViewModal from "../components/shared/modals/importedTestSummaryTextDataViewModal";
import ImportTestsModal from "../components/shared/modals/importTestsModal";
import DragNDropFollowCharts from "../components/shared/modals/dragNDropFollowCharts"
import BlindSpotModal from '../components/shared/modals/blindSpotModal'
import AddHmdDeviceModal from '../components/shared/modals/addHmdDeviceModal'
import AddAuth0UserModel from '../components/shared/modals/addAuth0User'
import DbBackupModal from '../components/shared/modals/dbBackupModal'
import AddHelpQueryContent from '../components/shared/modals/addHelpQueryContentModal'
import { MODALS } from "../shared/constants/actions.constants"


const ModalContainer = (props) => {

  const onModalClose = () => {
    props.setModalWithType(false, "")
  }

  return (
    <Modal size={ props.modalData && props.modalData.size ? props.modalData.size : '' } show = { props.setModal && props.modalType && true } onHide = { onModalClose }  backdrop={props.modalData && props.modalData.backdrop ? props.modalData.backdrop : true}>
      {
        props.modalType === MODALS.ADD_PATIENT_MODAL ? <AddEditPatientModal patient = { null } /> : (
          props.modalType === MODALS.ADD_USER_MODAL ? <AddUserModal /> : (
            (props.modalType === MODALS.EDIT_PATIENT_MODAL && props.activePatient) ? <AddEditPatientModal patient = { props.activePatient } /> : 
              (props.modalType === MODALS.ADD_WORKLIST_MODAL ? <AddEditWorklistModal /> : (
                props.modalType === MODALS.EDIT_WORKLIST_MODAL ? <AddEditWorklistModal worklist = { props.activeWorklist } /> : (
                  props.modalType === MODALS.CONFIRMATION_MODAL ? <ConfirmationModal modalData = { props.modalData || {} } modalType = { props.modalType } /> : (
                    props.modalType === MODALS.BLIND_SPOT_MODAL ? <BlindSpotModal modalData = { props.modalData || {} } modalType = { props.modalType } /> : (
                      props.modalType === MODALS.CHART_VIEW_MODAL ? <ChartViewModal modalData = { props.modalData || {} } modalType = { props.modalType } /> : (
                        (props.modalType === MODALS.DND_FOLLOW_CHARTS || props.modalType === MODALS.DND_SINGLE_VIEW_CHARTS || props.modalType === MODALS.DND_OVERVIEW_VIEW_CHARTS) ? <DragNDropFollowCharts modalData = { props.modalData || {} } modalType = { props.modalType } /> : (
                          props.modalType === MODALS.IMPORT_TESTS_MODAL ? <ImportTestsModal modalData = { props.modalData || {} } modalType = { props.modalType } /> : (
                            props.modalType === MODALS.PATIENT_EXTRACTED_IMAGE_FROM_PDF_MODAL ? <ImportedTestSummaryImageViewModal modalData = { props.modalData || {} } modalType = { props.modalType } /> : (
                              props.modalType === MODALS.PATIENT_EXTRACTED_TEXT_DATA_FROM_PDF_MODAL ? <ImportedTestSummaryTextDataViewModal modalData = { props.modalData || {} } modalType = { props.modalType } /> : (
                                props.modalType === MODALS.PATIENTS_IMPORTED_TEST_VIEW_MODAL ? <ImportedTestViewModal modalData = { props.modalData || {} } modalType = { props.modalType } /> : 
                                  props.modalType === MODALS.DB_BACKUP_MODAL ? <DbBackupModal modalData = { props.modalData || {} } modalType = { props.modalType } /> : 
                                    props.modalType === MODALS.ADD_HELP_QUERY_CONTENT_MODAL ? <AddHelpQueryContent modalData = { props.modalData || {} } modalType = { props.modalType } /> : 
                                      props.modalType === MODALS.ADD_HMD_MODAL ? <AddHmdDeviceModal modalData = { props.modalData || {} } modalType = { props.modalType } /> :
                                        props.modalType === MODALS.AUTH0_USER_CREATION_MODEL ? <AddAuth0UserModel modalData = { props.modalData || {} } modalType = { props.modalType } /> : null
                              )
                            )
                          )
                        )
                      )
                    )
                  )
                )
              )
            )
          )
        )
      }
    </Modal>
  )

}


const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setModalWithType
  }, dispatch)
)

const mapStateToProps = ({ modals, patients, worklists }) => ({
  setModal: modals.set,
  modalType: modals.type,
  modalData: modals.data,
  activePatient: patients.activePatient,
  activeWorklist: worklists.activeWorklist
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer)
