import Axios from "axios"
import { API_CONSTANTS } from "../../shared/constants/api.constants"

/**
 * @desc To fetch all users list with minimal details
 * @public
 */
export const getUsersList = () => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.get(API_CONSTANTS.USERS.GET_ALL_USERS)
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
 * @desc To fetch all users list with bulk details
 * @public
 */
export const getUsersListWithBulkData = (searchText = '', sort = 1) => {
    return new Promise(async (res, rej) => {
        try {
            let params = { searchText, sort }

            if (searchText) params = { ...params, searchText }

            let { data } = await Axios.get(API_CONSTANTS.USERS.GET_ALL_USERS_WITH_BULK_DATA, { params })
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
 * @desc To create a new user
 * @param {object} userObj
 * @public
 */
export const createUser = (userObj) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.post(API_CONSTANTS.USERS.CREATE_USER, userObj)
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
 * @desc To create a new auth0 user
 * @param {object} userObj
 * @public
 */
export const createUserAuth0 = (userObj) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.post(API_CONSTANTS.USERS.CREATE_USER_FROM_AUTH0, userObj)
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
 * @desc To get user object of given id
 * @param {string} userId
 * @public
 */
export const getUserById = (userId) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.get(`${API_CONSTANTS.USERS.GET_USER_BY_ID}/${userId}`)
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
 * @desc To get user object of given userEmail
 * @param {string} userEmail
 * @public
 */
export const getUserByEmail = (userEmail) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.post(`${API_CONSTANTS.USERS.GET_USER_BY_EMAIL}?userEmail=${encodeURIComponent(userEmail)}`)
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
 * @desc To update an user
 * @param {object} userObj
 * @public
 */
export const updateUser = (userObj) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.put(`${API_CONSTANTS.USERS.EDIT_USER}/${userObj.id}`, userObj)
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
 * @desc To update an user's password
 * @param {string} userId
 * @param {object} userObj
 * @public
 */
export const updateUserPassword = ({ userId, userObj }) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.put(API_CONSTANTS.USERS.USER_CHANGE_PASSOWRD(userId), userObj)
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
 * @desc To update an user's charts order
 * @param {string} userId
 * @param {object} userObj
 * @public
 */
export const updateChartSettingsOrder = (userId, settingsObj) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.put(API_CONSTANTS.USERS.USER_CHANGE_CHART_ORDER(userId), settingsObj)
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
 * @desc To upload a file
 * @param {object} fileObj
 * @public
 */
export const uploadFile = (fileObj) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.post(API_CONSTANTS.USERS.UPLOAD, fileObj)
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}