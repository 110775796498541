import moment from 'moment';
import { connect } from 'react-redux';
import React, { useEffect, useState, useMemo } from 'react';
import Eye from '../../shared/graphs/eye';
import { STRATEGIES_LABELS } from '../../../models/assessment.model';
import { CHART_NAMES, CHART_ORIENTATION, EYE_COLOR_MAP_TDP_PDP } from '../../../shared/constants/chart.constants';
import { patientFullName, patientRefractionDetails, patientTrialLensDetails } from '../../../shared/utils/patient';
import { calculateAge, calculateTimeDuration, parseDate, parseDateWithMonth, parseTime, toCamelCase, toPercentage } from '../../../shared/utils/wrappers';

const generateChartSizes = () => {
  const commonSizes = {
    '10-2': 0.2,
    '24-2': 0.075,
    '24-2C': 0.075,
    '30-2': 0.075,
  };

  const deviationSizes = {
    '10-2': 0.1,
    '24-2': 0.04,
    '24-2C': 0.04,
    '30-2': 0.04,
  };

  return {
    [CHART_NAMES.SENSITIVITY_VALUES]: commonSizes,
    [CHART_NAMES.SENSITIVITY_GRAYSCALE]: commonSizes,
    [CHART_NAMES.TOTAL_DEVIATION_VALUES]: deviationSizes,
    [CHART_NAMES.TOTAL_DEVIATION_PROBABILITIES]: deviationSizes,
    [CHART_NAMES.PATTERN_DEVIATION_VALUES]: deviationSizes,
    [CHART_NAMES.PATTERN_DEVIATION_PROBABILITIES]: deviationSizes,
  };
};

const chartSizes = generateChartSizes();

const PrintAssessmentReport = ({ assessment, patient }) => {
  const [OSOD, setOSOD] = useState('OD');
  const [grid, setGrid] = useState('24-2');

  useEffect(() => {
    if (assessment) {
      setOSOD(assessment.configuration.eye === 'right' ? 'OD' : 'OS');
      setGrid(assessment.configuration.grid);
    }
  }, [assessment]);

  const assessmentData = assessment?.data || {};
  const processedData = assessmentData?.processed || {};
  const summaryData = assessmentData?.summary || {};

  const patientInfo = useMemo(() => ({
    fullName: patientFullName(patient),
    dateOfBirth: parseDate(patient.dateOfBirth),
    gender: toCamelCase(patient.gender),
    patientId: patient.patientId,
    age: calculateAge(patient.dateOfBirth),
    testDate: parseDateWithMonth(assessment?.testStartedAt),
    testTime: parseTime(assessment?.testStartedAt),
    testDuration: calculateTimeDuration(assessment?.testStartedAt, assessment?.testEndedAt),
  }), [patient, assessment]);

  if (!assessment || Object.keys(assessmentData).length === 0) {
    return null;
  }

  const renderEyeChart = (chartName, chartData, height, width, top, left) => (
    <Eye
      chartId={`chart-print-${chartName.toLowerCase().replace(/_/g, '-')}`}
      assessment={assessment}
      chartName={chartName}
      chartData={chartData}
      chartOrientation={CHART_ORIENTATION.OD}
      transform={chartSizes[chartName][grid].transform}
      transformOrigin="left top"
      height={height}
      width={width}
      top={top}
      left={left}
    />
  );

  return (
    <div className="p-5 d-none d-print-block">
      <section id="print-header" className="print-header">
        <div className="d-flex justify-content-between align-items-center">
          <div className="patient-info">
            <p>Patient: <b>{patientInfo.fullName}</b></p>
            <p>Date of Birth: <b>{patientInfo.dateOfBirth}</b></p>
            <p>Sex: <b>{patientInfo.gender}</b></p>
            <p>Patient Id: <b>{patientInfo.patientId}</b></p>
          </div>
          <img src="/assets/images/bannar-logo.png" alt="Eadietech Logo" width="200px" className="d-print-block d-none" />
        </div>
        <div className="position-relative mt-4 mb-3" style={{ height: '25px' }}>
          <div className="position-absolute" style={{ top: 0, left: 0, height: '100%', width: '100%', backgroundColor: '#808080' }} />
          <div className="position-absolute m-0" style={{ top: 0, left: '5%', height: '100%', backgroundColor: '#ffffff' }}>
            <h1 className="bg-white pl-2 pr-2" style={{ color: '#27428d' }}>{OSOD}</h1>
          </div>
          <div className="position-absolute m-0" style={{ top: 0, left: '10%', height: '100%' }}>
            <h2 className="text-white pl-2 pr-2">Single Field Analysis</h2>
          </div>
          <div className="position-absolute mr-2" style={{ top: 0, right: 0, height: '100%' }}>
            <h2 className="text-white pl-2 pr-2">
              Center {grid} {toCamelCase(assessment?.configuration.strategy)} Test
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <p>Fixation Monitor: <span style={{ color: '#0504fc' }}>Blind Spot</span></p>
            <p>Fixation Target: <span style={{ color: '#0504fc' }}>Center</span></p>
            <p>Fixation Losses: <span style={{ color: '#0504fc' }}>{summaryData.fixationLosses}/{summaryData.totalfixationlosses} {`${toPercentage(summaryData.fixationLosses, summaryData.totalfixationlosses)}`}</span></p>
            <p>False POS Errors (Active): <span style={{ color: '#0504fc' }}>{summaryData.falsePosErrors_active}/{summaryData.totalfalsePosis_active} {`${toPercentage(summaryData.falsePosErrors_active, summaryData.totalfalsePosis_active)}`}</span></p>
            <p>False POS Errors (Passive): <span style={{ color: '#0504fc' }}>{summaryData.falsePOSErrors}</span></p>
            <p>False NEG Errors: <span style={{ color: '#0504fc' }}>{summaryData.falseNegErrors}/{summaryData.totalfalseNegs} {`${toPercentage(summaryData.falseNegErrors, summaryData.totalfalseNegs)}`}</span></p>
            <p>Test Duration: <span style={{ color: '#0504fc' }}>{patientInfo.testDuration}</span></p>
            <p>Fovea: <span style={{ color: '#0504fc' }}>Off</span></p>
          </div>
          <div className="col-4">
            <p>Stimulus: <span style={{ color: '#0504fc' }}>III, White</span></p>
            <p>Background: <span style={{ color: '#0504fc' }}>10 cd/m<sup>2</sup></span></p>
            <p>Strategy: <span style={{ color: '#0504fc' }}>{STRATEGIES_LABELS[assessment.configuration.strategy]?.label}</span></p>
            <p>Pupil Diameter: <span style={{ color: '#0504fc' }}>{summaryData.pupilSize} mm</span></p>
            <p>Rx: <span style={{ color: '#0504fc' }}>{patientRefractionDetails(assessment.configuration, OSOD)}</span></p>
            <p>Trial Lens: <span style={{ color: '#0504fc' }}>{patientTrialLensDetails(assessment.configuration, OSOD)}</span></p>
          </div>
          <div className="col-4">
            <p>Date: <span style={{ color: '#0504fc' }}>{patientInfo.testDate}</span></p>
            <p>Time: <span style={{ color: '#0504fc' }}>{patientInfo.testTime}</span></p>
            <p>Age: <span style={{ color: '#0504fc' }}>{patientInfo.age}</span></p>
          </div>
        </div>
      </section>
      {processedData[OSOD] && (
        <section id="print-graph" className="print-graph">
          <div className="row position-relative" style={{marginTop: "-50px"}}>
            <div className="col-6 pl-5">
              {renderEyeChart(CHART_NAMES.SENSITIVITY_VALUES, assessmentData['raw']?.[OSOD], 430, 250, 0, '-10%')}
            </div>
            <div className="col-6 pr-5" >
              {renderEyeChart(CHART_NAMES.SENSITIVITY_GRAYSCALE, assessmentData['raw']?.[OSOD], 400, 250, 0, '-25%')}
            </div>
          </div>
          <div className="row" style={{marginTop: "-50px"}}>
            <div className="col-4">
              {renderEyeChart(CHART_NAMES.TOTAL_DEVIATION_VALUES, assessmentData['totalDeviation']?.[OSOD], 225, 250, 0, 0)}
            </div>
            <div className="col-4">
              {renderEyeChart(CHART_NAMES.PATTERN_DEVIATION_VALUES, assessmentData['patternDeviation']?.[OSOD], 225, 250, 0, 0)}
            </div>
            <div className="col-4 d-flex flex-column align-items-start justify-content-end">
              <p>MD {grid}: {summaryData.meanDeviation}</p>
              <p>PSD {grid}: {summaryData.PSD}</p>
            </div>
            <div className="col-4">
              <p className="text-center" style={{ color: '#0504fc' }}>Total Deviation</p>
            </div>
            <div className="col-4">
              <p className="text-center" style={{ color: '#0504fc' }}>Pattern Deviation</p>
            </div>
            <div className="col-4"></div>
            <div className="col-4">
              {renderEyeChart(CHART_NAMES.TOTAL_DEVIATION_PROBABILITIES, assessmentData['totalDeviationProbabilities']?.[OSOD], 225, 250, 0, 0)}
            </div>
            <div className="col-4">
              {renderEyeChart(CHART_NAMES.PATTERN_DEVIATION_PROBABILITIES, assessmentData['patternDeviationProbabilities']?.[OSOD], 225, 250, 0, 0)}
            </div>
            <div className="col-4 d-flex justify-content-start align-items-center position-relative">
              <p>No Probability Values</p>
              <div className="position-absolute" style={{ top: '60%', left: '50%' }}>
                <div className="d-flex justify-content-start gap-5">
                  <svg width="20" height="20"><rect x="0" y="0" width="20" height="20" style={{ fill: EYE_COLOR_MAP_TDP_PDP.LIGHT }}></rect></svg>
                  <p className="ml-2">{`P < 5%`}</p>
                </div>
                <div className="d-flex justify-content-start gap-5">
                  <svg width="20" height="20"><rect x="0" y="0" width="20" height="20" style={{ fill: EYE_COLOR_MAP_TDP_PDP.LIGHT_GRAY }}></rect></svg>
                  <p className="ml-2">{`P < 2%`}</p>
                </div>
                <div className="d-flex justify-content-start gap-5">
                  <svg width="20" height="20"><rect x="0" y="0" width="20" height="20" style={{ fill: EYE_COLOR_MAP_TDP_PDP.GREY }}></rect></svg>
                  <p className="ml-2">{`P < 1%`}</p>
                </div>
                <div className="d-flex justify-content-start gap-5">
                  <svg width="20" height="20"><rect x="0" y="0" width="20" height="20" style={{ fill: EYE_COLOR_MAP_TDP_PDP.DARK }}></rect></svg>
                  <p className="ml-2">{`P < 0.05%`}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <section id="print-footer" className="print-footer">
        <div className="row mt-2">
          <div className="col-7">
            <small className="ml-2">Comments</small>
            <div style={{ width: '100%', height: '100px', backgroundColor: '#f1f1f1' }}></div>
          </div>
          <div className="col-3">
            <small className="ml-2">Signature</small>
            <div style={{ width: '100%', height: '100px', backgroundColor: '#f1f1f1' }}></div>
          </div>
          <div className="col-2 d-flex justify-content-end align-items-center">
            <img src="/assets/images/bannar-logo.png" alt="Eadietech Logo" width="175px" height="65px" className="d-print-block d-none" />
          </div>
          <div className="col-4 d-flex justify-content-center align-items-center">
            <small>Version 3.1.1.264</small>
          </div>
          <div className="col-4 d-flex justify-content-center align-items-center">
            <small>Created {moment().format('M/D/YYYY  h:mm:ss A')}</small>
          </div>
          <div className="col-4 d-flex justify-content-center align-items-center">
            <small>Page 1 of 1</small>
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = ({ assessment, modals, patients }) => ({
  // assessment: assessment.activeAssessment,
  setModal: modals.set,
  processedData: assessment.activeAssessmentProcessedData,
});

export default connect(mapStateToProps)(PrintAssessmentReport);
