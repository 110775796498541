import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setModalWithType } from '../../../actions/modal.action';
import { addHmdDevice } from '../../../actions/hmdDevices.action';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { UserForm, UserSchema } from '../../../models/users.model';
import { addUserFromAuth0, getUserForEdit } from '../../../actions/users.action';
import history from '../../../history';

export const AddAuth0UserProfileSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
});

export const AddAuth0UserModel = props => {
  const userInfo = useMemo(() => ({
    ...UserForm,
    ...props.modalData.data,
  }), [props.user]);

  const cancelClickHandler = () => {
    props.setModalWithType(false);
  };


  const addProfileInfoFormSubmitHandler = async values => {
    await props.addUserFromAuth0(values, history);
    props.setModalWithType(false);
  }

  return (
    <React.Fragment>
      <div className="modal-header">
        <h2 className="modal-title" id="patient-modalLabel">
          Add Your Profile Info
        </h2>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={cancelClickHandler}
        >
          <span aria-hidden="true">
            <i className="fabicon-close"></i>
          </span>
        </button>
      </div>
      <div className="modal-body">
        <Formik
          enableReinitialize={true}
          initialValues={userInfo}
          validationSchema={AddAuth0UserProfileSchema}
          onSubmit={addProfileInfoFormSubmitHandler}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            setFieldValue,
            errors,
            touched,
          }) => (
            <React.Fragment>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-group col-md-12">
                    <div className="patient__flex mg-b-10">
                      <small>First Name</small>
                      <div className="input-group patient__flex--row">
                        <input
                          type="text"
                          className={`form-control width__34 ${
                            errors.firstName ? 'is-invalid' : ''
                          }`}
                          id="firstName"
                          name="firstName"
                          placeholder="First Name"
                          onChange={handleChange}
                          value={values.firstName}
                        />
                      </div>
                    </div>

                    <div className="patient__flex mg-b-10">
                      <small>Last Name</small>
                      <div className="input-group patient__flex--row">
                        <input
                          type="text"
                          className={`form-control width__34 ${
                            errors.lastName ? 'is-invalid' : ''
                          }`}
                          id="lastName"
                          name="lastName"
                          placeholder="Last Name"
                          onChange={handleChange}
                          value={values.lastName}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  className="btn btn-red"
                  style={{ marginRight: '10px' }}
                >
                  Register my profile
                </button>
              </form>
            </React.Fragment>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ modals, users }) => ({
  setModal: modals.set,
  modalType: modals.type,
  modalData: modals.data,
  user: users.editableUser
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setModalWithType,
      addHmdDevice,
      addUserFromAuth0,
      getUserForEdit
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddAuth0UserModel);
