import { FAQ, NOTES } from '../shared/constants/actions.constants';

const initialState = {
  faqList: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FAQ.SET_FAQ_LIST:
      return {
        ...state,
        faqList: action.payload,
      };
    case FAQ.DELETE_FAQ:
      return {
        ...state,
        faqList: state.faqList.filter(faq => faq.id !== action.payload),
      };
    default:
      return state;
  }
}
