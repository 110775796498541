import { useCallback, useDebugValue, useEffect, useRef, useState } from 'react';

export const usePagination = (isLoading, callBack, options = {}
) => {
  const {
    defaultLimit = 10,
    defaultSortOption = -1,
    defaultCurrentPage = 1
  } = options;
  
  const limit = defaultLimit;
  const [sortOption, setSortOption] = useState(defaultSortOption);

  const [currentPage, setCurrentPage] = useState(defaultCurrentPage);
  const [totalPage, setTotalPage] = useState(1);


  let observer = useRef(null);
  
  const lastElementRef = useCallback(
    node => {
      if (isLoading) return;
      if (observer?.current?.disconnect) observer.current.disconnect();
      observer.current = new IntersectionObserver(async entries => {
        if (entries[0].isIntersecting) {
          setCurrentPage(prev => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading],
  );

  useEffect(() => {
    (async () => {
      try {
        if (currentPage <= totalPage) {
          const data = await callBack({limit, sortOption, currentPage})
          if(data?.page){
            setTotalPage(await data.page);
          }
        }
      } catch (e) {
        console.log(e);
      }
    })()
  }, [currentPage]);

  useDebugValue({currentPage, setCurrentPage,totalPage, setTotalPage, lastElementRef, limit, sortOption});

  return {currentPage, setCurrentPage,totalPage, setTotalPage, lastElementRef, limit, sortOption, setSortOption}
};

export default usePagination
