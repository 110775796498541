import React from 'react'
import { toCamelCase } from "../../../../shared/utils/wrappers";

export const FollowChartBlock = (props) => {
    return (
        <div className="col-3">
            <div className="fliter__imgbox">
                <p className="mg-b-15 semiBold">{ props.chartObj && toCamelCase(props.chartObj.text) }</p>
                <img alt="" src="/assets/images/overview/graph-1.jpg" className="img-center" />
            </div>
        </div>
    )
}

export default FollowChartBlock;      
