import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Header, Sidebar } from "../components/shared"
import EditUserOptionsPanel from "../components/editUser/leftSection/editUserOptionsPanel"
import EditUserPanel from "../components/editUser/rightSection/editUserPanel"

const EditUserContainer = (props) => {

  useEffect(() => {
    window.heightcalculation()
  }, [])

  return (
    <div>
      <Header loggedInUser = { props.loggedInUser } />
      <main className="wrapper">
        <Sidebar />
        <div className="content-wrapper">
          <div className="content__inner">
            <EditUserOptionsPanel />
            <EditUserPanel  />
          </div>
        </div>
      </main>
    </div>
  )
}

const mapStateToProps = ({ auth }) => ({
  loggedInUser: auth.loggedInUser
})

export default connect(mapStateToProps)(EditUserContainer)
