import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FaqForm, FaqSchema } from '../../../models/faq.model';
import { addFaq, deleteFAQ, updateFaq } from '../../../actions/faq.action';
import { uploadFile } from '../../../actions/users.action';
import { setModalWithType } from '../../../actions/modal.action';
import { toCamelCase } from '../../../shared/utils/wrappers';

export const AddHelpQueryContent = props => {
  let [faqInitialData, setFaqInitialData] = useState(FaqForm);

  /**
   * @desc To handle user form submission.
   * @param {object} event
   */
  const addFaqFormHandler = async event => {
    if (props?.modalData?.faq) {
      await props.updateFaq(props?.modalData?.faq.id, {
        id: props?.modalData?.faq?.id,
        title: getFaqData(event).title,
        description: getFaqData(event).description,
        img: getFaqData(event).img,
      });
    } else {
      await props.addFaq(getFaqData(event));
    }
    props.setModalWithType(false);
  };

  /**
   * @desc To format user data for submission
   * @param {object} data
   * @returns user's object
   */
  const getFaqData = data => {
    let user = Object.assign({}, data);
    // delete user.description
    return user;
  };

  const handleFileUpload = async (event, setFieldValue) => {
    let formData = new FormData();
    for (let i = 0; i < event.target.files.length; i++) {
      formData.append('files', event.target.files[i]);
    }
    const uploadedFileUrl = await props.uploadFile(formData);
    setFieldValue('img', uploadedFileUrl);
  };


  useEffect(() => {
    if (props.modalData && props.modalData.faq) {
      setFaqInitialData(props.modalData.faq);
    }
  }, [props.modalData]);

  return (
    <React.Fragment>
      <div className="modal-header">
        <h2 className="modal-title" id="patient-modalLabel">
          {props?.modalData?.faq ? 'Edit' : 'Add'} FAQ
        </h2>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => props.setModalWithType(false)}
        >
          <span aria-hidden="true">
            <i className="fabicon-close"></i>
          </span>
        </button>
      </div>
      <div className="modal-body pd-b-30">
        <Formik
          enableReinitialize={true}
          initialValues={faqInitialData}
          validationSchema={FaqSchema}
          onSubmit={addFaqFormHandler}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            setFieldValue,
            errors,
            touched,
          }) => (
            <React.Fragment>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-group col-md-12">
                    <label>Title</label>
                    <textarea
                      id="title"
                      name="title"
                      type="text"
                      className={`form-control ${
                        errors.title && touched.title ? 'is-invalid' : null
                      }`}
                      placeholder="Enter Title"
                      onChange={handleChange}
                      value={values.title}
                    />
                    {errors.title && touched.title ? (
                      <div className="invalid-feedback">{errors.title}</div>
                    ) : null}
                  </div>
                  <div className="form-group col-md-12">
                    <label>Attachment</label>
                    <br />
                    <input
                      id="files"
                      name="files"
                      multiple={false}
                      type="file"
                      placeholder="Please Select Files."
                      accept="image/*"
                      onChange={e=>handleFileUpload(e, setFieldValue)}
                    />
                    {errors.setFieldValue && touched.setFieldValue ? (
                      <div className="invalid-feedback">{errors.files}</div>
                    ) : null}
                  </div>

                  <div className="form-group col-md-12">
                    <label>Description</label>
                    <textarea
                      id="description"
                      name="description"
                      type="text"
                      className={`form-control ${
                        errors.description && touched.description
                          ? 'is-invalid'
                          : null
                      }`}
                      style={{ minHeight: '500px' }}
                      placeholder="Enter Description"
                      onChange={handleChange}
                      value={values.description}
                    />
                    {errors.description && touched.description ? (
                      <div className="invalid-feedback">
                        {errors.description}
                      </div>
                    ) : null}
                  </div>
                </div>
                <button type="submit" className="btn btn-red">
                  {props?.modalData?.faq ? 'Edit' : 'Add'}
                </button>
              </form>
            </React.Fragment>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addFaq,
      setModalWithType,
      updateFaq,
      uploadFile,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(AddHelpQueryContent);
