import { NOTES } from "../shared/constants/actions.constants"

const initialState = {
  notes: null
}

export default function(state = initialState, action) {

  switch (action.type) {

    case NOTES.SET_NOTES:
      return {
        ...state,
        notes: action.payload
      }

    default:
      return state
  }
}
