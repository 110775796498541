import React, { useState, useEffect } from 'react'
import { parseDate } from "../../../../shared/utils/wrappers";

export const FilterOptions = (props) => {
    const [assessments, setAssessments] = useState([])

    useEffect(() => {
        if (props.patient && props.assessments && Object.keys(props.assessments).length > 0 && props.assessments[props.patient.id]) {
            setAssessments(props.assessments[props.patient.id])
        } else {
            setAssessments([])
        }
    }, [props.patient, props.assessments])

    return (
        <div className="evaluation-filter js-evaluation-filter active">
            <div className="filter-box">
            <div className="filter-50 left"><p className="filter-head text-center">OS</p></div>
            <div className="filter-50"><p className="filter-head text-center">OD</p></div>
            </div>
            <div className="evaluation-filter--scroll mcustomscrollbar">
                {
                    assessments && assessments.map((assessment, i) => (
                        <div className="filter-box" onClick = { () => props.onFilterOptionSelect(assessment, props.patient) }>
                            <div className="filter-50 left"><p className="filter-text">{ parseDate(assessment.visitedOn) }</p></div>
                            <div className="filter-50"><p className="filter-text text-right">{ assessment.configuration && assessment.configuration.grid }</p></div>
                            <div className="filter-50 left"><img alt="" src="/assets/images/left-dB-greyscale.png" className="img-center" /></div>
                            <div className="filter-50"><img alt="" src="/assets/images/right-dB-greyscale.png" className="img-center" /></div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default FilterOptions
