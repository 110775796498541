import React from 'react'

export const WorklistPatientSearchPanel = (props) => {
  return (
    <div className="patient__main patient__main--left patient__main-nofilter">
        <h3 className="mt-2 mb-2">Patient List</h3>
        <ul>
          <li>
            <input type="text" placeholder="Firt Name, Last Name, ID" className="form-control" onInput={ props.localSearch } />
          </li>
          <li></li>
        </ul>
    </div>
  )
}

export default React.memo(WorklistPatientSearchPanel)
