import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setModalWithType } from '../../../actions/modal.action';
import { addHmdDevice } from '../../../actions/hmdDevices.action';
import { Formik } from 'formik';
import * as Yup from 'yup';

export const AddHmdSchema = Yup.object().shape({
  name: Yup.string().required('HMD name is required'),
  deviceId: Yup.string().required('HMD device id is required'),
});

export const AddHmdDeviceModal = props => {
  const [addHmdForm, setAddHmdForm] = useState({
    name: '',
    deviceId: '',
  });


  const cancelClickHandler = () => {
    props.setModalWithType(false);
  };


  const addHmdFormSubmitHandler = values => {
    props.addHmdDevice(values);
  }

  return (
    <React.Fragment>
      <div className="modal-header">
        <h2 className="modal-title" id="patient-modalLabel">
          Add Device
        </h2>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={cancelClickHandler}
        >
          <span aria-hidden="true">
            <i className="fabicon-close"></i>
          </span>
        </button>
      </div>
      <div className="modal-body">
        <Formik
          enableReinitialize={true}
          initialValues={addHmdForm}
          validationSchema={AddHmdSchema}
          onSubmit={addHmdFormSubmitHandler}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            setFieldValue,
            errors,
            touched,
          }) => (
            <React.Fragment>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-group col-md-12">
                    <div className="patient__flex mg-b-10">
                      <small>Device Name</small>
                      <div className="input-group patient__flex--row">
                        <input
                          type="text"
                          className={`form-control width__34 ${
                            errors.name ? 'is-invalid' : ''
                          }`}
                          id="name"
                          name="name"
                          placeholder="Device Name"
                          onChange={handleChange}
                          value={values.name}
                        />
                      </div>
                    </div>

                    <div className="patient__flex mg-b-10">
                      <small>Device Id</small>
                      <div className="input-group patient__flex--row">
                        <input
                          type="text"
                          className={`form-control width__34 ${
                            errors.deviceId ? 'is-invalid' : ''
                          }`}
                          id="deviceId"
                          name="deviceId"
                          placeholder="Device Id"
                          onChange={handleChange}
                          value={values.deviceId}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  className="btn btn-red"
                  style={{ marginRight: '10px' }}
                >
                  Add new Device
                </button>
              </form>
            </React.Fragment>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setModalWithType,
      addHmdDevice
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(AddHmdDeviceModal);
