import React from 'react'
import UserListRow from "./userListRow"
import { Scrollbars } from 'react-custom-scrollbars'

export const UsersListPanel = (props) => {
  return (
    <Scrollbars>
        <div className="tableCntrl tableCntrl--left">
          <div className="table mg-b-0 table-hover">
            <div className="thead">
              <div className="trow">
                <div className="tcol w-30"><a href="#!" className="filter" onClick = { props.onUserArrowClick }>User Name <i className={props.isUserAsc ? "icon-arrow-down-lg eadieLightGray1-color" : "fabicon-arrow-up-lg eadieLightGray1-color"}></i></a></div>
                <div className="tcol w-30">Email</div>
                <div className="tcol w-15">Role</div>
                <div className="tcol w-25"></div>
              </div>
            </div>
            <div className="tbody mcustomscrollbar table-hover js-usertable">
              {
                Object.keys(props.users || {}).map((userId) => (
                    <UserListRow 
                      key = { userId }
                      user = { props.users[userId] }
                      activeUser = { props.activeUser }
                      onUserRowClick = { props.onUserRowClick }
                    />
                ))
              }
            </div>
          </div>
        </div>
    </Scrollbars>
    
  )
}

export default UsersListPanel
