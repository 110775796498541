import { roundToNearestQuarter, toCamelCase } from "./wrappers";

export const patientFullName = (patient) => {
    if (!patient) return "";
    const { firstName, middleName, lastName } = patient;
    return `${toCamelCase(firstName)} ${toCamelCase(middleName)} ${toCamelCase(lastName)}`;
};

export const showPositiveOrNegative = (number, toFixed) => {
    if (isNaN(number)) {
        return '0.00';
    } else {
        const formattedNumber = number.toFixed(toFixed);
        return number < 0 ? formattedNumber : `+${formattedNumber}`;
    }
};

export const showInAtLeastThreeDigits = (number) => {
    if (isNaN(number)) {
        return '000'; 
    } else if (number < 10) {
        return `00${number}`;
    } else if (number < 100) {
        return `0${number}`;
    } else {
        return `${number}`;
    }
};

export const patientRefractionDetails = (configuration, OSOD) => {
    if (!configuration) return "";
    if (OSOD === 'OS') {
        return `${roundToNearestQuarter(configuration.osSphere, 2)} ${roundToNearestQuarter(configuration.osCylinder, 2)} x${showInAtLeastThreeDigits(configuration.osAxis)}`;
    } else if (OSOD === 'OD') {
        return `${roundToNearestQuarter(configuration.odSphere, 2)} ${roundToNearestQuarter(configuration.odCylinder, 2)} x${showInAtLeastThreeDigits(configuration.odAxis)}`;
    }
    return "";
};

export const patientTrialLensDetails = (configuration, OSOD) => {
    if (!configuration) return "";
    if (OSOD === 'OS') {
        return `${roundToNearestQuarter(configuration.trialLensOSsphere, 2)} ${roundToNearestQuarter(configuration.trialLensOScylinder, 2)} x${showInAtLeastThreeDigits(configuration.trialLensOSaxis)}`;
    } else if (OSOD === 'OD') {
        return `${roundToNearestQuarter(configuration.trialLensODsphere, 2)} ${roundToNearestQuarter(configuration.trialLensODcylinder, 2)} x${showInAtLeastThreeDigits(configuration.trialLensODaxis)}`;
    }
    return "";
};
