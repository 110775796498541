import React from 'react'
import { patientFullName } from "../../../shared/utils/patient"
import { parseDate } from "../../../shared/utils/wrappers"
import { TOOLTIP_TEXTS } from "../../../shared/constants/shared.constants";
import { CustomTooltip } from "../../shared/tooltip";

export const WorklistNonPatientListRow = (props) => {
    return (
        <div className="trow" key={props.patient.patientId}>
            <div className="tcol w-40">{ patientFullName(props.patient) }</div>
            <div className="tcol w-30">{ parseDate(props.patient.dateOfBirth) }</div>
            <div className="tcol w-25">{ props.patient.patientId }</div>
            <CustomTooltip 
                text = { TOOLTIP_TEXTS.ADD_PATIENT_TO_WORKLIST }
            >
                <div className="tcol w-5 text-right">
                    <i className="fabicon-move-right cursor-pointer" onClick = { () => props.onRightArrowClick(props.patient.id) }></i>
                </div>
            </CustomTooltip>
        </div>
    )
}

export default WorklistNonPatientListRow
