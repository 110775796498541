import React, { useEffect } from "react"
import { connect } from "react-redux"
import Header from "../components/shared/header"
import Sidebar from "../components/shared/sidebar"
import PatientsPanel from "../components/patients/leftSection/patientPanel"
import PatientDetailsPanel from "../components/patients/rightSection/patientDetailsPanel"

const PatientsContainer = (props) => {

  useEffect(() => {
    window.heightcalculation()
  }, [])

  return (
    <div>
      <Header loggedInUser = { props.loggedInUser } />
      <main className="wrapper">
        <Sidebar />
        <div className="content-wrapper">
          <div className="content__inner">
            <PatientsPanel />
            <PatientDetailsPanel />
          </div>
        </div>
      </main>
    </div>
  )
}

const mapStateToProps = ({ auth }) => ({
  loggedInUser: auth.loggedInUser
})

export default connect(mapStateToProps)(PatientsContainer)
