export const hmdTimerInterval = (testStartedAt, setTimer) => {
  setInterval(() => {
    let timeStart = new Date(testStartedAt).getTime()
    let timeEnd = new Date().getTime()
    let hourDiff = timeEnd - timeStart //in ms
    let secDiff = hourDiff / 1000 //in s
    let minDiff = hourDiff / 60 / 1000 //in minutes
    let hDiff = hourDiff / 3600 / 1000 //in hours
    setTimer(`${Math.floor(hDiff)}:${Math.floor(minDiff - 60 * (Math.floor(hDiff)))}:${Math.floor(secDiff %60)}`)
  }, 1000)
}

export const hmdTimeElapsed = (testStartedAt) => {
    let timeStart = new Date(testStartedAt).getTime()
    let timeEnd = new Date().getTime()
    let hourDiff = timeEnd - timeStart //in ms
    let secDiff = hourDiff / 1000 //in s
    let minDiff = hourDiff / 60 / 1000 //in minutes
    let hDiff = hourDiff / 3600 / 1000 //in hours
    return (`${Math.floor(hDiff)}:${Math.floor(minDiff - 60 * (Math.floor(hDiff)))}:${Math.floor(secDiff %60)}`)
}

export const OS_OD = {
  left: "OS",
  right: "OD"
};

export const getHmdAssessmentPercentage = ( assessment, prev = 0 ) => {
  if (assessment?.configuration.eye) {
    const maxLength = {
      '10-2': 68,
      '24-2': 54,
      '24-2C': 64,
      '30-2': 76,
    }
    const raw = assessment.data?.raw;
    const eyeIndex = OS_OD[assessment.configuration.eye];
    const sensitivityLength = raw?.[eyeIndex]?.length || 0;
    const process = ((sensitivityLength / maxLength[assessment.configuration.grid]) * 100).toFixed(0);
    return process;
  } else {
    if ([0, 100].includes(prev)) {
      // set 100% when assessment is completed and then make it 0 again
      return 0;
    } else {
      return 100;
    }
  }
};
