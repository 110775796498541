import { createStore, applyMiddleware, compose } from "redux"
import thunk from "redux-thunk"
import rootReducer from "../reducers"

let initialState = {}
let middleware = [thunk]
let CONSTANTS = {}
let Store

if (process.env.NODE_ENV === "development") {
    Store = createStore(
        rootReducer,
        initialState, 
        compose(
            applyMiddleware(...middleware),
            window.__REDUX_DEVTOOLS_EXTENSION__
            ? window.__REDUX_DEVTOOLS_EXTENSION__()
            : args => args,
        )
    )
} else {
    Store = createStore(
        rootReducer,
        initialState, 
        compose(
          applyMiddleware(...middleware)
        )
    )
}

if (process.env.NODE_ENV === "development") {
    CONSTANTS = {
        ASSESSMENTS_SOCKET_URL: "http://localhost:8082/assessments",
        ASSESSMENTS_SOCKET_URL: "http://192.168.0.115:8082/assessments"
    }
} else {
    CONSTANTS = {
        ASSESSMENTS_SOCKET_URL: "https://api-ui.eadietech.com/assessments"
    }
}

export default {
    Store,
    CONSTANTS
}