import React, { useState, useEffect } from 'react';
import NoRecord from '../../shared/noRecord';
import { toCamelCase, parseDate } from '../../../shared/utils/wrappers';
import { NO_RECORD_MSG } from '../../../shared/constants/shared.constants';
import { Scrollbars } from 'react-custom-scrollbars';
import { GRID_TYPES } from '../../../models/assessment.model';

export const PatientAssessmentsList = props => {
  let [isAsc, setIsAsc] = useState(false);
  let [filterBy, setFilterBy] = useState('');
  let [assessments, setAssessments] = useState([]); 

  const toggleSort = () => {
    setIsAsc(!isAsc);
    props.fetchSortedList(isAsc ? -1 : 1);
  };

  const handleChange = value => {
    setFilterBy(value);

    if (value) {
      let filteredList = props.assessments.filter(
        assessment =>
          assessment &&
          assessment.configuration &&
          assessment.configuration.grid === value,
      );
      setAssessments(filteredList);
    } else {
      setAssessments(props.assessments);
    }
  };

  useEffect(() => {
    setAssessments(props.assessments);
  }, [props.assessments]);

  return (
    <React.Fragment>
      <div className="patient__table">
        <div className="patient__filter">
          <h3>Test History</h3>
          <div className="dropdown">
            <button
              className="btn btn-transparent dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {filterBy || 'Filter by'}
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              {GRID_TYPES.map((grid, i) => (
                <a
                  className="dropdown-item"
                  href="#!"
                  key={i}
                  onClick={() => handleChange(grid)}
                >
                  {grid}
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="tableCntrl">
          <div className="table mg-b-0">
            <div className="thead">
              <div className="trow">
                <div className="tcol w-20">
                  <a href="#!" className="filter" onClick={() => toggleSort()}>
                    Date of Visit{' '}
                    <i
                      className={
                        isAsc
                          ? 'icon-arrow-down-lg eadieLightGray1-color'
                          : 'fabicon-arrow-up-lg eadieLightGray1-color'
                      }
                    ></i>
                  </a>
                </div>
                <div className="tcol w-20">Grid</div>
                <div className="tcol w-20">Eye</div>
                <div className="tcol w-10">Parameter</div>
                <div className="tcol w-20 text-center">Reliability</div>
                <div className="tcol w-10 text-center">Imported</div>
              </div>
            </div>
            <div className="tbody table-hover">
              <Scrollbars style={{ position: 'relative', minHeight: 100 }}>
                {assessments && assessments.length ? (
                  assessments.map((assessment, index) => {
                      return (
                        <div
                          className="trow cursor-pointer"
                          key={index}
                          onClick={() => props.openReports(assessment)}
                          ref={assessments.length === index + 1 ? props.lastElementRef: null}
                        >
                          <div className="tcol w-20">
                            {parseDate(assessment.visitedOn)}
                          </div>
                          <div className="tcol w-20">
                            {toCamelCase(assessment.configuration.grid)}
                          </div>
                          <div className="tcol w-20">
                            {toCamelCase(assessment.configuration.eye)}
                          </div>
                          <div className="tcol w-10">-</div>
                          <div className="tcol w-20 text-center">-</div>
                          <div className="tcol w-10 text-center">-</div>
                        </div>
                      );
                  })
                ) : (
                  <NoRecord msg={NO_RECORD_MSG.NO_TEST_FOUND} />
                )}
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PatientAssessmentsList;
