import React, { useEffect, useState } from "react"

export const Pagination = (props) => {
    let [ pages, setPages] = useState([])
    let [ paginationTagRange ] = useState(3)
    
    useEffect(() => {
        if (!isValidDataForPagination()) return
        
        let slotNo = Math.ceil(props.currentPage / paginationTagRange)
        setActiveSlotPages(+slotNo, +props.currentPage)
    }, [props.currentPage, props.totalPages])
    
    const setActiveSlotPages = (slotNo) => {
        let p = []
        let endPg = slotNo * paginationTagRange
        let startPg = endPg - paginationTagRange + 1
        if (endPg > props.totalPages) endPg = +props.totalPages

        while (startPg <= endPg) {
            p.push(startPg++)
        }
        setPages(p)
    }

    const isValidDataForPagination = () => {
        return +props.totalPages > 0 && +props.currentPage > 0 && +props.currentPage <= +props.totalPages
    }

    const onPageSelect = (pageNo) => {
        props.onPageChange(pageNo)
    }

    const onLeftArrowClick = () => {
        if ((props.currentPage - 1) > 0) {
            props.onPageChange(props.currentPage - 1)
        }
    }

    const onRightArrowClick = () => {
        if ((+props.currentPage + 1) <= props.totalPages) {
            props.onPageChange(+props.currentPage + 1)
        }
    }

    return (
        <React.Fragment>
            {
                isValidDataForPagination() && (
                    <ul className = "pagination justify-content-center pt-3 pb-3">
                        {
                            
                            <li className = { `page-item ${ !(props.currentPage > 1) && 'disabled' }` } onClick = { onLeftArrowClick }>
                                <a className = "page-link" href="#!" aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                </a>
                            </li>
                        }
                        {
                            pages.map((pg, i) => (
                                <li className = { `page-item ${ pg === props.currentPage ? 'active' : '' }` } onClick = { () => onPageSelect(pg) } key = { i }><a className = "page-link" href="#!">{ pg }</a></li>
                            ))
                        }
                        {
                            <li className = { `page-item ${ !(props.currentPage < props.totalPages) && 'disabled' }` } onClick = { onRightArrowClick }>
                                <a className = "page-link" href="#!" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                </a>
                            </li>
                        }   
                    </ul>
                )
            }
        </React.Fragment>
    )
}

export default Pagination
