import React, { useEffect, useState, useRef } from 'react'
import { useParams, withRouter, useLocation } from "react-router-dom"
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import AssessmentDetails from '../shared/assessment/assessmentDetails'
import AssessmentConfigurationCollaboration from './bottomSection/assessmentConfigurationCollaboration'
import { addAssessment, updateAssessment, startAssessment, clearStateAfterAssessmentConf, getAssessmentsByPatientId, getAssessmentById, selectAssessmentForForm } from "../../actions/assessment.action"
import { getPatientById } from "../../actions/patients.action"
import { AssessmentConfigurationForm, AssessmentConfigurationSchema, AssessmentCalibrationForm, AssessmentCalibrationSchema } from "../../models/assessment.model"
import * as AssessmentService from "../../services/assessment/assessment.service"
import { SHARED } from "../../shared/constants";
import { ASSESSMENT_STATUSES } from "../../shared/constants/shared.constants";
import { DYNAMIC_ROUTES } from "../../shared/constants/routes.constants";
import * as FlashMessage from "../../shared/utils/flashMessage";
import { SUCCESS } from '../../shared/constants/messages.constants'
import { roundToNearestQuarter } from '../../shared/utils/wrappers'

export const AssessmentPanel = ({ history, ...props }) => {
  const limit = 5
  let location = useLocation()

  let [assessmentCalibrationFormData, setAssessmentCalibrationFormData] = useState({})
  let [assessmentConfigureFormData, setAssessmentConfigureFormData] = useState({})

  let [isAssessmentCalibrationFormValid, setAssessmentCalibrationFormValidStatus] = useState(false)
  let [isAssessmentConfigureFormValid, setAssessmentConfigureFormValidStatus] = useState(false)

  let [trialLensOSsphere, setTrialLensOSsphere] = useState(0)
  let [trialLensOScylinder, setTrialLensOScylinder] = useState(0)
  let [trialLensOSaxis, setTrialLensOSaxis] = useState(0)

  let [trialLensODsphere, setTrialLensODsphere] = useState(0)
  let [trialLensODcylinder, setTrialLensODcylinder] = useState(0)
  let [trialLensODaxis, setTrialLensODaxis] = useState(0)

  let [vertexDistance, setVertexDistance] = useState(13.5)

  let [initiateTest, setInitiateTest] = useState(false);
  
  const interval = useRef();
  let { patientId = null } = useParams()

  let [assessmentConfiguration, setAssessmentConfiguration] = useState(AssessmentConfigurationForm)
  let [assessmentCalibration, setAssessmentCalibration] = useState(AssessmentCalibrationForm)
  assessmentConfiguration.patientId = patientId

  const calculateTrialLensHandler = () => {
    const convertCylinderFormat = (sphere, cylinder, axis, targetFormat = '+') => {
      if(!targetFormat) {
        return { sphere, cylinder, axis }
      }
      let newSphere = Number(sphere);
      let newCylinder = Number(cylinder);
      let newAxis = Number(axis);
    
      if (targetFormat === "+") {
        if (newCylinder < 0) {
          newSphere = newSphere + newCylinder;
          newCylinder = -newCylinder;
          newAxis = (newAxis + 90) % 180;
        }
      } else if (targetFormat === "-") {
        if (newCylinder > 0) {
          newSphere = newSphere + newCylinder;
          newCylinder = -newCylinder;
          newAxis = (newAxis + 90)  % 180;          
        }
      }
    
      return { sphere: newSphere, cylinder: newCylinder, axis: newAxis };
    };
    
      if(assessmentConfigureFormData && assessmentConfigureFormData.configuration){
        const vertexDistance = (Number(assessmentConfigureFormData.configuration.vertexDistance) || 0);
        // os
        const osSphere = (Number(assessmentConfigureFormData.configuration.osSphere) || 0);
        const osCylinder = (Number(assessmentConfigureFormData.configuration.osCylinder) || 0);
        const osAxis = (Number(assessmentConfigureFormData.configuration.osAxis) || 0);
        const osSphericalEquivalent = osSphere + osCylinder/2;
        const osEffectivePower = ((osSphericalEquivalent*osSphericalEquivalent)/1000)*(22-vertexDistance)

        if(osCylinder >= 1.75){
          assessmentConfigureFormData.configuration.trialLensOSsphere = osSphere
          assessmentConfigureFormData.configuration.trialLensOScylinder = osCylinder
          assessmentConfigureFormData.configuration.trialLensOSaxis = osAxis
        } else {
          assessmentConfigureFormData.configuration.trialLensOSsphere = osSphericalEquivalent
          assessmentConfigureFormData.configuration.trialLensOScylinder = osCylinder
          assessmentConfigureFormData.configuration.trialLensOSaxis = osAxis
        }

        if(osEffectivePower > 0.125){
          assessmentConfigureFormData.configuration.trialLensOSsphere = osSphericalEquivalent - osEffectivePower
        }
        // const osConversion = convertCylinderFormat(assessmentConfigureFormData.configuration.osSphere, assessmentConfigureFormData.configuration.osCylinder, assessmentConfigureFormData.configuration.osAxis, assessmentConfigureFormData.configuration.selectedCylinderFormat)
        const osConversion = convertCylinderFormat(assessmentConfigureFormData.configuration.trialLensOSsphere, assessmentConfigureFormData.configuration.trialLensOScylinder, assessmentConfigureFormData.configuration.trialLensOSaxis, assessmentConfigureFormData.configuration.selectedCylinderFormat)

        const newOS_Sphere = osConversion.sphere;
        const newOS_Cylinder = osConversion.cylinder;
        const newOS_Axis = osConversion.axis;

        // const newOS_Sphere = assessmentConfigureFormData.configuration.trialLensOSsphere;
        // const newOS_Cylinder = assessmentConfigureFormData.configuration.trialLensOScylinder;
        // const newOS_Axis = assessmentConfigureFormData.configuration.trialLensOSaxis;

        setTrialLensOSsphere(newOS_Sphere)
        setTrialLensOScylinder(newOS_Cylinder)
        setTrialLensOSaxis(newOS_Axis)
        // od
        const odSphere = (Number(assessmentConfigureFormData.configuration.odSphere) || 0);
        const odCylinder = (Number(assessmentConfigureFormData.configuration.odCylinder) || 0);
        const odAxis = (Number(assessmentConfigureFormData.configuration.odAxis) || 0);
        const odSphericalEquivalent = odSphere + odCylinder/2;
        const odEffectivePower = ((odSphericalEquivalent*odSphericalEquivalent)/1000)*(22-vertexDistance)


        if(odCylinder >= 1.75){
          assessmentConfigureFormData.configuration.trialLensODsphere = odSphere
          assessmentConfigureFormData.configuration.trialLensODcylinder = odCylinder
          assessmentConfigureFormData.configuration.trialLensODaxis = odAxis
        } else {
          assessmentConfigureFormData.configuration.trialLensODsphere = odSphericalEquivalent
          assessmentConfigureFormData.configuration.trialLensODcylinder = odCylinder
          assessmentConfigureFormData.configuration.trialLensODaxis = odAxis
        }
        if(odEffectivePower > 0.125){
          assessmentConfigureFormData.configuration.trialLensODsphere = odSphericalEquivalent - odEffectivePower
        }

        // const odConversion = convertCylinderFormat(assessmentConfigureFormData.configuration.odSphere, assessmentConfigureFormData.configuration.odCylinder, assessmentConfigureFormData.configuration.odAxis, assessmentConfigureFormData.configuration.selectedCylinderFormat)
        const odConversion = convertCylinderFormat(assessmentConfigureFormData.configuration.trialLensODsphere, assessmentConfigureFormData.configuration.trialLensODcylinder, assessmentConfigureFormData.configuration.trialLensODaxis, assessmentConfigureFormData.configuration.selectedCylinderFormat)

        const newOD_Sphere = odConversion.sphere;
        const newOD_Cylinder = odConversion.cylinder;
        const newOD_Axis = odConversion.axis;

        // const newOD_Sphere = assessmentConfigureFormData.configuration.trialLensODsphere;
        // const newOD_Cylinder = assessmentConfigureFormData.configuration.trialLensODcylinder;
        // const newOD_Axis = assessmentConfigureFormData.configuration.trialLensODaxis;

        setTrialLensODsphere(newOD_Sphere)
        setTrialLensODcylinder(newOD_Cylinder)
        setTrialLensODaxis(newOD_Axis)
        setVertexDistance(assessmentConfigureFormData.configuration.vertexDistance)
      }
  }

  useEffect(() => {
    if(props.assessment && props.assessment.id) {
      if(props.assessment.status === ASSESSMENT_STATUSES.START_VFT) {
        history.push(DYNAMIC_ROUTES.INITIATE_ASSESSMENT(patientId, props.assessment.id))
        FlashMessage.success(SUCCESS.START_ASSESSMENT)
      } else {
        if(interval.current){
          clearInterval(interval.current)
        }
      }
    }

  }, [props.assessment])

  useEffect(() => {
    if([ASSESSMENT_STATUSES.CALIBRATE, ASSESSMENT_STATUSES.TRIAL, ASSESSMENT_STATUSES.TRIAL_COMPLETED].includes(props.assessment?.status) && props.assessment?.id) {
      // start observing assessment
      getAssessmentDetailsByPolling(props.assessment.id, props.assessment.hmdId);
    }
  }, [props.assessment])
  
  useEffect(() => {
    setAssessmentConfiguration(AssessmentConfigurationForm)
    setAssessmentCalibration(AssessmentCalibrationForm)
    setAssessmentConfigureFormData(AssessmentConfigurationForm)
  }, [])

  useEffect(() => {
    let searchParams = new URLSearchParams(location.search).get("recalibrateId")
    if ((searchParams || "").length) {
      onRecalibrateQueryParam(searchParams)
    }
  }, [location])

  const onRecalibrateQueryParam = async (recalibrateId) => {
    try {
      let { data } = await AssessmentService.getAssessmentById(recalibrateId)

      if (data && data.status === SHARED.ASSESSMENT_STATUSES.RECALIBRATE) {
        setPreviousConfiguration(data)
      }
    } catch {}
  }

  useEffect(() => {
      props.getPatientById(patientId)
      props.getAssessmentsByPatientId(patientId, limit, -1)

      return () => {
        props.clearStateAfterAssessmentConf();
        if(interval.current){
          clearInterval(interval.current)
        }
      }
  }, [])

  const assessmentConfigureTestFormHandler = (values) => {

    let modifiedValues = JSON.parse(JSON.stringify(values));
    modifiedValues.configuration.visualAcuity =`${values.configuration.visualAcuityPart1}/${values.configuration.visualAcuityPart2}`
    // delete modifiedValues.configuration.visualAcuityPart1
    // delete modifiedValues.configuration.visualAcuityPart2

    setAssessmentConfigureFormData(modifiedValues)

    AssessmentConfigurationSchema()
      .isValid(modifiedValues)
      .then((isValid) => {
        setAssessmentConfigureFormValidStatus(isValid)
        setInitiateTest(false)
      })
  }

  const assessmentCalibrateTestFormHandler = (values) => {
    setAssessmentCalibrationFormData(values)

    AssessmentCalibrationSchema()
      .isValid(values)
      .then((isValid) => {
        setAssessmentCalibrationFormValidStatus(isValid)
        setInitiateTest(false)
      })
  }

  const trialTestHandler = (callback)=> {
    if(isAssessmentCalibrationFormValid && isAssessmentConfigureFormValid) {
      if(props.assessment) {
        calculateTrialLensHandler();
        props.updateAssessment({ ...props.assessment, ...assessmentConfigureFormData, ...assessmentCalibrationFormData, status: ASSESSMENT_STATUSES.TRIAL }, callback)
      } else {
        calculateTrialLensHandler();
        props.addAssessment({ ...assessmentConfigureFormData, ...assessmentCalibrationFormData }, callback);
      }
      setInitiateTest(true)
    }
  }

  const getAssessmentDetailsByPolling = (assessmentId, hmdId) => {
    if(interval.current){
      clearInterval(interval.current)
    }

    interval.current = setInterval(()=>{
      props.getAssessmentById(assessmentId, false)
    }, 2500)
  }

  const startTestHandler = () => {
    if(props.assessment && props.assessment.id) {
      calculateTrialLensHandler();
      props.startAssessment( { patientId, assessmentId:props.assessment.id }, history )
      if(interval.current){
        clearInterval(interval.current)
      }
    }
  }

  const setPreviousConfiguration = async ({ patientId, configuration, calibration})=> {
    let configurationObj = {
      patientId,
      hmdId: assessmentConfigureFormData ? assessmentConfigureFormData.hmdId : "",
      configuration
    }
    let calibrationObj = { calibration }

    // setAssessmentConfiguration(prev=>({...AssessmentConfigurationForm.configuration, ...configurationObj}))
    props.selectAssessmentForForm({...configurationObj, timestamp: new Date().getTime()})
    setAssessmentCalibration(calibrationObj)

    assessmentConfigureTestFormHandler(configurationObj)
    assessmentCalibrateTestFormHandler(calibrationObj)
  }

  return (
    <React.Fragment>
      {
        props.patient && Object.keys(props.patient).length>0 && (
          <React.Fragment>
            <AssessmentDetails assessmentConfigureFormData={assessmentConfigureFormData} assessmentConfiguration={assessmentConfigureFormData} assessment={ props.assessment } patient={ props.patient[patientId]}/>
            <AssessmentConfigurationCollaboration 
              assessment={ props.assessment }
              isTrialTestAllowed={isAssessmentCalibrationFormValid && isAssessmentConfigureFormValid } 
              patient={ props.patient[patientId] } 
              hmdDevices={props.hmdDevices} 
              assessmentConfigureTestFormHandler={ assessmentConfigureTestFormHandler } 
              assessmentCalibrateTestFormHandler={ assessmentCalibrateTestFormHandler } 
              trialTestHandler={ trialTestHandler } 
              startTestHandler={ startTestHandler }
              initiateTest = { initiateTest }
              previousAssessments = { props.assessmentsByPatientId ? props.assessmentsByPatientId[patientId] : null }
              setPreviousConfiguration = { setPreviousConfiguration }
              assessmentConfiguration = { assessmentConfiguration }
              assessmentCalibration = { assessmentCalibration }
              calculateTrialLensHandler = { calculateTrialLensHandler }
              trialLensOSsphere = { trialLensOSsphere }
              trialLensOScylinder = { trialLensOScylinder }
              trialLensOSaxis = { trialLensOSaxis }
              trialLensODsphere = { trialLensODsphere }
              trialLensODcylinder = { trialLensODcylinder }
              trialLensODaxis = { trialLensODaxis }
              vertexDistance = { vertexDistance }
            />
          </React.Fragment>
        )
      }
    </React.Fragment>
  )
}

const mapStateToProps = ({ patients, ui, hmdDevices, assessment }) => ({
  patient: patients.patientsObj,
  hmdDevices: hmdDevices.hmdDeviceList,
  assessment: assessment.activeAssessment,
  assessmentsByPatientId: assessment.activeAssessmentsByPatient,
})

const mapDispatchToProps = dispatch => (
  bindActionCreators({ 
    getPatientById,
    addAssessment,
    updateAssessment,
    startAssessment,
    getAssessmentsByPatientId,
    getAssessmentById,
    clearStateAfterAssessmentConf,
    selectAssessmentForForm
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AssessmentPanel))
