import * as moment from 'moment'
import { showPositiveOrNegative } from './patient'

export const toCamelCase = (string = "") => {
    return string.toLowerCase().split(' ').map(word => word && word.replace(word[0], word[0].toUpperCase())).join(' ')
}

export const parseDate = (date) => {
    try {
        return date ? moment(date).format('DD/MM/YYYY') : ""
    } catch (error) {
        return ""
    }
}

export const parseDateAndTime = (date) => {
    try {
        return date ? moment(date).format('DD/MM/YY HH:mm A') : ""
    } catch (error) {
        return ""
    }
}

export const parseDateWithMonth = (date) => {
    try {
        return date ? moment(date).format('ll') : ""
    } catch (error) {
        return ""
    }
}

export const parseTime = (date) => {
    try {
        return date ? moment(date).format('LT') : ""
    } catch (error) {
        return ""
    }
}

export const datePickerParser = (date, format = "") => {
    try {
        return (format && format.length) ? moment(date).format(format) : moment(date).toDate()
    } catch (error) {
        return moment(new Date()).toDate()
    }
}

export const calculateTimeDuration = (startDate, endDate) => {
    try {
        return moment.utc(moment(endDate).diff(moment(startDate))).format("mm:ss") // will always remain under 1 hour
    } catch (error) {
        return ''
    }
}

export const calculateAge = (dob) => {
    try {
        let age = moment.duration(moment().diff(moment(dob)));
        let years = age.years();
        return years
    } catch (error) {
        return ''
    }
}


export const toPercentage = (num =0, total=0) => {
    if (total === 0) {
      return ` (0%)`; // To avoid division by zero
    }
  
    const percentage = (num / total) * 100;
    return ` (${Math.round(percentage)}%)`;
  };

export const roundToNearestQuarter = (num = 0, toFixed = 0) => {
    return showPositiveOrNegative(Math.round(num * 4) / 4, toFixed);
}