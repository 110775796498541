import * as NotesService from "../services/notes/notes.service"
import * as FaqService from "../services/faq/faq.service"
import { FAQ, LOADER, NOTES } from "../shared/constants/actions.constants"
import * as FlashMessage from "../shared/utils/flashMessage"
import { SUCCESS, ERROR } from '../shared/constants/messages.constants'

/**
 * @desc To add new note 
 * @param {string} patientId
 * @param {string} assessmentId
 * @param {object} noteObj
 * @public
 */
export const addFaq = (noteObj) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true))

      let { data } = await FaqService.createFaq(noteObj)

      onSuccessAddFaq(dispatch, data)

      fetchFAQsAfterSave(dispatch)      
    } catch (error) {
      onErrorFaqAdd(dispatch, error)
    }
  }
}

/**
 * @desc To add new faq 
 * @param {string} faqId
 * @param {object} faqObj
 * @public
 */
export const updateFaq = (faqId, faqObj) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true))

      let { data } = await FaqService.updateFaq(faqId, faqObj)

      onSuccessAddFaq(dispatch, data)

      fetchFAQsAfterSave(dispatch)      
    } catch (error) {
      onErrorFaqAdd(dispatch, error)
    }
  }
}


/**
 * @desc To delete FAQ and update it into Redux Store
 * @public
 */
export const deleteFAQ = (faqId) => {
  return async (dispatch) => {
    try {
      dispatch(setLoader(true))

      await FaqService.deleteFaq(faqId)

      onSuccessFaqDelete(dispatch, faqId)
    } catch (error) {
      onErrorFaqDeviceDelete(dispatch, error)
    }
  }
}


/**
 * @desc To get all FAQ
 * @public
 */
export const getFAQs = () => {
  return async dispatch => {
    try {
      dispatch(setLoader(true))

      let { data } = await FaqService.getFAQ()

      onSuccessGetFaq(dispatch, data)
    } catch (error) {
      onErrorGetNotes(dispatch, error)
    }
  }
}

// Private Methods
/**
 * @desc Fetch all FAQ's after addition of note
 * @private
 */
const fetchFAQsAfterSave = async(dispatch) => {
    let { data } = await FaqService.getFAQ()
    dispatch(setAllFaqInStore(data))
}

/**
 * @desc Action to set loader state
 * @param {boolean} flag
 * @private
 */
const setLoader = (flag) => {
  return { 
    type: LOADER.SET_LOADER, 
    payload: flag
  }
}

/**
 * @desc success handler for created note
 * @param {object} dispatch
 * @param {object} noteObj
 * @private
 */
const onSuccessAddFaq = (dispatch, noteObj) => {
  dispatch(setLoader(false))
  FlashMessage.success(SUCCESS.FAQ_SUCCESSFULLY_UPDATED)
}

/**
 * @desc Error handler for created note
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorFaqAdd = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.FAQ_WAS_NOT_CREATED)
}

/**
 * @desc Success handler when FAQ's are fetched
 * @param {object} dispatch
 * @param {object} faqList
 * @private
 */
const onSuccessGetFaq = (dispatch, faqList) => {
  dispatch(setLoader(false))
  dispatch(setAllFaqInStore(faqList))
}

// /**
//  * @desc Success handler when notes are fetched
//  * @param {object} dispatch
//  * @param {object} notes
//  * @private
//  */
// const onSuccessGetNotes = (dispatch, notes) => {
//   dispatch(setLoader(false))
//   dispatch(setAllNotesInStore(notes))
//   FlashMessage.success(SUCCESS.NOTES_SUCCESSFULLY_FETCHED)
// }

/**
 * @desc Error handler when failed to fetch notes
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorGetNotes = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.NOTES_WAS_NOT_FETCHED)
}

/**
 * @desc Action to add FAQ in store
 * @param {array} faqlist
 * @private
 */
const setAllFaqInStore = (faqlist = []) => {
  return {
    type: FAQ.SET_FAQ_LIST,
    payload: faqlist
  }
}

const deleteFaqById = (faqId) => {
  return {
    type: FAQ.DELETE_FAQ,
    payload: faqId
  }
}

const onSuccessFaqDelete = (dispatch, faqId) => {
  dispatch(setLoader(false))
  dispatch(deleteFaqById(faqId))
}


const onErrorFaqDeviceDelete = (dispatch) => {
  dispatch(setLoader(false))
}