import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';

import './i18n';
import 'rc-slider/assets/index.css';
import './index.css';
import App from './App';
import { getConfig } from './auth0config';
import history from './history';

require('./middlewares/http-interceptor');

const config = getConfig();


const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  onRedirectCallback,
  redirectUri: window.location.origin,
    ...(config.audience ? { audience: config.audience } : null),
};

if (module.hot && process.env.NODE_ENV !== 'production') {
  module.hot.accept();
}

ReactDOM.render(
  <Auth0Provider {...providerConfig}>
    <App />
  </Auth0Provider>,
  document.getElementById('root'),
);
