import Axios from "axios"
import { API_CONSTANTS } from "../../shared/constants/api.constants"

/**
 * @desc To fetch patients list(with pagination)
 * @public
 */
export const getPatientsList = (searchText, dateRange, page = 1, limit = 20, sort = 0) => {
    return new Promise(async (res, rej) => {
        try {
            let params = { page, limit, sort }

            if (searchText) params = { ...params, searchText }

            if (dateRange && dateRange.fromDate && dateRange.toDate) params = { ...params, fromDate: dateRange.fromDate, toDate: dateRange.toDate }

            let { data } = await Axios.get(API_CONSTANTS.PATIENTS.GET_ALL_PATIENTS, { params })

            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
 * @desc To fetch patients list(with pagination)
 * @public
 */
export const getPatientById= (patientId) => {
    return new Promise(async (res, rej) => {
        try {

            let { data } = await Axios.get(API_CONSTANTS.PATIENTS.GET_PATIENT_BY_ID(patientId))

            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
 * @desc To fetch complete patients list (without pagination)
 * @public
 */
export const getCompletePatientsList = () => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.get(API_CONSTANTS.PATIENTS.GET_SIMPLE_PATIENTS_LIST)
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
 * @desc To create a new patient
 * @param {object} patientObj
 * @public
 */
export const createPatient = (patientObj) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.post(API_CONSTANTS.PATIENTS.CREATE_PATIENT, patientObj)
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
 * @desc To update a patient
 * @param {object} patientObj
 * @public
 */
export const updatePatient = (patientObj) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.put(`${API_CONSTANTS.PATIENTS.EDIT_PATIENT}/${patientObj.id}`, patientObj)
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
 * @desc To delete a patient
 * @param {object} patientId
 * @public
 */
export const deletePatient = (patientId) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.delete(`${API_CONSTANTS.PATIENTS.DELETE_PATIENT}/${patientId}`)
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
 * @desc To create a patient's import test
 * @param {object} patientObj
 * @public
 */
export const createPatientsImportTest = (patientId, importTestObj) => {
    return new Promise(async (res, rej) => {
        try {
            let formData = new FormData()

            if (importTestObj.files && Object.values(importTestObj.files).length) {
                Object.values(importTestObj.files).forEach((f) => {
                    formData.append('file', f)
                })
            }

            let { data } = await Axios.post(API_CONSTANTS.PATIENTS.CREATE_PATIENTS_IMPORT_TEST(patientId), formData)

            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}