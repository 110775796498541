import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  getAssessmentById,
  clearStateAfterAssessmentInitiate,
  getAssessmentsByPatientId,
} from '../../actions/assessment.action';
import { getPatientById } from '../../actions/patients.action';
import { updateChartSettingsOrder } from '../../actions/users.action';
import { useParams, useLocation, HashRouter } from 'react-router-dom';
import PatientDetails from './topSection/patientDetails';
import SingleView from './bottomSection/singleView/index';
import Overview from './bottomSection/overview/index';
import Progression from './bottomSection/progression/index';
import { setModalWithType } from '../../actions/modal.action';
import { MODALS } from '../../shared/constants/actions.constants';
import { REPORT_PANELS } from '../../shared/constants/shared.constants';
import { DYNAMIC_ROUTES } from '../../shared/constants/routes.constants';
import { FilterFooterPanel } from './bottomSection/filterFooter/filterFooterPanel';
import { FilterOptions } from './bottomSection/filterFooter/filterOptions';
import PrintAssessmentReport from './export/printReport.js'
import ReactToPrint, { PrintContextConsumer, useReactToPrint } from 'react-to-print';

export const AssessmentReportsPanel = props => {
  let { patientId } = useParams();
  let [showFilterOptions, setShowFilterOptions] = useState(false);
  let [assessmentRefs, setAssessmentRefs] = useState({});
  let [activePanel, setActivePanel] = useState(REPORT_PANELS.SINGLE_VIEW);

  useEffect(() => {
    fetchData(patientId);

    return () => {
      props.clearStateAfterAssessmentInitiate();
    };
  }, []);

  useEffect(() => {
    if (
      patientId &&
      props.assessmentsByPatientId &&
      Object.keys(props.assessmentsByPatientId).length > 0 &&
      props.assessmentsByPatientId[patientId]
    ) {
      let tmprefs = {};
      (props.assessmentsByPatientId[patientId] || []).forEach(assessment => {
        tmprefs[assessment.id] = React.createRef();
      });
      setAssessmentRefs(tmprefs);
    }
  }, [patientId, props.assessmentsByPatientId]);

  const fetchData = async patientId => {
    await props.getPatientById(patientId);
  };

  /**
   * @desc Handler for add worklist
   * @param {object} event
   */
  const showInModal = ({
    chartName,
    chartData,
    chartOrientation,
    transform,
    ...rest
  }) => {
    let modalState = {
      ...rest,
      header: 'Chart',
      size: 'lg',
      chartName: chartName,
      chartData: chartData,
      chartOrientation: chartOrientation,
      transform: transform || 0.6,
      assessment: props.activeAssessment
    };

    props.setModalWithType(true, MODALS.CHART_VIEW_MODAL, modalState);
  };

  const updateChartsOrder = settingsObj => {
    props.updateChartSettingsOrder(props.loggedInUser.id, settingsObj);
  };

  const onFilterClick = () => {
    setShowFilterOptions(!showFilterOptions);
  };

  const onFilterOptionSelect = (assessment, patient) => {
    if (activePanel === REPORT_PANELS.SINGLE_VIEW) {
      if (assessment && assessment.id && patient && patient.id) {
        props.history.push(
          DYNAMIC_ROUTES.REPORTS_BY_ASSESSMENT_ID(patient.id, assessment.id),
        );
      }
    } else if (activePanel === REPORT_PANELS.OVERVIEW) {
      if (assessmentRefs[assessment.id]) {
        assessmentRefs[assessment.id].current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  };

  const onTabChange = tabName => {
    setActivePanel(tabName);
    setShowFilterOptions(false);
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div className="content__inner content__main">
      <div className="content__center w-100 block__toggle position-relative" >
        <div className="patient__list evaluation__section" ref={componentRef}>
          <PatientDetails patient={props.patient[patientId] || {}} />
          <PrintAssessmentReport patient={props.patient[patientId] || {}} assessment={props.activeAssessment}/>

          <div className="evaluation__main h-100 d-print-none">
            <div className="evaluation__tab">
              <button className="btn btn-report d-print-none" onClick={handlePrint}>
                <i className="fabicon-Print"></i> Report
              </button>
             
              <div className="evaluation__tab--inners d-print-none">
                <ul
                  className="nav nav-pills nav__customtab"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="singleview-tab"
                      data-toggle="pill"
                      href="#singleview"
                      role="tab"
                      aria-controls="singleview"
                      aria-selected="true"
                      onClick={() => onTabChange(REPORT_PANELS.SINGLE_VIEW)}
                    >
                      Single View
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="overview-tab"
                      data-toggle="pill"
                      href="#overview"
                      role="tab"
                      aria-controls="overview"
                      aria-selected="false"
                      onClick={() => onTabChange(REPORT_PANELS.OVERVIEW)}
                    >
                      Overview
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="progression-tab"
                      data-toggle="pill"
                      href="#progression"
                      role="tab"
                      aria-controls="progression"
                      aria-selected="false"
                      onClick={() => onTabChange(REPORT_PANELS.PROGRESSION)}
                    >
                      Progression
                    </a>
                  </li>
                </ul>
                {activePanel === REPORT_PANELS.PROGRESSION && (
                  <div className="dropdown dropdown-filter w-176 mg-r-10">
                    <button
                      className="btn btn-outline-gray dropdown-toggle"
                      type="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Filter by
                    </button>
                    <div className="dropdown-menu dropdown-menu-right">
                      <a className="dropdown-item" href="#!">
                        Action
                      </a>
                      <a className="dropdown-item" href="#!">
                        Another action
                      </a>
                      <a className="dropdown-item" href="#!">
                        Something else here
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="evaluation__inner position-relative">
              <div className="evaluation__content js-evaluation__content  w-100">
                <div className="tab-content">
                  {activePanel === REPORT_PANELS.SINGLE_VIEW && (
                    <SingleView
                      activeAssessment={props.activeAssessment}
                      patient={props.patient[patientId] || {}}
                      showInModal={showInModal}
                      loggedInUser={props.loggedInUser}
                      updateChartSettingsOrder={updateChartsOrder}
                      setModalWithType={props.setModalWithType}
                      // forwardRef={componentRef}
                    />
                  )}
                  {activePanel === REPORT_PANELS.OVERVIEW &&  (
                    <Overview
                      assessments={props.assessmentsByPatientId}
                      patient={props.patient[patientId] || {}}
                      updateChartSettingsOrder={updateChartsOrder}
                      loggedInUser={props.loggedInUser}
                      setModalWithType={props.setModalWithType}
                      assessmentRefs={assessmentRefs}
                    />
                  )}
                  {activePanel === REPORT_PANELS.PROGRESSION && (
                    <Progression
                      assessments={props.assessmentsByPatientId}
                      patient={props.patient[patientId] || {}}
                      loggedInUser={props.loggedInUser}
                    />
                  )}
                </div>
              </div>
              {showFilterOptions && (
                <FilterOptions
                  assessments={props.assessmentsByPatientId}
                  patient={props.patient[patientId] || {}}
                  onFilterOptionSelect={onFilterOptionSelect}
                />
              )}
            </div>
          </div>
        </div>
        {([REPORT_PANELS.SINGLE_VIEW, REPORT_PANELS.OVERVIEW, REPORT_PANELS.PROGRESSION].includes(activePanel)) && (
          <FilterFooterPanel onFilterClick={onFilterClick} />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ patients, ui, assessment, auth }) => ({
  patient: patients.patientsObj,
  activeAssessment: assessment.activeAssessment,
  assessmentsByPatientId: assessment.activeAssessmentsByPatient,
  showDeviceSidebar: ui.showDeviceSidebar,
  loggedInUser: auth.loggedInUser || {},
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAssessmentById,
      getPatientById,
      setModalWithType,
      getAssessmentsByPatientId,
      clearStateAfterAssessmentInitiate,
      updateChartSettingsOrder,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(AssessmentReportsPanel));
