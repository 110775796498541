import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFAQs, deleteFAQ } from '../../actions/faq.action';
import { setModalWithType } from '../../actions/modal.action';
import { MODALS } from '../../shared/constants/actions.constants';

export const HelpPanel = props => {
  const addFaqContentButtonHandler = useCallback(() => {
    props.setModalWithType(true, MODALS.ADD_HELP_QUERY_CONTENT_MODAL, {
      backdrop: 'static',
      size: 'xl',
    });
  }, []);

  const editFaqContentButtonHandler = useCallback(faq => {
    let modalState = {
      actionType: MODALS.CONFIRMATION_MODAL_TO_DELETE_HMD_DEVICE,
      backdrop: 'static',
      size: 'xl',
      faq,
    };
    props.setModalWithType(
      true,
      MODALS.ADD_HELP_QUERY_CONTENT_MODAL,
      modalState,
    );
  }, []);

  const deleteFaqContentButtonHandler = faq => {
    let modalState = {
      actionType: MODALS.CONFIRMATION_MODAL_TO_DELETE_HMD_DEVICE,
      header: 'FAQ Deletion',
      body: `Are you sure you want to delete this info?`,
      backdrop: 'static',
      size: 'sm',
      actionType: MODALS.CONFIRMATION_MODAL_TO_DELETE_FAQ,
      faqId: faq.id,
    };

    props.setModalWithType(true, MODALS.CONFIRMATION_MODAL, modalState);
  };

  useEffect(() => {
    props.getFAQs();
  }, []);

  return (
    <React.Fragment>
      <div className="content__left patient__list">
        <div className="content__main h-100">
          <div className="content__top eadieSnow-bg js-heightcal">
            <div className="row patient__main patient__main--left patient__main-nofilter">
              <div className="col-sm-6">
                <input
                  type="text"
                  placeholder="Search question"
                  className="form-control"
                />
              </div>
              {props?.loggedInUser?.email === 'admin@email.com' ? (
                <div className="col-sm-6 profile-action">
                  <button
                    className="add__profile"
                    type="button"
                    data-toggle="modal"
                    data-target="#user-modal"
                    onClick={addFaqContentButtonHandler}
                  >
                    <i className="fabicon-help"></i>
                    <span>Add More FAQ</span>
                  </button>
                </div>
              ) : null}
            </div>
          </div>
          <div className="content__bottom eadieWhite-bg block__toggle js-heightleft mcustomscrollbar pd-y-0 pd-x-0 content--mcustomscrollbar">
            <ul
              className="nav nav-tabs flex-column nav--numbertab pd-x-25 pd-y-25"
              role="tablist"
            >
              {props?.faqList?.map((faq, index) => {
                return (
                  <li className="nav-item" key={'tab' + faq.id}>
                    <a
                      className={`nav-link d-flex justify-content-between ${
                        index === 0 ? 'active' : ''
                      }`}
                      id="collapse-tab"
                      data-toggle="tab"
                      href={`#collapse${faq.id}`}
                      role="tab"
                      aria-controls="collapse"
                      aria-selected="true"
                    >
                      <span className='text-justify'>{faq.title}</span>
                      {props?.loggedInUser?.email === 'admin@email.com' ? (
                        <div className='d-flex justify-content-center align-items-center mt-3 ml-2 mr-2'>
                          <div className="device__delete--btn position-static">
                            <button
                              type="button"
                              className="btn btn-red  ml-2"
                              onClick={() => editFaqContentButtonHandler(faq)}
                            >
                              <i className="fabicon-Edit"></i>
                            </button>
                          </div>
                          <div className="device__delete--btn position-static">
                            <button
                              type="button"
                              className="btn btn-red  ml-2"
                              onClick={() => {deleteFaqContentButtonHandler(faq);}}
                            >
                              <i className="fabicon-Delete"></i>
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      <div className="content__right">
        <div className="eadieSnow-bg js-heightright mcustomscrollbar content--mcustomscrollbar">
          <div className="tab-content help-tabcontent">
            {props?.faqList?.map((faq, index) => {
              return (
                <div
                  className={
                    'tab-pane fade show' + (index === 0 ? ' active' : '')
                  }
                  id={`collapse${faq.id}`}
                  role="tabpanel"
                  aria-labelledby="collapse-tab"
                  key={'tabContent' + faq.id}
                  style={{ textAlign: 'justify' }}
                >
                  <h2 className="semiBold">{faq.title}</h2>
                  {faq.img && <img
                    src={faq.img}
                    alt="faq"
                    className="img-fluid mg-t-15 mg-b-15"
                  />}
                  <pre
                    className="mg-t-15 mg-b-15 common-font h-100 w-100"
                    style={{
                      whiteSpace: 'pre-wrap',
                      maxHeight: '75vh',
                      lineHeight: '25px',
                      overflowX: 'hidden',
                    }}
                  >
                    {faq.description}
                  </pre>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ auth, users, ui, faq }) => ({
  loggedInUser: auth.loggedInUser,
  faqList: faq.faqList,
  activeUser: users.activeUser,
  showDeviceSidebar: ui.showDeviceSidebar,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setModalWithType,
      getFAQs,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(HelpPanel);
