import React, { useEffect, useCallback, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getPatients, addActivePatient, deletePatient, setPatientSearchText, setPatientListSort, setAssessmentDateRangeInPatientSearch, clearPatientSearchState } from '../../../actions/patients.action'
import { setModalWithType } from '../../../actions/modal.action'
import PatientSearch from "./patientSearch"
import PatientList from "./patientsList"
import { MODALS } from "../../../shared/constants/actions.constants"
import { DEBOUNCE_TIME } from "../../../shared/constants/shared.constants"
import { debounce } from "../../../shared/utils/debounce"
import { datePickerParser } from "../../../shared/utils/wrappers"

export const PatientPanel = (props) => {

  let [ patientSearchDebouncer ] = useState(null)
  const [isPatientListAsc, setPatientListAsc] = useState(true)

  useEffect(() => {
    props.getPatients(true)

    return () => {
      props.clearPatientSearchState()
    }
  }, [])

  /**
   * @desc Handler for particular patient row click
   * @param {object} patient
   */
  const onPatientRowClick = useCallback((patient) => {
    props.addActivePatient(patient)
  }, [])

  /**
   * @desc Handler for patient edit
   * @param {object} patient
   */
  const onPatientEditClick = useCallback((patient) => {
    props.addActivePatient(patient)
    props.setModalWithType(true, MODALS.EDIT_PATIENT_MODAL)
  }, [])

  /**
   * @desc Handler for patient delete
   */
  const onPatientDeleteClick = useCallback((patient) => {
    if (patient && patient.id) {
      let modalState = { 
        patientId: patient.id,
        actionType: MODALS.CONFIRMATION_MODAL_TO_DELETE_PATIENT,
        header: "Patient Deletion",
        body: "Are you sure you want to delete the patient?"
      }

      props.setModalWithType(true, MODALS.CONFIRMATION_MODAL, modalState)
    }
  }, [])

  /**
   * @desc Handler for patient modal
   */
  const onAddPatientModalClick = useCallback(() => {
    props.setModalWithType(true, MODALS.ADD_PATIENT_MODAL)
  }, [])

  /**
   * @desc Handler for patient list sort
   */
  const onPatientListArrowClick = () => {
    setPatientListAsc(!isPatientListAsc)
    
    props.setPatientListSort(!isPatientListAsc? -1 : 1)
    props.getPatients(true)
  }

  /**
   * @desc Handler for search patient
   * @param {object} event
   */
  const searchPatientHandler = useCallback((event) => {
    props.setPatientSearchText(event && event.target ? event.target.value : '')

    if (!patientSearchDebouncer) patientSearchDebouncer = debounce(() => props.getPatients(false), DEBOUNCE_TIME)
    patientSearchDebouncer()

  }, [])

  const clearSearchPatientHandler = useCallback(() => {
    props.setAssessmentDateRangeInPatientSearch("", "")
    props.setPatientSearchText("")

    if (!patientSearchDebouncer) patientSearchDebouncer = debounce(() => props.getPatients(false), DEBOUNCE_TIME)
    patientSearchDebouncer()

  }, [])

  const onDateRangeSelect = useCallback((event, picker) => {
      props.setAssessmentDateRangeInPatientSearch(datePickerParser(picker.startDate, "YYYY-MM-DD"), datePickerParser(picker.endDate, "YYYY-MM-DD"))

      if (!patientSearchDebouncer) 
        patientSearchDebouncer = debounce(() => props.getPatients(false), DEBOUNCE_TIME)
      
      patientSearchDebouncer()
  }, [])

  const onPaginationClick = useCallback((pageNo) => {
    props.getPatients(true, pageNo)
  }, [])

  return (
    <React.Fragment>
      <div className="content__left js-content__left">
        <div className="patient__list">
          <PatientSearch 
            clearSearchPatientHandler = { clearSearchPatientHandler }
            searchPatientHandler = { searchPatientHandler }
            onAddPatientModalClick = { onAddPatientModalClick }
            onDateRangeSelect = { onDateRangeSelect }
            patientSearchData = { props.patientSearchData }
          />
          <div className = { `patient__table block__toggle patient--heightleft ${ props.showDeviceSidebar ? "block__toggle--left" : "" }` }>
            <PatientList 
              patients = { props.patients }
              activePatient = { props.activePatient }
              isPatientListAsc = { isPatientListAsc }
              patientPagination = { props.patientPagination || {} }
              onPatientRowClick = { onPatientRowClick }
              onPatientEditClick = { onPatientEditClick }
              onPatientDeleteClick = { onPatientDeleteClick }
              onPatientListArrowClick = { onPatientListArrowClick }
              onPaginationClick = { onPaginationClick }
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({ patients, ui }) => ({
  patients: patients.patientsObj,
  activePatient: patients.activePatient,
  showDeviceSidebar: ui.showDeviceSidebar,
  patientPagination: patients.patientPagination,
  patientSearchData: patients.patientSearch || {}
})

const mapDispatchToProps = dispatch => (
  bindActionCreators({ 
    getPatients,
    addActivePatient,
    deletePatient,
    setPatientSearchText,
    setAssessmentDateRangeInPatientSearch,
    setPatientListSort,
    setModalWithType,
    clearPatientSearchState
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(PatientPanel)
