import React from 'react';
import ChartBlock from './chartBox';
import { connect } from 'react-redux';
import {
  useParams,
  withRouter,
} from 'react-router-dom';
import { toPercentage } from '../../../../shared/utils/wrappers';
import { patientRefractionDetails, patientTrialLensDetails } from '../../../../shared/utils/patient';

const InfoBox = props => {
  let { patientId = null } = useParams();

  if (Object.keys(props.assessment.data).length === 0) {
    return <></>;
  }

  return (
      <div className={`col-md-6 col-print-12`}>
        <div className="filter__details eadieWhite-bg d-print-none">
          <div className="filter__left w-100">
            <div className="d-flex">
              <div className="filter-equal">
                <p>Refraction: {patientRefractionDetails(props.assessment.configuration, props.OSOD)}</p>
                <p>Trial Lens: {patientTrialLensDetails(props.assessment.configuration, props.OSOD)}</p>
                <p>Pupil size: {props.assessment.data.summary.pupilSize}mm</p>
                <p>
                  Visual Acuity: {props.assessment.configuration?.visualAcuity}
                </p>
              </div>
              <div className="filter-equal">
                <p>
                  Fixation Losses (L):{' '}
                  {props.assessment.data.summary.fixationLosses}/
                  {props.assessment.data.summary?.totalfixationlosses}
                  {`${toPercentage(props.assessment.data.summary.fixationLosses,props.assessment.data.summary.totalfixationlosses )}`}
                </p>
                <p>
                  False POS Errors (Active):{' '}
                  {props.assessment.data.summary.falsePosErrors_active}/
                  {props.assessment.data.summary.totalfalsePosis_active} {`${toPercentage(props.assessment.data.summary.falsePosErrors_active,props.assessment.data.summary.totalfalsePosis_active )}`}
                </p>
                <p>
                  False POS Errors (Passive):{' '}
                  {props.assessment.data.summary.falsePOSErrors}
                </p>
                <p>
                  False Neg Errors (L):{' '}
                  {props.assessment.data.summary.falseNegErrors}/
                  {props.assessment.data.summary.totalfalseNegs}

                  {`${toPercentage(props.assessment.data.summary.falseNegErrors,props.assessment.data.summary.totalfalseNegs )}`}
                </p>
              </div>
              <div className="filter-equal">
                <p>
                  MD: {props.assessment.data.summary.meanDeviation}
                </p>
                <p>
                  PSD: {props.assessment.data.summary.PSD}
                </p>
                <p>
                  Test Duration:{' '}
                  {props.calculateTimeDuration(
                    props.assessment.testStartedAt,
                    props.assessment.testEndedAt,
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="filter__details eadieWhite-bg d-none d-print-block">
          <div className="filter__left w-100">
            <div className="d-flex">
              <div className="filter-equal">
                <p>
                  Fixation Monitor:
                  {/* {props.patient[patientId].language} */}
                </p>
                <p>Fixation Target: </p>
                <p>
                  Fixation Losses (L):{' '}
                  {props.assessment.data.summary.fixationLosses}/
                  {props.assessment.data.summary.fixationLosses}
                  {`${toPercentage(props.assessment.data.summary.fixationLosses,props.assessment.data.summary.totalfixationlosses )}`}
                </p>
                <p>
                  Test Duration:{' '}
                  {props.calculateTimeDuration(
                    props.assessment.testStartedAt,
                    props.assessment.testEndedAt,
                  )}
                </p>
              </div>
              <div className="filter-equal">
                <p>
                  False POS Errors (Active):{' '}
                  {props.assessment.data.summary.falsePosErrors_active}/
                  {props.assessment.data.summary.totalfalsePosis_active}
                  {`${toPercentage(props.assessment.data.summary.falsePosErrors_active,props.assessment.data.summary.totalfalsePosis_active )}`}
                </p>
                <p>
                  False POS Errors (Passive):{' '}
                  {props.assessment.data.summary.falsePOSErrors}
                </p>
                <p>
                  False Neg Errors (L):{' '}
                  {props.assessment.data.summary.falseNegErrors}/
                  {props.assessment.data.summary.totalfalseNegs}

                  {`${toPercentage(props.assessment.data.summary.falseNegErrors,props.assessment.data.summary.totalfalseNegs )}`}
                </p>
                <p>Fovea: </p>
              </div>
              <div className="filter-equal">
                <p>Stimulus: </p>
                <p>Background: </p>
                <p>Pupil Diameter: </p>
                <p>Rx: {patientRefractionDetails(props.assessment.configuration)}</p>
                {/* <p className="strong">
                  Date: {parseDateWithMonth(props.assessment.testStartedAt)}
                </p>
                <p>Time: {parseTime(props.assessment.testStartedAt)}</p>
                <p>Age: {calculateAge(props.patient[patientId].dateOfBirth)}</p> */}
              </div>
            </div>
          </div>
        </div>
        <div className="view__outers pd-l-30">
          <div className="view__outers--action d-print-none">
            <a
              href="#!"
              className="actions--text"
              onClick={props.editClickHandler}
            >
              <i className="fabicon-Edit"></i>
              Edit
            </a>
            <span className="actions--text">
              <i className="fabicon-BarChart4"></i>
              Label
            </span>
          </div>
          <div className="d-flex flex-wrap no-gutters filter__content__box mg-r-0 pd-l-3 pd-y-3">
            {props.singleViewCharts &&
              props.singleViewCharts.length &&
              props.singleViewCharts.map((chart, i) => (
                <ChartBlock
                  key={chart.id}
                  id={`${chart.id}`}
                  chartObj={chart}
                  assessment={props.assessment}
                  OSOD={props.OSOD}
                  showInModal={props.showInModal}
                />
              ))}
          </div>
        </div>
      </div>
  );
};

const mapStateToProps = ({ patients, ui, hmdDevices, assessment }) => ({
  patient: patients.patientsObj,
});

export default connect(mapStateToProps)(withRouter(InfoBox));
