import Axios from "axios"
import { API_CONSTANTS } from "../../shared/constants/api.constants"

/**
 * @desc add new backup
 * @public
 */
export const addNewBackup = (payload) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.post(API_CONSTANTS.JOBS.ADD_NEW_BACKUP, payload)

            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
 * @desc add change running cron job schedule
 * @public
 */
export const changeCronJobSchedule = (payload) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.put(API_CONSTANTS.JOBS.UPDATE_SCHEDULE(payload.id), payload)

            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
 * @desc get all  backup
 * @public
 */
export const getAllBackup = (searchText, page = 1, limit = 20, sort = 0) => {
    return new Promise(async (res, rej) => {
        try {
            let params = { page, limit, sort }

            if (searchText) params = { ...params, searchText }

            // if (dateRange && dateRange.fromDate && dateRange.toDate) params = { ...params, fromDate: dateRange.fromDate, toDate: dateRange.toDate }

            let { data } = await Axios.get(API_CONSTANTS.JOBS.GET_ALL_BACKUPS, { params })

            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
 * @desc restore single  backup
 * @public
 */
export const restoreBackupFile = (s3Key) => {
    return new Promise(async (res, rej) => {
        try {
            let data;

            // Function to handle beforeunload event
            const beforeUnloadHandler = (event) => {
                // Cancel the event to prevent the browser from closing
                event.preventDefault();
                // Chrome requires to set a return value
                event.returnValue = 'There is an ongoing operation. Are you sure you want to leave?';

                // Optionally, you can inform the user about ongoing operation
                return 'There is an ongoing operation. Are you sure you want to leave?';
            };

            // Add beforeunload event listener
            window.addEventListener("beforeunload", beforeUnloadHandler);

            try {
                // Perform the API call
                console.log("API call start");
                let response = await Axios.post(API_CONSTANTS.JOBS.RESTORE_BACKUP(s3Key));
                console.log("API call end");
                data = response.data;
            } finally {
                // Remove beforeunload event listener regardless of success or failure
                window.removeEventListener("beforeunload", beforeUnloadHandler);
            }

            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            });
        } catch (error) {
            rej(error);
        }
    });
};

/**
 * @desc get all CRON job schedules
 * @public
 */
export const getAllCronJobSchedule = () => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.get(API_CONSTANTS.JOBS.GET_ALL_SCHEDULES)

            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}
