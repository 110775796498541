import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { setModalWithType } from "../../../actions/modal.action"
import { API_CONSTANTS } from "../../../shared/constants/api.constants";
import { ERROR } from "../../../shared/constants/messages.constants";
import { Document, Page, pdfjs } from 'react-pdf';
import * as FlashMessage from "../../../shared/utils/flashMessage";

export const ImportedTestViewModal = (props) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
 
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
    setPageNumber(1)
  }

  const onDocumentLoadError = () => {
    props.setModalWithType(false)
    FlashMessage.error(ERROR.UNABLE_TO_LOAD_IMPORTED_TEST)
  }

  const onDocumentPageLoadError = () => {
    FlashMessage.error(ERROR.UNABLE_TO_LOAD_IMPORTED_TEST_PAGE)
  }

  const changePage = (offset) => {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  const previousPage = () => {
    changePage(-1)
  }

  const nextPage = () => {
    changePage(1)
  }

  const cancelClickHandler = () => {
    props.setModalWithType(false)
  }

  return (
    <React.Fragment>
      <div className="modal-header">
        <h2 className="modal-title" id="patient-modalLabel">{ props.modalData.fileName }</h2>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick = { cancelClickHandler }>
            <span aria-hidden="true"><i className="fabicon-close"></i></span>
        </button>
      </div>
      <div className="modal-body">
          {
            props.modalData && props.modalData.filePath && (
              <>
                <div className="row">
                  <Document
                    file = { `${ API_CONSTANTS.PATIENTS.GET_PATIENTS_IMPORT_TEST(props.modalData.filePath) }` }
                    onLoadSuccess = { onDocumentLoadSuccess }
                    onLoadError = { onDocumentLoadError }
                  >
                    <Page 
                      pageNumber = { pageNumber }
                      error = { onDocumentPageLoadError }
                    />
                  </Document>
                </div>
                <div>
                  <p>Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}</p>
                  <button type="button" disabled = { pageNumber <= 1 } onClick = { previousPage }>Previous</button>
                  <button type="button" disabled={ pageNumber >= numPages } onClick = { nextPage }>Next</button>
                </div>
                {/* <div className="row">
                  <img src={`${ API_CONSTANTS.PATIENTS.GET_PATIENTS_IMPORT_TEST(props.modalData.imageDataPath + 'gray_scale_image.png') }`} />
                </div> */}
              </>
            )
          }
      </div>
    </React.Fragment>
  )
}

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setModalWithType
  }, dispatch)
)

export default connect(null, mapDispatchToProps)(ImportedTestViewModal)
 