import * as NotesService from "../services/notes/notes.service"
import { LOADER, NOTES } from "../shared/constants/actions.constants"
import * as FlashMessage from "../shared/utils/flashMessage"
import { SUCCESS, ERROR } from '../shared/constants/messages.constants'

/**
 * @desc To add new note 
 * @param {string} patientId
 * @param {string} assessmentId
 * @param {object} noteObj
 * @public
 */
export const addNote = (patientId, assessmentId, noteObj) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true))

      let { data } = await NotesService.createNote(patientId, assessmentId, noteObj)

      onSuccessAddNote(dispatch, data)

      fetchNotesAfterSave(patientId, assessmentId, dispatch)      
    } catch (error) {
      onErrorAddNote(dispatch, error)
    }
  }
}

/**
 * @desc To get all notes
 * @param {string} patientId
 * @param {string} assessmentId
 * @public
 */
export const getNotes = (patientId, assessmentId) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true))

      let { data } = await NotesService.getNotes(patientId, assessmentId)

      onSuccessGetNotes(dispatch, data)
    } catch (error) {
      onErrorGetNotes(dispatch, error)
    }
  }
}


// Private Methods
/**
 * @desc Fetch all notes after addition of note
 * @param {sting} patientId
 * @param {sting} assessmentId
 * @private
 */
const fetchNotesAfterSave = async(patientId, assessmentId, dispatch) => {
    let { data } = await NotesService.getNotes(patientId, assessmentId)
    dispatch(setAllNotesInStore(data))
}

/**
 * @desc Action to set loader state
 * @param {boolean} flag
 * @private
 */
const setLoader = (flag) => {
  return { 
    type: LOADER.SET_LOADER, 
    payload: flag
  }
}

/**
 * @desc success handler for created note
 * @param {object} dispatch
 * @param {object} noteObj
 * @private
 */
const onSuccessAddNote = (dispatch, noteObj) => {
  dispatch(setLoader(false))
  FlashMessage.success(SUCCESS.NOTE_SUCCESSFULLY_CREATED)
}

/**
 * @desc Error handler for created note
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorAddNote = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.NOTE_WAS_NOT_CREATED)
}

/**
 * @desc Success handler when notes are fetched
 * @param {object} dispatch
 * @param {object} notes
 * @private
 */
const onSuccessGetNotes = (dispatch, notes) => {
  dispatch(setLoader(false))
  dispatch(setAllNotesInStore(notes))
  FlashMessage.success(SUCCESS.NOTES_SUCCESSFULLY_FETCHED)
}

/**
 * @desc Error handler when failed to fetch notes
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorGetNotes = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.NOTES_WAS_NOT_FETCHED)
}

/**
 * @desc Action to add worklist into worklist object (by converting list into object)
 * @param {array} notes
 * @private
 */
const setAllNotesInStore = (notes = []) => {
  return {
    type: NOTES.SET_NOTES,
    payload: notes
  }
}
