import { LOADER } from "../shared/constants/actions.constants"

/**
 * @desc Action to set loader state
 * @param {boolean} flag
 * @public
 */
export const setLoader = (flag) => {
    return {
      type: LOADER.SET_LOADER,
      payload: flag
    }
}