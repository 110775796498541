import * as UserService from '../services/user/user.service';
import { USERS, AUTHTYPE, MODALS } from '../shared/constants/actions.constants';
import { setModalWithType } from './modal.action';
import { onSuccessfulLogin, setCurrentUser } from './auth.action';
import { setLoader } from './loader.action';
import * as FlashMessage from '../shared/utils/flashMessage';
import { SUCCESS, ERROR } from '../shared/constants/messages.constants';
import history from '../history';

/**
 * @desc To get all the users list with bulk information of users
 * @public
 */
export const getUsers = (showLoader = true) => {
  return async (dispatch, state) => {
    try {
      if (showLoader) {
        dispatch(setLoader(true));
      }

      let { data } = await UserService.getUsersListWithBulkData(
        state().users.userSearchText,
        state().users.userSort,
      );

      onSuccessUsersList(dispatch, data);
    } catch (error) {
      onErrorUsersList(dispatch, error);
    }
  };
};

/**
 * @desc Action to set active user details into store
 * @param {object} user
 * @public
 */
export const addActiveUser = user => {
  return {
    type: USERS.ADD_ACTIVE_USER,
    payload: user,
  };
};

/**
 * @desc To add new user and further add it into Store
 * @param {object} userObj
 * @public
 */
export const addUser = userObj => {
  return async dispatch => {
    try {
      dispatch(setLoader(true));

      let { data } = await UserService.createUser(userObj);

      onSuccessAddUser(dispatch, data);
    } catch (error) {
      onErrorAddUser(dispatch, error);
    }
  };
};

/**
 * @desc To add new user from auth0 and further add it into Store
 * @param {object} userObj
 * @public
 */
export const addUserFromAuth0 = (userObj, history) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true));

      // let { data } = await UserService.createUserAuth0(userObj)

      // onSuccessAddUser(dispatch, data)

      let {
        data: { user, authToken, refreshToken },
      } = await UserService.createUserAuth0(userObj);

      onSuccessfulLogin(dispatch, history, user, authToken, refreshToken);
    } catch (error) {
      onErrorAddUser(dispatch, error);
    }
  };
};

/**
 * @desc To update an user and further update it into Store
 * @param {object} user
 * @public
 */
export const updateUser = user => {
  return async dispatch => {
    try {
      dispatch(setLoader(true));

      let { data } = await UserService.updateUser(user);

      onSuccessUpdateUser(dispatch, data);
    } catch (error) {
      onErrorUpdateUser(dispatch, error);
    }
  };
};

/**
 * @desc To update user's settings and further update user's object into Store
 * @param {object} user
 * @public
 */

export const updateUserSettings = user => {
  return async dispatch => {
    try {
      dispatch(setLoader(true));

      let { data } = await UserService.updateUser(user);

      onSuccessUpdateUserSettings(dispatch, data);
    } catch (error) {
      onErrorUpdateUserSettings(dispatch, error);
    }
  };
};

/**
 * @desc To update user's password
 * @param {string} userId
 * @param {object} userObj
 * @public
 */
export const updatePassword = (userId, userObj) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true));

      await UserService.updateUserPassword({ userId, userObj });

      dispatch(setLoader(false));
      FlashMessage.success(SUCCESS.PASSWORD_SUCCESSFULLY_UPDATED);
    } catch (error) {
      dispatch(setLoader(false));
      FlashMessage.error(ERROR.PASSWORD_WAS_NOT_UPDATED);
    }
  };
};

/**
 * @desc To fetch user detail by its id
 * @param {string} userId
 * @public
 */
export const getUserForEdit = userId => {
  return async dispatch => {
    try {
      dispatch(setLoader(true));

      let { data } = await UserService.getUserById(userId);

      onSuccessGetUserForEdit(dispatch, data);
    } catch (error) {
      onErrorGetUserForEdit(dispatch, error);
    }
  };
};

/**
 * Retrieves a user by email.
 * @param {string} userEmail - The email of the user to retrieve.
 * @returns {Function} - The async dispatch function.
 */
export const getUserByEmail = (userEmail, data) => {
  return async dispatch => {
    try {
      console.log({data});
      if(data?.email_verified === false){
        return FlashMessage.error(ERROR.VERIFY_YOUR_EMAIL_AND_TRY_LOGIN_AGAIN);
      }
      dispatch(setLoader(true));

      let { data: userInfo } = await UserService.getUserByEmail(userEmail);
      dispatch(setLoader(false));

      onSuccessGetUserForEdit(dispatch, userInfo);
      //   return data;
      // } catch (error){
      //   onErrorGetUserForEdit(dispatch, error)

      // }
      // addUserFromAuth0(res, history);
      let {
        data: { user, authToken, refreshToken },
      } = await UserService.createUserAuth0(userInfo);

      onSuccessfulLogin(dispatch, history, user, authToken, refreshToken);
    } catch (error) {
      dispatch(setLoader(false));
      console.log({ error });

      dispatch(
        setModalWithType(true, MODALS.AUTH0_USER_CREATION_MODEL, {
          backdrop: 'static',
          data: data
        }),
      );
    }
  };
};

/**
 * Uploads a file.
 * @param {File} file - The file to be uploaded.
 * @returns {Function} A function that dispatches actions.
 */
export const uploadFile = file => {
  return async dispatch => {
    try {
      dispatch(setLoader(true));

      const { data } = await UserService.uploadFile(file);

      dispatch(setLoader(false));
      FlashMessage.success(SUCCESS.FILE_SUCCESSFULLY_UPLOADED);
      return data.fileUrl;
    } catch (error) {
      dispatch(setLoader(false));
      FlashMessage.error(ERROR.FILE_WAS_NOT_UPLOADED);
    }
  };
};

/**
 * @desc Actions to reset user's state from store.
 * @public
 */
export const resetState = () => {
  return async dispatch => {
    dispatch(setEditableUser(null));
    dispatch(addActiveUser(null));
  };
};

export const setUsersSearchText = text => {
  return {
    type: USERS.SET_USERS_SEARCH_TEXT,
    payload: text,
  };
};

export const setUsersSort = text => {
  return {
    type: USERS.SET_USERS_SORT,
    payload: text,
  };
};

export const updateChartSettingsOrder = (userId, settingsObj) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true));

      let { data } = await UserService.updateChartSettingsOrder(
        userId,
        settingsObj,
      );

      onSuccessUpdateChartSettingsOrder(dispatch, data);
    } catch (error) {
      onErrorUpdateChartSettingsOrder(dispatch, error);
    }
  };
};

// Private Methods
/**
 * @desc To update store when users list is successfully fetched
 * @param {object} dispatch
 * @param {object} user
 * @private
 */
const onSuccessUsersList = (dispatch, users) => {
  dispatch(setUsersObj(users));
  dispatch(setLoader(false));
};

/**
 * @desc To notify user if there is any error while fetching users list
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorUsersList = (dispatch, error) => {
  dispatch(setLoader(false));
};

/**
 * @desc Action to set users list into Store by converting it into object
 * @param {array} list
 * @private
 */
const setUsersObj = (list = []) => {
  return {
    type: USERS.SET_USERS_OBJ,
    payload: getUserObj(list),
  };
};

/**
 * @desc To convert users list into object (user's id as key and user's object as value)
 * @param {array} usersList
 * @private
 */
const getUserObj = (usersList = []) => {
  return usersList.reduce((res, currVal) => {
    res[currVal.id] = currVal;
    return res;
  }, {});
};

/**
 * @desc To update store when user is successfully added.
 * @param {object} dispatch
 * @param {object} user
 * @private
 */
const onSuccessAddUser = (dispatch, user) => {
  dispatch(addUserIntoObj(user));
  dispatch(setModalWithType(false));
  dispatch(setLoader(false));
  FlashMessage.success(SUCCESS.USER_SUCCESSFULLY_CREATED);
};

/**
 * @desc To notify user if there is any error while adding user
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorAddUser = (dispatch, error) => {
  dispatch(setLoader(false));
  FlashMessage.error(ERROR.USER_WAS_NOT_CREATED);
};

/**
 * @desc Action to add user into user's object
 * @param {object} user
 * @private
 */
const addUserIntoObj = (user = {}) => {
  return {
    type: USERS.ADD_USER_INTO_OBJ,
    payload: { [user.id]: user },
  };
};

/**
 * @desc To update store states when user is successfully updated.
 * @param {object} dispatch
 * @param {object} user
 * @private
 */
const onSuccessUpdateUser = (dispatch, user) => {
  dispatch(addUserIntoObj(user));
  dispatch(setEditableUser(user));
  dispatch(setLoader(false));
  FlashMessage.success(SUCCESS.USER_SUCCESSFULLY_UPDATED);
};

/**
 * @desc To notify user if there is any error while updating user
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorUpdateUser = (dispatch, error) => {
  dispatch(setLoader(false));
  FlashMessage.error(ERROR.USER_WAS_NOT_UPDATED);
};

/**
 * @desc To update store states when user's settings are successfully updated.
 * @param {object} dispatch
 * @param {object} user
 * @private
 */
const onSuccessUpdateUserSettings = (dispatch, user) => {
  dispatch(addUserIntoObj(user));
  dispatch(setCurrentUser(user));
  dispatch(setLoader(false));
  FlashMessage.success(SUCCESS.USER_SUCCESSFULLY_UPDATED);
};

/**
 * @desc To notify user if there is any error while updating user's settings
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorUpdateUserSettings = (dispatch, error) => {
  dispatch(setLoader(false));
  FlashMessage.error(ERROR.USER_WAS_NOT_UPDATED);
};

/**
 * @desc To update store states when user's chart order are successfully updated.
 * @param {object} dispatch
 * @param {object} user
 * @private
 */
const onSuccessUpdateChartSettingsOrder = (dispatch, settingsObj) => {
  dispatch(updateLoggedInUserSettingsAction(settingsObj));
  dispatch(setModalWithType(false));
  dispatch(setLoader(false));
};

/**
 * @desc To notify user if there is any error while updating user's settings
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorUpdateChartSettingsOrder = (dispatch, error) => {
  dispatch(setLoader(false));
  FlashMessage.error(ERROR.USER_CHART_ORDER_WAS_NOT_UPDATED);
};

/**
 * @desc To update Store state when an user's info is succesffuly fetched
 * @param {object} dispatch
 * @param {object} user
 * @private
 */
const onSuccessGetUserForEdit = (dispatch, user) => {
  dispatch(setEditableUser(user));
  dispatch(addUserIntoObj(user));
  dispatch(setLoader(false));
  FlashMessage.success(SUCCESS.USER_SUCCESSFULLY_FETCHED);
};

/**
 * @desc To notify user if there is any error while fetching an user's info
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorGetUserForEdit = (dispatch, error) => {
  dispatch(setLoader(false));
  FlashMessage.error(ERROR.USER_WAS_NOT_FETCHED);
};

/**
 * @desc Action to set editableUser state, when user tries to edit user
 * @param {object} user
 * @private
 */
const setEditableUser = user => {
  return {
    type: USERS.SET_EDITABLE_USER,
    payload: user,
  };
};

/**
 * @desc Action to update user settings
 * @param {object} user
 * @private
 */
const updateLoggedInUserSettingsAction = (userSettings = {}) => {
  return {
    type: AUTHTYPE.UPDATE_CURRENT_USER_SETTINGS,
    payload: userSettings,
  };
};
