import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import Select from 'react-select'
import { setModalWithType } from "../../../actions/modal.action"
import { addWorklist, updateWorklist } from "../../../actions/worklist.action"
import { getCompletePatientsList } from "../../../services/patient/patient.service"
import { getUsersList } from "../../../services/user/user.service"
import { WorklistForm, WorklistSchema } from "../../../models/worklist.model"

export const AddEditWorklistModal = (props) => {
    const [worklist, setWorklist] = useState(WorklistForm)
    const [patientsList, setPatientsList] = useState([])
    const [usersList, setUsersList] = useState([])

    useEffect(() => {
        getInitialData()
    }, [])

    /**
     * @desc To get patients and users list and format it.
     */
    const getInitialData = async () => {
        try {
            let { data: pList } = await getCompletePatientsList()
            let { data: uList } = await getUsersList()
            
            pList = pList.map(patient => ({ 
                label: `${patient.firstName} ${patient.lastName}`, 
                value: patient.id 
            }))

            uList = uList.map(user => ({ 
                label: `${user.firstName} ${user.lastName}`, 
                value: user.id 
            }))

            setPatientsList([ ...pList ])
            setUsersList([ ...uList ])
            if (props.worklist) {
                setWorklistOnLoad(props.worklist)
            }
        } catch (error) {
            setPatientsList([])
            setUsersList([])
        }
    }

    /**
     * @desc To handle wishlist form submission and hit create/update api based on worklist property of props.
     * @param {object} event
     */
    const worklistFormSubmitHandler = (event) => {
        props.worklist ? props.updateWorklist({ id: props.worklist.id, ...getWorklistFormValues(event) }) : props.addWorklist(getWorklistFormValues(event))
    }

    /**
     * @desc To format worklist object for submission
     * @param {object} assignedTo
     * @param {Array} patients
     */
    const getWorklistFormValues = ({ name, assignedTo = {}, patients }) => {
        patients = patients || []
        return { name, assignedTo: assignedTo.value, patients: patients.map(p => p.value) }
    }

    /**
     * @desc set state's worklist, if worklist is present in props (update case)
     * @param {object} event
     */
    const setWorklistOnLoad = (worklist) => {
        let { patients, assignedTo, name } = worklist

        setWorklist({ 
            name,
            assignedTo: { value: assignedTo.id, label: `${assignedTo.firstName} ${assignedTo.lastName}` },
            patients: patients.map(p => ({ value: p.id, label: `${p.firstName} ${p.lastName}` })) 
        })
    }

    return (
        <React.Fragment>
            <div className="modal-header">
            <h2 className="modal-title" id="patient-modalLabel">{ `${ props.worklist ? "Edit" : "Add" }` } Worklist</h2>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick = { () => props.setModalWithType(false) }>
                    <span aria-hidden="true"><i className="fabicon-close"></i></span>
                </button>
            </div>
            <div className="modal-body pd-b-30">
                <Formik
                    enableReinitialize = { true }
                    initialValues = { worklist }
                    validationSchema = { WorklistSchema }
                    onSubmit = { worklistFormSubmitHandler }
                >
                    {
                        ({ values, handleChange, handleSubmit, setFieldValue, errors, touched }) => (
                            <React.Fragment>
                                <form onSubmit = { handleSubmit }>
                                    <div className="row">
                                        <div className="form-group col-md-12">
                                            <label>Name</label>
                                            <input id = "name" name = "name" type="text" className = { `form-control ${ (errors.name && touched.name) ? "is-invalid" : null }` } placeholder="Enter worklist name" onChange = { handleChange } value = { values.name } />
                                            { errors.name && touched.name ? (
                                            <div className="invalid-feedback">
                                                { errors.name }
                                            </div>
                                            ) : null }
                                        </div>
                                        <div className="form-group col-md-12">
                                            <label>Assigned To</label>
                                            <Select id="assignedTo" name = "assignedTo" 
                                                className = { `form-select ${ (errors.assignedTo && touched.assignedTo) && "is-invalid" }` }
                                                options = { usersList }
                                                onChange = { (e) => setFieldValue("assignedTo", e) }
                                                value = { values.assignedTo }
                                            />
                                            { errors.assignedTo && touched.assignedTo && (
                                                <div className="invalid-feedback">
                                                    { errors.assignedTo }
                                                </div>
                                            ) }
                                        </div>
                                        <div className="form-group col-md-12">
                                            <label>Patients</label>
                                            <Select id="patients" name = "patients" className = "form-select"
                                                options = { patientsList } 
                                                isMulti = { true }
                                                onChange = { (e) => setFieldValue("patients", e) }
                                                value = { values.patients }
                                            />
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-red">{ props.worklist ? "Update" : "Create" }</button>
                                </form>
                            </React.Fragment>
                        )
                    }
                </Formik>
            </div>
        </React.Fragment>
    )
}

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setModalWithType,
    addWorklist,
    updateWorklist
  }, dispatch)
)

export default connect(null, mapDispatchToProps)(AddEditWorklistModal)
