import React, { useEffect } from 'react'
import { Link, useParams } from "react-router-dom"
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getUserForEdit, resetState } from "../../../actions/users.action"

export const EditUserOptionsPanel = (props) => {

    let { userId } = useParams()

    useEffect(() => {
        props.getUserForEdit(userId)

        return () => {
            props.resetState()
        }
    }, [])

    return (
        <div className="content__left">
            <div className="content__main h-100">
                <div className="content__top eadieSnow-bg js-heightcal">
                    <h2>Edit Profile</h2>
                </div>
                <div className = { `content__bottom eadieWhite-bg block__toggle js-heightleft patient--heightleft patient__table ${ props.showDeviceSidebar ? "block__toggle--left" : "" }` }>
                    { props.user && (
                        <ul className="nav nav-tabs flex-column nav--verticaltab" role="tablist">
                            <li className="nav-item">
                                <Link to = { `/users/${props.user.id}/editProfile` } className="nav-link">Profile <span>{ `${props.user.firstName} ${props.user.lastName}` }</span></Link>
                            </li>
                            <li className="nav-item">
                                <Link to = { `/users/${props.user.id}/emailSettings` } className="nav-link">Email Setting <span>{ props.user.email }</span></Link>
                            </li>
                            <li className="nav-item">
                                <Link to = { `/users/${props.user.id}/changeSettings` } className="nav-link">Change Password <span>xxxxxxxxx</span></Link>
                            </li>
                        </ul>
                    )}   
                </div>
            </div>
        </div> 
    )
}

const mapStateToProps = ({ users, ui }) => ({
    showDeviceSidebar: ui.showDeviceSidebar,
    user: users.editableUser
})
  
const mapDispatchToProps = dispatch => (
    bindActionCreators({ 
        getUserForEdit,
        resetState
    }, dispatch)
)
  
export default connect(mapStateToProps, mapDispatchToProps)(EditUserOptionsPanel)
