import { UI } from "../shared/constants/actions.constants"
import { setDeviceSidebarState, getDeviceSidebarState } from "../services/localstorage/localStorage.service"

/**
 * @desc To update device sidebar status that whether its open or close
 * @param {boolean} flag
 * @public
 */
export const setDeviceSidebar = (flag = false) => {
  return async dispatch => {
    setDeviceSidebarState(flag)
    dispatch(setDeviceSidebarAction(flag))
  }
}

/**
 * @desc To get sidebar status flag from local storage
 * @public
 */
export const getDeviceSidebar = () => {
  return async dispatch => {
    let deviceSidebarState = getDeviceSidebarState() || false
    dispatch(setDeviceSidebarAction(deviceSidebarState))
  }
}

/**
 * @desc Action to set device sidebar disable flag
 * @param {boolean} flag
 * @public
 */
export const setDeviceSideBarDisable = (flag) => {
  return {
    type: UI.SET_DEVICE_SIDEBAR_DISABLE,
    payload: flag
  }
}

/**
 * @desc Action to set device sidebar state flag
 * @param {boolean} flag
 * @private
 */
const setDeviceSidebarAction = (flag) => {
  return {
    type: UI.SET_DEVICE_SIDEBAR,
    payload: flag
  }
}
