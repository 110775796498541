import { UI } from "../shared/constants/actions.constants"

const initialState = {
  showDeviceSidebar: false,
  disableSideBar: false
}

export default function(state = initialState, action) {
  switch (action.type) {
      
    case UI.SET_DEVICE_SIDEBAR:
        return {
            ...state,
            showDeviceSidebar: action.payload
        }

    case UI.SET_DEVICE_SIDEBAR_DISABLE:
        return {
            ...state,
            disableSideBar: action.payload
        }

    default:
      return state
  }
}
