import * as WorklistService from "../services/worklist/worklist.service"
import { WORKLISTS } from "../shared/constants/actions.constants"
import { setModalWithType } from "./modal.action"
import { setLoader } from "./loader.action"
import * as FlashMessage from "../shared/utils/flashMessage"
import { SUCCESS, ERROR } from '../shared/constants/messages.constants'

/**
 * @desc To fetch all worklists and add them into store
 * @public
 */
export const getWorklists = (showLoader = true) => {
  return async (dispatch, state) => {
    try {
      if (showLoader) {
        dispatch(setLoader(true))
      }
      
      let { data } = await WorklistService.getWorklists(state().worklists.worklistSearchText, state().worklists.worklistSort)

      onSuccessWorklistsList(dispatch, data)
    } catch (error) {
      onErrorWorklistsList(dispatch, error)
    }
  }
}

/**
 * @desc To add new worklist object and also add it into store.
 * @param {object} worklistObj
 * @public
 */
export const addWorklist = (worklistObj) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true))
      
      let { data } = await WorklistService.createWorklist(worklistObj)

      onSuccessAddWorklist(dispatch, data)
    } catch (error) {
      onErrorAddWorklist(dispatch, error)
    }
  }
}

/**
 * @desc To update the worklist object and update its object into store.
 * @param {object} worklist
 * @public
 */
export const updateWorklist = (worklist) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true))

      let { data } = await WorklistService.updateWorklist(worklist)

      onSuccessUpdateWorklist(dispatch, data)
    } catch (error) {
      onErrorUpdateWorklist(dispatch, error)
    }
  }
}

/**
 * @desc To delete worklist and update store accordingly
 * @param {string} worklistId
 * @public
 */
export const deleteWorklist = (worklistId) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true))

      await WorklistService.deleteWorklist(worklistId)

      onSuccessDeleteWorklist(dispatch, worklistId)
    } catch (error) {
      onErrorDeleteWorklist(dispatch, error)
    }
  }
}

/**
 * @desc To delete all patients from worklist and update store accordingly
 * @param {string} worklistId
 * @public
 */
export const deleteAllPatientsFromWorklist = (worklistId) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true))

      let { data } = await WorklistService.deleteAllPatientsFromWorklist(worklistId)

      onSuccessDeleteAllPatientsFromWorklist(dispatch, data)
    } catch (error) {
      onErrorDeleteAllPatientsFromWorklist(dispatch, error)
    }
  }
}

/**
 * @desc Action to add active worklist object into store
 * @param {object} worklist
 * @public
 */
export const addActiveWorklist = (worklist) => {
  return {
    type: WORKLISTS.ADD_ACTIVE_WORKLIST,
    payload: worklist
  }
}

/**
 * @desc Action to set worklist serach text into store.
 * @param {string} text
 * @public
 */
export const setWorklistSearchText = (text) => {
  return { 
    type: WORKLISTS.SET_WORKLIST_SEARCH_TEXT, 
    payload: text
  }
}

export const setWorklistSort = (text) => {
  return { 
    type: WORKLISTS.SET_WORKLIST_SORT, 
    payload: text
  }
}

export const addPatientIntoWorklist = (worklistId, patientId) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true))

      let { data } = await WorklistService.addPatientIntoWorklist(worklistId, patientId)

      onSuccessAddPatientIntoWorklist(dispatch, data)
    } catch (error) {
      onErrorAddPatientIntoWorklist(dispatch, error)
    }
  }
}

export const deletePatientFromWorklist = (worklistId, patientId) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true))

      let { data } = await WorklistService.deletePatientFromWorklist(worklistId, patientId)

      onSuccessDeletePatientFromWorklist(dispatch, data)
    } catch (error) {
      onErrorDeletePatientFromWorklist(dispatch, error)
    }
  }
}

// Private Methods
/**
 * @desc To update store when worklist's list is successfully fetched.
 * @param {object} dispatch
 * @param {array} worklists
 * @private
 */
const onSuccessWorklistsList = (dispatch, worklists) => {
  dispatch(setWorklistsObj(worklists))
  dispatch(setLoader(false))
}

/**
 * @desc To notify user if there is any error while fetching worklist's list
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorWorklistsList = (dispatch, error) => {
  dispatch(setLoader(false))
}

/**
 * @desc Action to add worklist into worklist object (by converting list into object)
 * @param {array} list
 * @private
 */
const setWorklistsObj = (list = []) => {
  return {
    type: WORKLISTS.SET_WORKLISTS_OBJ,
    payload: getWorklistObj(list)
  }
}

/**
 * @desc To add new worklist object into store when its successfully created.
 * @param {object} dispatch
 * @param {object} Worklist
 * @private
 */
const onSuccessAddWorklist = (dispatch, worklist) => {
  dispatch(addWorklistIntoObj(worklist))
  dispatch(setModalWithType(false))
  dispatch(setLoader(false))
}

/**
 * @desc To notify user if there is any error while adding new worklist
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorAddWorklist = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.WORKLIST_WAS_NOT_CREATED)
}

/**
 * @desc Action to add worklist into worklist's object
 * @param {object} Worklist
 * @private
 */
const addWorklistIntoObj = (worklist = {}) => {
  return {
    type: WORKLISTS.ADD_WORKLIST_INTO_OBJ,
    payload: { [worklist.id]: worklist }
  }
}

/**
 * @desc To convert worklist list into object (worklist id as key and worklist's object as value)
 * @param {array} worklistsList
 * @private
 */
const getWorklistObj = (worklistsList = []) => {
  return worklistsList.reduce((res, currVal) => { 
    res[currVal.id] = currVal 
    return res 
  }, {})
}

/**
 * @desc To update worklist object in store when its successfully updated
 * @param {object} dispatch
 * @param {object} worklist
 * @private
 */
const onSuccessUpdateWorklist = (dispatch, worklist) => {
  dispatch(addWorklistIntoObj(worklist))
  dispatch(setModalWithType(false))
  dispatch(setLoader(false))
  dispatch(addActiveWorklist(worklist))
  FlashMessage.success(SUCCESS.WORKLIST_SUCCESSFULLY_UPDATED)
}

/**
 * @desc To notify user if there is any error while updating worklist's object
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorUpdateWorklist = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.WORKLIST_WAS_NOT_UPDATED)
}

/**
 * @desc To update store when worklist is successfully deleted.
 * @param {object} dispatch
 * @param {object} worklistId
 * @private
 */
const onSuccessDeleteWorklist = (dispatch, worklistId) => {
  dispatch(deleteWorklistAction(worklistId))
  dispatch(setLoader(false))
  dispatch(addActiveWorklist(null))
  FlashMessage.success(SUCCESS.WORKLIST_SUCCESSFULLY_DELETED)
}

/**
 * @desc To notify user if there is any error while deleting worklist
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorDeleteWorklist = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.WORKLIST_WAS_NOT_DELETED)
}

/**
 * @desc To update store when all patients from worklist is successfully deleted.
 * @param {object} dispatch
 * @param {object} worklistId
 * @private
 */
const onSuccessDeleteAllPatientsFromWorklist = (dispatch, worklist) => {
  dispatch(addWorklistIntoObj(worklist))
  dispatch(setModalWithType(false))
  dispatch(setLoader(false))
  dispatch(addActiveWorklist(worklist))
  FlashMessage.success(SUCCESS.ALL_PATIENTS_SUCCESSFULLY_DELETED_FROM_WORKLIST)
}

/**
 * @desc To notify user if there is any error while deleting worklist
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorDeleteAllPatientsFromWorklist = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.ALL_PATIENTS_WAS_NOT_DELETED_FROM_WORKLIST)
}

/**
 * @desc Action to delete worklist from store
 * @param {string} worklistId
 * @private
 */
const deleteWorklistAction = (worklistId) => {
  return {
    type: WORKLISTS.DELETE_WORKLIST,
    payload: worklistId
  }
}

/**
 * @desc To update worklist object in store when its successfully updated
 * @param {object} dispatch
 * @param {object} worklist
 * @private
 */
const onSuccessAddPatientIntoWorklist = (dispatch, worklist) => {
  dispatch(addWorklistIntoObj(worklist))
  dispatch(setLoader(false))
  dispatch(addActiveWorklist(worklist))
}

/**
 * @desc To notify user if there is any error while updating worklist's object
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorAddPatientIntoWorklist = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.PATIENT_WAS_NOT_ADDED_INTO_WORKLIST)
}

/**
 * @desc To update worklist object in store when its successfully deleted
 * @param {object} dispatch
 * @param {object} worklist
 * @private
 */
const onSuccessDeletePatientFromWorklist = (dispatch, worklist) => {
  dispatch(addWorklistIntoObj(worklist))
  dispatch(setLoader(false))
  dispatch(addActiveWorklist(worklist))
}

/**
 * @desc To notify user if there is any error while updating worklist's object
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorDeletePatientFromWorklist = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.PATIENT_WAS_NOT_DELETED_FROM_WORKLIST)
}
