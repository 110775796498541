import React, { useEffect } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from 'redux'
import Header from "../components/shared/header"
import Sidebar from "../components/shared/sidebar"
import HmdDevicePanel from "../components/hmdDevices/hmdDevicePanel"
import { setDeviceSidebar, setDeviceSideBarDisable } from "../actions/ui.action"

const HmdDevicesContainer = (props) => {

  useEffect(() => {
    props.setDeviceSidebar(false)
    props.setDeviceSideBarDisable(true)

    return () => {
      props.setDeviceSideBarDisable(false)
    }
  }, [])

  return (
    <div>
        <Header loggedInUser = { props.loggedInUser } />
        <main className="wrapper">
            <Sidebar />
            <div className="content-wrapper">
                <div className="content__inner content__main">
                    <HmdDevicePanel />
                </div>
            </div>
        </main>
    </div>
  )
}

const mapStateToProps = ({ auth }) => ({
  loggedInUser: auth.loggedInUser
})

const mapDispatchToProps = dispatch => (
  bindActionCreators({ 
    setDeviceSidebar,
    setDeviceSideBarDisable
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(HmdDevicesContainer)
