import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  clearStateAfterAssessmentConf,
  getAssessmentsByPatientId,
  getPaginatedAssessmentsByPatientId,
} from '../../../actions/assessment.action';
import { setLoader } from '../../../actions/loader.action';
import { setModalWithType } from '../../../actions/modal.action';
import { MODALS } from '../../../shared/constants/actions.constants';
import { DYNAMIC_ROUTES } from '../../../shared/constants/routes.constants';
import PatientActions from './patientActions';
import PatientAssessmentsList from './patientAssessmentsList';
import PatientImportTestList from './patientImportTestList';
import PatientInfo from './patientInfo';
import usePagination from '../../../hooks/usePagination';

export const PatientDetailsPanel = ({ history, ...props }) => {

  const paginationCallback = async ({ limit, sortOption, currentPage }) => {
    if (props.activePatient?.id && props?.assessmentsByPatientId) {
      await props.getPaginatedAssessmentsByPatientId(
        props.activePatient.id,
        limit,
        sortOption,
        currentPage,
        props.assessmentsByPatientId?.[props.activePatient.id],
      );
    }
  };

  const { currentPage, setCurrentPage, _totalPage, setTotalPage, lastElementRef, limit, sortOption, setSortOption } = usePagination(props.loading, paginationCallback, { defaultLimit: 5 });

  useEffect(() => {
    (async () => {
      try {
        if (props.activePatient?.id) {
          const data = await props.getPaginatedAssessmentsByPatientId(
            props.activePatient.id,
            limit,
            sortOption,
            currentPage,
            [],
          );
          setTotalPage(await data.page);
          setCurrentPage(1);
        }
      } catch (e) {
        console.log(e);
      }
    })()
    return () => {
      props.clearStateAfterAssessmentConf();
    }
  }, [props.activePatient]);

  const fetchSortedList = sort => {
    setCurrentPage(1);
    setSortOption(sort);
    // props.getAssessmentsByPatientId(props.activePatient.id, 0, sort);
    props.getPaginatedAssessmentsByPatientId(
      props.activePatient.id,
      limit,
      sort, // sortOption,
      1,
      // props.assessmentsByPatientId?.[props.activePatient.id],
      []
    )
  };

  const openReports = assessment => {
    let { id } = assessment;

    if (id && props.activePatient && props.activePatient.id) {
      props.setLoader(true);
      history.push(
        DYNAMIC_ROUTES.REPORTS_BY_ASSESSMENT_ID(props.activePatient.id, id),
      );
    }
  };

  /**
   * @desc To handle edit patient, and open modal to edit details
   */
  const onPatientEditClick = () => {
    props.setModalWithType(true, MODALS.EDIT_PATIENT_MODAL);
  };

  const onImportTestButtonClick = () => {
    let modalState = {
      patientId: props.activePatient.id,
    };

    props.setModalWithType(true, MODALS.IMPORT_TESTS_MODAL, modalState);
  };

  const onImportTestRowClick = importTest => {
    props.setModalWithType(true, MODALS.PATIENTS_IMPORTED_TEST_VIEW_MODAL, {
      ...importTest,
      size: 'lg',
    });
  };

  const onExtractTestRowClick = importTest => {
    props.setModalWithType(true, MODALS.PATIENT_SUMMARY_VIEW_MODAL, {
      ...importTest,
      size: 'lg',
    });
  };

  const onExtractImageFromPdf = (importTest) => {
    props.setModalWithType(true, MODALS.PATIENT_EXTRACTED_IMAGE_FROM_PDF_MODAL, { ...importTest, size: 'lg' })
  }

  const onExtractTextDataFromPdf = (importTest) => {
    props.setModalWithType(true, MODALS.PATIENT_EXTRACTED_TEXT_DATA_FROM_PDF_MODAL, { ...importTest, size: 'lg' })
  }

  return (
    <div className="content__right js-content__right">
      <div className="patient__list eadieWhite-bg patient__list--active">
        <PatientInfo patient={props.activePatient} />
        {props.activePatient ? (
          <div className="patient__tableList">
            <PatientActions
              patient={props.activePatient}
              onPatientEditClick={onPatientEditClick}
              onImportTestButtonClick={onImportTestButtonClick}
            />
            {props.activePatient && props.assessmentsByPatientId ? (
              <PatientAssessmentsList
                assessments={
                  props.assessmentsByPatientId[props.activePatient.id]
                }
                openReports={openReports}
                fetchSortedList={fetchSortedList}
                lastElementRef={lastElementRef}
              />
            ) : null}
            {props.activePatient && (
              <PatientImportTestList
                importedTests={props.activePatient.importedTests}
                onImportTestRowClick={onImportTestRowClick}
                onExtractTestRowClick={onExtractTestRowClick}
                onExtractImageFromPdf = { onExtractImageFromPdf }
                onExtractTextDataFromPdf = { onExtractTextDataFromPdf }
              />
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = ({ patients, assessment, loader }) => ({
  loading: loader.set,
  activePatient: patients.activePatient,
  assessmentsByPatientId: assessment.activeAssessmentsByPatient,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setModalWithType,
      getAssessmentsByPatientId,
      getPaginatedAssessmentsByPatientId,
      clearStateAfterAssessmentConf,
      setLoader,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(PatientDetailsPanel));
