import * as CountryStateCityService from "../services/countryStateCity/countryStateCity"
import { COUNTRY_STATE_CITY } from "../shared/constants/actions.constants"

/**
 * @desc To fetch all countries list
 * @public
 */
export const getCountries = () => {
  return async dispatch => {
    try {
        let countries  = await CountryStateCityService.getCountries()
        dispatch(setCountiesObj(countries))
    } catch (error) {
        dispatch(setCountiesObj([]))
    }
  }
}

/**
 * @desc To fetch all states based on countryId
 * @param {Number} countryId
 * @public
 */
export const getStatesByCountryId = (countryId) => {
  return async (dispatch, state) => {
    try {
      if (hasStatesListInStore(state(), countryId)) {
        return true
      } else {
        let states = await CountryStateCityService.getStatesByCountryId(countryId)
        dispatch(setStatesObj(countryId, states))
      }
    } catch (error) {
        dispatch(setStatesObj(0, []))
    }
  }
}

// Private Methods
/**
 * @desc Action to set country list in store
 * @param {Array} countries
 * @private
 */
const setCountiesObj = (countries = []) => {
    return {
      type: COUNTRY_STATE_CITY.SET_COUNTRIES,
      payload: countries
    }
}

/**
 * @desc Action to set state object in store
 * @param {Number} countryId
 * @param {Array} states
 * @private
 */
const setStatesObj = (countryId, states = []) => {
    return {
      type: COUNTRY_STATE_CITY.SET_STATES_BY_COUNTRY_ID,
      payload: { countryId, states }
    }
}

/**
 * @desc Check is the states list is there in store based on countryId input
 * @param {Object} store
 * @param {Number} countryId
 * @private
 */
const hasStatesListInStore = (store, countryId) => {
  try {
    return store.countryStateCity.statesObj[countryId].length > 0
  } catch (error) {
    return false
  }
}
