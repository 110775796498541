import React from 'react'
import { parseDate, toCamelCase } from "../../../shared/utils/wrappers"
import { patientFullName } from "../../../shared/utils/patient"

export const PatientInfo = (props) => {
    let patient = props.patient || {}

    return (
      <div className="patient__main">
        <div className="patient__detail">
          <label className="patient__name">{ patientFullName(patient) }</label>
        </div>
        <div className="patient__detail">
          <label>DOB: </label>
          {" "}{ parseDate(patient.dateOfBirth) }
        </div>
        <div className="patient__detail">
          <label>Patient ID:</label>
          {" "}{ patient.patientId }
        </div>
        <div className="patient__detail">
          <label>Health Card No:</label>
          {" "}{ patient.personalHealthNumber }
        </div>
        <div className="patient__detail">
          <label>Language:</label>{" "}
          {" "}{ toCamelCase(patient.language) }
        </div>
        {/* <div className="patient__detail">
          <label>Strabismus:</label>
          { patient.strabismus }
        </div> */}
        {/* <div className="patient__detail">
          <label>Single Eye:</label>
          {" "}{ patient.singleEye }
        </div> */}
      </div>
    )
}

export default PatientInfo
