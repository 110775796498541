import React, { useEffect } from "react"
import { connect } from "react-redux"
import Header from "../components/shared/header"
import Sidebar from "../components/shared/sidebar"
import InitiateAssessmentPanel from "../components/initiateAssessment/initiateAssessmentPanel"

const InitiateAssessmentContainer = (props) => {

  useEffect(() => {
    window.heightcalculation()
  }, [])

  return (
    <div>
        <Header loggedInUser = { props.loggedInUser } />
        <main className="wrapper">
            <Sidebar />
            <div className = "content-wrapper">
                <InitiateAssessmentPanel />
            </div>
        </main>
    </div>
  )
}

const mapStateToProps = ({ auth }) => ({
    loggedInUser: auth.loggedInUser
})

export default connect(mapStateToProps)(InitiateAssessmentContainer)
