import React, { useState } from 'react'
import { Formik } from 'formik'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { UserForm, UserSchema, USER_ROLES, DATE_FORMATS, LEFT_RIGHT_SETTINGS } from "../../../models/users.model"
import { addUser } from "../../../actions/users.action"
import { setModalWithType } from "../../../actions/modal.action"
import { toCamelCase } from "../../../shared/utils/wrappers"

export const AddUserModal = (props) => {

  let [ user ] = useState(UserForm)

  /**
   * @desc To handle user form submission.
   * @param {object} event
   */
  const addUserFormHandler = (event) => {
    props.addUser(getUserData(event))
  }

  /**
   * @desc To format user data for submission
   * @param {object} data
   * @returns user's object
   */
  const getUserData = (data) => {
    let user = Object.assign({}, data)
    delete user.confirmPassword
    return user
  }

  return (
    <React.Fragment>
      <div className="modal-header">
        <h2 className="modal-title" id="patient-modalLabel">Add User</h2>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick = { () => props.setModalWithType(false) }>
            <span aria-hidden="true"><i className="fabicon-close"></i></span>
        </button>
      </div>
      <div className="modal-body pd-b-30">
        <Formik
          enableReinitialize = { true }
          initialValues = { user }
          validationSchema = { UserSchema(true) }
          onSubmit = { addUserFormHandler }
        >
          {
            ({ values, handleChange, handleSubmit, setFieldValue, errors, touched }) => (
              <React.Fragment>
                  <form onSubmit = { handleSubmit }>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label>First Name</label>
                        <input id = "firstName" name = "firstName" type="text" className = { `form-control ${ (errors.firstName && touched.firstName) ? "is-invalid" : null }` } placeholder="Enter First Name" onChange = { handleChange } value = { values.firstName } />
                          { errors.firstName && touched.firstName ? (
                            <div className="invalid-feedback">
                              { errors.firstName }
                            </div>
                          ) : null }
                      </div>
                      <div className="form-group col-md-6">
                        <label>Last Name</label>
                        <input id = "lastName" name = "lastName" type="text" className = { `form-control ${ (errors.lastName && touched.lastName) ? "is-invalid" : null }` } placeholder="Enter Last Name" onChange = { handleChange } value = { values.lastName } />
                          { errors.lastName && touched.lastName ? (
                            <div className="invalid-feedback">
                              { errors.lastName }
                            </div>
                          ) : null }
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="role">Role</label>
                        <select className = { `form-control ${ (errors.role && touched.role) ? "is-invalid" : null }` } id = "role" name = "role" onChange = { handleChange } value = { values.role }>
                            <option defaultValue value = "">Select</option>
                            {
                              USER_ROLES.map((role, i) => (
                                <option value = { role } key = { i } >{ toCamelCase(role) }</option>
                              ))
                            }
                          </select>
                          { errors.role && touched.role ? (
                            <div className="invalid-feedback">
                              { errors.role }
                            </div>
                          ) : null }
                      </div>
                      <div className="form-group col-md-6">
                        <label>Email Address</label>
                        <input id = "email" name = "email" type="text" className = { `form-control ${ (errors.email && touched.email) ? "is-invalid" : null }` } placeholder="Enter Email" onChange = { handleChange } value = { values.email } />
                          { errors.email && touched.email ? (
                            <div className="invalid-feedback">
                              { errors.email }
                            </div>
                          ) : null }
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="dateformat">Date Format</label>
                        <select className = { `form-control ${ (errors.settings && errors.settings.dateFormat && touched.settings && touched.settings.dateFormat) ? "is-invalid" : null }` } id = "settings.dateFormat" name = "settings.dateFormat" onChange = { handleChange } value = { values.settings.dateFormat }>
                            <option defaultValue value = "">Select Format</option>
                            {
                              DATE_FORMATS.map((format, i) => (
                                <option value = { format } key = { i }>{ format }</option>
                              ))
                            }
                          </select>
                          { errors.settings && errors.settings.dateFormat && touched.settings && touched.settings.dateFormat ? (
                            <div className="invalid-feedback">
                              { errors.settings.dateFormat }
                            </div>
                          ) : null }
                      </div>
                      <div className="form-group col-md-6">
                        <label>Password</label>
                        <input id = "password" name = "password" type="password" className = { `form-control ${ (errors.password && touched.password) ? "is-invalid" : null }` } placeholder="Enter Password" onChange = { handleChange } value = { values.password } />
                          { errors.password && touched.password ? (
                            <div className="invalid-feedback">
                              { errors.password }
                            </div>
                          ) : null }
                      </div>
                      <div className="form-group col-md-6">
                        <label>Confirm password</label>
                        <input id = "confirmPassword" name = "confirmPassword" type="password" className = { `form-control ${ (errors.confirmPassword && touched.confirmPassword) ? "is-invalid" : null }` } placeholder="Enter Confirm Password" onChange = { handleChange } value = { values.confirmPassword } />
                          { errors.confirmPassword && touched.confirmPassword ? (
                            <div className="invalid-feedback">
                              { errors.confirmPassword }
                            </div>
                          ) : null }
                      </div>
                    </div>
                    <button type="submit" className="btn btn-red">Create</button>
                  </form>
              </React.Fragment>
            )
          }
        </Formik>
      </div>
    </React.Fragment>
  )
}

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    addUser,
    setModalWithType
  }, dispatch)
)

export default connect(null, mapDispatchToProps)(AddUserModal)
 