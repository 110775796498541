import React from 'react'
import { parseDate } from "../../../shared/utils/wrappers"
import { patientFullName } from "../../../shared/utils/patient"

export const PatientListRow = (props) => {
    return (
        <div className = { `trow ${ (props.activePatient && props.patient.id === props.activePatient.id) ? "active" : ""}` } onClick = { () => props.onPatientRowClick(props.patient) }>
            <div className="tcol w-30">{ patientFullName(props.patient) }</div>
            <div className="tcol w-30">{ parseDate(props.patient.dateOfBirth) }</div>
            <div className="tcol w-30">{ props.patient.patientId }</div>
            <div className="tcol w-10">
                <div className="dropdown dropdown-more text-right">
                    <button className="btn btn-transparent" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                        <i className="fabicon-more-vertical"></i>
                    </button>
                    <div className="dropdown-menu dropdown-menu-right">
                        <a className="dropdown-item" href="#!" onClick = { () => props.onPatientEditClick(props.patient) }>Edit</a>
                        <a className="dropdown-item" href="#!"  onClick = { () => props.onPatientDeleteClick(props.patient) }>Delete</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PatientListRow
