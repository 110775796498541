import csc from 'country-state-city'

/**
 * @desc To fetch all countries list
 * @public
 */
export const getCountries = () => {
    return new Promise(async (res, rej) => {
        try {
            let data = await csc.getAllCountries()
            data = data.filter((countryObj, id)=>{
                return ['india', 'canada', 'united states'].indexOf(countryObj.name.toLowerCase())>=0
            })
            res(data)
        } catch (error) {
            rej(error)
        }
    })
}

/**
 * @desc To fetch all states list by given country Id
 * @param {number} countryId
 * @public
 */
export const getStatesByCountryId = (countryId) => {
    return new Promise(async (res, rej) => {
        try {
            let data = await csc.getStatesOfCountry(countryId)
            res(data)
        } catch (error) {
            rej(error)
        }
    })
}
