import React from 'react'
import { CHART_ORIENTATION, CHART_NAMES, OVERVIEW_CHART_SIZE } from  '../../../../shared/constants/chart.constants'
import Eye from '../../../shared/graphs/eye'
import { chartNameToCamelCase } from '../singleView/chartBox';

export const ChartBlock = (props) => {
    const OSOD = props.OSOD || 'OD';
    let eyeParams = {
        chartId: `chart-Overview-${ OSOD }-${ props.assessmentIndex }-${props.chartObj.key}`,
        chartName: props.chartObj.key,
        chartData: props.assessment && props.assessment.data && props.assessment.data.processed && props.assessment.data.processed && props.assessment.data?.[chartNameToCamelCase(props.chartObj.text)]?.[OSOD] ? props.assessment.data.processed && props.assessment.data?.[chartNameToCamelCase(props.chartObj.text)]?.[OSOD] : [],
        chartOrientation: CHART_ORIENTATION[OSOD],
        transform: OVERVIEW_CHART_SIZE[props?.assessment?.configuration?.grid] || 0.05,
        height: 100,
        width: 100,
        transformOrigin: 'left top',
        assessment: props.assessment
    }

    if (props.chartObj.key === CHART_NAMES.CLUSTER_MD) {
        eyeParams = {
            ...eyeParams,
            transform: 1.3,
            right: 0,
            top: 10,
            left: 15
        }
    }


    return (
        <div className="col-3"> 
            <div className="fliter__imgbox">
                <p className="mg-b-15 bold">&nbsp;</p>
                {eyeParams.chartData.length === 0 ? 
                    <div>
                        <img src="/assets/images/noDataFound.png" alt="" className="w-100 mg-b-5"/>
                    </div> 
                    : 
                    props.assessment.data.processed && props.assessment.data.processed[OSOD] && <Eye {...eyeParams} />
                }
            </div>
        </div>
    )
}

export default ChartBlock
