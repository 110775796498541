import { HMDDEVICES, BACKUP } from "../shared/constants/actions.constants"

const initialState = {
  backupList: [],
  scheduleList: [],
  backupPagination: null
}

export default function(state = initialState, action) {
  switch (action.type) {
    case BACKUP.ADD_BACKUP:
        return {
            ...state,
            backupList: [...state.backupList, ...action.payload]
        }
    
    case BACKUP.SET_BACKUP_LIST:
        return {
            ...state,
            backupList: [ ...action.payload ]
        }
    
    case BACKUP.SET_SCHEDULE_LIST:
        return {
            ...state,
            scheduleList: [ ...action.payload ]
        }
    case BACKUP.SET_BACKUP_PAGINATION:
        return {
            ...state,
            backupPagination: action.payload
        }
    
    default:
      return state
  }
}
