import { COUNTRY_STATE_CITY } from "../shared/constants/actions.constants"

const initialState = {
  countriesList: [],
  statesObj: {}
}

export default function(state = initialState, action) {

  switch (action.type) {
    case COUNTRY_STATE_CITY.SET_COUNTRIES:
      return {
        ...state,
        countriesList: [ ...action.payload ]
      }

    case COUNTRY_STATE_CITY.SET_STATES_BY_COUNTRY_ID:
      let { countryId, states } = action.payload
      let statesObj = { ...state.statesObj, [countryId]: states }

      return {
        ...state,
        statesObj
      }

    default:
      return state
  }
}
