import CONFIG from "../../config/index"
import { setActiveAssessmentProcessedData, updateActiveAssessment } from "../../actions/assessment.action"
import * as Logger from "../../shared/utils/logger";

export const onProcessedSummaryDataUpdate = (data, assessmentId) => {
    Logger.message("SUMMARY DATA STREAM RECEIVING DATA...", { data })
    let { data: { summary }, status, _id , ...rest } = data;

    if(assessmentId !== _id ) return;

    Logger.message("SUMMARY DATA STREAM RECEIVING DATA...", { summary, status, _id, ...rest })

    CONFIG.Store.dispatch(updateActiveAssessment(_id, { status, data: { summary } }))
}