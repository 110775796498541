import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom"
import { bindActionCreators } from 'redux'
import { patientFullName } from "../../../shared/utils/patient"
import { parseDate, toCamelCase } from "../../../shared/utils/wrappers"
import AssessmentConfiguration from './assessmentConfiguration'

export const AssessmentDetails = (props) => {

  const selectedHmdIndex = props.selectedHmdDevice === null ? null : (props.hmdDeviceList.findIndex(hmdDevice => hmdDevice.id === props.selectedHmdDevice) + 1);

  return (
    <React.Fragment>
      {
        props.patient && (
          <React.Fragment>
              <div className="patient__configure">
                <div className="patient__configure--innner">
                  <div className="patient__configure--left">
                    <ul>
                      <li className="patient__configure--first">
                        <div className="patient__configure--name">
                          <span className="device-square light-blue">D{selectedHmdIndex}</span>
                          <p>{ patientFullName(props.patient) }</p>
                        </div>
                      </li>
                      <li>
                        <div className="patient__detail">
                          <label className="bold">DOB:</label>{" "}
                          <span className="patient__value">{ parseDate(props.patient.dateOfBirth) }</span>
                        </div>
                      </li>
                      <li>
                        <div className="patient__detail">
                          <label className="bold">Patient ID:</label>{" "}
                          <span className="patient__value">{ props.patient.patientId }</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="patient__configure--right">
                    {/* <ul>
                      <li>
                        <label>Reliability</label>
                        <p>-- %</p>
                      </li>
                      <li>
                        <label>Progress</label>
                        <p>0%</p>
                      </li>
                      <li>
                        <label>Timer</label>
                        <p>00:00:00</p>
                      </li>
                    </ul> */}
                  </div>
                </div>
                <button type="button" className="btn btn-arrow" data-toggle="collapse" data-target="#collapseDetails"
                  aria-expanded="true" aria-controls="collapseDetails"></button>
              </div>

              <div className="collapse show" id="collapseDetails">
                <div className="patients__language">
                  <div className="patients__details--left">
                    <div className="patient__detail">
                      <label>Language:</label>{" "}
                      <input type="text" className="patient__value" readOnly value={ toCamelCase(props.patient.language) } />
                    </div>
                    {/* <div className="patient__detail">
                      <label>Strabismus:</label>
                      <input type="text" className="patient__value" readOnly value={ props.patient.strabismus } />
                    </div> */}
                  </div>

                  <AssessmentConfiguration assessmentConfigureFormData={props.assessmentConfigureFormData || null} assessment={ props.assessment } patient={props.patient} />

                </div>
              </div>

          </React.Fragment>
        )
      }
    </React.Fragment>
  )
}

const mapStateToProps = ({ patients, assessment, loader, hmdDevices }) => ({
  loading: loader.set,
  hmdDeviceList: hmdDevices.hmdDeviceList,
  selectedHmdDevice: hmdDevices.selectedHmdDevice,
});

export default connect(
  mapStateToProps,
  null,
)(withRouter(AssessmentDetails));