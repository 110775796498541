import React from 'react'
import { Formik } from 'formik'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { UserSchema } from "../../../models/users.model"
import { updateUser } from "../../../actions/users.action"

export const EditProfile = (props) => {

    /**
     * @desc To handle user form submittion and update it.
     * @param {object} event
     */
    const userFormHandler = (event) => {
        props.updateUser(event)
    }

    return (
        <div className="tab-pane fade active show" id="profile" role="tabpanel" aria-labelledby="profile-tab">
            <Formik
                enableReinitialize = { true }
                initialValues = { props.user }
                validationSchema = { UserSchema(false) }
                onSubmit = { userFormHandler }
            >
            {
                ({ values, handleChange, handleSubmit, setFieldValue, errors, touched }) => (
                    <React.Fragment>
                        <form onSubmit = { handleSubmit }>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <input type="text" className = { `form-control ${ (errors.firstName && touched.firstName) ? "is-invalid" : null }` } placeholder="Enter first name" id = "firstName" name = "firstName" onChange = { handleChange } value = { values.firstName } />
                                            { errors.firstName && touched.firstName ? (
                                            <div className="invalid-feedback">
                                                { errors.firstName }
                                            </div>
                                            ) : null }
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <input type="text" className = { `form-control ${ (errors.lastName && touched.lastName) ? "is-invalid" : null }` } placeholder="Enter first name" id = "lastName" name = "lastName" onChange = { handleChange } value = { values.lastName } />
                                            { errors.lastName && touched.lastName ? (
                                            <div className="invalid-feedback">
                                                { errors.lastName }
                                            </div>
                                            ) : null }
                                    </div>
                                </div>
                                <div className="col-lg-12 mg-t-15">
                                    <button className="btn btn-red" type="submit">Save</button>
                                </div>
                            </div>
                        </form>
                    </React.Fragment>
                )
            }
            </Formik>
        </div>
    )
}

const mapDispatchToProps = dispatch => (
    bindActionCreators({
      updateUser
    }, dispatch)
  )
  
export default connect(null, mapDispatchToProps)(EditProfile)
