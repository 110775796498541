import React from "react"

export const NoRecord = (props) => {
  return (
    <div className="text-center pt-5 pb-5">
        <h4><small>{ props.msg || "No records found for your search!" }</small></h4>
    </div>
  )
}

export default NoRecord