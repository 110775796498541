import React, { useEffect } from "react"
import { connect } from "react-redux"
import Header from "../components/shared/header"
import Sidebar from "../components/shared/sidebar"
import UsersPanel from "../components/manageUsers/leftSection/usersPanel"
import UserDetailPanel from "../components/manageUsers/rightSection/userDetailPanel"

const ManageUsersContainer = (props) => {

  useEffect(() => {
    window.heightcalculation()
  }, [])

  return (
    <div>
      <Header loggedInUser = { props.loggedInUser } />
      <main className="wrapper">
        <Sidebar />
        <div className="content-wrapper">
          <div className="content__inner">
            <UsersPanel />
            <UserDetailPanel />
          </div>
        </div>
      </main>
    </div>
  )
}

const mapStateToProps = ({ auth }) => ({
  loggedInUser: auth.loggedInUser
})

export default connect(mapStateToProps)(ManageUsersContainer)
