import React from 'react'

export const FilterFooterPanel = (props) => {
    return (
        <div className="footer-filter">
            <button className="btn btn-filter js-fliteroption d-print-none" onClick = { props.onFilterClick }><i className="icon-filter"></i> Filter & Sort Options</button>
        </div>
    )
}

export default FilterFooterPanel
