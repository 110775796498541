import React, { useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { toCamelCase, roundToNearestQuarter } from "../../../shared/utils/wrappers"
import { GRID_TYPES, RESPONSE_TYPES, STRATEGIES, EYE, TEST_FOVEAL_THRESHOLD, STRATEGIES_LABELS, GRID_TYPES_MAP, SELECTED_CYLINDER_FORMAT, AssessmentConfigurationForm } from "../../../models/assessment.model"
import { AssessmentConfigurationSchema } from "../../../models/assessment.model"
import PreviousConfigurations from './previousConfigurations'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { setLoader } from '../../../actions/loader.action'
import { selectHmdDevice } from '../../../actions/hmdDevices.action'
import { HMD_STATUSES } from '../../../shared/constants/shared.constants'
import { showInAtLeastThreeDigits } from '../../../shared/utils/patient'
import { selectAssessmentForForm } from '../../../actions/assessment.action'

export const ConfigureTest = (props) => {
    const validateSchema = (values) => {
        props.assessmentConfigureTestFormHandler(values)
    }
    
    const proceedToNext = async (_values, formikHelpers) => {
        try {
            await props.calculateTrialLensHandler();
            await props.trialTestHandler(()=>props.setIsConfigurationsActive(false));
        } catch (error) {
            await props.setIsConfigurationsActive(true);
            console.log('error from proceedToNext', error);
        }
    };

    const handleGridChange = ( grid, values, setFieldValue ) => {
        setFieldValue('configuration.grid', grid);

        if([GRID_TYPES_MAP["10-2"], GRID_TYPES_MAP["30-2"]].includes(grid) && ![STRATEGIES_LABELS['zest'].value].includes(values.configuration.strategy)){
            setFieldValue('configuration.strategy', STRATEGIES_LABELS['zest'].value);
        }
        else if ([GRID_TYPES_MAP['24-2C']].includes(values.configuration.grid) && [STRATEGIES_LABELS['zest fast'].value].includes(values.configuration.strategy)){
            setFieldValue('configuration.strategy', STRATEGIES_LABELS['zest'].value);
        }
    }

    const isStrategyDisabled = (grid, strategy) => {
        if([GRID_TYPES_MAP["10-2"], GRID_TYPES_MAP["30-2"]].includes(grid) && [STRATEGIES_LABELS['zest fast'].value, STRATEGIES_LABELS['zest faster'].value].includes(strategy)){
            return true;
        }
        else if([GRID_TYPES_MAP["24-2C"]].includes(grid) && [STRATEGIES_LABELS['zest fast'].value].includes(strategy)){
            return true;
        }
        else {
            return false;
        }
    }

    const handleSelectedHmd = ( handleChange, e) => {
        handleChange(e);
        props.selectHmdDevice(e.target.value);
    }

    const handleBlur = setFieldValue => (e)=> setFieldValue(e.target.name, Number(e.target.value).toFixed(2));

    useEffect(() => {
        return () => {
            props.selectHmdDevice(null);
            props.selectAssessmentForForm({...AssessmentConfigurationForm, timestamp: new Date().getTime()})
        }
    }, []);


    return (
        <React.Fragment>
            <div className={`tab-pane fade ${props.isConfigurationsActive ? "show active" : ""}`} id="pills-configure" role="tabpanel">
                <div className="congfigure__test--flex congfigure__jsheight">
                    <Formik
                        enableReinitialize = { true }
                        initialValues={ props.selectedAssessmentForForm }
                        validationSchema = { AssessmentConfigurationSchema }
                        validate = { validateSchema }
                        onSubmit={ proceedToNext }
                    >
                        {
                            ({ values, handleChange, handleSubmit, setFieldValue, errors, touched, isSubmitting }) => (
                            <React.Fragment>
                                <Form className="congfigure__testcontent width__70per">
                                    <div>
                                            <div className="row">
                                                <div className="col-lg-7">
                                                    <div className="congfigure__testcontent--row mg-b-15">

                                                        <h3 className="mg-b-10">Assign Device</h3>
                                                        {
                                                            (props.hmdDevices === null || props.hmdDevices === undefined || (props.hmdDevices.length === 0)) && (
                                                                <div className="alert alert-secondary d-inline-block" role="alert">
                                                                    No device added to account
                                                                </div>
                                                            )
                                                        }
                                                        <ul className="congfigure__list--flex assign-device-list" id="hmdId" name="hmdId">
                                                        {
                                                            props.hmdDevices && props.hmdDevices.map((device, i) => (
                                                                <li key={i} className={values.hmdId && device.id === values.hmdId ? '' : 'disabled__device'}>
                                                                    <div className="checks__button">
                                                                        <input type="radio" className={device.patient || device.status === HMD_STATUSES.NOT_DETECTED ? 'cursor-not-allowed' : 'not-in-use'} 
                                                                            name="hmdId" value={ device.patient || device.status === HMD_STATUSES.NOT_DETECTED? '' : device.id }
                                                                            checked={values.hmdId && device.id === values.hmdId} 
                                                                            onChange={(e) => handleSelectedHmd(handleChange, e)} />
                                                                        <label>
                                                                            <span className={ values.hmdId && device.id === values.hmdId ? 'device-square device__square--big light-blue' : 'device-square device__square--big'}
                                                                            style={{background: (props.assessment && props.assessment.hmdId && props.assessment.hmdId === device.id ? '' : (values.hmdId && device.id === values.hmdId ? '' : (device.patient || device.status === HMD_STATUSES.NOT_DETECTED? '': 'black')) )}}
                                                                            >
                                                                                <i className="device-square--text">D{i+1}</i>
                                                                                <i className="icon-device"></i>
                                                                            </span>
                                                                            <span className="asssign__text">{ props.assessment && props.assessment.hmdId && props.assessment.hmdId === device.id ? 'Connected' : (values.hmdId && device.id === values.hmdId ? 'Selected' : (device.status === HMD_STATUSES.NOT_DETECTED ? 'Not Detected' :  device.patient? 'In Use': 'Not In Use')) }</span>
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                            ))
                                                        }
                                                        </ul>
                                                        { errors.hmdId && touched.hmdId ? (
                                                            <div className="d-block invalid-feedback">
                                                            { errors.hmdId }
                                                            </div>
                                                        ) : null }
                                                    </div>

                                                    <div className="congfigure__testcontent--row mg-b-15">
                                                        <h3 className="mg-b-5">Grid</h3>
                                                        <ul className="congfigure__list--flex" id = "configuration.grid" name = "configuration.grid" onChange = { handleChange } value = { values.configuration.grid }>
                                                            {
                                                                GRID_TYPES.map((grid, i) => (
                                                                    <li key = { i }>
                                                                        <div className="checks__button">
                                                                            <input type="radio" name="configuration.grid" readOnly value = { grid } key = { i } checked = { values.configuration.grid && grid === values.configuration.grid }
                                                                            onChange={()=>handleGridChange(grid, values, setFieldValue)}
                                                                            disabled={isStrategyDisabled(grid, values.configuration.strategy)}
                                                                            />
                                                                            <label>{grid}</label>
                                                                        </div>
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                        { errors.configuration && touched.configuration && errors.configuration.grid && touched.configuration.grid ? (
                                                            <div className="d-block invalid-feedback">
                                                            { errors.configuration.grid }
                                                            </div>
                                                        ) : null }
                                                    </div>

                                                    <div className="congfigure__testcontent--row mg-b-15">
                                                        <h3 className="mg-b-5">Response Type</h3>
                                                        <ul className="congfigure__list--flex" id = "configuration.responseType" name = "configuration.responseType" onChange = { handleChange } value = { values.configuration.responseType }>
                                                            {
                                                                RESPONSE_TYPES.map((responseType, i) => (
                                                                    <li key = { i }>
                                                                        <div className="checks__button">
                                                                            <input type="radio" name="configuration.responseType" readOnly value = { responseType } key = { i } checked = { values.configuration.responseType && responseType === values.configuration.responseType }/>
                                                                            <label>{toCamelCase(responseType)}</label>
                                                                        </div>
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                        { errors.configuration && touched.configuration && errors.configuration.responseType && touched.configuration.responseType ? (
                                                            <div className="d-block invalid-feedback">
                                                            { errors.configuration.responseType }
                                                            </div>
                                                        ) : null }
                                                    </div>
                                                    
                                                    <div className="congfigure__testcontent--row mg-b-15">
                                                        <h3 className="mg-b-5">Strategy</h3>
                                                        <ul className="congfigure__list--flex" id = "configuration.strategy" name = "configuration.strategy" onChange = { handleChange } value = { values.configuration.strategy }>
                                                            {
                                                                STRATEGIES.map((strategy, i) => (
                                                                    <li key = { i }>
                                                                        <div className="checks__button">
                                                                            <input type="radio" name="configuration.strategy" readOnly value = { strategy.value } key = { i } checked = { values.configuration.strategy && strategy.value === values.configuration.strategy } disabled={isStrategyDisabled(values.configuration.grid, strategy.value)}/>
                                                                            <label>{(strategy.label)}</label>
                                                                        </div>
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                        { errors.configuration && touched.configuration  && errors.configuration.strategy && touched.configuration.strategy ? (
                                                            <div className="d-block invalid-feedback">
                                                            { errors.configuration.strategy }
                                                            </div>
                                                        ) : null }
                                                    </div>

                                                    <div className="congfigure__testcontent--row mg-b-15">
                                                        <h3 className="mg-b-5">Eye</h3>
                                                        <ul className="congfigure__list--flex" id = "configuration.eye" name = "configuration.eye" onChange = { handleChange } value = { values.configuration.eye }>
                                                            {
                                                                EYE.map((eye, i) => (
                                                                    <li key = { i }>
                                                                        <div className="checks__button">
                                                                            <input type="radio" name="configuration.eye" readOnly value = { eye } key = { i } checked = { values.configuration.eye && eye === values.configuration.eye } />
                                                                            <label>{toCamelCase(eye)}</label>
                                                                        </div>
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                        { errors.configuration && touched.configuration && errors.configuration.eye && touched.configuration.eye ? (
                                                            <div className="d-block invalid-feedback">
                                                            { errors.configuration.eye }
                                                            </div>
                                                        ) : null }
                                                    </div>

                                                    
                                                    <div className="congfigure__testcontent--row mg-b-15">
                                                        <h3 className="mg-b-5">Test Foveal Threshold</h3>
                                                        <ul className="congfigure__list--flex" id = "configuration.testFovealThreshold" name = "configuration.testFovealThreshold" onChange = { handleChange } value = { values.configuration.testFovealThreshold }>
                                                            {
                                                                TEST_FOVEAL_THRESHOLD.map((threshold, i) => (
                                                                    <li key = { i }>
                                                                        <div className="checks__button">
                                                                            <input type="radio" name="configuration.testFovealThreshold" readOnly value = { threshold } key = { i } checked = { values.configuration.testFovealThreshold && threshold === values.configuration.testFovealThreshold } />
                                                                            <label>{toCamelCase(threshold)}</label>
                                                                        </div>
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                        { errors.configuration && touched.configuration && errors.configuration.testFovealThreshold && touched.configuration.testFovealThreshold ? (
                                                            <div className="d-block invalid-feedback">
                                                            { errors.configuration.testFovealThreshold }
                                                            </div>
                                                        ) : null }
                                                    </div>
                                                </div>
                                                <div className="col-lg-5">
                                                    <div className="congfigure__testcontent--row mg-b-15">
                                                        <h3 className="mg-b-10">Patient Parameters</h3>
                                                        <div className = "patient__flex">                                
                                                            <div className = "patient__flex--row">
                                                                <h3 className = "mr-2">Visual Acuity</h3>
                                                            </div>                              
                                                        </div>
                                                        <div className = "patient__flex mg-b-10">                                
                                                            <div className = "input-group patient__flex--row">
                                                                <input type="text" className = { `form-control width__34 border-right-0 ${ (errors.configuration && (errors.configuration.visualAcuityPart1 || errors.configuration.visualAcuityPart2)) ? "is-invalid" : '' }` } id = "configuration.visualAcuityPart1" name = "configuration.visualAcuityPart1" placeholder="-" onChange = { handleChange } value = { values.configuration.visualAcuityPart1 } />
                                                                <span  className = { `form-control width__34 input-group-text border-right-0 ${ (errors.configuration && (errors.configuration.visualAcuityPart1 || errors.configuration.visualAcuityPart2)) ? "is-invalid" : '' }` }>/</span>
                                                                <input type="text" className = { `form-control width__34 border-left-0 ${ (errors.configuration && (errors.configuration.visualAcuityPart1 || errors.configuration.visualAcuityPart2)) ? "is-invalid" : '' }` } id = "configuration.visualAcuityPart2" name = "configuration.visualAcuityPart2" placeholder="-" onChange = { handleChange } value = { values.configuration.visualAcuityPart2 } />
                                                            </div>                               
                                                        </div>

                                                        {/* <div className="patient__flex mg-b-15">
                                                            <div className="patient__flex--row">
                                                                <input type="text" className = { `form-control ${ (errors.configuration && touched.configuration && errors.configuration.patientParameters && touched.configuration.patientParameters) ? "is-invalid" : null }` } id = "configuration.patientParameters" name = "configuration.patientParameters" placeholder="-" onChange = { handleChange } value = { values.configuration.patientParameters } />
                                                            </div>
                                                            
                                                            <div className="patient__flex--row">
                                                                <span className="patient__flex--text">IPD</span>
                                                            </div>
                                                        </div> */}
                                                        <div className = "patient__flex m-0 mg-b-10"> 
                                                            <div className="patient__flex--row">
                                                                <span className="patient__flex--text"></span>
                                                            </div>
                                                            <div className="patient__flex--row">
                                                                <h3 className="w-60-px mr-2">Sphere</h3>
                                                            </div>
                                                            <div className="patient__flex--row">
                                                                <h3 className="w-60-px mr-2">Cylinder</h3>
                                                            </div>
                                                            <div className="patient__flex--row">
                                                                <h3 className="w-45-px">Axis</h3>
                                                            </div>                    
                                                        </div>
                                                        <div className = "patient__flex m-0 mg-b-10">                                
                                                            <div className = "">
                                                                <span className = "patient__flex--text">OD</span>
                                                            </div> 
                                                            <div className = "patient__flex--row">
                                                                <input type="text" className = { `form-control text-center w-60-px ${ (errors.configuration && errors.configuration.odSphere) ? "is-invalid" : '' }` } id = "configuration.odSphere" name = "configuration.odSphere" placeholder="-" onChange = { handleChange } onBlur={ handleBlur(setFieldValue) } value = { values.configuration.odSphere } />
                                                            </div>
                                                            <div className = "patient__flex--row">
                                                                <input type="text" className = { `form-control text-center w-60-px ${ (errors.configuration && errors.configuration.odCylinder) ? "is-invalid" : '' }` } id = "configuration.odCylinder" name = "configuration.odCylinder" placeholder="-" onChange = { handleChange } onBlur={ handleBlur(setFieldValue) } value = { values.configuration.odCylinder } />
                                                            </div>
                                                            <div className = "patient__flex--row">
                                                                <input type="text" className = { `form-control text-center w-45-px ${ (errors.configuration && errors.configuration.odAxis) ? "is-invalid" : '' }` } id = "configuration.odAxis" name = "configuration.odAxis" placeholder="-" onChange = { handleChange } onBlur={ e => setFieldValue(e.target.name, showInAtLeastThreeDigits(e.target.value)) } value = { values.configuration.odAxis } />
                                                            </div>
                                                                                           
                                                        </div>
                                                        <div className = "patient__flex m-0 mg-b-10">                                
                                                            <div className = "">
                                                                <span className = "patient__flex--text">OS</span>
                                                            </div> 
                                                            <div className = "patient__flex--row">
                                                                <input type="text" className = { `form-control text-center w-60-px ${ (errors.configuration && errors.configuration.osSphere) ? "is-invalid" : '' }` } id = "configuration.osSphere" name = "configuration.osSphere" placeholder="-" onChange = { handleChange } onBlur={ handleBlur(setFieldValue) } value = { values.configuration.osSphere } />
                                                            </div>
                                                            <div className = "patient__flex--row">
                                                                <input type="text" className = { `form-control text-center w-60-px ${ (errors.configuration && errors.configuration.osCylinder) ? "is-invalid" : '' }` } id = "configuration.osCylinder" name = "configuration.osCylinder" placeholder="-" onChange = { handleChange } onBlur={ handleBlur(setFieldValue) } value = { values.configuration.osCylinder } />
                                                            </div>
                                                            <div className = "patient__flex--row">
                                                                <input type="text" className = { `form-control text-center w-45-px ${ (errors.configuration && errors.configuration.osAxis) ? "is-invalid" : '' }` } id = "configuration.osAxis" name = "configuration.osAxis" placeholder="-" onChange = { handleChange } onBlur={ e => setFieldValue(e.target.name, showInAtLeastThreeDigits(e.target.value)) } value = { values.configuration.osAxis } />
                                                            </div>
                                                                                           
                                                        </div>

                                                        <div className = "patient__flex">                                
                                                            <div className = "patient__flex--row">
                                                                <h3 className = "mr-2">Vertex Distance</h3>
                                                            </div>                              
                                                        </div>
                                                        <div className = "patient__flex mg-b-10">                                
                                                            <div className = "input-group patient__flex--row">
                                                                <input type="text" className = { `form-control text-center width__80 ${ (errors.configuration && errors.configuration.vertexDistance) ? "is-invalid" : '' }` } id = "configuration.vertexDistance" name = "configuration.vertexDistance" placeholder="-" onChange = { handleChange } value = { values.configuration.vertexDistance } />
                                                            </div>                               
                                                        </div>
                                                    </div>
                                                    <div className="congfigure__testcontent--row mg-b-15">
                                                        <h3 className="mg-b-5">Selected Cylinder Format</h3>
                                                        <ul className="congfigure__list--flex" id = "configuration.selectedCylinderFormat" name = "configuration.selectedCylinderFormat" onChange = { handleChange } value = { values.configuration.selectedCylinderFormat }>
                                                            {
                                                                SELECTED_CYLINDER_FORMAT.map((threshold, i) => (
                                                                    <li key = { i }>
                                                                        <div className="checks__button">
                                                                            <input type="radio" name="configuration.selectedCylinderFormat" readOnly value = { threshold } key = { i } checked = { values.configuration.selectedCylinderFormat && threshold === values.configuration.selectedCylinderFormat } />
                                                                            <label>{toCamelCase(threshold)}</label>
                                                                        </div>
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                        { errors.configuration && touched.configuration && errors.configuration.selectedCylinderFormat && touched.configuration.selectedCylinderFormat ? (
                                                            <div className="d-block invalid-feedback">
                                                            { errors.configuration.selectedCylinderFormat }
                                                            </div>
                                                        ) : null }
                                                    </div>
                                                    <div className="congfigure__testcontent--row mg-b-15">
                                                        <ul className="test__monitoring--lists">
                                                            <li>
                                                                <button type="button" className="btn btn__white" onClick = {()=>{props.calculateTrialLensHandler()}}>Calculate Trial Lens</button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="congfigure__testcontent--row mg-b-15">
                                                        {/* <ul className="stimulus__lists" id = "osod" name = "osod">
                                                            <li>
                                                                <div>
                                                                    <label>OS(Sphere):</label> {props.trialLensOSsphere}
                                                                    <br />
                                                                    <label>OS(Cylinder):</label> {props.trialLensOScylinder}
                                                                    <br />
                                                                    <label>OS(Axis):</label> {props.trialLensOSaxis}
                                                                </div>
                                                                <br />
                                                                <div>
                                                                    <label>OD(Sphere):</label> {props.trialLensODsphere}
                                                                    <br />
                                                                    <label>OD(Cylinder):</label> {props.trialLensODcylinder}
                                                                    <br />
                                                                    <label>OD(Axis):</label> {props.trialLensODaxis}
                                                                </div>
                                                            </li>
                                                            <li >
                                                            </li>
                                                        </ul> */}
                                                        <div className = "patient__flex">                                
                                                            <div className = "patient__flex--row width__34">
                                                                <h3>&nbsp;</h3>
                                                            </div>    
                                                            <div className = "patient__flex--row">
                                                                <h3 className = "width__34 mr-2">Sphere</h3>
                                                            </div>
                                                            <div className = "patient__flex--row">
                                                                <h3 className = "width__34 mr-2">Cylinder</h3>
                                                            </div>
                                                            <div className = "patient__flex--row width__34">
                                                                <h3 className = "width__34">Axis</h3>
                                                            </div>
                                                                                        
                                                        </div>
                                                        <div className = "patient__flex mg-b-10">                                
                                                            <div className = "patient__flex--row">
                                                                <span className = "patient__flex--text width__34">OD</span>
                                                            </div>  
                                                            <div className = "patient__flex--row">
                                                                {/* <h3 className = "width__34 mr-2">{roundToNearestQuarter(props.trialLensODsphere)}</h3> */}
                                                                <h3 className = "width__34 mr-2">{roundToNearestQuarter(props.trialLensODsphere, 2)}</h3>
                                                                {/* <h3 className = "width__34 mr-2">{(props.trialLensODsphere.toFixed(2))}</h3> */}
                                                            </div>
                                                            <div className = "patient__flex--row">
                                                                {/* <h3 className = "width__34 mr-2">{roundToNearestQuarter(props.trialLensODcylinder)}</h3> */}
                                                                <h3 className = "width__34 mr-2">{roundToNearestQuarter(props.trialLensODcylinder, 2)}</h3>
                                                                {/* <h3 className = "width__34 mr-2">{(props.trialLensODcylinder.toFixed(2))}</h3> */}
                                                            </div>
                                                            <div className = "patient__flex--row">
                                                                <h3 className = "width__34 mr-2">{showInAtLeastThreeDigits(props.trialLensODaxis)}</h3>
                                                            </div>
                                                                                          
                                                        </div>
                                                        <div className = "patient__flex mg-b-10">                                
                                                            <div className = "patient__flex--row">
                                                                <span className = "patient__flex--text width__34">OS</span>
                                                            </div> 
                                                            <div className = "patient__flex--row">
                                                                {/* <h3 className = "width__34 mr-2">{roundToNearestQuarter(props.trialLensOSsphere)}</h3> */}
                                                                <h3 className = "width__34 mr-2">{roundToNearestQuarter(props.trialLensOSsphere, 2)}</h3>
                                                                {/* <h3 className = "width__34 mr-2">{(props.trialLensOSsphere.toFixed(2))}</h3> */}
                                                            </div>
                                                            <div className = "patient__flex--row">
                                                                {/* <h3 className = "width__34 mr-2">{roundToNearestQuarter(props.trialLensOScylinder)}</h3> */}
                                                                <h3 className = "width__34 mr-2">{roundToNearestQuarter(props.trialLensOScylinder, 2)}</h3>
                                                                {/* <h3 className = "width__34 mr-2">{(props.trialLensOScylinder.toFixed(2))}</h3> */}
                                                            </div>
                                                            <div className = "patient__flex--row">
                                                                <h3 className = "width__34 mr-2">{showInAtLeastThreeDigits(props.trialLensOSaxis)}</h3>
                                                            </div>
                                                                                           
                                                        </div>
                                                    </div>
                                                    <button type="submit" className={`btn btn__white ${isSubmitting? 'disabled': ''}`} disabled={isSubmitting}>
                                                        Next
                                                    </button>
                                                </div>
                                            </div>
                                    </div>
                                </Form>
                            </React.Fragment>
                            )
                        }
                    </Formik>
                    
                    <PreviousConfigurations 
                        previousAssessments = { props.previousAssessments } 
                        setPreviousConfiguration = { props.setPreviousConfiguration }
                    />
                    
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = ({ patients, assessment, loader }) => ({
    loading: loader.set,
    selectedAssessmentForForm: assessment.selectedAssessmentForForm,
});
  
const mapDispatchToProps = dispatch => bindActionCreators({ setLoader, selectHmdDevice, selectAssessmentForForm }, dispatch,);
  
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(ConfigureTest));
