import * as Yup from 'yup'

export const LANGUAGES = [
  'english',
  'french'
]

export const SINGLE_EYE = [
  'left',
  'right',
  'both'
]

export const GENDER = [
  'not to say',
  'male',
  'female',
  'transgender'
]

export const PatientForm = {
  firstName: "",
  middleName: "",
  lastName: "",
  provinceState: "",
  countryCode: "",
  gender: GENDER[0],
  email: "",
  mobile: "",
  dateOfBirth: "",
  language: LANGUAGES[0],
  singleEye: SINGLE_EYE[2],
  personalHealthNumber: "",
  doctor: "",
  diagnosis: "",
  site: "",
  referringPhysician: "",
  descent: "",
  patientId: "",
  importedTests: []
}

export const PatientSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('First name is required'),
  middleName: Yup.string(),
  lastName: Yup.string()
    .required('Last name is required'),
  patientId: Yup.string()
    .required('Patient Id is required'),
  provinceState: Yup.string(),
  countryCode: Yup.string(),
  email: Yup.string()
    .email('Invalid email'),
  mobile: Yup.string()
    .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/, "Please enter valid phone number"),
  dateOfBirth: Yup.string()
    .required("Please select date of birth"),
  gender: Yup.string()
    .required("Please select sex")
    .oneOf(GENDER),
  language: Yup.string()
    .required("Please select Language")
    .oneOf(LANGUAGES),
  singleEye: Yup.string()
    .required('Please select single eye')
    .oneOf(SINGLE_EYE),
  personalHealthNumber: Yup.string()
    .matches(/^\d+$/, "Please enter integer values")
    .max(12),
  doctor: Yup.string(),
  diagnosis: Yup.string(),
  site: Yup.string(),
  referringPhysician: Yup.string(),
  descent: Yup.string()
})