import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { setModalWithType } from "../../../actions/modal.action"
import Eye from '../../shared/graphs/eye'

export const ChartViewModal = (props) => {

  const cancelClickHandler = () => {
    props.setModalWithType(false)
  }

  return (
    <React.Fragment>
      <div className="modal-header">
        <h2 className="modal-title" id="patient-modalLabel">{ props.modalData.header }</h2>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick = { cancelClickHandler }>
            <span aria-hidden="true"><i className="fabicon-close"></i></span>
        </button>
      </div>
      <div className="modal-body">
        <div className="row">
          <Eye chartId={'modal-view-chart'} assessment={props.modalData.assessment} chartName={props.modalData.chartName} chartData={props.modalData.chartData} chartOrientation={props.modalData.chartOrientation} height={600} width={600} top={props.modalData.top || -120} left={props.modalData.left || -150}/>  
        </div>
      </div>
    </React.Fragment>
  )
}

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setModalWithType
  }, dispatch)
)

const mapStateToProps = ({ assessment, modals, patients }) => ({
  assessment: assessment.activeAssessment,
})


export default connect(mapStateToProps, mapDispatchToProps)(ChartViewModal)
 