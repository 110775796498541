import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { setModalWithType } from "../../../actions/modal.action"
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { MODALS } from "../../../shared/constants/actions.constants"
import { updateChartSettingsOrder } from "../../../actions/users.action";
import * as DNDService from "../../../shared/utils/dnd";
import { toCamelCase } from '../../../shared/utils/wrappers'

const DragNDropFollowCharts = (props) => {
  const [ sourceList, setSourceList ] = useState(null)
  const [ modalData ] = useState(props.modalData || {})
  const [ destinationList, setDestinationList ] = useState(modalData.followupCharts || [])

  useEffect(() => {
    if (modalData && (modalData.followupCharts || []).length && (modalData.totalCharts || []).length) {
      let followUpContexts = modalData.followupCharts.map((chart) => chart.key)
      setSourceList(modalData.totalCharts.filter((chart) => followUpContexts.indexOf(chart.key) < 0))
    }
  }, [])

  const listsMappings = {
    droppable: () => sourceList,
    droppable2: () => destinationList,
    setdroppable: setSourceList,
    setdroppable2: setDestinationList
  }

  const cancelClickHandler = () => {
    props.setModalWithType(false)
  }

  const confirmClickHandler = () => {
      if (props.modalType === MODALS.DND_FOLLOW_CHARTS && props.loggedInUser && props.loggedInUser.id) {
        props.updateChartSettingsOrder(props.loggedInUser.id, { followupCharts: destinationList.map((chart) => chart.text) })
      } else if (props.modalType === MODALS.DND_SINGLE_VIEW_CHARTS && props.loggedInUser && props.loggedInUser.id) {
        props.updateChartSettingsOrder(props.loggedInUser.id, { singleViewCharts: destinationList.map((chart) => chart.text) })
      } else if (props.modalType === MODALS.DND_OVERVIEW_VIEW_CHARTS && props.loggedInUser && props.loggedInUser.id) {
        props.updateChartSettingsOrder(props.loggedInUser.id, { overviewCharts: destinationList.map((chart) => chart.text) })
      }
  }

  const warningMessageHandler = (message) => {
    alert(message)
  }

  return (
    <React.Fragment>
      <div className="modal-header">
        <h2 className="modal-title" id="patient-modalLabel">{ modalData.header }</h2>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick = { cancelClickHandler }>
            <span aria-hidden="true"><i className="fabicon-close"></i></span>
        </button>
      </div>
      <div className="modal-body">
        <div className="row d-flex justify-content-center">
          <div className = "col-6">
            <h4><strong>Total Charts</strong></h4>
          </div>
          <div className = "col-6">
            <h4><strong>{ modalData.header }</strong></h4>
          </div>
            <DragDropContext onDragEnd={ (result) => DNDService.onDragEnd(result, listsMappings, destinationList, modalData, warningMessageHandler) }>
              <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                      <div
                          ref={provided.innerRef}
                          style={ DNDService.getListStyle(snapshot.isDraggingOver) }>
                          {
                            sourceList && sourceList.length && sourceList.map((item, index) => (
                              <Draggable
                                  key={item.id}
                                  draggableId={item.id}
                                  index = { index }>
                                  {(provided, snapshot) => (
                                      <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={ DNDService.getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style,
                                              "left"
                                          ) }>
                                          { toCamelCase(item.content) }
                                      </div>
                                  )}
                              </Draggable>
                            ))
                          }
                          {provided.placeholder}
                      </div>
                  )}
              </Droppable>
              <Droppable droppableId="droppable2">
                  {(provided, snapshot) => (
                      <div
                          ref={provided.innerRef}
                          style={ DNDService.getListStyle(snapshot.isDraggingOver)}>
                          { destinationList && destinationList.length && destinationList.map((item, index) => (
                              <Draggable
                                  key={item.id}
                                  draggableId={item.id}
                                  index={index}>
                                  {(provided, snapshot) => (
                                      <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={ DNDService.getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style,
                                              "right"
                                          )}>
                                          { toCamelCase(item.content) }
                                      </div>
                                  )}
                              </Draggable>
                          ))}
                          {provided.placeholder}
                      </div>
                  )}
              </Droppable>
          </DragDropContext>
        </div>
        {
          destinationList && destinationList.length >= modalData.minDestinationCount && destinationList.length <= modalData.maxDestinationCount && (
            <button type="submit" className="btn btn-red" onClick = { confirmClickHandler }>Confirm</button>
          )
        }
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({ auth }) => ({
  loggedInUser: auth.loggedInUser || {}
})


const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setModalWithType,
    updateChartSettingsOrder
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(DragNDropFollowCharts)
 