import React from 'react'
import { Link } from "react-router-dom"

export const UserListRow = (props) => {
    return (
        <div className = { `trow ${ (props.activeWorklist && props.worklist.id === props.activeWorklist.id) ? "active" : ""}` } onClick = { () => props.worklistRowClickHandler(props.worklist) }>
            <div className="tcol w-40">{ props.worklist.name }</div>
            <div className="tcol w-40">{ props.worklist.assignedTo && `${props.worklist.assignedTo.firstName} ${props.worklist.assignedTo.lastName}` }</div>
            <div className="tcol w-20">
            <div className="dropdown dropdown-more text-right">
                <button className="btn btn-transparent" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="fabicon-more-vertical"></i>
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                    <Link to="#" className="dropdown-item" onClick = { () => props.worklistEditClickHandler(props.worklist) }>Edit</Link>
                    <Link to="#" className="dropdown-item" onClick = { () => props.worklistDeleteClickHandler(props.worklist) }>Delete</Link>
                    <Link to="#" className="dropdown-item" onClick = { () => props.removeAllPatientsClickHandler(props.worklist) }>Remove All Patients</Link>
                </div>
            </div>
            </div>
        </div>
    )
}

export default UserListRow
