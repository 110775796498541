import * as HmdDeviceService from "../services/hmdDevice/hmdDevice.service"
import { LOADER, HMDDEVICES } from "../shared/constants/actions.constants"
import { ERROR, SUCCESS } from "../shared/constants/messages.constants"
import * as FlashMessage from "../shared/utils/flashMessage"

/**
 * @desc To fetch all Hmd devices and store it into Redux Store
 * @public
 */
export const resetDevicesStatus = () => {
  return async (dispatch) => {
    try {
      dispatch(setLoader(true))

      await HmdDeviceService.resetDevicesStatus()

      dispatch(setLoader(false))
    } catch (error) {
      dispatch(setLoader(false))
    }
  }
}

/**
 * @desc To reset single hmd Device status
 * @public
 */
export const resetSingleDevicesStatus = (deviceId) => {
  return async (dispatch) => {
    try {
      dispatch(setLoader(true))

      const { data } = await HmdDeviceService.resetSingleDevicesStatus({ deviceId })

      onSuccessHmdSingleDeviceReset(dispatch, data)

      dispatch(setLoader(false))
    } catch (error) {
      dispatch(setLoader(false))
      onErrorHmdSingleDeviceReset(dispatch, error)
    }
  }
}

/**
 * @desc To fetch all Hmd devices and store it into Redux Store
 * @public
 */
export const getHmdDevices = () => {
  return async (dispatch) => {
    try {
      dispatch(setLoader(true))

      let { data } = await HmdDeviceService.getHmdDevicesList()

      onSuccessHmdDevicesList(dispatch, data, true)
    } catch (error) {
      onErrorHmdDevicesList(dispatch, error, true)
    }
  }
}

/**
 * @desc To fetch Hmd by id and store it into Redux Store
 * @public
 */
export const getHmdDeviceById = (id) => {
  return async (dispatch) => {
    try {
      let { data } = await HmdDeviceService.getHmdDeviceById(id)

      onSuccessHmdDeviceById(dispatch, data)
    } catch (error) {
      onErrorHmdDeviceById(dispatch, error)
    }
  }
}

/**
 * @desc To fetch all Hmd devices and store it into Redux Store without loading animations
 * @public
 */
export const getHmdDevicesWithoutLoading = () => {
  return async (dispatch) => {
    try {

      let { data } = await HmdDeviceService.getHmdDevicesList()

      onSuccessHmdDevicesList(dispatch, data, false)
    } catch (error) {
      onErrorHmdDevicesList(dispatch, error, false)
    }
  }
}

/**
 * @desc To add Hmd device and 
 * @public
 */
export const addHmdDevice = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setLoader(true))

      const data = await HmdDeviceService.addHmdDevice(payload)
      onSuccessHmdDeviceAdd(dispatch, data.data)
    } catch (error) {
      onErrorHmdDeviceAdd(dispatch, error)
    }
  }
}


/**
 * @desc To delete Hmd device and update it into Redux Store
 * @public
 */
export const deleteHmdDevice = (deviceId) => {
  return async (dispatch) => {
    try {
      dispatch(setLoader(true))

      await HmdDeviceService.deleteHmdDevice(deviceId)

      onSuccessHmdDeviceDelete(dispatch, deviceId)
    } catch (error) {
      onErrorHmdDeviceDelete(dispatch, error)
    }
  }
}

/**
 * Selects an HMD device.
 * 
 * @param {string} deviceId - The ID of the device to be selected.
 * @returns {Function} A function that dispatches the action to select the HMD device.
 */
export const selectHmdDevice = (deviceId) => {
  return (dispatch) => {
    dispatch(onSelectHmdDevice(deviceId))
  }
}

// Private Methods
/**
 * @desc To fetch all states based on countryId
 * @param {object} dispatch
 * @param {object} countryId
 * @private
 */
const onSuccessHmdDevicesList = (dispatch, hmdDevices, checkLoadingScreen) => {
  dispatch(setHmdDeiceListAction(hmdDevices))
  if(checkLoadingScreen) {
    dispatch(setLoader(false))
  }
}


const onSuccessHmdDevicesListWithoutLoading = (dispatch, hmdDevices) => {
  dispatch(setHmdDeiceListAction(hmdDevices))
}

const onSuccessHmdSingleDeviceReset = (dispatch, device) => {
  dispatch(setLoader(false))
  dispatch(updateHmdDeviceToStore(device))
  FlashMessage.success(SUCCESS.HMD_DEVICE_SUCCESSFULLY_RESET)
}

const onSuccessHmdDeviceById = (dispatch, hmdDevice) => {
  dispatch(setLoader(false))
}

/**
 * @desc To handle error while fetching HMD devices list
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorHmdDevicesList = (dispatch, error, checkLoadingScreen) => {
  if(checkLoadingScreen) {
    dispatch(setLoader(false))
  }
}

const onErrorHmdSingleDeviceReset = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.HMD_DEVICE_RESET_FAILED)
}

const onErrorHmdDeviceById = (dispatch, error) => {
  dispatch(setLoader(false))
}

/**
 * @desc Action to set loader
 * @param {boolean} flag
 * @private
 */
const setLoader = (flag) => {
  return { 
    type: LOADER.SET_LOADER, 
    payload: flag
  }
}

/**
 * @desc Action to set Hmd List into store
 * @param {Array} list
 * @private
 */
const setHmdDeiceListAction = (list = []) => {
  return {
    type: HMDDEVICES.SET_HMD_LIST,
    payload: list
  }
}

const updateHmdDeviceToStore = (payload) => {
  return {
    type: HMDDEVICES.UPDATE_HMD,
    payload: payload
  }
}

const addHmdDeviceToStore = (payload) => {
  return {
    type: HMDDEVICES.ADD_HMD,
    payload: payload
  }
}

const deleteHmdDeviceById = (deviceId) => {
  return {
    type: HMDDEVICES.DELETE_HMD,
    payload: deviceId
  }
}

const onSuccessHmdDeviceAdd = (dispatch, payload) => {
  dispatch(setLoader(false))
  dispatch(addHmdDeviceToStore(payload))
  FlashMessage.success(SUCCESS.HMD_DEVICE_SUCCESSFULLY_CREATED)
}

const onSuccessHmdDeviceDelete = (dispatch, deviceId) => {
  dispatch(setLoader(false))
  dispatch(deleteHmdDeviceById(deviceId))
}

const onErrorHmdDeviceAdd = (dispatch, deviceId) => {
  dispatch(setLoader(false))
}

const onErrorHmdDeviceDelete = (dispatch, deviceId) => {
  dispatch(setLoader(false))
}

const onSelectHmdDevice = (deviceId) => {
  return {
    type: HMDDEVICES.SELECT_HMD_DEVICE,
    payload: deviceId
  }
}