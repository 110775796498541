import React from 'react'

export const UserSearchPanel = (props) => {
  return (
    <div className="patient__main patient__main--left patient__main-nofilter">
      <ul>
        <li>
          <input type="text" placeholder="Name" className="form-control" onInput = { props.searchUserHandler }></input>
        </li>
        <li>
          <div className="profile-action">
            <button className="add__profile" type="button" data-toggle="modal" data-target="#user-modal" onClick = { props.addUserButtonHandler }>
              <i className="fabicon-add-user"></i>
              <span>Add User</span>
            </button>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default React.memo(UserSearchPanel)
