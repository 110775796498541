import React, { useState, useEffect } from 'react'
import { Formik } from 'formik'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import DatePicker from "react-datepicker"
import { PatientForm, PatientSchema, LANGUAGES, SINGLE_EYE, GENDER } from "../../../models/patients.model"
import { addPatient, updatePatient } from "../../../actions/patients.action"
import { getStatesByCountryId } from '../../../actions/countryStateCity.action'
import { setModalWithType } from "../../../actions/modal.action"
import { datePickerParser, toCamelCase } from "../../../shared/utils/wrappers"
import * as moment from 'moment'

export const AddEditPatientModal = (props) => {

  const getYear = (date) => {
    return date ? (new Date).getFullYear(date) : (new Date).getFullYear()
  }

  const getMonth = (date) => {
    return date ? (new Date).getMonth(date) : (new Date).getMonth()
  }

  const range = (start, end) => {
    return Array(end - start).fill().map((_, idx) => start + idx)
  }

  const years = range(1920, getYear() + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  let [yr, selectedyr] = useState('2020')
  let [mnt, selectedMnt] = useState(months[(new Date()).getMonth()])

  let [patient, setPatient] = useState(PatientForm)
  let [selectedCountryId, setSelectedCountryId] = useState("")
  let [provincesList, setProvincesList] = useState([])

  useEffect(() => {
    if (props.patient) {
      setPatient(props.patient)
      onCountryChangeHandler(props.patient.countryCode)
      setYearAndMonth(props.patient)
    } else {
      setPatient(PatientForm)
    }
  }, [])

  useEffect(() => {
    setProvincesList(props.states[selectedCountryId] || [])
  }, [selectedCountryId, props.states])

  /**
   * @desc To handle patient form submission and create/update based on patient in props.
   * @param {object} event
   */
  const addPatientFormHandler = (event) => {
    if (props.patient) {
      props.updatePatient(getPatientObj(event))
    } else {
      props.addPatient(event)
    }
  }

  const getPatientObj = (patientObj) => {
    let patient = Object.assign({}, patientObj)
    return patient
  }

  /**
   * @desc To load states list whenever country is changed.
   * @param {object} event
   */
  const onCountryChangeHandler = (event) => {
    let country = getCountryObjByShortName(event)
    
    if (country && country.id) {
      props.getStatesByCountryId(country.id)
      setSelectedCountryId(country.id)
      setProvincesList(props.states[country.id] || [])
    }  
  }

  /**
   * @desc To get country object based in country's short name
   * @param {string} shortName
   */
  const getCountryObjByShortName = (shortName = "") => {
    shortName = shortName.toLowerCase()
    return props.countries.find(country => country.sortname.toLowerCase() === shortName)
  }

  const setYearAndMonth = (patient) => {
    let t = moment(patient.dateOfBirth)
    selectedyr(t.year())
    selectedMnt(t.format("MMMM"))
  }

  return (
    <React.Fragment>
      <div className="modal-header">
        <h2 className="modal-title" id="patient-modalLabel">{ props.patient ? "Edit" : "Add" } Patient</h2>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick = { () => props.setModalWithType(false) }>
          <span aria-hidden="true"><i className="fabicon-close"></i></span>
        </button>
      </div>
      <div className="modal-body pd-b-30">
        <Formik
          enableReinitialize = { true }
          initialValues = { patient }
          validationSchema = { PatientSchema }
          onSubmit = { addPatientFormHandler }
        >
          {
            ({ values, handleChange, handleSubmit, setFieldValue, errors, touched }) => (
              <React.Fragment>
                  <form onSubmit = { handleSubmit }>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label>First Name</label>
                        <input id = "firstName" name = "firstName" type="text" className = { `form-control ${ (errors.firstName && touched.firstName) ? "is-invalid" : null }` } placeholder="Enter first name" onChange = { handleChange } value = { values.firstName } />
                        { errors.firstName && touched.firstName ? (
                          <div className="invalid-feedback">
                            { errors.firstName }
                          </div>
                        ) : null }
                      </div>
                      <div className="form-group col-md-6">
                        <label>Middle Name</label>
                        <input type="text" className = { `form-control ${ (errors.middleName && touched.middleName) ? "is-invalid" : null }` } placeholder="Enter last name" id = "middleName" name = "middleName" onChange = { handleChange } value = { values.middleName } />
                        { errors.middleName && touched.middleName ? (
                          <div className="invalid-feedback">
                            { errors.middleName }
                          </div>
                        ) : null }
                      </div>
                      <div className="form-group col-md-6">
                        <label>Last Name</label>
                        <input type="text" className = { `form-control ${ (errors.lastName && touched.lastName) ? "is-invalid" : null }` } placeholder="Enter last name" id = "lastName" name = "lastName" onChange = { handleChange } value = { values.lastName } />
                        { errors.lastName && touched.lastName ? (
                          <div className="invalid-feedback">
                            { errors.lastName }
                          </div>
                        ) : null }
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="inputState">Sex</label>
                        <select className = { `form-control ${ (errors.gender && touched.gender) ? "is-invalid" : null }` } id = "gender" name = "gender" onChange = { handleChange } value = { values.gender }>
                          <option defaultValue value = "">Select</option>
                          { 
                            (GENDER || []).map(gen => (
                              <option key = { gen } value = { gen }>{ toCamelCase(gen) }</option>
                            )) 
                          }
                        </select>
                        { errors.gender && touched.gender ? (
                          <div className="invalid-feedback">
                            { errors.gender }
                          </div>
                        ) : null }
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="inputState">DOB</label>
                        <DatePicker 
                          id = "dateOfBirth" 
                          name = "dateOfBirth" 
                          className = { `form-control ${ (errors.dateOfBirth && touched.dateOfBirth) ? "is-invalid" : null }` }  
                          selected={values.dateOfBirth ? datePickerParser(values.dateOfBirth) : null}
                          onChange={date => setFieldValue('dateOfBirth', date)}
                          dateFormat = "dd/MM/yyyy" maxDate = { new Date() } onKeyDown={ e => e.preventDefault() } 
                          renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled
                          }) => (
                            <div
                              style={{
                                margin: 10,
                                display: "flex",
                                justifyContent: "center"
                              }}
                            >
                              <select
                                value={yr}
                                onChange={({ target: { value } }) => {
                                  changeYear(value);
                                  selectedyr(value);
                                }}
                              >
                                {years.map(option => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                    
                              <select
                                value={mnt}
                                onChange={({ target: { value } }) => {
                                  changeMonth(months.indexOf(value));
                                  selectedMnt(value);
                                }}
                              >
                                {months.map(option => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}
                        />
                        { errors.dateOfBirth && touched.dateOfBirth? (
                          <div className="invalid-feedback" style = { { display: "block"} }>
                            { errors.dateOfBirth }
                          </div>
                        ) : null }
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="inputState">Patient ID</label>
                        <input type="text" className = { `form-control ${ (errors.patientId && touched.patientId) ? "is-invalid" : null }` } id = "patientId" name = "patientId" placeholder="Enter patientId" onChange = { handleChange } value = { values.patientId } />
                        { errors.patientId && touched.patientId ? (
                          <div className="invalid-feedback">
                            { errors.patientId }
                          </div>
                        ) : null }
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="inputState">Language</label>
                        <select className = { `form-control ${ (errors.language && touched.language) ? "is-invalid" : null }` } id = "language" name = "language" onChange = { handleChange } value = { values.language }>
                          { 
                            (LANGUAGES || []).map(lang => (
                              <option key = { lang } value = { lang }>{ toCamelCase(lang) }</option>
                            )) 
                          }
                        </select>
                        { errors.language && touched.language ? (
                          <div className="invalid-feedback">
                            { errors.language }
                          </div>
                        ) : null }
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="inputState">Single Eye</label>
                        <select className = { `form-control ${ (errors.singleEye && touched.singleEye) ? "is-invalid" : null }` } id = "singleEye" name = "singleEye" onChange = { handleChange } value = { values.singleEye }>
                          { 
                            (SINGLE_EYE || []).map(lang => (
                              <option key = { lang } value = { lang }>{ toCamelCase(lang) }</option>
                            )) 
                          }
                        </select>
                        { errors.singleEye && touched.singleEye ? (
                          <div className="invalid-feedback">
                            { errors.singleEye }
                          </div>
                        ) : null }
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="inputState">Personal Health Number</label>
                        <input type="text" className = { `form-control ${ (errors.personalHealthNumber && touched.personalHealthNumber) ? "is-invalid" : null }` } id = "personalHealthNumber" name = "personalHealthNumber" placeholder="Enter personal health number" onChange = { handleChange } value = { values.personalHealthNumber } />
                        { errors.personalHealthNumber && touched.personalHealthNumber ? (
                          <div className="invalid-feedback">
                            { errors.personalHealthNumber }
                          </div>
                        ) : null }
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="inputState">Doctor</label>
                        <input type="text" className = { `form-control ${ (errors.doctor && touched.doctor) ? "is-invalid" : null }` } id = "doctor" name = "doctor" placeholder="Enter doctor" onChange = { handleChange } value = { values.doctor } />
                        { errors.doctor && touched.doctor ? (
                          <div className="invalid-feedback">
                            { errors.doctor }
                          </div>
                        ) : null }
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="inputState">Site</label>
                        <input type="text" className = { `form-control ${ (errors.site && touched.site) ? "is-invalid" : null }` } id = "site" name = "site" placeholder="Enter site" onChange = { handleChange } value = { values.site } />
                        { errors.site && touched.site ? (
                          <div className="invalid-feedback">
                            { errors.site }
                          </div>
                        ) : null }
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="inputState">Referring Physician</label>
                        <input type="text" className = { `form-control ${ (errors.referringPhysician && touched.referringPhysician) ? "is-invalid" : null }` } id = "referringPhysician" name = "referringPhysician" placeholder="Enter referring physician" onChange = { handleChange } value = { values.referringPhysician } />
                        { errors.referringPhysician && touched.referringPhysician ? (
                          <div className="invalid-feedback">
                            { errors.referringPhysician }
                          </div>
                        ) : null }
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="inputState">Descent</label>
                        <input type="text" className = { `form-control ${ (errors.descent && touched.descent) ? "is-invalid" : null }` } id = "descent" name = "descent" placeholder="Enter descent" onChange = { handleChange } value = { values.descent } />
                        { errors.descent && touched.descent ? (
                          <div className="invalid-feedback">
                            { errors.descent }
                          </div>
                        ) : null }
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="inputState">Phone</label>
                        <input type="text" className = { `form-control ${ (errors.mobile && touched.mobile) ? "is-invalid" : null }` } id = "mobile" name = "mobile" placeholder="+1 XXX XXX XXXX" onChange = { handleChange } value = { values.mobile } />
                        { errors.mobile && touched.mobile ? (
                          <div className="invalid-feedback">
                            { errors.mobile }
                          </div>
                        ) : null }
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="inputState">Email Address</label>
                        <input type="text" className = { `form-control ${ (errors.email && touched.email) ? "is-invalid" : null }` } id = "email" name = "email" placeholder="Enter email address" onChange = { handleChange } value = { values.email } />
                        { errors.email && touched.email ? (
                          <div className="invalid-feedback">
                            { errors.email }
                          </div>
                        ) : null }
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="inputState">Country</label>
                        <select className = { `form-control ${ (errors.countryCode && touched.countryCode) ? "is-invalid" : null }` } id = "countryCode" name = "countryCode" onChange = { (event) => { setFieldValue("countryCode", event.target.value); onCountryChangeHandler(event.target.value); setFieldValue("provinceState", "") } } value = { values.countryCode }>
                          <option defaultValue value = "">Select</option>
                          {props.countries.map((obj) => {
                            return <option key={obj.id} value={obj.sortname}>{obj.name}</option>
                          })}
                        </select>
                        { errors.countryCode && touched.countryCode ? (
                          <div className="invalid-feedback">
                            { errors.countryCode }
                          </div>
                        ) : null }
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="inputState">Province State</label>
                        <select className = { `form-control ${ (errors.provinceState && touched.provinceState) ? "is-invalid" : null }` } id = "provinceState" name = "provinceState" onChange = { handleChange } value = { values.provinceState } disabled = { !(values.countryCode || "").length }>
                          <option defaultValue value = "">Select</option>
                          { 
                            (provincesList).map(obj => (
                              <option key = { obj.id } value = { obj.sortname }>{ obj.name }</option>
                            )) 
                          }
                        </select>
                        { errors.provinceState && touched.provinceState ? (
                          <div className="invalid-feedback">
                            { errors.provinceState }
                          </div>
                        ) : null }
                      </div>
                      <div className="form-group col-md-12">
                        <label htmlFor="inputState">Diagnosis</label>
                        <textarea rows = "3" className = { `form-control ${ (errors.diagnosis && touched.diagnosis) ? "is-invalid" : null }` } id = "diagnosis" name = "diagnosis" placeholder="Enter diagnosis" onChange = { handleChange } value = { values.diagnosis } />
                        { errors.diagnosis && touched.diagnosis ? (
                          <div className="invalid-feedback">
                            { errors.diagnosis }
                          </div>
                        ) : null }
                      </div>
                    </div>
                    <button type="submit" className="btn btn-red">{ props.patient ? "Update" : "Create" }</button>
                  </form>
              </React.Fragment>
            )
          }
        </Formik>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({ countryStateCity }) => ({
  countries: countryStateCity.countriesList,
  states: countryStateCity.statesObj
})

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    getStatesByCountryId,
    addPatient,
    updatePatient,
    setModalWithType
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(AddEditPatientModal)