import React, { useEffect, useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import UserSearchPanel from "./userSearchPanel"
import UsersListPanel from "./usersList"
import { getUsers, addActiveUser, resetState, setUsersSearchText, setUsersSort } from "../../../actions/users.action"
import { setModalWithType } from "../../../actions/modal.action"
import { MODALS } from "../../../shared/constants/actions.constants"
import { DEBOUNCE_TIME } from "../../../shared/constants/shared.constants"
import { debounce } from "../../../shared/utils/debounce"

export const UsersPanel = (props) => {

  let [ userSearchDebouncer ] = useState(null)
  const [isUserAsc, setUserAsc] = useState(true)

  useEffect(() => {
    props.getUsers()
    return () => {
      props.resetState()
    }
  }, [])

  /**
   * @desc Handler for search patient
   */
  const addUserButtonHandler = useCallback(() => {
    props.setModalWithType(true, MODALS.ADD_USER_MODAL)
  }, [])

  /**
   * @desc Handler for user row click
   * @param {object} user
   */
  const onUserRowClick = useCallback((user) => {
    props.addActiveUser(user)
  }, [])

  /**
   * @desc Handler for search user
   * @param {object} event
   */
  const searchUserHandler = useCallback((event) => {
    props.setUsersSearchText(event.target.value)

    if (!userSearchDebouncer) userSearchDebouncer = debounce(() => props.getUsers(false), DEBOUNCE_TIME)
    userSearchDebouncer()

  }, [])

  /**
   * @desc Handler for user list sort
   */
  const onUserArrowClick = useCallback(() => {
    setUserAsc(prev => !prev)
    
    props.setUsersSort(isUserAsc? 1 : -1)
    props.getUsers()
  }, [isUserAsc])

  return (
    <div className="content__left js-content__left">
      <div className="patient__list">
        <UserSearchPanel 
          searchUserHandler = { searchUserHandler }
          addUserButtonHandler = { addUserButtonHandler }
        />
        <div className = { `patient__table block__toggle patient--heightleft ${ props.showDeviceSidebar ? "block__toggle--left" : "" }` }>
          <UsersListPanel 
            users = { props.users }
            activeUser = { props.activeUser }
            onUserRowClick = { onUserRowClick }
            isUserAsc = { isUserAsc }
            onUserArrowClick = { onUserArrowClick }
          />
        </div>
      </div>
    </div>    
  )
}

const mapStateToProps = ({ users, ui }) => ({
  users: users.usersObj,
  activeUser: users.activeUser,
  showDeviceSidebar: ui.showDeviceSidebar
})

const mapDispatchToProps = dispatch => (
  bindActionCreators({ 
    getUsers,
    addActiveUser,
    setModalWithType,
    resetState,
    setUsersSearchText,
    setUsersSort
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(UsersPanel)
