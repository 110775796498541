import { default as React, useCallback, useEffect, useState } from 'react';
import { connect } from "react-redux";
import { useHistory, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { getHmdAssessmentPercentage, hmdTimeElapsed } from '../../shared/utils/hmd';
import { patientFullName } from "../../shared/utils/patient";
import { setModalWithType } from '../../actions/modal.action'

import { DYNAMIC_ROUTES } from '../../shared/constants/routes.constants';
import { MODALS } from '../../shared/constants/actions.constants';


export const HmdDeviceDetails = (props) => {
    const isDeviceActive = props.hmdDevice.status === "ok" && props.hmdDevice.patient && props.hmdDevice.patient.id
    const assessmentConfiguration = props?.hmdDevice?.assessment?.configuration
    let [timer, setTimer] = useState("00:00:00")
    let [process, setProcess] = useState(0)

    const history = useHistory()

    const navigateToAssessment = (
      ) => {
        if(props.hmdDevice.patient?.id && props.hmdDevice.assessment?.id)
            history.push(DYNAMIC_ROUTES.INITIATE_ASSESSMENT(props.hmdDevice.patient?.id, props.hmdDevice.assessment?.id));
      };

    const onHmdDeviceDeleteModalOpen = useCallback(() => {
        let modalState = { 
            hmdDeviceId: props.hmdDevice.id,
            actionType: MODALS.CONFIRMATION_MODAL_TO_DELETE_HMD_DEVICE,
            header: "Device Deletion",
            body: `Are you sure you want to delete "${props.hmdDevice.name}" device?`
        }

        props.setModalWithType(true, MODALS.CONFIRMATION_MODAL, modalState)
    }, [])

    const onHmdDeviceResetModalOpen = useCallback(() => {
        let modalState = { 
            hmdDeviceId: props.hmdDevice.id,
            actionType: MODALS.CONFIRMATION_MODAL_TO_RESET_HMD_DEVICE,
            header: "Device Reset",
            body: `Are you sure you want to reset "${props.hmdDevice.name}" device?`
        }

        props.setModalWithType(true, MODALS.CONFIRMATION_MODAL, modalState)
    }, [])

    useEffect(() => {
        let timerInterval
        if (props.hmdDevice && props.hmdDevice.assessment && props.hmdDevice.assessment.testStartedAt) {
            timerInterval = setInterval(() => {setTimer(hmdTimeElapsed(props.hmdDevice.assessment.testStartedAt))},1000)
            setProcess(prev =>getHmdAssessmentPercentage(props.hmdDevice.assessment, prev))
        }

        if ( props.hmdDevice.assessment === null ){
            clearInterval(timerInterval);
            setTimer("00:00:00")
            setProcess(prev =>getHmdAssessmentPercentage(props.hmdDevice.assessment, prev))
        }

        return () => {
            console.log("CLEARING HMD TIME INTERVAL")
            clearInterval(timerInterval)
        }
    }, [props.hmdDevice])

    return (
        <div className="col-xl-4 col-lg-6">
            <div className="patient__view--list">
                <div className="patient__view--images">
                    <div className = "row">
                        <div className = "col-md-6">
                            {
                                isDeviceActive && props.hmdDevice && (
                                    <video style={{ width: '100%'}} controls={false} autoPlay loop>
                                        <source src = { props.hmdDevice.leftVideoUrl } type="video/mp4" />
                                        Your browser does not support HTML video.
                                    </video>
                                )
                            }
                        </div>
                        <div className = "col-md-6">
                            {
                                isDeviceActive && props.hmdDevice && (
                                    <video style={{ width: '100%'}} controls={false} autoPlay loop>
                                        <source src = { props.hmdDevice.rightVideoUrl } type="video/mp4" />
                                        Your browser does not support HTML video.
                                    </video>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="patient__view--outers">
                    <div className="patient__view--top">
                        <div className="row">
                            <div className="col-6 cursor-pointer" onClick={ navigateToAssessment }>
                                <div className="patient__top--left">
                                    <span className="device-square device__square--big light-blue">
                                    <i className="device-square--text">D{ props.index }</i>
                                    <i className="icon-device"></i>
                                    </span>
                                    <div className="patient__view--timer">
                                        <span className="patient__view--sm">{ props.hmdDevice.name }</span>
                                        <span className="patient__view--18">{ patientFullName(props.hmdDevice.patient) }</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="patient__view--timer">
                                    <span className="patient__view--sm">Timer</span>
                                    <span className="patient__view--18">{timer}</span>
                                </div>
                            </div>
                        </div>
                        { !isDeviceActive && props.isAdmin && (
                            <div className="device__delete--btn">
                                <button type="submit" className="btn btn-red mr-2" onClick = { () => onHmdDeviceDeleteModalOpen() }><i className="fabicon-Delete"></i></button>
                                <button type="submit" className="btn btn-red" onClick = { onHmdDeviceResetModalOpen }><i className="fabicon-ResetDevice"></i></button>
                            </div>
                        )}
                    </div>
                    <div className="patient__view--center">
                        <div className="row">
                            <div className="col-3">
                                <span className="patient__view--circle"></span>
                            </div>
                            <div className="col-9">
                                <div className="row">
                                <div className="col-4">
                                    <span className="patient__view--sm">Grid</span>
                                    <span className="patient__view--18">{assessmentConfiguration?.grid || "0-0"}</span>
                                </div>
                                <div className="col-4">
                                    <span className="patient__view--sm">Reliability</span>
                                    <span className="patient__view--18">-/-</span>
                                </div>
                                <div className="col-4">
                                    <span className="patient__view--sm">Progress</span>
                                    <span className="patient__view--18">{process}%</span>
                                </div>
                                </div>
                            </div>                              
                        </div>
                    </div>
                    <div className="patient__view--bottom">
                        <div className="progress progress__customs">
                            <div className="progress-bar" role="progressbar" style = { { width: `${process}%` } } aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = ({ hmdDevices, auth }) => ({
    hmdDeviceList: hmdDevices.hmdDeviceList,
    isAdmin: auth.isAdmin
})

const mapDispatchToProps = dispatch => (
    bindActionCreators({ 
        setModalWithType
    }, dispatch)
  )
  
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HmdDeviceDetails))
