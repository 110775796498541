import * as Yup from 'yup';

export const FaqForm = {
  title: '',
  description: '',
  img: '',
};

export const FaqSchema = Yup.object().shape({
  title: Yup.string().required('Name is required'),
  description: Yup.string().required('Description is required').min(8,''),
});
