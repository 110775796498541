import React, { useEffect, useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import WorklistSearchPanel from "./worklistSearchPanel"
import WorklistList from "./worklistList"
import WorklistPatientSearchPanel from "./worklistPatientSearchPanel"
import WorklistPatientList from "./worklistPatientList"
import { setModalWithType } from "../../../actions/modal.action"
import { getWorklists, addActiveWorklist, setWorklistSearchText, setWorklistSort, addPatientIntoWorklist } from "../../../actions/worklist.action"
import { getPatientSimpleList } from "../../../actions/patients.action"
import { MODALS } from "../../../shared/constants/actions.constants"
import { DEBOUNCE_TIME } from "../../../shared/constants/shared.constants"
import { debounce } from "../../../shared/utils/debounce"

export const WorklistPanel = (props) => {

  let [ worklistSearchDebouncer ] = useState(null)
  const [isWorklistAsc, setWorklistAsc] = useState(true)
  const [currentPatientList, setCurrentPatientList] = useState([])
  
  useEffect(() => {
    props.getWorklists()
    props.getPatientSimpleList()
  }, [])

  useEffect(() => {
    if(props.patientSimpleList){
      filterPatientListFromMainList()
    }
  }, [props.activeWorklist])

  const filterPatientListFromMainList = ()=>{
    if(props.activeWorklist) {
      const activeWorklistPatientList = [];
      props.activeWorklist.patients.forEach(patient => {
        activeWorklistPatientList.push(patient.id)
      });

      const filterPatientList = props.patientSimpleList.filter((patient) => {
        return activeWorklistPatientList.indexOf(patient.id) < 0
      });
      setCurrentPatientList(filterPatientList)
    }
  }

  const localSearch = useCallback((event) => {
    if(event.target.value) {
      let searchVal = (event.target.value || '').toLowerCase()
      let newFilterPatientListList = currentPatientList.filter((patient)=>{
        let fn = (patient.firstName || "").toLowerCase();
        let ln = (patient.lastName || "").toLowerCase();
        let id = (patient.patientId || "").toString().toLowerCase();
        return (fn.indexOf(searchVal)>=0 || ln.indexOf(searchVal)>=0 || id.indexOf(searchVal)>=0)
      })
      setCurrentPatientList(newFilterPatientListList)
    } else {
      filterPatientListFromMainList()
    }
  }, [currentPatientList])

  /**
   * @desc Handler for add worklist
   * @param {object} event
   */
  const worklistAddButtonHandler = useCallback(() => {
    props.setModalWithType(true, MODALS.ADD_WORKLIST_MODAL)
  }, [])

  /**
   * @desc Handler for worklist row click
   * @param {object} event
   */
  const worklistRowClickHandler = useCallback((worklist) => {
    props.addActiveWorklist(worklist)
  }, [])

  /**
   * @desc Handler for worklist edit handler
   * @param {object} event
   */
  const worklistEditClickHandler = useCallback((worklist) => {
    props.addActiveWorklist(worklist)
    props.setModalWithType(true, MODALS.EDIT_WORKLIST_MODAL)
  }, [])

  /**
   * @desc Handler for worklist delete
   * @param {object} event
   */
  const worklistDeleteClickHandler = useCallback((worklist) => {
    if (worklist && worklist.id) {
      let modalState = { 
        worklistId: worklist.id,
        actionType: MODALS.CONFIRMATION_MODAL_TO_DELETE_WORKLIST,
        header: "Worklist Deletion",
        body: "Are you sure you want to delete the worklist?"
      }

      props.setModalWithType(true, MODALS.CONFIRMATION_MODAL, modalState)
    }
  }, [])

  const removeAllPatientsClickHandler = useCallback((worklist) => {
    if (worklist && worklist.id) {
      let modalState = { 
        worklistId: worklist.id,
        actionType: MODALS.CONFIRMATION_MODAL_TO_REMOVE_ALL_PATIENTS_FROM_WORKLIST,
        header: "Worklist's All Patients Deletion",
        body: "Are you sure you want to delete all the patients from the worklist?"
      }

      props.setModalWithType(true, MODALS.CONFIRMATION_MODAL, modalState)
    }
  }, [])

  /**
   * @desc Handler for search worklist
   * @param {object} event
   */
  const searchWorklistHandler = useCallback((event) => {
    props.setWorklistSearchText(event.target.value)

    if (!worklistSearchDebouncer) worklistSearchDebouncer = debounce(() => props.getWorklists(false), DEBOUNCE_TIME)
    worklistSearchDebouncer()

  }, [])

  /**
   * @desc Handler for worlist sort
   * @param {object} event
   */
  const onWorklistArrowClick = useCallback(() => {
    setWorklistAsc(prev => !prev)
    
    props.setWorklistSort(isWorklistAsc? 1 : -1)
    props.getWorklists()
  }, [isWorklistAsc])

  const onRightArrowClick = (patientId) => {
    if (patientId && props.activeWorklist && props.activeWorklist.id) {
      props.addPatientIntoWorklist(props.activeWorklist.id, patientId)
    }
  }

  return (
    <div className="content__left js-content__left">
      <div className="patient__list height-auto">
        <WorklistSearchPanel 
          searchWorklistHandler = { searchWorklistHandler }
          worklistAddButtonHandler = { worklistAddButtonHandler }
        />
        <div className = { `patient__table block__toggle ${ props.showDeviceSidebar ? "block__toggle--left" : "" }` }>
          <WorklistList
            worklistsObj = { props.worklistsObj }
            activeWorklist = { props.activeWorklist }
            worklistRowClickHandler = { worklistRowClickHandler }
            worklistEditClickHandler = { worklistEditClickHandler }
            worklistDeleteClickHandler = { worklistDeleteClickHandler }
            removeAllPatientsClickHandler = { removeAllPatientsClickHandler }
            isWorklistAsc = { isWorklistAsc }
            onWorklistArrowClick = { onWorklistArrowClick }
          />
        </div>
      </div>

      <div className = { `patient__list  height-auto patient__table block__toggle ${ props.showDeviceSidebar ? "block__toggle--left" : "" } ` }>
        <WorklistPatientSearchPanel patientSimpleList={currentPatientList} localSearch={localSearch}/>
        <WorklistPatientList 
          patientSimpleList={currentPatientList} 
          onRightArrowClick = { onRightArrowClick }
        />
      </div>     
    </div>    
  )
}

const mapStateToProps = ({ worklists, ui, patients }) => ({
  showDeviceSidebar: ui.showDeviceSidebar,
  worklistsObj: worklists.worklistsObj,
  activeWorklist: worklists.activeWorklist, 
  patientSimpleList: patients.patientSimpleList
})

const mapDispatchToProps = dispatch => (
  bindActionCreators({ 
    setModalWithType,
    getWorklists,
    addActiveWorklist,
    setWorklistSearchText,
    setWorklistSort,
    getPatientSimpleList,
    addPatientIntoWorklist
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(WorklistPanel)
