import React from "react"
import LoginPanel from "../components/login/loginPanel"

const LoginContainer = (props) => {
    return (
        <LoginPanel />
    )
}

export default LoginContainer
