import { HMDDEVICES } from "../shared/constants/actions.constants"

const initialState = {
  selectedHmdDevice: null,
  hmdDeviceList: [],
  hmdById: null,
  hmdAssignedAssessment: null
}

export default function(state = initialState, action) {

  switch (action.type) {
    case HMDDEVICES.SELECT_HMD_DEVICE:
        return {
            ...state,
            selectedHmdDevice: action.payload
        }
    case HMDDEVICES.SET_HMD_LIST:
        return {
            ...state,
            hmdDeviceList: [ ...action.payload ]
        }
        
    case HMDDEVICES.UPDATE_HMD:
        const updatedDevice = action.payload
        const updatedDeviceIndex = state.hmdDeviceList.findIndex((device) => device.id == updatedDevice.id)
        state.hmdDeviceList[updatedDeviceIndex] = updatedDevice

        return {
          ...state,
          hmdDeviceList: [ ...state.hmdDeviceList ]
        }

    case HMDDEVICES.DELETE_HMD:
        const deviceId = action.payload
        const deletedDeviceIndex = state.hmdDeviceList.findIndex((device) => device.id == deviceId)
        state.hmdDeviceList.splice(deletedDeviceIndex, 1)

        return {
          ...state,
          hmdDeviceList: [ ...state.hmdDeviceList ]
        }
    
    default:
      return state
  }
}
