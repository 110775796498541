import React, { useState, useEffect } from 'react'
import NoRecord from "../../shared/noRecord"
import { parseDate } from "../../../shared/utils/wrappers"
import { NO_RECORD_MSG } from "../../../shared/constants/shared.constants"
import { Scrollbars } from 'react-custom-scrollbars'
import { GRID_TYPES } from "../../../models/assessment.model"

export const PatientImportTestList = (props) => {
    let [ importedTests, setImportedTests ] = useState([])

    useEffect(() => {
        if (props.importedTests && props.importedTests.length) {
            setImportedTests(props.importedTests)
        } else {
            setImportedTests([])
        }
    }, [ props.importedTests ])
                  
  return (
    <React.Fragment>
        <div className="patient__table">
          <div className="patient__filter">
            <h3>Imported Test History</h3>
          </div>
          <div className="tableCntrl">
            <div className="table mg-b-0">
              <div className="thead">
                <div className="trow">
                  <div className="tcol w-40">Upload On</div>
                  <div className="tcol w-60">File Name</div>
                </div>
              </div>
              <div className="tbody table-hover">
                <Scrollbars style={{ 'position': 'relative', 'minHeight': 200 }}>
                  {
                    importedTests && importedTests.length ? (
                      importedTests?.map((importedTest, index) => (
                        <div className="trow mt-1" key={index}>
                          <div className="tcol w-40">{ parseDate(importedTest.uploadedDate) }</div>
                          <div className="tcol w-60">{ importedTest.fileName }</div>
                          <div className="tcol"><button className="btn btn-light" key={ index } onClick = { () => props.onImportTestRowClick(importedTest)}>Preview</button></div>
                          <div className="tcol" style={{paddingLeft:"1rem", paddingRight:"1rem"}}><button className="btn btn-light" key={ index } onClick = { () => props.onExtractImageFromPdf(importedTest)}>Extract Images</button></div>
                          <div className="tcol" style={{ paddingRight:"1rem"}}><button className="btn btn-light" key={ index } onClick = { () => props.onExtractTextDataFromPdf(importedTest)}>Extract Text Data</button></div>
                        </div>
                      ))
                    ) : (
                      <NoRecord msg = { NO_RECORD_MSG.NO_IMPORTED_TESTS_FOUND } />
                    )
                  }
                </Scrollbars>
              </div>
            </div>
          </div>
        </div>
      
    </React.Fragment>
  )
}

export default PatientImportTestList
