import React, { useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import { setModalWithType } from "../../../actions/modal.action"
import { addImportTest } from "../../../actions/patients.action";
import { validateImportTestExtension } from "../../../shared/utils/fileValidations";
import * as FlashMessage from "../../../shared/utils/flashMessage";
import { ERROR } from "../../../shared/constants/messages.constants";

const ImportTestsModal = (props) => {
    let [importTestObj, setImportTestObj] = useState({
        files: []
    })

    const importTestsFormSubmitHandler = (event) => {
        if (event.files && event.files.length) {
            props.addImportTest(props.modalData.patientId, event)
        } else {
            FlashMessage.error(ERROR.PATIENTS_IMPORT_TEST_NOT_SELECTED)
        }
    }

    const onImportFileChange = (event, setFieldValue) => {
        if (validateImportTestExtension(event)) {
            setFieldValue("files", event.target.files)
        }
    }
    return (
        <React.Fragment>
            <div className="modal-header">
            <h2 className="modal-title" id="patient-modalLabel">{ `${ props.worklist ? "Edit" : "Add" }` } Test</h2>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick = { () => props.setModalWithType(false) }>
                    <span aria-hidden="true"><i className="fabicon-close"></i></span>
                </button>
            </div>
            <div className="modal-body pd-b-30">
                <Formik
                    enableReinitialize = { true }
                    initialValues = { importTestObj }
                    onSubmit = { importTestsFormSubmitHandler }
                >
                    {
                        ({ values, handleChange, handleSubmit, setFieldValue, errors, touched }) => (
                            <React.Fragment>
                                <form onSubmit = { handleSubmit }>
                                    <div className="row">
                                        <div className="form-group col-md-12">
                                            <input id="files" name="files" multiple type="file" placeholder="Please Select Files." onChange = { (event) => onImportFileChange(event, setFieldValue) } />
                                            { errors.setFieldValue && touched.setFieldValue ? (
                                            <div className="invalid-feedback">
                                                { errors.files }
                                            </div>
                                            ) : null }
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-red">Upload</button>
                                </form>
                            </React.Fragment>
                        )
                    }
                </Formik>
            </div>
        </React.Fragment>
    )
}

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setModalWithType,
    addImportTest
  }, dispatch)
)

const mapStateToProps = () => ({})


export default connect(mapStateToProps, mapDispatchToProps)(ImportTestsModal)
