export const ROUTES = { 
    PATIENTS: "/patients",
    ASSESSMENT: "/patients/:patientId/assessment",
    USERS: "/manage-users",
    USER_DETAILS: "/users/:userId",
    LOGIN: "/login",
    HMD_DEVICES: "/hmd-devices",
    SETTINGS: "/settings",
    WORKLIST: "/worklists",
    HELP: "/help",
    INITIATE_ASSESSMENT: "/patients/:patientId/assessments/:assessmentId/initiate",
    ASSESSMENT_REPORTS: "/patients/:patientId/reports"
}

export const DYNAMIC_ROUTES = {
    ASSESSMENT: (patientId) =>  `/patients/${patientId}/assessment`,
    ASSESSMENT_WITH_RECALLIBRATION: (patientId, recallibrateId) => `/patients/${patientId}/assessment?recalibrateId=${recallibrateId}`,
    USER_DETAILS: (userId) =>  `/users/${userId}`,
    INITIATE_ASSESSMENT: (patientId, assessmentId) =>  `/patients/${patientId}/assessments/${assessmentId}/initiate`,
    ASSESSMENT_REPORTS: (patientId) =>  `/patients/${patientId}/reports`,
    REPORTS_BY_ASSESSMENT_ID: (patientId, assessmentId) =>  `/patients/${patientId}/reports?assessmentId=${assessmentId}`
}

export const HOMEPAGE_ROUTES = [
    ROUTES.PATIENTS,
    ROUTES.WORKLIST
]