import Axios from "axios"
import { API_CONSTANTS } from "../../shared/constants/api.constants"

/**
 * @desc To create a new note
 * @param {object} faqObj
 * @public
 */
export const createFaq = (faqObj) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.post(API_CONSTANTS.FAQ.CREATE_FAQ, faqObj)
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
 * @desc To create a new note
 * @param {object} faqObj
 * @public
 */
export const updateFaq = (faqId, faqObj) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.put(API_CONSTANTS.FAQ.UPDATE_FAQ(faqId), faqObj)
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
 * @desc To get FAQ
 * @public
 */
export const getFAQ = () => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.get(API_CONSTANTS.FAQ.GET_FAQS)
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
 * @desc To delete faq
 * @public
 */
export const deleteFaq = (faqId) => {
    return new Promise(async (res, rej) => {
        try {
            const { data } = await Axios.delete(API_CONSTANTS.FAQ.DELETE_FAQ(faqId))

            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

