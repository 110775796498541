import React, { useState, useEffect } from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import * as moment from 'moment'

export const PatientSearch = (props) => {
    const [isDateRangeSelected, setIsDateRangeSelected] = useState(false)
    const [startDate, setStartDate] = useState(moment())
    const [endDate, setEndDate] = useState(moment(moment()).add(10, 'days'))

    useEffect(() => {
        if (!props.patientSearchData.assessmentFromDate && !props.patientSearchData.assessmentToDate) {
            setIsDateRangeSelected(false)
        }
    }, [props.patientSearchData.assessmentFromDate, props.patientSearchData.assessmentToDate])

    const dateRangeClickHandler = (event, picker) => {
        setIsDateRangeSelected(true);
        
        setStartDate(picker.startDate)
        setEndDate(picker.endDate)

        props.onDateRangeSelect(null, { startDate: picker.startDate, endDate: picker.endDate })
    }

    const todayClickHandler = (event, picker) => {
        setIsDateRangeSelected(true);

        let sdt = moment()
        let edt = moment(sdt).add(1, 'days')
        setStartDate(sdt)
        setEndDate(edt)

        props.onDateRangeSelect(null, { startDate: sdt, endDate: edt })
    }

    const clearClickHandler = ()=>{
        let sdt = moment()
        let edt = moment(sdt).add(10, 'days')
        setStartDate(sdt)
        setEndDate(edt)

        setIsDateRangeSelected(false)
        props.clearSearchPatientHandler()
    }

    return (
        <div className="patient__main patient__main--left">
            <ul>
                <li>
                    <input type="text" placeholder="Name" className="form-control" value = { props.patientSearchData.text } onChange = { props.searchPatientHandler } />
                </li>
                <li>
                    <div className="calender-today">
                        <DateRangePicker 
                            startDate = { startDate }
                            endDate = { endDate }
                            showDropdowns = { true }
                            buttonClasses = { ['btn btn-red'] }
                            onApply = { dateRangeClickHandler }
                        >
                            <div className="calender position-relative">
                                {
                                    isDateRangeSelected ? (<input type="text" placeholder="Assessment Date" className="form-control" readOnly value= {`${startDate.format('l')}-${endDate.format('l')}`} />) : (<input type="text" readOnly placeholder="Assessment Date" className="form-control" value='' />)
                                }
                                <button type="button" className="btn__calendar">
                                    <i className="fabicon-calendar"></i>
                                </button>
                            </div> 
                        </DateRangePicker>
                        <a className="date-today" href="#!" onClick = { todayClickHandler }>
                            Today
                        </a>
                        <a className="date-today" href="#!" onClick = { clearClickHandler }>
                            Clear
                        </a>
                    </div>
                </li>
                <li>
                    <div className="profile-action">
                        <button className="add__profile" type="button" data-toggle="modal" data-target="#patient-modal" onClick = { props.onAddPatientModalClick } >
                            <i className="fabicon-add-user"></i>
                            <span>Add Patient</span>
                        </button>
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default React.memo(PatientSearch)
