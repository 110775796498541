import React, { useEffect } from "react"
import { connect } from "react-redux"
import Header from "../components/shared/header"
import Sidebar from "../components/shared/sidebar"
import WorklistPanel from "../components/worklist/leftSection/worklistPanel"
import WorklistDetailPanel from "../components/worklist/rightSection/worklistDetailPanel"

const WorklistContainer = (props) => {

  useEffect(() => {
    window.heightcalculation()
  }, [])

  return (
    <div>
        <Header loggedInUser = { props.loggedInUser } />
        <main className="wrapper">
        <Sidebar />
        <div className="content-wrapper">
          <div className="content__inner">
            <WorklistPanel />
            <WorklistDetailPanel />
          </div>
        </div>
      </main>
    </div>
  )
}

const mapStateToProps = ({ auth }) => ({
  loggedInUser: auth.loggedInUser
})

export default connect(mapStateToProps)(WorklistContainer)
