let API_BASE_URL = ''

if (process.env.NODE_ENV === 'production') {
  API_BASE_URL = "https://api-ui.eadietech.com/api/v1"
} else {
  API_BASE_URL = "http://localhost:8080/api/v1"
  API_BASE_URL = "http://192.168.0.115:8080/api/v1"
  // API_BASE_URL = "https://api-ui.eadietech.com/api/v1"
}

export const _API_BASE_URL = API_BASE_URL;

export const API_CONSTANTS = {

  AUTH: {
    LOGIN: `${API_BASE_URL}/users/login`,
    FETCH_MY_DETAILS: `${API_BASE_URL}/users/fetch-my-details`
  },

  USERS: {
    GET_ALL_USERS: `${API_BASE_URL}/users/simple-list`,
    GET_ALL_USERS_WITH_BULK_DATA: `${API_BASE_URL}/users/list`,
    CREATE_USER: `${API_BASE_URL}/users/register`,
    CREATE_USER_FROM_AUTH0: `${API_BASE_URL}/users/registerFromAuth0`,
    GET_USER_BY_ID: `${API_BASE_URL}/users`,
    GET_USER_BY_EMAIL: `${API_BASE_URL}/users/getByEmail`,
    EDIT_USER: `${API_BASE_URL}/users`,
    USER_CHANGE_PASSOWRD: (userId) =>  `${API_BASE_URL}/users/${userId}/change-password`,
    USER_CHANGE_CHART_ORDER: (userId) =>  `${API_BASE_URL}/users/${userId}/charts`,

    UPLOAD: `${API_BASE_URL}/users/upload`,
  },

  PATIENTS: {
    GET_ALL_PATIENTS: `${API_BASE_URL}/patients`,
    GET_PATIENT_BY_ID: (patientId) =>  `${API_BASE_URL}/patients/${patientId}`,
    GET_SIMPLE_PATIENTS_LIST: `${API_BASE_URL}/patients/simple-list`,
    CREATE_PATIENT: `${API_BASE_URL}/patients/create`,
    EDIT_PATIENT: `${API_BASE_URL}/patients`,
    DELETE_PATIENT: `${API_BASE_URL}/patients`,
    SEARCH_PATIENT: `${API_BASE_URL}/patients/search`,
    CREATE_PATIENTS_IMPORT_TEST: (patientId) => `${API_BASE_URL}/patients/${patientId}/importTest`,
    GET_PATIENTS_IMPORT_TEST: (path) => `${API_BASE_URL}${path}`
  },

  ASSESSMENT: {
    CREATE_ASSESSMENT: `${API_BASE_URL}/assessments/create`,
    UPDATE_ASSESSMENT: (assessmentId) =>  `${API_BASE_URL}/assessments/${assessmentId}`,
    UPDATE_ASSESSMENT_STATUS: (assessmentId) =>  `${API_BASE_URL}/assessments/${assessmentId}/status`,
    GET_ASSESSMENT_BY_ID: (assessmentId) =>  `${API_BASE_URL}/assessments/${assessmentId}`,
    START_ASSESSMENT: (assessmentId) =>  `${API_BASE_URL}/assessments/${assessmentId}/start`,
    GET_ASSESSMENTS_BY_PATIENT_ID: (patientId) =>  `${API_BASE_URL}/assessments/list?patientId=${patientId}`,
    GET_ASSESSMENTS_BY_PATIENT_ID_AND_LIMIT: (patientId, limit) =>  `${API_BASE_URL}/assessments/list?patientId=${patientId}&limit=${limit}`,
    GET_ASSESSMENTS_BY_PATIENT_ID_AND_LIMIT_AND_SORT: (patientId, limit, sort) =>  `${API_BASE_URL}/assessments/list?patientId=${patientId}&limit=${limit}&sort=${sort}`,
    GET_ASSESSMENTS_BY_PATIENT_ID_AND_LIMIT_AND_SORT_AND_PAGE: (patientId, limit, sort, page) =>  `${API_BASE_URL}/assessments/list?patientId=${patientId}&limit=${limit}&sort=${sort}&page=${page}`,
    CANCEL_ASSESSMENT_BY_ID: (assessmentId) =>  `${API_BASE_URL}/assessments/${assessmentId}/cancel`,
    PAUSE_ASSESSMENT_BY_ID: (assessmentId) =>  `${API_BASE_URL}/assessments/${assessmentId}/pause`,
    RESUME_ASSESSMENT_BY_ID: (assessmentId) =>  `${API_BASE_URL}/assessments/${assessmentId}/resume`,
    PAUSE_ASSESSMENT_BY_ID_NEW: (assessmentId) =>  `${API_BASE_URL}/assessments/${assessmentId}/pausenew`,
    RESUME_ASSESSMENT_BY_ID_NEW: (assessmentId) =>  `${API_BASE_URL}/assessments/${assessmentId}/resumenew`,

    START_PRE_BP_BY_ID: (assessmentId) =>  `${API_BASE_URL}/assessments/${assessmentId}/startPreBP`,
    STOP_PRE_BP_BY_ID: (assessmentId) =>  `${API_BASE_URL}/assessments/${assessmentId}/stopPreBP`,

    START_POST_BP_BY_ID: (assessmentId) =>  `${API_BASE_URL}/assessments/${assessmentId}/startPostBP`,
    STOP_POST_BP_BY_ID: (assessmentId) =>  `${API_BASE_URL}/assessments/${assessmentId}/stopPostBP`,

    START_PRE_BASELINE_BY_ID: (assessmentId) =>  `${API_BASE_URL}/assessments/${assessmentId}/startPreBaseline`,
    STOP_PRE_BASELINE_BY_ID: (assessmentId) =>  `${API_BASE_URL}/assessments/${assessmentId}/stopPreBaseline`,

    START_POST_BASELINE_BY_ID: (assessmentId) =>  `${API_BASE_URL}/assessments/${assessmentId}/startPostBaseline`,
    STOP_POST_BASELINE_BY_ID: (assessmentId) =>  `${API_BASE_URL}/assessments/${assessmentId}/stopPostBaseline`,

    START_VFT_BY_ID: (assessmentId) =>  `${API_BASE_URL}/assessments/${assessmentId}/startVFT`,
    STOP_VFT_BY_ID: (assessmentId) =>  `${API_BASE_URL}/assessments/${assessmentId}/stopVFT`,

    START_PRE_QUESTIONNAIRE_BY_ID: (assessmentId) =>  `${API_BASE_URL}/assessments/${assessmentId}/startPreQuestionnaire`,
    STOP_PRE_QUESTIONNAIRE_BY_ID: (assessmentId) =>  `${API_BASE_URL}/assessments/${assessmentId}/stopPreQuestionnaire`,

    START_POST_QUESTIONNAIRE_BY_ID: (assessmentId) =>  `${API_BASE_URL}/assessments/${assessmentId}/startPostQuestionnaire`,
    STOP_POST_QUESTIONNAIRE_BY_ID: (assessmentId) =>  `${API_BASE_URL}/assessments/${assessmentId}/stopPostQuestionnaire`,

    RECALLIBRATE_ASSESSMENT_BY_ID: (assessmentId) =>  `${API_BASE_URL}/assessments/${assessmentId}/recalibrate`,
  },

  NOTES: {
    CREATE_NOTE: (patientId, assessmentId) =>  `${API_BASE_URL}/assessments/${assessmentId}/patients/${patientId}/notes`,
    GET_NOTES: (patientId, assessmentId) =>  `${API_BASE_URL}/assessments/${assessmentId}/patients/${patientId}/notes`
  },

  FAQ: {
    CREATE_FAQ: `${API_BASE_URL}/users/faq/add`,
    UPDATE_FAQ: (faqId) => `${API_BASE_URL}/users/faq/update-faq/${faqId}`,
    GET_FAQS: `${API_BASE_URL}/users/faq/list`,
    DELETE_FAQ: (faqId) => `${API_BASE_URL}/users/faq/${faqId}`,
  },

  HMD_DEVICE: {
    ADD_NEW_HMD_DEVICE: `${API_BASE_URL}/users/device/add`,
    GET_ALL_HMD_DEVICES: `${API_BASE_URL}/users/device/list`,
    RESET_DEVICES: `${API_BASE_URL}/users/device/reset-devices`,
    DELETE_DEVICE: (deviceId) => `${API_BASE_URL}/users/device/${deviceId}`,
    UPDATE_DEVICE: (deviceId) => `${API_BASE_URL}/users/device/${deviceId}`,
    GET_HMD_BY_ID: (deviceId) => `${API_BASE_URL}/users/device/${deviceId}`,
    CHECK_DEVICE_MISMATCH: (deviceId, assessmentId) => `${API_BASE_URL}/users/device/device-matching-status/${deviceId}/${assessmentId}`,
  },

  JOBS: {
    ADD_NEW_BACKUP: `${API_BASE_URL}/jobs/create`,
    GET_ALL_BACKUPS: `${API_BASE_URL}/jobs/get-info-all`,
    GET_ALL_SCHEDULES: `${API_BASE_URL}/jobs/schedule/get-all-schedules`,
    UPDATE_SCHEDULE: (scheduleId)=>`${API_BASE_URL}/jobs/schedule/change-schedule/${scheduleId}`,
    RESTORE_BACKUP: (s3Key) => `${API_BASE_URL}/jobs/restore?file=${s3Key}`,
  },

  WORKLIST: {
    GET_ALL_WORKLISTS: `${API_BASE_URL}/patients/worklist`,
    CREATE_WORKLIST: `${API_BASE_URL}/patients/worklist/create`,
    EDIT_WORKLIST: `${API_BASE_URL}/patients/worklist`,
    DELETE_WORKLIST: `${API_BASE_URL}/patients/worklist`,
    ADD_PATIENT_INTO_WORKLIST: (worklistId) => `${API_BASE_URL}/patients/worklist/${worklistId}/addPatient`,
    REMOVE_PATIENT_FROM_WORKLIST: (worklistId) => `${API_BASE_URL}/patients/worklist/${worklistId}/removePatient`,
    DELETE_ALL_PATIENTS_FROM_WORKLIST: (worklistId) => `${API_BASE_URL}/patients/worklist/${worklistId}/removeAllPatients`,
  }
  
}
