import React from 'react'
import { Route, withRouter, Switch } from "react-router-dom"
import { connect } from 'react-redux'
import EditProfile from "./editProfile"
import ChangePassword from "./changePassword"
import EditEmailSettings from "./editEmailSettings"


export const EditUserPanel = (props) => {
    return (
        <div className="content__right">
            <div className="eadieSnow-bg h-100">
                <div className="tab-content profile-tabcontent">
                    { props.user && (
                        <Switch>
                            <Route exact path = { `${props.match.path}` } render = { p => <EditProfile { ...p } user = { props.user } /> } />
                            <Route exact path = { `${props.match.path}/editProfile` } render = { p => <EditProfile { ...p } user = { props.user } /> } />
                            <Route exact path = { `${props.match.path}/emailSettings` } render = { p => <EditEmailSettings { ...p } user = { props.user } /> } />
                            <Route exact path = { `${props.match.path}/changeSettings` } render = { p => <ChangePassword { ...p } user = { props.user } isAdminLoggedIn = { props.isAdminLoggedIn } /> } />
                        </Switch>
                    )}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ users, auth }) => ({
    user: users.editableUser,
    isAdminLoggedIn: auth.isAdmin
})

export default connect(mapStateToProps)(withRouter(EditUserPanel))
