import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { UpdatePasswordSchema } from "../../../models/users.model"
import { updatePassword } from "../../../actions/users.action"

export const ChangePassword = (props) => {

  let [passwordObj, setPasswordObj] = useState({
    newPassword: ""
  })

  useEffect(() => {
    initPasswordObj()
  }, [props.user, props.isAdminLoggedIn])

  /**
   * @desc To set the passwordObj's properties of State based in logged in user role.
   */
  const initPasswordObj = () => {
    if (props.isAdminLoggedIn) {
      setPasswordObj({
        newPassword: ""
      })
    } else {
      setPasswordObj({
        newPassword: "",
        oldPassword: ""
      })
    }
  }

  /**
   * @desc To handle change password form submittion and update it.
   * @param {object} event
   */
  const userFormHandler = (event) => {
    props.updatePassword(props.user.id ,event)
  }

  return (
    <div className="tab-pane fade active show" id="profile" role="tabpanel" aria-labelledby="profile-tab">
        <Formik
            enableReinitialize = { true }
            initialValues = { passwordObj }
            validationSchema = { UpdatePasswordSchema(props.isAdminLoggedIn) }
            onSubmit = { userFormHandler }
        >
        {
            ({ values, handleChange, handleSubmit, errors, touched, resetForm }) => (
                <React.Fragment>
                    <form onSubmit = { handleSubmit }>
                        <div className="row">
                            { values.oldPassword && (
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <input type="password" className = { `form-control ${ (errors.oldPassword && touched.oldPassword) ? "is-invalid" : null }` } placeholder="Enter New Password" id = "oldPassword" name = "oldPassword" onChange = { handleChange } value = { values.oldPassword } />
                                        { errors.oldPassword && touched.oldPassword ? (
                                        <div className="invalid-feedback">
                                            { errors.oldPassword }
                                        </div>
                                        ) : null }
                                </div>
                            </div>
                            )}
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <input type="password" className = { `form-control ${ (errors.newPassword && touched.newPassword) ? "is-invalid" : null }` } placeholder="Enter New Password" id = "newPassword" name = "newPassword" onChange = { handleChange } value = { values.newPassword } />
                                        { errors.newPassword && touched.newPassword ? (
                                        <div className="invalid-feedback">
                                            { errors.newPassword }
                                        </div>
                                        ) : null }
                                </div>
                            </div>
                            <div className="col-lg-12 mg-t-15">
                                <button className="btn btn-red" type="submit">Change Password</button>
                            </div>
                        </div>
                    </form>
                </React.Fragment>
            )
        }
        </Formik>
    </div>
  )
}

const mapDispatchToProps = dispatch => (
    bindActionCreators({
      updatePassword
    }, dispatch)
  )
  
export default connect(null, mapDispatchToProps)(ChangePassword)
