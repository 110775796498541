import React from 'react'
import { Link , useHistory} from "react-router-dom"
import { patientFullName } from "../../../shared/utils/patient"
import { parseDate } from "../../../shared/utils/wrappers"
import { CustomTooltip } from "../../shared/tooltip";
import { TOOLTIP_TEXTS } from "../../../shared/constants/shared.constants";
import { DYNAMIC_ROUTES } from "../../../shared/constants/routes.constants";

export const WorklistPatientListRow = (props) => {
    const history = useHistory()
    const navigateToAssessment = ()=>history.push(DYNAMIC_ROUTES.ASSESSMENT(props.patient.id))
    
    return (
        <div className="trow" key={props.patient.patientId}>
            <CustomTooltip 
                text = { TOOLTIP_TEXTS.REMOVE_PATIENT_FROM_WORKLIST }
            >
                <div className="tcol w-5"><i className="fabicon-move-left cursor-pointer" onClick = { () => props.onLeftArrowClick(props.patient.id) }></i></div>
            </CustomTooltip>
            <div className="tcol w-40">{ patientFullName(props.patient) }</div>
            <div className="tcol w-30">{ parseDate(props.patient.dateOfBirth) }</div>
            <div className="tcol w-25">{ props.patient.patientId }</div>
            <button className=" tcol w-20 btn btn-light " onClick = { navigateToAssessment } >Start Test</button> 
            <div className=" tcol w-2" ></div> 
        </div>
    )   
}

export default WorklistPatientListRow
