import React from 'react'
import PatientListRow from "./patientListRow"
import NoRecord from "../../shared/noRecord"
import Pagination from "../../shared/pagination"
import { NO_RECORD_MSG } from "../../../shared/constants/shared.constants"

export const PatientList = (props) => {
        let patientIds = Object.keys(props.patients || {})

        return (
            <React.Fragment>
                <div className="tableCntrl tableCntrl--left">
                    <div className="table mg-b-0 table-hover">
                        <div className="thead">
                            <div className="trow">
                                <div className="tcol w-30">
                                    <a href="#!" className="filter" onClick = { props.onPatientListArrowClick }>
                                    Patient Name{" "}
                                    <i className={props.isPatientListAsc ? "icon-arrow-down-lg eadieLightGray1-color" : "fabicon-arrow-up-lg eadieLightGray1-color"}></i>
                                    </a>
                                </div>
                                <div className="tcol w-30">DOB</div>
                                <div className="tcol w-30">Patient ID</div>
                                <div className="tcol w-10"></div>
                            </div>
                        </div>
                        <div className="tbody table-hover">
                            {
                                patientIds.length > 0 ? (
                                    patientIds.map((patientId) => (
                                        <PatientListRow 
                                            key = { patientId } 
                                            patient = { props.patients[patientId] }
                                            activePatient = { props.activePatient }
                                            onPatientRowClick = { props.onPatientRowClick }
                                            onPatientEditClick = { props.onPatientEditClick }
                                            onPatientDeleteClick = { props.onPatientDeleteClick }
                                        />
                                    ))
                                ) : <NoRecord msg = { NO_RECORD_MSG.NO_PATIENT_FOUND } />
                            }
                        </div>
                    </div>
                </div>
                {
                    patientIds && patientIds.length > 0 && (
                        <Pagination 
                            totalPages = { props.patientPagination.page }
                            currentPage = { props.patientPagination.currentPage }
                            onPageChange = { (pageNo) => props.onPaginationClick(pageNo) }
                        />
                    )
                }
            </React.Fragment>
        )
}

export default PatientList
