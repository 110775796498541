import Axios from "axios"
import { API_CONSTANTS } from "../../shared/constants/api.constants"

/**
 * @desc To create a new note
 * @param {object} noteObj
 * @public
 */
export const createNote = (patientId, assessmentId, noteObj) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.post(API_CONSTANTS.NOTES.CREATE_NOTE(patientId, assessmentId), noteObj)
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
 * @desc To get notes
 * @param {string} assessmentId
 * @param {string} patientId
 * @public
 */
export const getNotes = (patientId, assessmentId) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.get(API_CONSTANTS.NOTES.GET_NOTES(patientId, assessmentId))
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}
