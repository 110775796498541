import React from "react"
import { Route, withRouter, Redirect } from "react-router-dom"
import { ROLES_ENUM } from "../../shared/constants/shared.constants"

export const AdminRoute = ({ component: Component, loggedInUser, ...props }) => {
    return (
        <React.Fragment>
            {
                (loggedInUser && loggedInUser.role === ROLES_ENUM.ADMIN) ? (
                    <Route 
                        { ...props }
                        render = { p => <Component { ...p } /> }
                    />
                ) : <Redirect to = "/" />
            }
        </React.Fragment>
    )
}

export default withRouter(AdminRoute)
