import * as PatientService from "../services/patient/patient.service"
import { LOADER, PATIENTS } from "../shared/constants/actions.constants"
import { LANGUAGES } from "../shared/constants/shared.constants"
import { setModalWithType } from "./modal.action"
import * as FlashMessage from "../shared/utils/flashMessage"
import { SUCCESS, ERROR } from '../shared/constants/messages.constants'

/**
 * @desc To fetch all patients list and then set list into store
 * @public
 */
export const getPatients = (showloader = true, pageNo = 1) => {
  return async (dispatch, state) => {
    try {
      if (showloader) {
        dispatch(setLoader(true))
      }

      let { data: { docs, ...pagination }} = await PatientService.getPatientsList(getPatientSearchText(state()), getPatientSearchDateRange(state()), pageNo, 20, getPatientSortValue(state()))

      onSuccessPatientsList(dispatch, docs, pagination)
    } catch (error) {
      onErrorPatientsList(dispatch, error)
    }
  }
}

/**
 * @desc To fetch all patients list and then set list into store
 * @public
 */
export const getPatientSimpleList = (showloader = true) => {
  return async (dispatch, state) => {
    try {
      if (showloader) {
        dispatch(setLoader(true))
      }
      let { data } = await PatientService.getCompletePatientsList()

      onSuccessPatientSimpleList(dispatch, data)
    } catch (error) {
      onErrorPatientsList(dispatch, error)
    }
  }
}

/**
 * @desc To fetch patient by Id and then set patient in store
 * @public
 */
export const getPatientById = (patientId) => {
  return async (dispatch, state) => {
    try {
      dispatch(setLoader(true))

      let { data } = await PatientService.getPatientById(patientId)

      onSuccessPatientFetchById(dispatch, data)
    } catch (error) {
      onErrorPatientFetchById(dispatch, error)
    }
  }
}

/**
 * @desc To add new patient and add new patient into store
 * @param {object} patientObj
 * @public
 */
export const addPatient = (patientObj) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true))
      
      patientObj = { ...patientObj, language: LANGUAGES.ENGLISH}

      let { data } = await PatientService.createPatient(patientObj)

      onSuccessAddPatient(dispatch, data)
    } catch (error) {
      onErrorAddPatient(dispatch, error)
    }
  }
}

/**
 * @desc To update user object and add updated object into store
 * @param {object} patient
 * @public
 */
export const updatePatient = (patient) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true))

      let { data } = await PatientService.updatePatient(patient)

      onSuccessUpdatePatient(dispatch, data)
    } catch (error) {
      onErrorUpdatePatient(dispatch, error)
    }
  }
}

/**
 * @desc To delete user and also delete it from store
 * @param {string} patientId
 * @public
 */
export const deletePatient = (patientId) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true))

      await PatientService.deletePatient(patientId)

      onSuccessDeletePatient(dispatch, patientId)
    } catch (error) {
      onErrorDeletePatient(dispatch, error)
    }
  }
}

export const addImportTest = (patientId, importTestObj) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true))

      let { data } = await PatientService.createPatientsImportTest(patientId, importTestObj)

      onSuccessAddImportTest(dispatch, data)
    } catch (error) {
      onErrorAddImportTest(dispatch, error)
    }
  }
}

/**
 * @desc Action to set patient object into store
 * @param {object} patient
 * @public
 */
export const addActivePatient = (patient) => {
  return {
    type: PATIENTS.ADD_ACTIVE_PATIENT,
    payload: patient
  }
}

/**
 * @desc Action to set patient search text into state
 * @param {string} text
 * @public
 */
export const setPatientSearchText = (text) => {
  return { 
    type: PATIENTS.SET_PATIENT_SEARCH_TEXT, 
    payload: text
  }
}

/**
 * @desc Action to set patient's assesssments search date range
 * @param {string} text
 * @public
 */
export const setAssessmentDateRangeInPatientSearch = (fromDate, toDate) => {
  return { 
    type: PATIENTS.SET_ASSESSMENT_DATE_RANGE, 
    payload: { fromDate, toDate }
  }
}

/**
 * @desc Action to set patient's assesssments list sort
 * @param {string} text
 * @public
 */
export const setPatientListSort = (text) => {
  return { 
    type: PATIENTS.SET_PATIENT_LIST_SORT, 
    payload: text
  }
}

/**
 * @desc Actions to reset patients's state from store.
 * @public
 */
export const clearPatientSearchState = () => {
  return async dispatch => {
    dispatch(setPatientListSort("0"))
    dispatch(setPatientSearchText(null))
    dispatch(setAssessmentDateRangeInPatientSearch(null, null))
  }
}

// Private Methods

const getPatientSearchText = (state) => {
  return state && state.patients && state.patients.patientSearch && (state.patients.patientSearch.text || "")
}

const getPatientSortValue = (state) => {
  return state && state.patients && (state.patients.patientListSort || "0")
}

const getPatientSearchDateRange = (state) => {
  if (state && state.patients && state.patients.patientSearch && state.patients.patientSearch.assessmentFromDate && state.patients.patientSearch.assessmentToDate) {
    return {
      fromDate: state.patients.patientSearch.assessmentFromDate,
      toDate: state.patients.patientSearch.assessmentToDate
    }
  }
  return null
}

/**
 * @desc To store patient's list into Store when successfully fetched
 * @param {object} dispatch
 * @param {Array} patients
 * @private
 */
const onSuccessPatientsList = (dispatch, patients, pagination) => {
  dispatch(setPatientsObj(patients))
  dispatch(setPatientPagination(pagination))
  dispatch(setLoader(false))
}

/**
 * @desc To store patient's simple list into Store when successfully fetched
 * @param {object} dispatch
 * @param {Array} patients
 * @private
 */
const onSuccessPatientSimpleList = (dispatch, patients) => {
  dispatch(setPatientsSimpleList(patients))
  dispatch(setLoader(false))
}

/**
 * @desc To notify user if there is any error in fetching patient's list
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorPatientsList = (dispatch, error) => {
  dispatch(setLoader(false))
}

/**
 * @desc To store patient's object into Store when successfully fetched
 * @param {object} dispatch
 * @param {Array} patients
 * @private
 */
const onSuccessPatientFetchById = (dispatch, patients) => {
  dispatch(setPatientsObj([patients]))
  dispatch(setLoader(false))
}

/**
 * @desc To notify user if there is any error in fetching patient's object
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorPatientFetchById = (dispatch, error) => {
  dispatch(setLoader(false))
}

/**
 * @desc Action to set loader state
 * @param {boolean} flag
 * @private
 */
const setLoader = (flag) => {
  return { 
    type: LOADER.SET_LOADER, 
    payload: flag
  }
}

const setPatientPagination = (paginationObj) => {
  return { 
    type: PATIENTS.SET_PATIENT_PAGINATION, 
    payload: paginationObj
  }
}

/**
 * @desc Action to set patients list into store in object form(patient id as key and patient's object as its value.)
 * @param {array} list
 * @private
 */
const setPatientsObj = (list = []) => {
  return {
    type: PATIENTS.SET_PATIENTS_OBJ,
    payload: getPatientsObj(list)
  }
}

/**
 * @desc Action to set patients list into store in object form(patient id as key and patient's object as its value.)
 * @param {array} list
 * @private
 */
const setPatientsSimpleList = (list = []) => {
  return {
    type: PATIENTS.SET_PATIENT_SIMPLE_LIST,
    payload: list
  }
}

/**
 * @desc To update store states when patient is successfully added
 * @param {object} dispatch
 * @param {object} patient
 * @private
 */
const onSuccessAddPatient = (dispatch, patient) => {
  dispatch(addPatientIntoObj(patient))
  dispatch(setModalWithType(false))
  dispatch(setLoader(false))
  FlashMessage.success(SUCCESS.PATIENT_SUCCESSFULLY_CREATED)
}

/**
 * @desc To update store states when patient's import tests are successfully saved
 * @param {object} dispatch
 * @param {object} patient
 * @private
 */
const onSuccessAddImportTest = (dispatch, patient) => {
  dispatch(addPatientImportTestIntoObj(patient))
  dispatch(setLoader(false))
  dispatch(setModalWithType(false))
  FlashMessage.success(SUCCESS.PATIENTS_IMPORT_TEST_SUCCESSFULLY_ADDED)
}

/**
 * @desc To notify user when patient is error in patient addition
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorAddPatient = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.PATIENT_WAS_NOT_CREATED)
}

/**
 * @desc To notify user when there is error in adding patient's import test
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorAddImportTest = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.PATIENTS_IMPORT_TEST_NOT_SAVED)
}

/**
 * @desc Action to add new patient into store's patients object
 * @param {object} patient
 * @private
 */
const addPatientIntoObj = (patient = {}) => {
  return {
    type: PATIENTS.ADD_PATIENT_INTO_OBJ,
    payload: { [patient.id]: patient }
  }
}

const addPatientImportTestIntoObj = (patient = {}) => {
  return {
    type: PATIENTS.SET_PATIENTS_IMPORT_TEST_INTO_OBJ,
    payload: { 
      patientId: patient.id, 
      importTests: patient.importedTests 
    }
  }
}

/**
 * @desc To convert patient list into object (patient id as key and patient's object as its value)
 * @param {array} patientsList
 * @private
 */
const getPatientsObj = (patientsList = []) => {
  return patientsList.reduce((res, currVal) => { 
    res[currVal.id] = currVal 
    return res 
  }, {})
}

/**
 * @desc To update store states when patient is successfully updated.
 * @param {object} dispatch
 * @param {object} patient
 * @private
 */
const onSuccessUpdatePatient = (dispatch, patient) => {
  dispatch(addPatientIntoObj(patient))
  dispatch(setModalWithType(false))
  dispatch(setLoader(false))
  dispatch(addActivePatient(patient))
  FlashMessage.success(SUCCESS.PATIENT_SUCCESSFULLY_UPDATED)
}

/**
 * @desc To notify user when there is any error while updating patient
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorUpdatePatient = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.PATIENT_WAS_NOT_UPDATED)
}

/**
 * @desc To update store states when patient is successfully deleted.
 * @param {object} dispatch
 * @param {string} patientId
 * @private
 */
const onSuccessDeletePatient = (dispatch, patientId) => {
  dispatch(deletePatientAction(patientId))
  dispatch(setLoader(false))
  dispatch(addActivePatient(null))
  FlashMessage.success(SUCCESS.PATIENT_SUCCESSFULLY_DELETED)
}

/**
 * @desc To notify user when there is any error while deleting patient.
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorDeletePatient = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.PATIENT_WAS_NOT_DELETED)
}

/**
 * @desc Action to delete patient object from store based in patient id.
 * @param {string} patientId
 * @private
 */
const deletePatientAction = (patientId) => {
  return {
    type: PATIENTS.DELETE_PATIENT,
    payload: patientId
  }
}
