import { PATIENTS_IMPORT_TEST } from "../constants/shared.constants";
import * as FlashMessage from "../utils/flashMessage";

export const validateImportTestExtension = (event) => {
    let files = Object.values(event.target.files || {})
    let validFileFormats = PATIENTS_IMPORT_TEST.VALID_FILE_FORMATS
    let isValid = files.filter((file) => validFileFormats.includes(file.type)).length === files.length

    if (!isValid) {
        FlashMessage.error(PATIENTS_IMPORT_TEST.INVALID_FILE_FORMAT)
    }

    return isValid
}