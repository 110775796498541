const grid = 8

const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source)
    const destClone = Array.from(destination)
    const [removed] = sourceClone.splice(droppableSource.index, 1)

    destClone.splice(droppableDestination.index, 0, removed)

    const result = {}
    result[droppableSource.droppableId] = sourceClone
    result[droppableDestination.droppableId] = destClone

    return result
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result;
}

export const onDragEnd = (result, listsMappings, destinationList, modalData, warningMessageHandler) => {
    const { source, destination } = result

    // dropped outside the list
    if (!destination) {
        return
    }

    if (source.droppableId !== destination.droppableId) {
      if (destination.droppableId === 'droppable2' && destinationList.length >= +modalData.maxDestinationCount) {
        warningMessageHandler(`The ${ modalData.header } can maximum have ${ modalData.maxDestinationCount } charts.`)
      } else {
        const result = move(
            listsMappings[source.droppableId](),
            listsMappings[destination.droppableId](),
            source,
            destination
        )

        listsMappings[`set${ source.droppableId }`](result[source.droppableId])
        listsMappings[`set${ destination.droppableId }`](result[destination.droppableId])
      }
    } else if (source.droppableId === destination.droppableId && destination.droppableId === 'droppable2') {
      let res = reorder(listsMappings[source.droppableId](), source.index, destination.index)
      listsMappings[`set${ source.droppableId }`](res)
    }
}
  
export const getListStyle = isDraggingOver => ({
    padding: grid,
    width: "50%"
})

export const getItemStyle = (isDragging, draggableStyle, position) => ({
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    background: isDragging ? 'grey' : 'lightgrey',

    ...draggableStyle,

    left: position === "left" ? 0 : "auto"
})
