import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import ChartBlock from './chartBlock'
import { CHART_NAMES } from '../../../../shared/constants/chart.constants';
import { MODALS } from '../../../../shared/constants/actions.constants';
import { toCamelCase, datePickerParser, toPercentage, calculateTimeDuration } from "../../../../shared/utils/wrappers"
import { PatientSchema } from '../../../../models/patients.model';
import {
    clearStateAfterAssessmentConf,
    clearStateAfterAssessmentInitiate,
    getAssessmentsByPatientId,
    getPaginatedAssessmentsByPatientId
  } from '../../../../actions/assessment.action';
import { API_CONSTANTS } from "../../../../shared/constants/api.constants";
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { bindActionCreators } from 'redux';
import usePagination from '../../../../hooks/usePagination';

export const Overview = (props) => {
    let assessments = props?.assessments?.[props?.patient?.id] ?? [];
    
    let importedTests = props["patient"].importedTests
    let [ overviewCharts, setOverviewCharts ] = useState(null)
    let [ totalCharts ] = useState(Object.keys(CHART_NAMES).map((name, i) => { 
        return {
            id: `t${ i + 1 }`,
            text: (name || "").toLowerCase().split("_").join(" "),
            content: (name || "").toLowerCase().split("_").join(" "),
            key: name
        }
    }))

    useEffect(() => {
        let { overviewCharts: userOverviewCharts } = props.loggedInUser.settings || {}

        if (userOverviewCharts && userOverviewCharts.length) {
            let arr = userOverviewCharts.map((chart, i) => { 
                return {
                    id: `o${i + 1}`,
                    text: chart,
                    key: (chart || "").split(" ").join("_").toUpperCase(),
                    content: (chart || "").toLowerCase().split("_").join(" ")
                }
            })
            setOverviewCharts(arr)
        }
    }, [props.loggedInUser])

    const editClickHandler = () => {
        let modalState = { 
            followupCharts: overviewCharts,
            totalCharts,
            header: "Overview View Charts",
            size: "lg",
            maxDestinationCount: 4,
            minDestinationCount: 4
          }
    
        props.setModalWithType(true, MODALS.DND_OVERVIEW_VIEW_CHARTS, modalState)
    }

    const paginationCallback = async ({ limit, sortOption, currentPage }) => {
        if(props.assessments){ await props.getPaginatedAssessmentsByPatientId(props.patient.id,limit,sortOption,currentPage,props.assessments[props?.patient?.id],);}
      };
    
    const { currentPage, setCurrentPage, _totalPage, setTotalPage, lastElementRef, limit, sortOption, } = usePagination(props.loading, paginationCallback, { defaultLimit: 1 });
    
    useEffect(() => {
      (async () => {
        try {
          if (props.patient) {
            const data = await props.getPaginatedAssessmentsByPatientId(
              props.patient.id,
              limit,
              sortOption,
              currentPage,
              [],
            );
            setTotalPage(await data.page);
            setCurrentPage(1);
          }
        } catch (e) {
          console.log(e);
        }
      })();
    
    //   return () => {
    //     props.clearStateAfterAssessmentConf();
    //    }
    }, [props.activePatient]);
    

    return (
    <React.Fragment>
      {
        props.patient && assessments && (
            <React.Fragment>
                <div className="tab-pane fade show active" id="overview" role="tabpanel">
                    <Scrollbars style={{ position: '' }}>
                    <div className="filter__details">
                        <div className="filter__left position-relative">
                            <span className="os-title">OS</span>
                            
                        </div>
                        <div className="filter__right position-relative">
                            <span className="os-title">OD</span>
                        </div>
                    </div>
                    <div className="filter__details__inner d-flex no-gutters">
                        <div className="col-6 eadie-borderright" style={{overflow: 'hidden'}}>
                            <div className="filter__details filter__details--tabsize eadieLightGray2-bg ">
                                <div className="filter__left w-100">
                                    <div className="d-flex">
                                        {
                                            overviewCharts && overviewCharts.length && overviewCharts.map((chart) => (
                                                <div className="filter-equal w-25" key = { chart.id + Math.random() }>
                                                    <p className="semiBold">{ toCamelCase(chart.text) }</p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                assessments.map((assessment, index) => {
                                    return (
                                        <React.Fragment key={assessment.id + Math.random()}>
                                            <div className="view__outers pd-l-30" ref = { props.assessmentRefs[assessment.id] }>
                                                <div className="view__outers--action">
                                                    <a href="#!" className="actions--text" onClick = { editClickHandler }>
                                                        <i className="fabicon-Edit"></i>
                                                        Edit
                                                    </a>
                                                    <span className="actions--text">
                                                        <i className="fabicon-BarChart4"></i>
                                                        Label
                                                    </span>
                                                </div>
                                                <div className="d-flex flex-wrap no-gutters filter__content__box mg-r-5 pd-x-3 pd-y-3 mg-t-5" ref={assessments.length === index + 1 ? lastElementRef: null}>
                                                    <div className="col-12">
                                                        <h3 className="semiBold mg-b-0">Follow up: { datePickerParser(assessment.visitedOn, "MMM DD, YYYY") }</h3>
                                                    </div>
                                                    {
                                                        overviewCharts && overviewCharts.length && overviewCharts.map((chart) => (
                                                            <ChartBlock 
                                                                key = { chart.id + Math.random() } 
                                                                id = { `${ chart.id }` }
                                                                chartObj = { chart }
                                                                assessment = { assessment }
                                                                assessmentIndex = { index }
                                                                OSOD = { 'OS' }
                                                            />
                                                        ))
                                                    }
                                                </div>
                                                <div className="col-12">
                                                <div className="fliter__imgbox minh-auto">
                                                <div className="d-flex">
                                                    <div className="filter-equal">
                                                    <p>Fixation Losses (L): 
                                                    {assessment.data.summary.fixationLosses}/{assessment.data.summary?.totalfixationlosses}
                                                        {`${toPercentage(assessment.data.summary.fixationLosses,assessment.data.summary.totalfixationlosses )}`}
                                                    </p>
                                                    <p>False POS Errors (Passive):
                                                        {assessment.data.summary.falsePOSErrors}
                                                    </p>
                                                    <p>False Neg Errors (L): 
                                                        {assessment.data.summary.falseNegErrors}/{assessment.data.summary.totalfalseNegs}
                                                        {`${toPercentage(assessment.data.summary.falseNegErrors,assessment.data.summary.totalfalseNegs )}`}
                                                    </p>
                                                    <p>Test Duration: 
                                                        {calculateTimeDuration(assessment.testStartedAt,assessment.testEndedAt)}
                                                    </p>
                                                    </div>
                                                    <div className="filter-equal">
                                                    <p className="bold">MD: {assessment.data?.summary?.meanDeviation}</p>
                                                    <p className="bold">PSD: {assessment.data?.summary?.PSD}</p>
                                                    </div>
                                                </div>
                                                </div>
                                                <hr/>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )
                                })
                            }
                            {
                                importedTests?.map((test) => {
                                return (
                                    <React.Fragment key={test.fileName + Math.random()}>
                                        <div className="view__outers pd-l-30">
                                            <div className="view__outers--action">
                                                <a href="#!" className="actions--text" onClick = { editClickHandler }>
                                                    <i className="fabicon-Edit"></i>
                                                    Edit
                                                </a>
                                                <span className="actions--text">
                                                    <i className="fabicon-BarChart4"></i>
                                                    Label
                                                </span>
                                            </div>
                                        </div>
                                        {
                                            (test && test['textData'] && test['textData'][0] && test['textData'][0]['Eye'] == 'OS') ? (
                                                <div className="d-flex flex-wrap no-gutters filter__content__box mg-r-5 pd-x-3 pd-y-3 mg-t-5">
                                                    <div className="col-12">
                                                        <h3 className="semiBold mg-b-0">Follow up: { datePickerParser(test['textData'][0]['Date'], "MMM DD, YYYY") }</h3>
                                                    </div>
                                                    {
                                                        <div className="row" style={{height:"200px", overflow:'hidden', }}>
                                                            <div className='col-3' style={{overflow:'overlay'}}>
                                                                <img style={{position:'relative', maxWidth:"200%", paddingLeft:'20px', background:'white'}} src={`${ API_CONSTANTS.PATIENTS.GET_PATIENTS_IMPORT_TEST(test.imageDataPath + 'gray_scale_image.png') }`} />
                                                            </div>
                                                            <div className='col-3' style={{overflow:'overlay'}}>
                                                                <img style={{position:'relative', maxWidth:"300%", paddingLeft:'20px', background:'white'}} src={`${ API_CONSTANTS.PATIENTS.GET_PATIENTS_IMPORT_TEST(test.imageDataPath + 'sensitivity_values_image.png') }`} />
                                                            </div>
                                                            <div className='col-3' style={{overflow:'overlay'}}>
                                                                <img style={{position:'relative', maxWidth:"400%", paddingLeft:'20px', background:'white'}} src={`${ API_CONSTANTS.PATIENTS.GET_PATIENTS_IMPORT_TEST(test.imageDataPath + 'total_deviation_values_image.png') }`} />
                                                            </div>
                                                            <div className='col-3' style={{overflow:'overlay'}}>
                                                                <img style={{position:'relative', maxWidth:"300%", paddingLeft:'20px', background:'white'}} src={`${ API_CONSTANTS.PATIENTS.GET_PATIENTS_IMPORT_TEST(test.imageDataPath + 'pattern_deviation_values_image.png') }`} />
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                    <div className="col-12">
                                                        <div className="fliter__imgbox minh-auto">
                                                            <div className="d-flex">
                                                                <div className="filter-equal">
                                                                <p>Fixation Losses (L): {test['textData'][0]['Fixation Losses']}(0%)</p>
                                                                <p>False POS Errors (Passive): {test['textData'][0]['False POS Errors']}</p>
                                                                <p>False Neg Errors (L): {test['textData'][0]['False NEG Errors']}</p>
                                                                <p>Test Duration: {test['textData'][0]['Test Duration']}</p>
                                                                </div>
                                                                <div className="filter-equal">
                                                                    {console.log(test['textData'])}
                                                                <p className="bold">MD: {test['textData'][0]['MD'+test['textData'][0]['Grid']]} <span className="device-1">{`$$$(p < 2%)`}</span></p>
                                                                <p className="bold">PSD: {test['textData'][0]['PSD'+test['textData'][0]['Grid']]}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr/>
                                                    </div>
                                                    }
                                                </div>
                                            ) : (
                                                <></>
                                            )
                                        }
                                    </React.Fragment>
                                )
                            })
                            }
                        </div>
                        <div className="col-6">
                            <div className="filter__details  filter__details--tabsize eadieLightGray2-bg">
                                <div className="filter__left w-100">
                                    <div className="d-flex">
                                        {
                                            overviewCharts && overviewCharts.length && overviewCharts.map((chart) => (
                                                <div className="filter-equal w-25" key = { chart.id  + Math.random()}>
                                                    <p className="semiBold">{ toCamelCase(chart.text) }</p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                                {
                                    assessments.map((assessment, index) => {
                                        return (
                                            <React.Fragment key={assessment.id + Math.random()}>
                                                <div className="view__outers pd-l-30">
                                                    <div className="view__outers--action">
                                                        <a href="#!" className="actions--text" onClick = { editClickHandler }>
                                                            <i className="fabicon-Edit"></i>
                                                            Edit
                                                        </a>
                                                        <span className="actions--text">
                                                            <i className="fabicon-BarChart4"></i>
                                                            Label
                                                        </span>
                                                    </div>  

                                                    <div className="d-flex flex-wrap no-gutters filter__content__box mg-r-5 pd-x-3 pd-y-3 mg-t-5">
                                                        <div className="col-12">
                                                            <h3 className="semiBold mg-b-0">Follow up: { datePickerParser(assessment.visitedOn, "MMM DD, YYYY") }</h3>
                                                        </div>
                                                        {
                                                            overviewCharts && overviewCharts.length && overviewCharts.map((chart) => (
                                                                <ChartBlock 
                                                                    key = { chart.id  + Math.random()} 
                                                                    id = { `${ chart.id }` }
                                                                    chartObj = { chart }
                                                                    assessment = { assessment }
                                                                    assessmentIndex = { index }
                                                                    OSOD = { 'OD' }
                                                                />
                                                            ))
                                                        }
                                                    </div>
                                                    <div className="col-12">
                                                    <div className="fliter__imgbox minh-auto">
                                                    <div className="d-flex">
                                                        <div className="filter-equal">
                                                        <p>Fixation Losses (L): 
                                                            {assessment.data.summary.fixationLosses}/{assessment.data.summary?.totalfixationlosses}
                                                            {`${toPercentage(assessment.data.summary.fixationLosses,assessment.data.summary.totalfixationlosses )}`}
                                                        </p>
                                                        <p>False POS Errors (Passive):
                                                            {assessment.data.summary.falsePOSErrors}
                                                        </p>
                                                        <p>False Neg Errors (L): 
                                                            {assessment.data.summary.falseNegErrors}/{assessment.data.summary.totalfalseNegs}
                                                            {`${toPercentage(assessment.data.summary.falseNegErrors,assessment.data.summary.totalfalseNegs )}`}
                                                        </p>
                                                        <p>Test Duration: 
                                                            {calculateTimeDuration(assessment.testStartedAt,assessment.testEndedAt)}
                                                        </p>
                                                        </div>
                                                        <div className="filter-equal">
                                                        <p className="bold">MD: {assessment.data?.summary?.meanDeviation}</p>
                                                        <p className="bold">PSD: {assessment.data?.summary?.PSD}</p>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    <hr/>
                                                </div>
                                                </div>
                                            </React.Fragment>
                                        )
                                    })
                                }
                                {
                                importedTests?.map((test) => {
                                return (
                                    <React.Fragment key={test.fileName  + Math.random()}>
                                        <div className="view__outers pd-l-30">
                                            <div className="view__outers--action">
                                                <a href="#!" className="actions--text" onClick = { editClickHandler }>
                                                    <i className="fabicon-Edit"></i>
                                                    Edit
                                                </a>
                                                <span className="actions--text">
                                                    <i className="fabicon-BarChart4"></i>
                                                    Label
                                                </span>
                                            </div>
                                        </div>
                                        {
                                            (test && test['textData'] && test['textData'][0] && test['textData'][0]['Eye']  == 'OD') ? (
                                                <div className="d-flex flex-wrap no-gutters filter__content__box mg-r-5 pd-x-3 pd-y-3 mg-t-5">
                                                    <div className="col-12">
                                                        <h3 className="semiBold mg-b-0">Follow up: { datePickerParser(test['textData'][0]['Date'], "MMM DD, YYYY") }</h3>
                                                    </div>
                                                    {
                                                        <div className="row" style={{height:"200px", overflow:'hidden', }}>
                                                            <div className='col-3' style={{overflow:'overlay'}}>
                                                                <img style={{position:'relative', maxWidth:"200%", paddingLeft:'20px', background:'white'}} src={`${ API_CONSTANTS.PATIENTS.GET_PATIENTS_IMPORT_TEST(test.imageDataPath + 'gray_scale_image.png') }`} />
                                                            </div>
                                                            <div className='col-3' style={{overflow:'overlay'}}>
                                                                <img style={{position:'relative', maxWidth:"300%", paddingLeft:'20px', background:'white'}} src={`${ API_CONSTANTS.PATIENTS.GET_PATIENTS_IMPORT_TEST(test.imageDataPath + 'sensitivity_values_image.png') }`} />
                                                            </div>
                                                            <div className='col-3' style={{overflow:'overlay'}}>
                                                                <img style={{position:'relative', maxWidth:"400%", paddingLeft:'20px', background:'white'}} src={`${ API_CONSTANTS.PATIENTS.GET_PATIENTS_IMPORT_TEST(test.imageDataPath + 'total_deviation_values_image.png') }`} />
                                                            </div>
                                                            <div className='col-3' style={{overflow:'overlay'}}>
                                                                <img style={{position:'relative', maxWidth:"300%", paddingLeft:'20px', background:'white'}} src={`${ API_CONSTANTS.PATIENTS.GET_PATIENTS_IMPORT_TEST(test.imageDataPath + 'pattern_deviation_values_image.png') }`} />
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                    <div className="col-12">
                                                        <div className="fliter__imgbox minh-auto">
                                                            <div className="d-flex">
                                                                <div className="filter-equal">
                                                                <p>Fixation Losses (L): {test['textData'][0]['Fixation Losses']}(0%)</p>
                                                                <p>False POS Errors (Passive): {test['textData'][0]['False POS Errors']}</p>
                                                                <p>False Neg Errors (L): {test['textData'][0]['False NEG Errors']}</p>
                                                                <p>Test Duration: {test['textData'][0]['Test Duration']}</p>
                                                                </div>
                                                                <div className="filter-equal">
                                                                {console.log(test['textData'])}
                                                                <p className="bold">MD: {test['textData'][0]['MD'+test['textData'][0]['Grid']]} </p>
                                                                <p className="bold">PSD: {test['textData'][0]['PSD'+test['textData'][0]['Grid']]}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr/>
                                                    </div>
                                                    }
                                                </div>
                                            ) : (
                                                <></>
                                            )
                                        }
                                    </React.Fragment>
                                )
                            
                            })
                        }
                        </div>
                    </div>
                </Scrollbars>
            </div>
          </React.Fragment>
        )
      }
    </React.Fragment>
  )
}

const mapStateToProps = ({ patients, assessment, loader }) => ({
    loading: loader.set,
  });

const mapDispatchToProps = dispatch =>
    bindActionCreators(
      {
        getAssessmentsByPatientId,
        clearStateAfterAssessmentInitiate,
        getPaginatedAssessmentsByPatientId,
        clearStateAfterAssessmentConf
      },
      dispatch,
    );
  
export default connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(Overview));
