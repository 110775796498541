import Axios from "axios"
import { API_CONSTANTS } from "../../shared/constants/api.constants"

/**
 * @desc To fetch all worklists
 * @public
 */
export const getWorklists = (searchText = '', sort = 1) => {
    return new Promise(async (res, rej) => {
        try {
            let params = { searchText, sort }

            if (searchText) params = { ...params, searchText }

            let { data } = await Axios.get(API_CONSTANTS.WORKLIST.GET_ALL_WORKLISTS, { params })
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
 * @desc To create a new worklist
 * @param {object} worklistObj
 * @public
 */
export const createWorklist = (worklistObj) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.post(API_CONSTANTS.WORKLIST.CREATE_WORKLIST, worklistObj)
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
 * @desc To update a worklist
 * @param {object} worklistObj
 * @public
 */
export const updateWorklist = (worklistObj) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.put(`${API_CONSTANTS.WORKLIST.EDIT_WORKLIST}/${worklistObj.id}`, worklistObj)
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
 * @desc To delete a worklist
 * @param {object} worklistObj
 * @public
 */
export const deleteWorklist = (worklistId) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.delete(`${API_CONSTANTS.WORKLIST.DELETE_WORKLIST}/${worklistId}`)
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
 * @desc To delete all patients from worklist
 * @param {object} worklistObj
 * @public
 */
export const deleteAllPatientsFromWorklist = (worklistId) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.put(API_CONSTANTS.WORKLIST.DELETE_ALL_PATIENTS_FROM_WORKLIST(worklistId))
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
 * @desc To add patient into worklist
 * @param {string} worklistId
 * @param {string} patientId
 * @public
 */
export const addPatientIntoWorklist = (worklistId, patientId) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.put(API_CONSTANTS.WORKLIST.ADD_PATIENT_INTO_WORKLIST(worklistId), { patientId })
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
 * @desc To delete patient from worklist
 * @param {string} worklistId
 * @param {string} patientId
 * @public
 */
export const deletePatientFromWorklist = (worklistId, patientId) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.put(API_CONSTANTS.WORKLIST.REMOVE_PATIENT_FROM_WORKLIST(worklistId), { patientId })
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}
