import React, { useEffect } from 'react'
import { connect } from "react-redux"
import { bindActionCreators } from 'redux'
import { withRouter } from "react-router-dom"
import NoRecord from "../shared/noRecord"
import HmdDeviceDetails from "./hmdDeviceDetails"
import { NO_RECORD_MSG } from "../../shared/constants/shared.constants"
import { deleteHmdDevice } from "../../actions/hmdDevices.action";
import { getHmdDevicesWithoutLoading } from "../../actions/hmdDevices.action.js";

export const HmdDevicePanel = (props) => {

    useEffect(()=>{

        let timerInterval
        timerInterval = setInterval(function () {props.getHmdDevicesWithoutLoading()}, 3000);

        return () => {
            console.log("CLEARING HMD TIME INTERVAL")
            clearInterval(timerInterval)
        }
    },[])

    return (
        <div className="content__center w-100 block__toggle">
            <div className="patient__list">
                <div className="patient__view--scroll mcustomscrollbar">
                    <div className="patient__view--all">
                        <a href="#!" className="closed__view mg-b-40" onClick = { () => { window.history.back() } } >
                            <span className="closed__view--icon" ><i className="fabicon-close"></i></span>
                            <span className="closed__view--text">Close</span>
                        </a>
                        {
                            (props.hmdDeviceList || []).length ? (
                                <div className="row">
                                    {
                                        props.hmdDeviceList.map((device, index) => (
                                            <HmdDeviceDetails 
                                                hmdDevice = { device }
                                                index = { index }
                                                key = { device.id }
                                            />
                                        ))
                                    }
                                </div>
                            ) : 
                            <NoRecord msg = { NO_RECORD_MSG.NO_HMD_FOUND } />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ hmdDevices, auth }) => ({
    hmdDeviceList: hmdDevices.hmdDeviceList,
    isAdmin: auth.isAdmin
})

const mapDispatchToProps = dispatch => (
    bindActionCreators({ 
        deleteHmdDevice,
        getHmdDevicesWithoutLoading
    }, dispatch)
  )
  
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HmdDevicePanel))
