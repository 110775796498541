import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateAssessmentStatus } from '../../../actions/assessment.action.js';
import { setModalWithType } from '../../../actions/modal.action';
import { ASSESSMENT_STATUSES } from '../../../shared/constants/shared.constants';
import * as Logger from '../../../shared/utils/logger';

export const BlindSpotModal = props => {
  const confirmClickHandler = () => {
    try {
      const payload = {
        id: props.assessment.id,
        status: ASSESSMENT_STATUSES.BLIND_SPOT_YES,
      };
      props.updateAssessmentStatus(payload);
      props.setModalWithType(false);
    } catch (err) {
      Logger.error(err);
    }
  };

  const cancelClickHandler = () => {
    try {
      const payload = {
        id: props.assessment.id,
        status: ASSESSMENT_STATUSES.BLIND_SPOT_NO,
      };
      props.updateAssessmentStatus(payload);
      props.setModalWithType(false);
    } catch (err) {
      Logger.error(err);
    }
  };

  return (
    <React.Fragment>
      <div className="modal-header">
        <h2 className="modal-title" id="patient-modalLabel"> Blindspot </h2>
      </div>
      <div className="modal-body pd-b-30">
        <div className="row">
            <div className="form-group col-md-12">
              <label>Blindspot test failed. Repeat blindspot test?</label>
            </div>
        </div>
        <button type="submit" className="btn btn-red" onClick = { cancelClickHandler }>No</button>&nbsp;&nbsp;
        <button type="submit" className="btn btn-red" onClick = { confirmClickHandler }>Yes</button>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ patients, ui, hmdDevices, assessment }) => ({
  patient: patients.patientsObj,
  hmdDevices: hmdDevices.hmdDeviceList,
  assessment: assessment.activeAssessment,
  assessmentsByPatientId: assessment.activeAssessmentsByPatient,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setModalWithType,
      updateAssessmentStatus,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(BlindSpotModal);
