import React from 'react'
import { Link } from "react-router-dom"

export const PatientActions = (props) => {
  return (
    <div className="patient__editdetails">
      <p onClick = { props.onPatientEditClick } className="cursor-pointer">Edit Patient Details</p>
      <div className='d-flex'>
        <a className="btn btn-light ml-3 mr-3" onClick = { props.onImportTestButtonClick }>Import Test</a> 
        <Link to = { `/patients/${props.patient.id}/assessment` } className="btn btn-light ml-3">New Test</Link> 
      </div>
    </div>
  )
}

export default PatientActions
