import React, { useEffect, useState } from "react"
import { Route, Switch, Redirect, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from 'redux'
import { ROUTES } from './shared/constants/routes.constants'
import { AdminRoute } from "./components/shared"
import * as CONTAINERS from './containers'
import 'react-toastify/dist/ReactToastify.css'
import { getAuthToken } from './services/localstorage/localStorage.service'
import { fetchMyDetails } from "./actions/auth.action"
import { getCountries } from "./actions/countryStateCity.action"
import { getDeviceSidebar } from "./actions/ui.action"
import { getHmdDevicesWithoutLoading } from "./actions/hmdDevices.action"
import { homePageUrl } from "./shared/utils/util"

const AppRouter = (props) => {

  let [authToken] = useState(getAuthToken())

  useEffect(()=>{
    props.getHmdDevicesWithoutLoading()
  })

  useEffect(() => {
    if (authToken && !props.loggedInUser) {
      props.fetchMyDetails()
    }
    props.getCountries()
    props.getDeviceSidebar()
  }, [])

  return (
    <React.Fragment>
      {
        props.loggedInUser ? (
          <Switch>
            <AdminRoute exact path={ROUTES.USERS} component={ CONTAINERS.ManageUsersContainer } loggedInUser = { props.loggedInUser } />
            <Route exact path={ROUTES.PATIENTS} component={ CONTAINERS.PatientsContainer } loggedInUser = { props.loggedInUser } />
            <Route exact path={ROUTES.SETTINGS} component={ CONTAINERS.SettingsContainer } loggedInUser = { props.loggedInUser } />
            <Route exact path={ROUTES.WORKLIST} component={ CONTAINERS.WorklistContainer } loggedInUser = { props.loggedInUser } />
            <Route exact path={ROUTES.HELP} component={ CONTAINERS.HelpContainer } loggedInUser = { props.loggedInUser } />
            <Route path={ROUTES.USER_DETAILS} component={ CONTAINERS.EditUserContainer } loggedInUser = { props.loggedInUser } />
            <Route path={ROUTES.HMD_DEVICES} component={ CONTAINERS.HmdDevicesContainer } loggedInUser = { props.loggedInUser } />
            <Route path={ROUTES.ASSESSMENT} component={ CONTAINERS.AssessmentContainer } loggedInUser = { props.loggedInUser } />
            <Route exact path={ROUTES.INITIATE_ASSESSMENT} component={ CONTAINERS.InitiateAssessmentContainer } />
            <Route exact path={ROUTES.ASSESSMENT_REPORTS} component={ CONTAINERS.AssessmentReportsContainer } />
            <Redirect to = { homePageUrl(props.loggedInUser) } />
          </Switch>
        ) : (
          <Switch>
            <Route exact path={ ROUTES.LOGIN } component = { CONTAINERS.LoginContainer } />
            { !authToken && (
              <Route exact path="/" >
                <Redirect to = { ROUTES.LOGIN } />
              </Route>
            )}
            { !authToken && <Redirect to = { ROUTES.LOGIN } /> }                
          </Switch>
        )
      }
    </React.Fragment>
  )

}

const mapStateToProps = ({ auth }) => ({
  loggedInUser: auth.loggedInUser
})

const mapDispatchToProps = dispatch => (
  bindActionCreators({ 
    fetchMyDetails,
    getCountries,
    getDeviceSidebar,
    getHmdDevicesWithoutLoading
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppRouter))
