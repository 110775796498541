import React, { useState } from 'react';
import { Router } from 'react-router-dom'; // Use Router instead of BrowserRouter
import { Provider } from 'react-redux';
import ModalContainer from './containers/modalContainer';
import Loader from './components/shared/loader';
import Auth0Checker from './components/shared/auth0Checker';
import CONFIG from './config/index';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import AppRouter from './appRouter';
import 'bootstrap-daterangepicker/daterangepicker.css';
import packageJson from '../package.json';
import releaseNotesJson from './release-notes.json';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import history from './history';

const App = () => {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  toast.configure({
    autoClose: 2000,
    draggable: false,
  });

  return (
    <Provider store={CONFIG.Store}>
      <Router history={history}>
        <div className="App">
          <AppRouter />
          <Auth0Checker />
          <ToastContainer />
        </div>
      </Router>
      <ModalContainer />
      <Loader />
      <pre className="app_version" onClick={onOpenModal}>
        {packageJson.version}
      </pre>
      <Modal open={open} onClose={onCloseModal} center>
        {Object.keys(releaseNotesJson)
          .slice(0, 5)
          .map((e, i) => {
            return (
              <div key={i}>
                <h2>{e}</h2>
                <ul>
                  {Object.keys(releaseNotesJson[e]).map((p, k) => (
                    <li key={k}>
                      {p}. {releaseNotesJson[e][p]}
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
      </Modal>
    </Provider>
  );
};

export default App;
