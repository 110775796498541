import React, { useEffect, useState } from "react"
import { Scrollbars } from 'react-custom-scrollbars'
import { connect } from "react-redux"
import { useParams, withRouter } from "react-router-dom"
import { bindActionCreators } from 'redux'
import { cancelAssessmentById, clearStateAfterAssessmentInitiate, getAssessmentById, pauseAssessmentById, pauseAssessmentByIdNew, recallibrateAssessmentById, resumeAssessmentById, resumeAssessmentByIdNew, startPostBPById, startPostBaselineById, startPostQuestionnaireById, startPreBPById, startPreBaselineById, startPreQuestionnaireById, startVFTById, stopPostBPById, stopPostBaselineById, stopPostQuestionnaireById, stopPreBPById, stopPreBaselineById, stopPreQuestionnaireById, stopVFTById } from "../../actions/assessment.action"
import { getPatientById } from "../../actions/patients.action"
import { connectAssessmentNamespaceSocket } from "../../sockets/assessments"
import AssessmentDetails from '../shared/assessment/assessmentDetails'
import AssessmentNotes from '../shared/notes/assessmentNotes'
import AssessmentActions from "./assessmentActions"
import EyeGraph from "./graphs/eyeGraph"
import GazeTracker from "./graphs/gazeTracker"


export const InitiateAssessmentPanel = (props) => {

    let { patientId, assessmentId } = useParams()
    const [toggle, setToggle] = useState(false)

    useEffect(() => {
        let socket = connectAssessmentNamespaceSocket(assessmentId)
        fetchData(patientId, assessmentId)

        return () => {
            socket.close()
            props.clearStateAfterAssessmentInitiate()
        }
    }, [])

    const fetchData = (patientId, assessmentId) => {
        props.getAssessmentById(assessmentId)
        props.getPatientById(patientId)
    }

    const onAssessmentCancel = () => {
        props.cancelAssessmentById(assessmentId)
    }

    const onAssessmentPause = () => {
        props.pauseAssessmentById(assessmentId)
    }

    const onAssessmentResume = () => {
        props.resumeAssessmentById(assessmentId)
    }

    const onAssessmentPauseNew = () => {
        props.pauseAssessmentByIdNew(assessmentId)
    }

    const onAssessmentResumeNew = () => {
        props.resumeAssessmentByIdNew(assessmentId)
    }

    const onStartPreBP = () => {
        props.startPreBPById(assessmentId)
    }

    const onStopPreBP = () => {
        props.stopPreBPById(assessmentId)
    }

    const onStartPreBaseline = () => {
        props.startPreBaselineById(assessmentId)
    }

    const onStopPreBaseline = () => {
        props.stopPreBaselineById(assessmentId)
    }

    const onStartPreQuestionnaire = () => {
        props.startPreQuestionnaireById(assessmentId)
    }

    const onStopPreQuestionnaire = () => {
        props.stopPreQuestionnaireById(assessmentId)
    }

    const onStartVFT = () => {
        props.startVFTById(assessmentId)
    }
    
    const onStopVFT = () => {
        props.stopVFTById(assessmentId)
    }

    const onStartPostBP = () => {
        props.startPostBPById(assessmentId)
    }

    const onStopPostBP = () => {
        props.stopPostBPById(assessmentId)
    }

    const onStartPostBaseline = () => {
        props.startPostBaselineById(assessmentId)
    }

    const onStopPostBaseline = () => {
        props.stopPostBaselineById(assessmentId)
    }

    const onStartPostQuestionnaire = () => {
        props.startPostQuestionnaireById(assessmentId)
    }

    const onStopPostQuestionnaire = () => {
        props.stopPostQuestionnaireById(assessmentId)
    }

    const onAssessmentRecallibrate = () => {
        props.recallibrateAssessmentById(assessmentId, props.history)
    }


    return (
        <div className = "content__inner content__main">
            <div className = { `patient__table content__center w-100 block__toggle ${ props.showDeviceSidebar ? "block__toggle--left" : "" }` }>
                <div className = "patient__list">
                    <AssessmentDetails assessmentConfigureFormData={null} assessment ={ props.assessment } patient = { props.patient[patientId] || {} }/>
                    <Scrollbars>
                        <div className = "configure__main full__pages pt-0">
                            <div className={toggle ? "add__notes--tab opened" : "add__notes--tab"}>
                                <AssessmentNotes assessment={ props.assessment } patient={ props.patient[patientId] || {} } />
                            </div>
                            <div className = "test__monitoring">
                                <AssessmentActions
                                    onAssessmentCancel = { onAssessmentCancel }
                                    onAssessmentPause = { onAssessmentPause }
                                    onAssessmentResume = { onAssessmentResume }
                                    onAssessmentPauseNew = { onAssessmentPauseNew }
                                    onAssessmentResumeNew = { onAssessmentResumeNew }
                                    onStartPreBP = { onStartPreBP }
                                    onStopPreBP = { onStopPreBP }
                                    onStartPreBaseline = { onStartPreBaseline }
                                    onStopPreBaseline = { onStopPreBaseline }
                                    onStartPreQuestionnaire = { onStartPreQuestionnaire }
                                    onStopPreQuestionnaire = { onStopPreQuestionnaire }
                                    onStartVFT = { onStartVFT }
                                    onStopVFT = {onStopVFT}
                                    onStartPostBP = { onStartPostBP }
                                    onStopPostBP = { onStopPostBP }
                                    onStartPostBaseline = { onStartPostBaseline }
                                    onStopPostBaseline = { onStopPostBaseline }
                                    onStartPostQuestionnaire = { onStartPostQuestionnaire }
                                    onStopPostQuestionnaire = { onStopPostQuestionnaire }
                                    onAssessmentRecallibrate = { onAssessmentRecallibrate }
                                    assessment ={ props.assessment}
                                />
                                {
                                    props.assessment && (<button type="button" onClick={() => setToggle(!toggle)} className={toggle ? "btn btn__add--notes jsAddNotes active" : "btn btn__add--notes jsAddNotes"} >
                                        <i className="fabicon-AddNotes"></i>
                                        <span>Notes</span>
                                    </button>)
                                }
                            </div>  
                            <div className = "test__content--scroll mcustomscrollbar">
                                <div className = "test__content--right">
                                    {/* <GazeTracker 
                                        processedData = { props.processedData }
                                        assessment = { props.assessment }
                                    /> */}
                                    <EyeGraph
                                        processedData = { props.processedData }
                                        assessment = { props.assessment }
                                    />
                                </div>
                            </div>
                        </div>
                    </Scrollbars>
                </div>
            </div>
        </div>  
    )
}

const mapStateToProps = ({ patients, ui, assessment, hmdDevices }) => ({
    patient: patients.patientsObj,
    processedData: assessment.activeAssessmentProcessedData,
    assessment: assessment.activeAssessment,
    showDeviceSidebar: ui.showDeviceSidebar,
    hmdDevices: hmdDevices.hmdDeviceList
})
  
const mapDispatchToProps = dispatch => (
    bindActionCreators({ 
        getAssessmentById,
        getPatientById,
        clearStateAfterAssessmentInitiate,
        cancelAssessmentById,
        pauseAssessmentById,
        pauseAssessmentByIdNew,
        resumeAssessmentByIdNew,
        resumeAssessmentById,
        startPreBPById,
        stopPreBPById,
        startPreBaselineById,
        stopPreBaselineById, 
        startPostBPById, 
        stopPostBPById,
        startPostBaselineById, 
        stopPostBaselineById,
        startPreQuestionnaireById,
        stopPreQuestionnaireById, 
        startPostQuestionnaireById, 
        stopPostQuestionnaireById,
        startVFTById,
        stopVFTById,
        recallibrateAssessmentById,

    }, dispatch)
)
  
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InitiateAssessmentPanel))
