import React from 'react'

export const WorklistInfo = (props) => {
    let worklist = props.worklist || {}

    return (
        <div className="patient__main">
            <div className="patient__detail">
                <label className="patient__name">{ worklist.name }</label>
            </div>
            <div className="patient__detail">
            <label>Assigned To: </label>
                { worklist.assignedTo && `${ worklist.assignedTo.firstName } ${ worklist.assignedTo.lastName }` }
            </div>
        </div>
    )
}

export default WorklistInfo
