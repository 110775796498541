import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { setModalWithType } from "../../../actions/modal.action"
import { _API_BASE_URL } from "../../../shared/constants/api.constants";

export const ImportedTestSummaryTextDataViewModal = (props) => {

  const cancelClickHandler = () => {
    props.setModalWithType(false)
  }

  return (
    <React.Fragment>
      <div className="modal-header">
        <h2 className="modal-title" id="patient-modalLabel">{ props.modalData.fileName }</h2>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick = { cancelClickHandler }>
            <span aria-hidden="true"><i className="fabicon-close"></i></span>
        </button>
      </div>
      <div className="modal-body">
          {
            props.modalData && props.modalData.textData && props.modalData.textData.length ? (
              <>                
                {
                  props.modalData.textData.map((data) => (
                    <>
                      <div className="row">
                        {
                          Object.keys(data).map(key => 
                            <div className="form-group col-md-6">
                              <label>{key}: &nbsp; &nbsp;</label>
                              <span>{data[key]}</span>
                            </div>
                          )
                        }
                      </div>

                      <hr />
                    </>
                  ))
                }
              </>
            ) : null
          }
      </div>
    </React.Fragment>
  );
}

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setModalWithType
  }, dispatch)
)

export default connect(null, mapDispatchToProps)(ImportedTestSummaryTextDataViewModal)


{/* <div className="row">
                  <div className="form-group col-md-6">
                    <label>First Name</label>
                    <span>5345345</span>
                  </div>
                  <div className="form-group col-md-6">
                    <label>Middle Name</label>
                    <span>fdfsdf</span>
                    
                  </div>
                </div> */}
 