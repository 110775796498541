import { ASSESSMENT } from "../shared/constants/actions.constants"
import { AssessmentConfigurationForm } from "../models/assessment.model"

const initialState = {
  activeAssessment: null,
  activeAssessmentProcessedData: null,
  activeAssessmentsByPatient: null,
  selectedAssessmentForForm: {...AssessmentConfigurationForm, timestamp: new Date().getTime()},
}

export default function(state = initialState, action) {

  switch (action.type) {

    case ASSESSMENT.ADD_ACTIVE_ASSESSMENT:
      return {
        ...state,
        activeAssessment: action.payload,
      }
    
    case ASSESSMENT.ADD_SELECTED_ASSESSMENT_FOR_FORM:
      return {
        ...state,
        selectedAssessmentForForm: action.payload
      }

    case ASSESSMENT.ADD_ACTIVE_ASSESSMENT_PROCESSED_DATA:
      return {
        ...state,
        activeAssessmentProcessedData: action.payload
      }

    case ASSESSMENT.ADD_ACTIVE_ASSESSMENTS_BY_PATIENT_ID:
      return {
        ...state,
        activeAssessmentsByPatient: action.payload
      }

    case ASSESSMENT.UPDATE_ACTIVE_ASSESSMENTS_STATUS:
      let { assessmentId } = action.payload

      if (state.activeAssessment && state.activeAssessment.id && state.activeAssessment.id === assessmentId) {
        return {
          ...state,
          activeAssessment: { ...state.activeAssessment, ...action.payload }
        }
      } else {
        return state
      }

    default:
      return state
  }
}
