import React from 'react'
import { roundToNearestQuarter, toCamelCase } from "../../../shared/utils/wrappers"
import NoRecord from "../../shared/noRecord"
import { NO_RECORD_MSG } from "../../../shared/constants/shared.constants"
import Scrollbars from 'react-custom-scrollbars'
import { AssessmentConfigurationForm, STRATEGIES, STRATEGIES_LABELS } from '../../../models/assessment.model'
import { patientRefractionDetails } from '../../../shared/utils/patient'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { selectAssessmentForForm } from '../../../actions/assessment.action';

export const PreviousConfigurations = (props) => {

    const handlePreviousConfigSelection = (assessment)=>{
        let modifiedAssessmentValues = JSON.parse(JSON.stringify(assessment))
        const [visualAcuityPart1='', visualAcuityPart2=''] = (modifiedAssessmentValues.configuration?.visualAcuity)?.split('/')
        modifiedAssessmentValues.configuration.visualAcuityPart1 = visualAcuityPart1
        modifiedAssessmentValues.configuration.visualAcuityPart2 = visualAcuityPart2

        modifiedAssessmentValues.configuration.osSphere = (modifiedAssessmentValues.configuration.osSphere).toFixed(2)
        modifiedAssessmentValues.configuration.osCylinder = (modifiedAssessmentValues.configuration.osCylinder).toFixed(2)
        modifiedAssessmentValues.configuration.osAxis = (modifiedAssessmentValues.configuration.osAxis).toFixed(2)

        modifiedAssessmentValues.configuration.odSphere = (modifiedAssessmentValues.configuration.odSphere).toFixed(2)
        modifiedAssessmentValues.configuration.odCylinder = (modifiedAssessmentValues.configuration.odCylinder).toFixed(2)
        modifiedAssessmentValues.configuration.odAxis = (modifiedAssessmentValues.configuration.odAxis).toFixed(2)

        if(modifiedAssessmentValues.configuration.trialLensOSsphere){
            delete modifiedAssessmentValues.configuration.trialLensOSsphere;
            delete modifiedAssessmentValues.configuration.trialLensOScylinder;
            delete modifiedAssessmentValues.configuration.trialLensOSaxis;
            delete modifiedAssessmentValues.configuration.trialLensODsphere;
            delete modifiedAssessmentValues.configuration.trialLensODcylinder;
            delete modifiedAssessmentValues.configuration.trialLensODaxis;
        }

        if (!assessment.configuration?.vertexDistance) {
            modifiedAssessmentValues.configuration.vertexDistance = AssessmentConfigurationForm.configuration.vertexDistance || ''; // setting as default value, old data does not have this field
        } 
        // if (assessment.configuration.strategy === 'zest') {
        //     modifiedAssessmentValues.configuration.strategy = STRATEGIES?.[0] // setting as default value, old data has 'zest' value which was replaced with 'ZEST'
        // }
        // props.selectAssessmentForForm(modifiedAssessmentValues)
        props.setPreviousConfiguration(modifiedAssessmentValues)
    }

    return (
        <React.Fragment>
            <div className="congfigure__test--previous width__30per">
                <h3 className="mg-b-15">Previous Configurations</h3>
                {
                    props.previousAssessments && props.previousAssessments.length > 0 ? 
                    ( <Scrollbars>
                        <ul className="congfigure__previous--list mb-3">
                            {
                                props.previousAssessments.map((assessment, index) => (
                                    <li key={index}>
                                        <div className="patient__detail">
                                            <label>Grid:</label>
                                            {" "}
                                            <span className="patient__value">{assessment.configuration ? assessment.configuration.grid : ''}</span>
                                        </div>
                                        <div className="patient__detail">
                                            <label> Response Type: </label>
                                            {" "}
                                            <span className="patient__value">{assessment.configuration ? toCamelCase(assessment.configuration.responseType) : ''}</span>
                                        </div>
                                        <div className="patient__detail">
                                            <label>Strategy: </label>
                                            {" "}
                                            <span className="patient__value">{assessment.configuration ? STRATEGIES_LABELS?.[assessment.configuration.strategy]?.label : ''}</span>
                                        </div>
                                        <div className="patient__detail">
                                            <label>Eye: </label>
                                            {" "}
                                            <span className="patient__value">{assessment.configuration ? toCamelCase(assessment.configuration.eye) : ''}</span>
                                        </div>
                                        {/* <div className="patient__detail">
                                            <label>IPD:</label>
                                            <span className="patient__value">{assessment.configuration ? assessment.configuration.patientParameters : ''}</span>
                                        </div> */}
                                        <div className="patient__detail">
                                            <label>OD Trial Lens: </label>
                                            {" "}
                                            <span className="patient__value">{assessment.configuration && patientRefractionDetails(assessment.configuration, "OD")}</span>
                                        </div>
                                        <div className="patient__detail">
                                            <label>OS Trial Lens:</label>
                                            {" "}
                                            <span className="patient__value">{assessment.configuration && patientRefractionDetails(assessment.configuration, "OS")}</span>
                                        </div>
                                        <div className="mg-t-10">
                                            <button className="btn btn__white" onClick={()=>{ handlePreviousConfigSelection(assessment) }}>Select</button>
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                      </Scrollbars>
                    ) : <NoRecord msg = { NO_RECORD_MSG.NO_PREVIOUS_CONFIGURATIONS_FOUND } />
                }
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = ({}) =>({});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
      {
        selectAssessmentForForm
      },
      dispatch,
    );
  
export default connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(PreviousConfigurations));

// export default PreviousConfigurations
