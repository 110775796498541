import React, { useEffect, useState } from 'react'
import WorklistListRow from "./worklistListRow"
import NoRecord from "../../shared/noRecord"
import { NO_RECORD_MSG } from "../../../shared/constants/shared.constants"
import { Scrollbars } from 'react-custom-scrollbars'

export const WorklistList = (props) => {
  let [worklistList, setWorklistList] = useState([])

  useEffect(() => {
    let worklist = Object.keys(props.worklistsObj || {}).reduce((res, id) => [ ...res, props.worklistsObj[id] ], [])
    setWorklistList([ ...worklist ])
  }, [props.worklistsObj])

  return (
      <div className="tableCntrl tableCntrl--left">
        <div className="table mg-b-0 table-hover">
          <div className="thead">
            <div className="trow">
              <div className="tcol w-40"><a className="filter" href="#!" onClick = { props.onWorklistArrowClick }>Name <i className={props.isWorklistAsc ? "icon-arrow-down-lg eadieLightGray1-color" : "fabicon-arrow-up-lg eadieLightGray1-color"}></i></a></div>
              <div className="tcol w-40">Assigned To</div>
              <div className="tcol w-20"></div>
            </div>
          </div>
          <div className="tbody mcustomscrollbar table-hover noheight" style={{ 'maxHeight' : '250px !important'}}>
          <Scrollbars style={{ 'position': 'relative', 'minHeight': 290 }}>
              {
                worklistList.length ? (
                  worklistList.map((worklist) => (
                    <WorklistListRow 
                      key = { worklist.id }
                      worklist = { worklist }
                      worklistRowClickHandler = { props.worklistRowClickHandler }
                      activeWorklist = { props.activeWorklist }
                      worklistEditClickHandler = { props.worklistEditClickHandler }
                      worklistDeleteClickHandler = { props.worklistDeleteClickHandler }
                      removeAllPatientsClickHandler = { props.removeAllPatientsClickHandler }
                    />
                  ))
                ) : (
                  <NoRecord msg = { NO_RECORD_MSG.NO_WORKLIST_FOUND } />
                )
              }
            </Scrollbars>
          </div>
        </div>
      </div>
  )
}

export default WorklistList
