import React from 'react'
import { Formik } from 'formik'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { UserSchema } from "../../../models/users.model"
import { updateUser } from "../../../actions/users.action"

export const EditEmailSettings = (props) => {

    /**
     * @desc To handle change email form submittion and update it.
     * @param {object} event
     * @public
     */
    const userFormHandler = (event) => {
        props.updateUser(event)
    }

    return (
        <div className="tab-pane fade active show" id="profile" role="tabpanel" aria-labelledby="profile-tab">
            <Formik
                enableReinitialize = { true }
                initialValues = { props.user }
                validationSchema = { UserSchema(false) }
                onSubmit = { userFormHandler }
            >
            {
                ({ values, handleChange, handleSubmit, setFieldValue, errors, touched }) => (
                    <React.Fragment>
                        <form onSubmit = { handleSubmit }>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <input type="text" className = { `form-control ${ (errors.email && touched.email) ? "is-invalid" : null }` } placeholder="Enter first name" id = "email" name = "email" onChange = { handleChange } value = { values.email } />
                                            { errors.email && touched.email ? (
                                            <div className="invalid-feedback">
                                                { errors.email }
                                            </div>
                                            ) : null }
                                    </div>
                                </div>
                                <div className="col-lg-12 mg-t-15">
                                    <button className="btn btn-red" type="submit">Change Email</button>
                                </div>
                            </div>
                        </form>
                    </React.Fragment>
                )
            }
            </Formik>
        </div>
    )
}

const mapDispatchToProps = dispatch => (
    bindActionCreators({
      updateUser
    }, dispatch)
  )
  
  export default connect(null, mapDispatchToProps)(EditEmailSettings)
