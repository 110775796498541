import React, { useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { setModalWithType } from "../../../actions/modal.action"
import { _API_BASE_URL } from "../../../shared/constants/api.constants";

export const ImportedTestSummaryImageViewModal = (props) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0)

  const images = [
    'PD_probability_values_image.png',
    'TD_probability_values_image.png',
    'gray_scale_image.png',
    'legend_image.png',
    'pattern_deviation_values_image.png',
    'sensitivity_values_image.png',
    'total_deviation_values_image.png'
  ]

  const cancelClickHandler = () => {
    props.setModalWithType(false)
  }

  const goPrevPage = ()=>setSelectedImageIndex(prev=> prev>0? prev-1: 0)

  const goNextPage= ()=>setSelectedImageIndex(prev=> prev<images.length-1? prev+1 : images.length-1)

  const isPrevBtnDisabled = ()=> selectedImageIndex === 0

  const isNextBtnDisabled = ()=> selectedImageIndex === images.length-1

  return (
    <React.Fragment>
      <div className="modal-header">
        <h2 className="modal-title" id="patient-modalLabel">{ props.modalData.fileName }</h2>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick = { cancelClickHandler }>
            <span aria-hidden="true"><i className="fabicon-close"></i></span>
        </button>
      </div>
      <div className="modal-body">
        <div className='modal-buttons'>
            <button className={`btn btn-light ${ isPrevBtnDisabled() && 'disabled'}`} type='button'><span aria-hidden="true" onClick={ goPrevPage }>« Prev</span></button>
            
            <button className={`btn btn-light ${ isNextBtnDisabled() && 'disabled'}`} type='button'><span aria-hidden="true" onClick={ goNextPage }>Next »</span></button>
        </div>
         
          {props.modalData && props.modalData.filePath && (
          <div className="row">
            <div>
              <label>{images[selectedImageIndex]}</label>
              <img
                src={`${_API_BASE_URL}${props.modalData.imageDataPath}${images[selectedImageIndex]}`}
                alt={`No record`}
              />
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setModalWithType
  }, dispatch)
)

export default connect(null, mapDispatchToProps)(ImportedTestSummaryImageViewModal)
 