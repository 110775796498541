import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useParams, withRouter, useLocation } from 'react-router-dom';
import { toPercentage } from '../../shared/utils/wrappers';

export const OngoingReliabilityMeasures = props => {
  const { assessment, processedData } = props;

  if (!assessment || !assessment.data || !assessment.data.summary) {
    return null;
  }

  return (
    <div className="d-flex justify-content-center align-items-center h-100">
      <div>
        <div class="d-flex justify-content-start align-items-start">
          <div class="patient__detail mr-3">
            <label class="bold">FL:</label>
          </div>
          <div class="patient__detail">
            <div class="patient__detail">
              {assessment.data.summary.fixationLosses}/{assessment.data.summary.totalfixationlosses}
              {`${toPercentage(assessment.data.summary.fixationLosses, assessment.data.summary.totalfixationlosses)}`}
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-start align-items-start">
          <div class="patient__detail mr-3">
            <label class="bold">FP:</label>
          </div>
          <div class="patient__detail">
            <div class="patient__detail">
              {assessment.data.summary.falsePosErrors_active}/{assessment.data.summary.totalfalsePosis_active}
              {`${toPercentage(assessment.data.summary.falsePosErrors_active,assessment.data.summary.totalfalsePosis_active,
              )}`}
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-start align-items-start">
          <div class="patient__detail mr-3">
            <label class="bold">FN:</label>
          </div>
          <div class="patient__detail">
            <div class="patient__detail">
              {assessment.data.summary.falseNegErrors}/{assessment.data.summary.totalfalseNegs}
              {`${toPercentage(assessment.data.summary.falseNegErrors,assessment.data.summary.totalfalseNegs,
              )}`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ assessment }) => ({
  assessment: assessment.activeAssessment,
  processedData: assessment.activeAssessmentProcessedData,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(OngoingReliabilityMeasures));
