import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import { toCamelCase, parseDate } from "../../../shared/utils/wrappers"
import { STRATEGIES_LABELS } from '../../../models/assessment.model'
import { patientRefractionDetails, patientTrialLensDetails } from '../../../shared/utils/patient'

export const AssessmentConfiguration = (props) => {

  const [configuration, setConfiguration] = useState(null)

  useEffect(()=>{
    if(props.assessment){
      setConfiguration(props.assessment && props.assessment.configuration ? props.assessment.configuration : null)
    } else if(props.assessmentConfigureFormData){
      setConfiguration(props.assessmentConfigureFormData && props.assessmentConfigureFormData.configuration ? props.assessmentConfigureFormData.configuration : null)
    }
  },[props.assessment, props.assessmentConfigureFormData])

  

  return (
    <React.Fragment>
      <div className="patients__details--center">
        <div className="patient__detail">
          <label>Grid:</label>{" "}
          <input type="text" className="patient__value" readOnly value={configuration ? configuration.grid : ''} />
        </div>
        <div className="patient__detail">
          <label>Response Type:</label> {" "}
          <input type="text" className="patient__value" readOnly value={configuration ? toCamelCase(configuration.responseType) : ''} />
        </div>
        <div className="patient__detail">
          <label>Strategy:</label>{" "}
          <input type="text" className="patient__value" readOnly value={configuration ? STRATEGIES_LABELS[configuration.strategy]?.label : ''} />
        </div>
        <div className="patient__detail">
          <label>Eye:</label>{" "}
          <input type="text" className="patient__value" readOnly value={configuration ? toCamelCase(configuration.eye) : ''} />
        </div>
      </div>
      <div className="patients__details--right">
          <div className="patient__detail">
            <label>Patient Parameters</label>
          </div>
          <div className="d-flex justify-content-start align-items-start">
            <div className="patient__detail mr-3"> 
              <label className="bold">OD</label>
            </div>
            <div className="patient__detail">
              <div className="patient__detail">
                Rx: {" "}
                <label className="regular">
                  {configuration && patientRefractionDetails(configuration, "OD")}
                </label>
              </div>
              <div className="patient__detail">
                Trial Lens: {" "}
                <label className="regular">
                  {configuration && patientTrialLensDetails(configuration, "OD")}
                </label>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-start align-items-start">
            <div className="patient__detail mr-3"> 
              <label className="bold">OS</label>
            </div>
            <div className="patient__detail">
              <div className="patient__detail">
                Rx: {" "}
                <label className="regular">
                  {configuration && patientRefractionDetails(configuration, "OS")}
                </label>
              </div>
              <div className="patient__detail">
                Trial Lens: {" "}
                <label className="regular">
                  {configuration && patientTrialLensDetails(configuration, "OS")}
                </label>
              </div>
            </div>
          </div>
      </div>
    </React.Fragment>
  )
}

export default AssessmentConfiguration
