import React, { useEffect } from "react"
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom"
import { DYNAMIC_ROUTES, ROUTES } from '../../shared/constants/routes.constants'
import { setDeviceSidebar } from "../../actions/ui.action"
import { patientFullName } from "../../shared/utils/patient";
import { getHmdDevicesWithoutLoading } from "../../actions/hmdDevices.action.js";
import { OS_OD } from "../hmdDevices/hmdDeviceDetails"
import { getHmdAssessmentPercentage } from "../../shared/utils/hmd"
import { useHistory, useLocation } from "react-router-dom"

export const Sidebar = (props) => {
  const history = useHistory()
  const location = useLocation();

  /**
   * @desc To show all devices
   */
  const onViewAllClick = () => {
    props.history.push(ROUTES.HMD_DEVICES)
  }

  /**
   * @desc Handler for device on left sidebar
   */
  const onDeviceClick = () => {
    if (!props.disableSideBar) {
      props.setDeviceSidebar(!props.showDeviceSidebar)
    }
  }

  const navigateToAssessment = (hmdDevice
    ) => {
      if(hmdDevice.patient?.id && hmdDevice.assessment?.id)
          history.push(DYNAMIC_ROUTES.INITIATE_ASSESSMENT(hmdDevice.patient?.id, hmdDevice.assessment?.id));
    };

  useEffect(()=>{

    let timerInterval
    const assessmentRouteRegex = new RegExp(`^/patients/[^/]+/assessment$`);

    // Check if the location matches the dynamic route and if the sidebar is open
    if (assessmentRouteRegex.test(location.pathname) || props.showDeviceSidebar){
      timerInterval = setInterval(function () {props.getHmdDevicesWithoutLoading()}, 3000);
    } else {
      clearInterval(timerInterval)
    }

    return () => {
        console.log("CLEARING HMD TIME INTERVAL")
        clearInterval(timerInterval)
    }
  },[props.showDeviceSidebar])

  return (
    <div className = { `devicepane ${props.showDeviceSidebar ? "devicepane_active" : "" }` }>
      <div className = { `devicepane-toggle ${props.showDeviceSidebar ? "active" : "" }` } onClick = { onDeviceClick }>
        <i className="icon-device" style = { { display: "block", textAlign: "center", lineHeight: "24px" } }></i>
        Devices
      </div>
      <div className="devicepane-list">
        <ul>
          <li>
            <div className="device cursor-pointer" onClick = { onViewAllClick }>
              <div className="device-all">
                <i className="icon-alldevice"></i>
                View All
              </div>
            </div>
          </li>
          {
            props.hmdDeviceList.map((device, index) => (
              device.status ? (
                <li key = { index }>
                  <div className={device.patient? `device device-${index}`: 'device device-disabled'}>
                    <div className="device-name cursor-pointer" onClick={()=>navigateToAssessment(device)}>D{ index }</div>
                    <div className="device-progress">
                      <span className="device-patient cursor-pointer" onClick={()=>navigateToAssessment(device)}>{device.patient? `${patientFullName(device.patient)}`: 'Unassigned'}</span>
                      <div className="progress">
                        <div className="progress-bar bg-success" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style = { { width: `${getHmdAssessmentPercentage(device.assessment)}%` } }></div>
                      </div>
                    </div>
                    <div className="device-status">
                      <i className="fabicon-circle"></i>
                      <span>{getHmdAssessmentPercentage(device.assessment)}%</span>
                    </div>
                  </div>
                </li>
              ) : null
            ))
          }
        </ul>
      </div>
    </div>
  )
}

const mapStateToProps = ({ ui, hmdDevices }) => ({
  showDeviceSidebar: ui.showDeviceSidebar,
  disableSideBar: ui.disableSideBar,
  hmdDeviceList: hmdDevices.hmdDeviceList
})

const mapDispatchToProps = dispatch => (
  bindActionCreators({ 
    setDeviceSidebar,
    getHmdDevicesWithoutLoading
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar))