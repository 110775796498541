import React from 'react'
import { connect } from "react-redux"
import { toCamelCase } from "../../../shared/utils/wrappers"

export const UserDetailPanel = (props) => {
    let user = props.activeUser || {}

    return (
        <div className="content__right js-content__right">
            <div className="patient__list eadieWhite-bg">
            <div className="patient__main">
                <div className="patient__detail">
                    <label className="patient__name">{ toCamelCase(user.firstName) } { toCamelCase(user.lastName) }</label>
                </div>
                <div className="patient__detail">
                <label>ID:</label>
                    { user.id }
                </div>
                <div className="patient__detail">
                <label>Email:</label>
                    { user.email }
                </div>
                <div className="patient__detail">
                <label>Role:</label>
                    { user.role }
                </div>
            </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ users }) => ({
    activeUser: users.activeUser
})
  
export default connect(mapStateToProps)(UserDetailPanel)
