import React from 'react'
import { toCamelCase, parseDate } from "../../../shared/utils/wrappers"
import { patientFullName } from "../../../shared/utils/patient"

export const PatientDetails = (props) => {
  return (
    <React.Fragment>
      {
        props.patient && (
          <React.Fragment>
                <div className="patient__configure d-print-none">
                    <div className="patient__configure--innner">
                        <div className="patient__configure--left">
                            <ul>
                                <li className="patient__configure--first">
                                    <div className="patient__configure--name">
                                        <p>{ patientFullName(props.patient) }</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="patient__detail">
                                        <label className="bold">DOB:</label> {" "}
                                        <span className="patient__value">{ parseDate(props.patient.dateOfBirth) }</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="patient__detail">
                                    <label className="bold">Patient ID:</label>{" "}
                                    <span className="patient__value">{ props.patient.patientId }</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="collapse" id="collapseDetails">
                    <div className="patients__language">
                        <div className="patients__details--left w-100">
                            <div className="patient__detail">
                                <label>Language:</label>{" "}
                                <input type="text" className="patient__value" readOnly value={ toCamelCase(props.patient.language) } />
                            </div>
                            {/* <div className="patient__detail">
                                <label>Strabismus:</label>
                                <input type="text" className="patient__value" readOnly value={ toCamelCase(props.patient.strabismus) } />
                            </div> */}
                            <div className="patient__detail">
                                <label>Single Eye:</label>
                                <input type="text" className="patient__value" readOnly value={ toCamelCase(props.patient.singleEye) } />
                            </div>
                        </div>
                    </div>
                </div>
          </React.Fragment>
        )
      }
    </React.Fragment>
  )
}

export default PatientDetails
