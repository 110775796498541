import { USERS } from "../shared/constants/actions.constants"

const initialState = {
  usersObj: {},
  activeUser: null,
  editableUser: null,
  userSearchText: ""
}

export default function(state = initialState, action) {

  switch (action.type) {
    case USERS.SET_USERS_OBJ:
      return {
        ...state,
        usersObj: { ...action.payload }
      }

    case USERS.ADD_USER_INTO_OBJ:
      return {
        ...state,
        usersObj: { ...state.usersObj, ...action.payload }
      }

    case USERS.ADD_ACTIVE_USER:
      return {
        ...state,
        activeUser: action.payload
      }

    case USERS.DELETE_USER:
      delete state.usersObj[action.payload]
      return {
        ...state,
        usersObj: { ...state.usersObj }
      }

    case USERS.SET_EDITABLE_USER:
      return {
        ...state,
        editableUser: action.payload
      }

    case USERS.SET_USERS_SEARCH_TEXT:
      return {
        ...state,
        userSearchText: action.payload
      }

    case USERS.SET_USERS_SORT:
      return {
        ...state,
        userSort: action.payload
      }

    default:
      return state
  }
}
