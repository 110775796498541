export const SUCCESS = { 
    PATIENT_SUCCESSFULLY_CREATED: "Patient successfully created",
    PATIENT_SUCCESSFULLY_UPDATED: "Patient successfully updated",
    PATIENT_SUCCESSFULLY_DELETED: "Patient successfully deleted",
    PATIENTS_IMPORT_TEST_SUCCESSFULLY_ADDED: "Patient's import tests successfully saved.",
    
    PASSWORD_SUCCESSFULLY_UPDATED: "Password successfully updated",

    USER_SUCCESSFULLY_CREATED: "User successfully created",
    USER_SUCCESSFULLY_UPDATED: "User successfully updated",
    USER_SUCCESSFULLY_FETCHED: "User successfully fetched",

    WORKLIST_SUCCESSFULLY_CREATED: "Worklist successfully created",
    WORKLIST_SUCCESSFULLY_UPDATED: "Worklist successfully updated",
    WORKLIST_SUCCESSFULLY_DELETED: "Worklist successfully deleted",
    PATIENT_SUCCESSFULLY_ADDED_INTO_WORKLIST: "Patient successfully added into Worklist",
    PATIENT_SUCCESSFULLY_DELETED_FROM_WORKLIST: "Patient successfully deleted from Worklist",
    ALL_PATIENTS_SUCCESSFULLY_DELETED_FROM_WORKLIST: "All Patients successfully deleted from Worklist",


    ASSESSMENT_SUCCESSFULLY_CREATED: "Assessment successfully created",
    ASSESSMENT_SUCCESSFULLY_UPDATED: "Assessment successfully updated",
    ASSESSMENT_SUCCESSFULLY_COMPLETED: "Assessment successfully completed",
    ASSESSMENT_SUCCESSFULLY_CANCELLED: "Assessment successfully cancelled",
    ASSESSMENT_SUCCESSFULLY_ADDED_TO_STORE: "Assessment successfully added to store",
    ASSESSMENTS_BY_PATIENT_ID_SUCCESSFULLY_FETCHED: "Assessments by patient id fetched successfully",

    HMD_DEVICE_SUCCESSFULLY_CREATED: "HMD Device successfully created",
    HMD_DEVICE_SUCCESSFULLY_RESET: "HMD Device successfully reset",

    BACKUP_SUCCESSFULLY_CREATED: "Backup successfully created",
    BACKUP_RESTORED_SUCCESSFULLY: "Backup successfully restored",
    CRON_JOB_SCHEDULE_CHANGED: "Cron Job Schedule successfully changed",

    START_ASSESSMENT: "Started the assessment",
    PAUSE_ASSESSMENT_SUCCESSFULLY: "Paused the assessment",
    RESUME_ASSESSMENT_SUCCESSFULLY: "Resumed the assessment",
    PRE_BP_COLLECTION_STARTED_SUCCESSFULLY: "Pre BP Collection Started Successfully",
    PRE_BP_COLLECTION_STOPED_SUCCESSFULLY: "Pre BP Collection Stoped Successfully",
    PRE_BASELINE_COLLECTION_STARTED_SUCCESSFULLY: "Pre Baseline Collection Started Successfully",
    PRE_BASELINE_COLLECTION_STOPED_SUCCESSFULLY: "Pre Baseline Collection Stoped Successfully",
    PRE_QUESTIONNAIRE_COLLECTION_STARTED_SUCCESSFULLY: "Pre Questionnaire Collection Started Successfully",
    PRE_QUESTIONNAIRE_COLLECTION_STOPED_SUCCESSFULLY: "Pre Questionnaire Collection Stopped Successfully",
    POST_BP_COLLECTION_STARTED_SUCCESSFULLY: "Post BP Collection Started Successfully",
    POST_BP_COLLECTION_STOPED_SUCCESSFULLY: "Post BP Collection Stoped Successfully",
    POST_BASELINE_COLLECTION_STARTED_SUCCESSFULLY: "Post Baseline Collection Started Successfully",
    POST_BASELINE_COLLECTION_STOPED_SUCCESSFULLY: "Post Baseline Collection Stoped Successfully",
    POST_QUESTIONNAIRE_COLLECTION_STARTED_SUCCESSFULLY: "Post Questionnaire Collection Started Successfully",
    POST_QUESTIONNAIRE_COLLECTION_STOPED_SUCCESSFULLY: "Post Questionnaire Collection Stopped Successfully",
    VFT_STARTED_SUCCESSFULLY: "Visual Field Test Started Successfully",
    VFT_STOPPED_SUCCESSFULLY: "Visual Field Test Stopped Successfully",

    NOTE_SUCCESSFULLY_CREATED: "Note successfully created",
    NOTES_SUCCESSFULLY_FETCHED: "Notes successfully fetched",

    FAQ_SUCCESSFULLY_CREATED: "FAQ successfully created",
    FAQ_SUCCESSFULLY_UPDATED: "FAQ successfully updated",

    FILE_SUCCESSFULLY_UPLOADED: "File successfully uploaded",
}

export const ERROR = {
    PATIENT_WAS_NOT_CREATED: "Patient was not created",
    PATIENT_WAS_NOT_UPDATED: "Patient was not updated",
    PATIENT_WAS_NOT_DELETED: "Patient was not deleted",
    PATIENTS_IMPORT_TEST_NOT_SAVED: "Patient's imports tests are not saved.",
    PATIENTS_IMPORT_TEST_NOT_SELECTED: "Please select the valid Test Files",

    PASSWORD_WAS_NOT_UPDATED: "Password was not updated",

    USER_WAS_NOT_CREATED: "User was not created",
    USER_WAS_NOT_UPDATED: "User was not updated",
    USER_WAS_NOT_FETCHED: "User was not fetched",
    USER_CHART_ORDER_WAS_NOT_UPDATED: "User's chart order was not fetched",

    CRON_JOB_SCHEDULE_CHANGE_FAILED: "Cron Job Schedule change failed",
    BACKUP_RESTORE_FAILED: "Backup restore failed",

    WORKLIST_WAS_NOT_CREATED: "Worklist was not created",
    WORKLIST_WAS_NOT_UPDATED: "Worklist was not updated",
    WORKLIST_WAS_NOT_DELETED: "Worklist was not deleted",
    PATIENT_WAS_NOT_ADDED_INTO_WORKLIST: "Patient was not added into worklist",
    PATIENT_WAS_NOT_DELETED_FROM_WORKLIST: "Patient was not deleted from worklist",
    ALL_PATIENTS_WAS_NOT_DELETED_FROM_WORKLIST: "All Patients was not deleted from worklist",

    ASSESSMENT_WAS_NOT_CREATED: "Assessment was not created",
    ASSESSMENT_WAS_NOT_UPDATED: "Assessment was not updated",
    ASSESSMENT_WAS_NOT_ADDED_TO_STORE: "Assessment was not added to store",
    ASSESSMENTS_BY_PATIENT_ID_WAS_NOT_SUCCESSFULLY_FETCHED: "Assessment was not added to store",
    CLICKER_IS_NOT_WORKING: "Clicker is not working",
    DEVICE_MISMATCHED: "Device mismatched",
    PLEASE_CONNECT_THE_CORRECT_DEVICE: "Please connect the correct device!",

    START_ASSESSMENT_FAILED: "Failed to start the assessment",
    CANCEL_ASSESSMENT_FAILED: "Failed to cancel the assessment",
    RECALLIBRATE_ASSESSMENT_FAILED: "Failed to recallibrate the assessment",
    PAUSE_ASSESSMENT_FAILED: "Failed to pause the assessment",
    RESUME_ASSESSMENT_FAILED: "Failed to resume the assessment",
    ASSESSMENT_HMD_AUTO_PAUSE: "Assessment auto pause due to Patient",

    HMD_DEVICE_WAS_NOT_CREATED: "HMD Device was not created",
    HMD_DEVICE_RESET_FAILED: "HMD Device reset failed",
    
    START_PRE_BP_FAILED: "Failed to start pre BP data collection",
    STOP_PRE_BP_FAILED: "Failed to stop pre BP data collection",
    START_PRE_BASELINE_FAILED: "Failed to start pre Baseline data collection",
    STOP_PRE_BASELINE_FAILED: "Failed to stop pre Baseline data collection",
    START_PRE_QUESTIONNAIRE_FAILED: "Failed to start pre Questionnaire data collection",
    STOP_PRE_QUESTIONNAIRE_FAILED: "Failed to stop pre Questionnaire data collection",

    START_VFT_FAILED: "Failed to start Visual Field Test",
    STOP_VFT_FAILED: "Failed to stop Visual Field Test",

    START_POST_BP_FAILED: "Failed to start Post BP data collection",
    STOP_POST_BP_FAILED: "Failed to stop Post BP data collection",
    START_POST_BASELINE_FAILED: "Failed to start Post Baseline data collection",
    STOP_POST_BASELINE_FAILED: "Failed to stop Post Baseline data collection",
    START_POST_QUESTIONNAIRE_FAILED: "Failed to start Post Questionnaire data collection",
    STOP_POST_QUESTIONNAIRE_FAILED: "Failed to stop Post Questionnaire data collection",

    NOTE_WAS_NOT_CREATED: "Note was not created",
    NOTES_WAS_NOT_FETCHED: "Notes was not fetched",

    LOGGED_IN_ERROR: "Something went wrong while login",
    UNABLE_TO_LOAD_IMPORTED_TEST: "Unable to load the Imported Test",
    UNABLE_TO_LOAD_IMPORTED_TEST_PAGE: "Unable to load the Test Page",

    FAQ_WAS_NOT_CREATED: "FAQ was not created",

    FILE_WAS_NOT_UPLOADED: "File upload failed",

    VERIFY_YOUR_EMAIL_AND_TRY_LOGIN_AGAIN: "Verify your email and try login again"
}

export const WARNINGS = {
    ASSESSMENTS: {
        unreliable: "Reliability indices are too high",
        excessiveblinking: "Patient is blinking frequently and slowing the test. Recommend pausing or re-starting the test after administration of artificial tears."
    }
}