export const debounce = (method, waitingTime) => {
    let timeout

    return (...args) => {
        const context = this
        
        if (timeout) clearTimeout(timeout)

        timeout = setTimeout(() => {
            timeout = null
            method.apply(context, args)
        }, waitingTime)
    }
}