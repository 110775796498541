import React, { Component } from 'react';
import CanvasJSReact from '../../../assets/canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
 
export class LineChart extends Component {
	render() {
		const options = {
			animationEnabled: true,
			exportEnabled: true,
			theme: "light2",
			axisY: {
				title: "MD in DB",
				includeZero: false,
				minimum: -30,
				maximum: 10,
				interval: 5
			},
			axisX: {
				title: "Age",
				interval: 5,
				minimum: 60,
				maximum: 90
			},
			data: [{
				click: function(e) {
					alert("dataSeries Event => Type: "+ e.dataSeries.type+ ", dataPoint { x:" + e.dataPoint.x + ", y: "+ e.dataPoint.y + " }")
				},
				type: "line",
				toolTipContent: "{x}: {y}",
				dataPoints: [
					{ x: 61, y: 5 },
					{ x: 65, y: 4 },
					{ x: 67, y: 6 },
					{ x: 70, y: 4 },
					{ x: 75, y: 4 },
					{ x: 77, y: -1 },
					{ x: 77, y: 2 },
					{ x: 80, y: 4 },
					{ x: 83, y: -4 },
					{ x: 85, y: -2 }
				]
			}]
		}
		
		return (
		<div>
			{/* <h1>React Line Chart</h1> */}
			<CanvasJSChart options = { options } 
				/* onRef={ref => this.chart = ref} */
			/>
			{/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
		</div>
		)
	}
}

export default LineChart;                           