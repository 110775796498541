import { setActiveAssessmentProcessedData, updateActiveAssessment } from "../../actions/assessment.action"
import CONFIG from "../../config/index"
import * as Logger from "../../shared/utils/logger";

export const onAssessmentStartVFT = (data = {}, assessmentId) => {

  let { data: { raw: processedData, summary }, status, _id , ...rest } = data;

  if(assessmentId !== _id ) return;

  Logger.message("START_VFT TEST DATA STREAM RECEIVING DATA...", { processedData, summary, status, _id, ...rest })

  CONFIG.Store.dispatch(setActiveAssessmentProcessedData(processedData))
  CONFIG.Store.dispatch(updateActiveAssessment(_id, { status, data: { summary } }))
}