import React, { useEffect, useState, useRef } from 'react'
import { Formik } from 'formik'
import { LANGUAGES } from "../../../models/assessment.model"
import { toCamelCase } from "../../../shared/utils/wrappers"
import Slider from 'rc-slider'
import { AssessmentCalibrationSchema, EYE_ENUM } from "../../../models/assessment.model"
import { ASSESSMENT_STATUSES, HMD_STATUSES } from '../../../shared/constants/shared.constants'

export const CalibrateDevice = (props) => {
    let [ leftHmdUrl, setLeftHmdUrl ] = useState(null)
    let [ rightHmdUrl, setRightHmdUrl ] = useState(null)

    useEffect(() => {
        if (props.hmdDevices && props.hmdDevices.length) {
            let hmdDevice = props.hmdDevices.find(hmdDev => props.assessment && (hmdDev.id === props.assessment.hmdId))

            if (hmdDevice && props.assessment && props.assessment.configuration && props.assessment.configuration.eye) {
                setHmdUrls(hmdDevice, props.assessment.configuration.eye)
                return
            }
        }

        setLeftHmdUrl(null)
        setRightHmdUrl(null)
    }, [(props.assessment && props.assessment.hmdId), ((props.assessment && props.assessment.configuration && props.assessment.configuration.eye)), props.hmdDevices])

    const setHmdUrls = (hmdDevice, eyeConfig) => {
        if (eyeConfig && (eyeConfig === EYE_ENUM.LEFT || eyeConfig === EYE_ENUM.BINOCULOR || eyeConfig === EYE_ENUM.LEFT_THEN_RIGHT)) {
            setLeftHmdUrl(hmdDevice.leftVideoUrl)
        } else {
            setLeftHmdUrl(null)
        }

        if (eyeConfig && (eyeConfig === EYE_ENUM.RIGHT || eyeConfig === EYE_ENUM.BINOCULOR || eyeConfig === EYE_ENUM.RIGHT_THEN_LEFT)) {
            setRightHmdUrl(hmdDevice.rightVideoUrl)
        } else {
            setRightHmdUrl(null)
        }
    }
    const validateSchema = (values) => {
        props.assessmentCalibrateTestFormHandler(values)
    }

    /* Temporal till V100, will be removed at V200 */
    const formRef = useRef()

    useEffect(() => {
        if (formRef.current) {
          formRef.current.validateForm()
        }
      }, [])
    /* --- */

    return (
        <React.Fragment>
            <Formik
                innerRef={formRef}
                enableReinitialize = { true }
                initialValues = { props.assessmentCalibration }
                validationSchema = { AssessmentCalibrationSchema }
                validate = { validateSchema }
            >
                {
                    ({ values, handleChange, handleSubmit, setFieldValue, errors, touched }) => (
                    <React.Fragment>
                        <div className={`tab-pane fade ${ props.isConfigurationsActive ? "" : "show active" }`} id="pills-calibrate" role="tabpanel">
                            <form>
                                <div className="calibrate__flex congfigure__jsheight">
                                    <div className="calibrate__flex--left" style={{visibility: 'hidden'}}>
                                        <div className="calibrate__ul">
                                            <div className="calibrate__li">
                                                <select className = { `form-control ${ (errors.calibration && touched.calibration && errors.calibration.language && touched.calibration.language) ? "is-invalid" : null }` } id = "calibration.language" name = "calibration.language" onChange = { handleChange } value = { values.calibration.language }>
                                                    <option defaultValue value = "">Select</option>
                                                    {
                                                        LANGUAGES.map((language, i) => (
                                                            <option value = { language } key = { i } >{ toCamelCase(language) }</option>
                                                        ))
                                                    }
                                                </select>
                                                { errors.calibration && touched.calibration && errors.calibration.language && touched.calibration.language ? (
                                                    <div className="invalid-feedback">
                                                    { errors.calibration.language }
                                                    </div>
                                                ) : null }
                                            </div>
                                            <div className="calibrate__li">
                                                <h3 className="regular">Volume Check</h3>
                                                <Slider min={0} max={100} onChange={(event)=>{ props.assessmentCalibration.calibration.volume = event || 0 }} defaultValue={0} />
                                                <span className="JsShowValue"></span>
                                            </div>
                                            <div className="calibrate__li">
                                                <h3 className="regular">Callibration Check</h3>
                                                <Slider min={0} max={100} defaultValue={0} disabled={true} />
                                                <div className="checks__button">
                                                    <input type="button" name="start" />
                                                    <label>Start Check</label>
                                                </div>
                                            </div>
                                            <div className="calibrate__li">
                                                <h3 className="regular">Patient Gaze Eye Tracking</h3>
                                                <Slider min={0} max={100} defaultValue={0} disabled={true} />
                                                <div className="checks__button">
                                                    <input type="button" name="start" />
                                                    <label>Start Check</label>
                                                </div>
                                            </div>
                                            <div className="calibrate__li">
                                                <h3 className="regular">Position Check</h3>
                                                <div className="custom-check">
                                                    <input type="checkbox" name="reports" onChange={()=>{ props.assessmentCalibration.calibration.positionCheck = !props.assessmentCalibration.calibration.positionCheck }}/>
                                                    <label>Check Complete</label>
                                                </div>
                                            </div>
                                            <div className="calibrate__li">
                                                <a href="#!" className="view__instruction" onClick={()=>{}}>View Instructions <i className="fabicon-Script"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="calibrate__flex--right">
                                        <div className="row">
                                            <div className="col-md-6">
                                            {
                                                leftHmdUrl && leftHmdUrl.length && (
                                                    <div className="test__monitor--eye mg-b-20">
                                                        <video style={{ width: '100%'}} controls={false} autoPlay loop>
                                                            <source src = { leftHmdUrl } type="video/mp4" />
                                                            Your browser does not support HTML video.
                                                        </video>
                                                        <span className="monitor__test">OS</span>
                                                    </div>
                                                )
                                            }
                                            </div>
                                            <div className="col-md-6">
                                            {
                                                rightHmdUrl && rightHmdUrl.length && (
                                                    <div className="test__monitor--eye mg-b-20">
                                                        <video style={{ width: '100%'}} controls={false} autoPlay loop>
                                                        <source src = { rightHmdUrl } type="video/mp4" />
                                                            Your browser does not support HTML video.
                                                        </video>
                                                        <span className="monitor__test monitor__test--right">OD</span>
                                                    </div>
                                                )
                                            }
                                            </div>
                                        </div>
                                        <div className="test__monitoring--btns">
                                            <ul className="test__monitoring--lists">
                                                {/* <li>
                                                    <button type="button" className="btn btn__white" onClick = {() => props.setIsConfigurationsActive(true)}>Previous</button>
                                                </li> */}
                                                <li>
                                                    <button type="button" className={props.isTrialTestAllowed && props.assessment && props.initiateTest && props.assessment.status === ASSESSMENT_STATUSES.CALIBRATE && props.assessment.hmdDeviceStatus === HMD_STATUSES.BUSY ? 'btn btn__white' : 'btn btn__white disabled'} disabled={props.isTrialTestAllowed && props.assessment && props.initiateTest && props.assessment.status === ASSESSMENT_STATUSES.CALIBRATE && props.assessment.hmdDeviceStatus === HMD_STATUSES.BUSY? false: true} onClick={ props.trialTestHandler } >Trial Test</button>
                                                </li>
                                                <li>
                                                    <button type="button" className={props.isTrialTestAllowed && props.assessment && props.initiateTest && props.assessment.status === ASSESSMENT_STATUSES.TRIAL_COMPLETED ? 'btn btn__white' : 'btn btn__white disabled'} onClick={ props.startTestHandler } disabled={props.isTrialTestAllowed && props.assessment && props.initiateTest && props.assessment.status === ASSESSMENT_STATUSES.TRIAL_COMPLETED? false: true}>Start Test</button>
                                                </li>                    
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </React.Fragment>
                    )
                }
            </Formik>          
        </React.Fragment>
    )
}

export default CalibrateDevice
