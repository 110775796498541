import * as Yup from 'yup'

export const USER_ROLES = [
    'doctor',
    'admin',
    'technician'
]

export const LEFT_RIGHT_SETTINGS = [
    'os',
    'od'
]

export const HOMEPAGES = [
    "patients",
    "worklists"
]

export const DATE_FORMATS = [
    'mm/dd/yyyy',
    'dd/mm/yyyy',
    'yyyy/mm/dd'
]

const minPasswordLength = 6

export const DEFAULT_SESSION_TIMEOUT = 120


export const UserForm = {
    firstName: "",
    lastName: "",
    role: "",
    email: "",
    password: "",
    confirmPassword: "",
    settings: {
        dateFormat: ""
    }
}

const PasswordSchema = Yup.string()
    .required("Please enter password.")
    .min(minPasswordLength)

const userDefaultSchema = {
    firstName: Yup.string()
      .required('First Name is Required.'),
    lastName: Yup.string()
      .required('Last Name is Required.'),
    email: Yup.string()
      .email('Invalid email')
      .required('Email is Required.'),
    role: Yup.string()
        .required("Please select Role.")
        .oneOf(USER_ROLES),
    password: PasswordSchema,
    confirmPassword: Yup.string()
        .required("Please enter confirm password.")
        .oneOf([Yup.ref('password'), ""], 'Passwords must match')
}

const userDefaultSettingSchema = {
    dateFormat: Yup.string()
        .required("Please select Date Format.")
        .oneOf(DATE_FORMATS),
}

const userDefaultExtraSettingSchema = {
    coloredReports: Yup.boolean()
        .required("Please enter color report value."),
    sessionTimeout: Yup.number()
        .required("Please enter session timeout."),
    homePage: Yup.string()
        .required("Please select Homepage.")
        .oneOf(HOMEPAGES)
}


export const UserSchema = (isNew) => {
    let userSchema = userDefaultSchema
    let userSettingsSchema = userDefaultSettingSchema
    let userExtraSettingsSchema = userDefaultExtraSettingSchema
    
    if (!isNew) {
        delete userSchema.password
        delete userSchema.confirmPassword
        userSettingsSchema = { ...userSettingsSchema, ...userExtraSettingsSchema }
    }
    
    userSchema.settings = Yup.object(userSettingsSchema)
    return Yup.object().shape(userSchema)
}

export const UpdatePasswordSchema = (isAdminUpdating) => {
    let passObj = {
        newPassword: PasswordSchema,
    }

    if (!isAdminUpdating) {
        passObj.confirmPassword = Yup.string()
            .required("Please enter confirm password.")
            .oneOf([Yup.ref('newPassword'), ""], 'Passwords must match')
    }

    return Yup.object().shape(passObj)
}
