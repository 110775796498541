import * as Yup from 'yup'

export const NoteForm = {
    note: ''
  }

export const NoteSchema = () => {
    let noteObj = {
        note: Yup.string().required("Note is required")
    }

    return Yup.object().shape(noteObj)
}