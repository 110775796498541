import * as CronJobService from "../services/jobs/cronjobs.service"
import { LOADER, BACKUP } from "../shared/constants/actions.constants"
import { ERROR, SUCCESS } from "../shared/constants/messages.constants"
import * as FlashMessage from "../shared/utils/flashMessage"


/**
 * @desc To fetch all backup list and store it into Redux Store
 * @public
 */
export const getDB_BackupList = (showloader = true, pageNo = 1) => {
  return async (dispatch, state) => {
    try {
      if (showloader) {
        dispatch(setLoader(true))
      }    
      
      const limit = 10
      const sort = 0

      let { data: { docs, ...pagination }} = await CronJobService.getAllBackup("", pageNo, limit, sort)

      onSuccessDB_BackupList(dispatch, docs, pagination)
    } catch (error) {
      onErrorDB_BackupList(dispatch, error)
    }
  }
}

/**
 * @desc To fetch all schedule list and store it into Redux Store
 * @public
 */
export const getAllScheduleList = () => {
  return async (dispatch) => {
    try {
      dispatch(setLoader(true))

      let { data } = await CronJobService.getAllCronJobSchedule()

      onSuccessAllScheduleList(dispatch, data)
    } catch (error) {
      onErrorScheduleList(dispatch, error)
    }
  }
}

/**
 * @desc To add db backup  
 * @public
 */
export const addMongoDbBackup = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setLoader(true))

      const data = await CronJobService.addNewBackup(payload)
      onSuccessBackupAdd(dispatch, data.data)
    } catch (error) {
      onErrorBackupAdd(dispatch, error)
    }
  }
}

/**
 * @desc To change CRON schedule
 * @public
 */
export const addCronJobSchedule = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setLoader(true))

      const data = await CronJobService.changeCronJobSchedule(payload)
      onSuccessScheduleChange(dispatch, data.data)
    } catch (error) {
      onErrorScheduleChange(dispatch, error)
    }
  }
}

export const restoreBackup = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setLoader(true))

      const data = await CronJobService.restoreBackupFile(payload)
      onSuccessBackupRestore(dispatch, data.data)
    } catch (error) {
      onErrorRestoreBackup(dispatch, error)
    }
  }
}

/**
 * @desc To fetch all cron job schedule
 * @param {object} dispatch
 * @private
 */
const onSuccessAllScheduleList = (dispatch, scheduleList) => {
  dispatch(setScheduleListAction(scheduleList))
  dispatch(setLoader(false))
}

/**
 * @desc To fetch all db backup 
 * @param {object} dispatch
 * @private
 */
const onSuccessDB_BackupList = (dispatch, backupList, pagination) => {
  dispatch(setDB_BackupListAction(backupList))
  dispatch(setBackupPagination(pagination))
  dispatch(setLoader(false))
}

/**
 * @desc To handle error while fetching schedule list
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorScheduleList = (dispatch, error) => {
  dispatch(setLoader(false))
}

/**
 * @desc To handle error while fetching db backup list
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorDB_BackupList = (dispatch, error) => {
  dispatch(setLoader(false))
}

/**
 * @desc Action to set loader
 * @param {boolean} flag
 * @private
 */
const setLoader = (flag) => {
  return { 
    type: LOADER.SET_LOADER, 
    payload: flag
  }
}

/**
 * @desc Action to set CRON schedule List into store
 * @param {Array} list
 * @private
 */
const setScheduleListAction = (list = []) => {
  return {
    type: BACKUP.SET_SCHEDULE_LIST,
    payload: list
  }
}

/**
 * @desc Action to set db backup List into store
 * @param {Array} list
 * @private
 */
const setDB_BackupListAction = (list = []) => {
  return {
    type: BACKUP.SET_BACKUP_LIST,
    payload: list
  }
}

const setBackupPagination = (paginationObj) => {
  return { 
    type: BACKUP.SET_BACKUP_PAGINATION, 
    payload: paginationObj
  }
}

const addDbBackupToStore = (payload) => {
  return {
    type: BACKUP.ADD_BACKUP,
    payload: payload
  }
}

const onSuccessScheduleChange = (dispatch, payload) => {
  dispatch(setLoader(false))
  // dispatch(addDbBackupToStore(payload))
  FlashMessage.success(SUCCESS.CRON_JOB_SCHEDULE_CHANGED)
}

const onSuccessBackupRestore = (dispatch, payload) => {
  dispatch(setLoader(false))
  FlashMessage.success(SUCCESS.BACKUP_RESTORED_SUCCESSFULLY)
}

const onSuccessBackupAdd = (dispatch, payload) => {
  dispatch(setLoader(false))
  // dispatch(addDbBackupToStore(payload))
  FlashMessage.success(SUCCESS.BACKUP_SUCCESSFULLY_CREATED)
}

const onErrorBackupAdd = (dispatch, deviceId) => {
  dispatch(setLoader(false))
}

const onErrorScheduleChange = (dispatch, deviceId) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.CRON_JOB_SCHEDULE_CHANGE_FAILED)
}

const onErrorRestoreBackup = (dispatch, s3Key) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.BACKUP_RESTORE_FAILED)
}