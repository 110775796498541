import React, { useState } from 'react'
import CalibrateDevice from './calibrateDevice'
import ConfigureTest from './configureTest'
import AssessmentNotes from '../../shared/notes/assessmentNotes'
import { Scrollbars } from 'react-custom-scrollbars'

export const AssessmentConfigurationCollaboration = (props) => {
    const [toggle, setToggle] = useState(false)
    let [isConfigurationsActive, setIsConfigurationsActive] = useState(true)

    return (
        <React.Fragment>
                <div className="configure__main full__pages pt-0">

                    <div className={toggle ? "add__notes--tab opened" : "add__notes--tab"}>
                        <AssessmentNotes assessment={ props.assessment } patient={props.patient} />
                    </div>

                    <div className="test__monitoring mg-b-0 pb-0 border-bottom-0">
                        <div className="test__monitoring--inner">
                            <ul className="nav nav-pills nav__customtab" id="pills-tab" role="tablist">
                                <li className={`nav-item disabledTab ${isConfigurationsActive? '': 'd-none'}`}>
                                    <a className={isConfigurationsActive ? "nav-link active" : "nav-link"} href="#pills-configure" role="tab"  data-toggle="pill" onClick={() => setIsConfigurationsActive(true)}>Configure Test</a>
                                </li>
                                <li className={`nav-item disabledTab ${isConfigurationsActive? 'd-none': ''}`}>
                                    <a className={isConfigurationsActive ? "nav-link" : "nav-link active"} href="#pills-calibrate" data-toggle="pill" role="tab" onClick={() => setIsConfigurationsActive(false)}>Calibrate Device</a>
                                </li>
                            </ul>

                        </div>
                        {
                            props.assessment && (<button type="button" onClick={() => setToggle(!toggle)} className={toggle ? "btn btn__add--notes jsAddNotes active" : "btn btn__add--notes jsAddNotes"} >
                                <i className="fabicon-AddNotes"></i>
                                <span>Notes</span>
                            </button>)
                        }

                    </div>

                    <div className="test__content--scroll mcustomscrollbar">

                        <div className="tab-content" id="pills-tabContent">
                            
                            <ConfigureTest 
                                calculateTrialLensHandler = { props.calculateTrialLensHandler } 
                                assessmentConfiguration = { props.assessmentConfiguration } 
                                setPreviousConfiguration = { props.setPreviousConfiguration } 
                                previousAssessments = { props.previousAssessments } 
                                assessment={ props.assessment } 
                                patient={props.patient} 
                                hmdDevices={props.hmdDevices} 
                                assessmentConfigureTestFormHandler={ props.assessmentConfigureTestFormHandler } 
                                isConfigurationsActive = {isConfigurationsActive} 
                                setIsConfigurationsActive = {setIsConfigurationsActive} 
                                trialLensOSsphere = { props.trialLensOSsphere }
                                trialLensOScylinder = { props.trialLensOScylinder }
                                trialLensOSaxis = { props.trialLensOSaxis }
                                trialLensODsphere = { props.trialLensODsphere }
                                trialLensODcylinder = { props.trialLensODcylinder }
                                trialLensODaxis = { props.trialLensODaxis }
                                trialTestHandler = { props.trialTestHandler }
                                vertexDistance = { props.vertexDistance }
                            />
                            
                            <CalibrateDevice 
                                assessmentCalibration = { props.assessmentCalibration } 
                                patient={props.patient}  
                                assessmentCalibrateTestFormHandler={ props.assessmentCalibrateTestFormHandler } 
                                hmdDevices = { props.hmdDevices } 
                                assessment = { props.assessment }
                                isTrialTestAllowed = { props.isTrialTestAllowed }
                                trialTestHandler = { props.trialTestHandler }
                                initiateTest = { props.initiateTest }
                                startTestHandler = { props.startTestHandler }
                                isConfigurationsActive = { isConfigurationsActive }
                                setIsConfigurationsActive = {setIsConfigurationsActive}
                            />

                        </div>

                    </div>
                </div>
        </React.Fragment>
    )
}

export default AssessmentConfigurationCollaboration
