import React, { useRef, useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { updateUserSettings } from "../../actions/users.action"
import { DATE_FORMATS, DEFAULT_SESSION_TIMEOUT } from "../../models/users.model"
import { USER_SETTINGS_HOMEPAGE_ENUM, CUSTOM_SESSION_TIMEOUT, OS_OD_DIRECTION } from "../../shared/constants/shared.constants"
import { isValidInteger } from "../../shared/utils/validation"
import * as FlashMessage from "../../shared/utils/flashMessage"

export const UserSettingsPanel = (props) => {

    let customSessionTimeoutInput = useRef(null)
    let [isDefaultSessionTimeout, setDefaultSessionTImeoutFlag] = useState(false)

    useEffect(() => {
        if (props.user && props.user.settings && props.user.settings.sessionTimeout) {
            if ((props.user.settings.sessionTimeout === DEFAULT_SESSION_TIMEOUT)) {
                setDefaultSessionTImeoutFlag(true)
            } else {
                customSessionTimeoutInput.current.value = props.user.settings.sessionTimeout
            }
        }
    }, [props.user])

    /**
     * @desc To submit or change UI based on changes done in form elements.
     * @param {object} target
     */
    const onFieldChange = ({ target }) => {
        switch (target.name) {
            case "sessionTimeout":
                if (target.value === "defaultSessionTimeout") {
                    props.updateUserSettings({ ...props.user, settings: { ...props.user.settings, sessionTimeout: DEFAULT_SESSION_TIMEOUT } })
                    customSessionTimeoutInput.current.disabled = true
                    customSessionTimeoutInput.current.value = ""
                    setDefaultSessionTImeoutFlag(true)
                } else {
                    setDefaultSessionTImeoutFlag(false)
                    customSessionTimeoutInput.current.disabled = false
                }
                break
                
            default:
                props.updateUserSettings({ ...props.user, settings: { ...props.user.settings, [target.name]: target.value } })
                break
        }
    }

    /**
     * @desc To validate and submit custom session timeout value, when it's changed.
     * @param {value} string
     */
    const onCustomSessionTimeoutInput = ({ target: { value } }) => {
        let val = isValidInteger(value)
        if (val && val >= CUSTOM_SESSION_TIMEOUT.MIN && val <= CUSTOM_SESSION_TIMEOUT.MAX) {
            props.updateUserSettings({ ...props.user, settings: { ...props.user.settings, sessionTimeout: val } })
        } else {
            FlashMessage.error(`Please enter valid integer value between ${CUSTOM_SESSION_TIMEOUT.MIN} and ${CUSTOM_SESSION_TIMEOUT.MAX}.`)
            customSessionTimeoutInput.current.value = ""
        }
    }

    return (
        <div className="settingpage">
            <div className="settingpage-options row">
                { props.user && (
                    <React.Fragment>
                        <div className="settingpage-item">
                            <div className="col-5">
                                <label className="control-label">Color in reports</label>
                            </div>
                            <div className="col-7">
                                <div className="controls">
                                <div className="custom-radio">
                                    <input type="radio" name="coloredReports" id = "coloredReports" 
                                        value = { true }
                                        checked = { props.user.settings.coloredReports }
                                        onChange = { (e) => onFieldChange(e) }
                                    />
                                    <label>Yes</label>
                                </div>
                                <div className="custom-radio">
                                    <input type="radio" name="coloredReports" id = "coloredReports" 
                                        value = { false }
                                        checked = { !props.user.settings.coloredReports }
                                        onChange = { (e) => onFieldChange(e) }
                                    />
                                    <label>No</label>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="settingpage-item">
                            <div className="col-5">
                                <label className="control-label">Session timeout</label>
                            </div>
                            <div className="col-7">
                                <div className="controls">
                                <div className="custom-radio">
                                    <input type="radio" name="sessionTimeout" id = "sessionTimeout" value = "defaultSessionTimeout"
                                        onChange = { (e) => onFieldChange(e) }
                                        checked = { isDefaultSessionTimeout }
                                    />
                                    <label>Default ({ DEFAULT_SESSION_TIMEOUT } min)</label>
                                </div>
                                <div className="custom-radio">
                                    <input type="radio" name="sessionTimeout" id = "sessionTimeout" value = "customSessionTimeout"
                                        onChange = { (e) => onFieldChange(e) }
                                        checked = { !isDefaultSessionTimeout }
                                    />
                                    <label>&nbsp;</label>
                                </div>
                                <input type="text" className="form-control session-control js-session-control" placeholder="Custom"
                                    disabled = { isDefaultSessionTimeout }
                                    ref = { customSessionTimeoutInput }
                                    onBlur = { onCustomSessionTimeoutInput }
                                />
                                <label>&nbsp;&nbsp;mins</label>
                                </div>
                            </div>
                        </div>
                        <div className="settingpage-item">
                            <div className="col-5">
                                <label className="control-label">Date Format</label>
                            </div>
                            <div className="col-5">
                                <div className="controls">
                                <select className="form-control" id = "dateFormat" name = "dateFormat"
                                    onChange = { (e) => onFieldChange(e) } 
                                    value = { props.user.settings.dateFormat }
                                >
                                    {
                                        DATE_FORMATS.map((df, i) => (
                                            <option value = { df } key = { i }>{ df }</option>
                                        ))
                                    }
                                </select>
                                </div>
                            </div>
                        </div>
                        <div className="settingpage-item">
                            <div className="col-5">
                                <label className="control-label">Home Page</label>
                            </div>
                            <div className="col-7">
                                <div className="controls">
                                <div className="custom-radio">
                                    <input type="radio" name="homePage" id = "homePage"
                                        value = { USER_SETTINGS_HOMEPAGE_ENUM.PATIENTS }
                                        checked = { props.user.settings.homePage === USER_SETTINGS_HOMEPAGE_ENUM.PATIENTS }
                                        onChange = { (e) => onFieldChange(e) }
                                    />
                                    <label>Search patient</label>
                                </div>
                                <div className="custom-radio">
                                    <input type="radio" name="homePage" id = "homePage"
                                        value = { USER_SETTINGS_HOMEPAGE_ENUM.WORKLISTS }
                                        checked = { props.user.settings.homePage === USER_SETTINGS_HOMEPAGE_ENUM.WORKLISTS }
                                        onChange = { (e) => onFieldChange(e) }
                                    />
                                    <label>WorkList</label>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="settingpage-item">
                            <div className="col-5">
                                <label className="control-label">OS OD direction setting</label>
                            </div>
                            <div className="col-7">
                                <div className="controls">
                                <div className="custom-radio">
                                    <input type="radio" name="osOdDirection" id = "osOdDirection"
                                        value = { OS_OD_DIRECTION.OS_ON_LEFT_OD_ON_RIGHT }
                                        checked = { props.user.settings.osOdDirection === OS_OD_DIRECTION.OS_ON_LEFT_OD_ON_RIGHT }
                                        onChange = { (e) => onFieldChange(e) }
                                    />
                                    <label>OS (Left) | OD (Right)</label>
                                </div>
                                <div className="custom-radio">
                                    <input type="radio" name="osOdDirection" id = "osOdDirection"
                                        value = { OS_OD_DIRECTION.OD_ON_LEFT_OS_ON_RIGHT }
                                        checked = { props.user.settings.osOdDirection === OS_OD_DIRECTION.OD_ON_LEFT_OS_ON_RIGHT }
                                        onChange = { (e) => onFieldChange(e) }
                                    />
                                    <label>OD (Left) | OS (Right)</label>
                                </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = ({ auth }) => ({
    user: auth.loggedInUser
})

const mapDispatchToProps = dispatch => (
    bindActionCreators({
        updateUserSettings
    }, dispatch)
)
  
export default connect(mapStateToProps, mapDispatchToProps)(UserSettingsPanel)

