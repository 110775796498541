export const AUTHTYPE = { 
  GET_USER_LOGIN_LOADING_START: "GET_USER_LOGIN_LOADING_START",
  GET_USER_LOGIN_LOADING_STOP: "GET_USER_LOGIN_LOADING_STOP",
  SET_CURRENT_USER: "SET_CURRENT_USER",
  SET_IS_ADMIN: "SET_IS_ADMIN",
  UPDATE_CURRENT_USER_SETTINGS: "UPDATE_CURRENT_USER_SETTINGS"
}

export const USERS = {
  GET_ALL_USERS: "GET_ALL_USERS",
  SET_USERS_OBJ: "SET_USERS_OBJ",
  ADD_USER_INTO_OBJ: "ADD_USER_INTO_OBJ",
  ADD_ACTIVE_USER: "ADD_ACTIVE_USER",
  DELETE_USER: "DELETE_USER",
  SET_EDITABLE_USER: "SET_EDITABLE_USER",
  SET_USERS_SEARCH_TEXT: "SET_USERS_SEARCH_TEXT",
  SET_USERS_SORT: "SET_USERS_SORT",
}

export const PATIENTS = {
  SET_PATIENTS_OBJ: "SET_PATIENTS_OBJ",
  SET_ADD_PATIENT_MODAL: "SET_ADD_PATIENT_MODAL",
  ADD_PATIENT_INTO_OBJ: "ADD_PATIENT_INTO_OBJ",
  SET_PATIENTS_IMPORT_TEST_INTO_OBJ: "SET_PATIENTS_IMPORT_TEST_INTO_OBJ",
  ADD_ACTIVE_PATIENT: "ADD_ACTIVE_PATIENT",
  DELETE_PATIENT: "DELETE_PATIENT",
  SET_PATIENT_SEARCH_TEXT: "SET_PATIENT_SEARCH_TEXT",
  SET_ASSESSMENT_DATE_RANGE: "SET_ASSESSMENT_DATE_RANGE",
  SET_PATIENT_LIST_SORT: "SET_PATIENT_LIST_SORT",
  SET_PATIENT_SIMPLE_LIST: "SET_PATIENT_SIMPLE_LIST",
  SET_PATIENT_PAGINATION: "SET_PATIENT_PAGINATION"
}

export const ASSESSMENT = {
  ADD_ASSESSMENT_INTO_OBJ: "ADD_ASSESSMENT_INTO_OBJ",
  ADD_ACTIVE_ASSESSMENT: "ADD_ACTIVE_ASSESSMENT",
  ADD_SELECTED_ASSESSMENT_FOR_FORM: "ADD_SELECTED_ASSESSMENT_FOR_FORM",
  ADD_ACTIVE_ASSESSMENT_PROCESSED_DATA: "ADD_ACTIVE_ASSESSMENT_PROCESSED_DATA",
  ADD_ACTIVE_ASSESSMENTS_BY_PATIENT_ID: "ADD_ACTIVE_ASSESSMENTS_BY_PATIENT_ID",
  UPDATE_ACTIVE_ASSESSMENTS_STATUS: "UPDATE_ACTIVE_ASSESSMENTS_STATUS"
}

export const NOTES = {
  SET_NOTES: "SET_NOTES"
}

export const FAQ = {
  SET_FAQ_LIST: "SET_FAQ_LIST",
  DELETE_FAQ: "DELETE_FAQ",
}

export const COUNTRY_STATE_CITY = {
  SET_COUNTRIES: "SET_COUNTRIES",
  SET_STATES_BY_COUNTRY_ID: "SET_STATES_BY_COUNTRY_ID"  
}

export const NOTIFICATION = {
  FLASH_MESSAGE: "FLASH_MESSAGE",
  CLEAR_CURRENT_FLASH_MESSAGE: "CLEAR_CURRENT_FLASH_MESSAGE"
}

export const LOADER = {
  SET_LOADER: "SET_LOADER"
}

export const MODALS = {
  SET_MODAL: "SET_MODAL",
  MODAL_TYPE: "MODAL_TYPE",
  ADD_PATIENT_MODAL: "ADD_PATIENT_MODAL",
  AUTH0_USER_CREATION_MODEL: "AUTH0_USER_CREATION_MODEL",
  ADD_USER_MODAL: "ADD_USER_MODAL",
  EDIT_PATIENT_MODAL: "EDIT_PATIENT_MODAL",
  BLIND_SPOT_MODAL: "BLIND_SPOT_MODAL",
  PATIENTS_IMPORTED_TEST_VIEW_MODAL: "PATIENTS_IMPORTED_TEST_VIEW_MODAL",
  PATIENT_EXTRACTED_IMAGE_FROM_PDF_MODAL: "PATIENT_EXTRACTED_IMAGE_FROM_PDF_MODAL",
  PATIENT_EXTRACTED_TEXT_DATA_FROM_PDF_MODAL: "PATIENT_EXTRACTED_TEXT_DATA_FROM_PDF_MODAL",
  ADD_WORKLIST_MODAL: "ADD_WORKLIST_MODAL",
  EDIT_WORKLIST_MODAL: "EDIT_WORKLIST_MODAL",
  CONFIRMATION_MODAL: "CONFIRMATION_MODAL",
  CONFIRMATION_MODAL_TO_RESTORE_BACKUP_FILE: "CONFIRMATION_MODAL_TO_RESTORE_BACKUP_FILE",
  CONFIRMATION_MODAL_TO_REMOVE_ALL_PATIENTS_FROM_WORKLIST: "CONFIRMATION_MODAL_TO_REMOVE_ALL_PATIENTS_FROM_WORKLIST",
  CONFIRMATION_MODAL_TO_DELETE_WORKLIST: "CONFIRMATION_MODAL_TO_DELETE_WORKLIST",
  CONFIRMATION_MODAL_TO_DELETE_PATIENT: "CONFIRMATION_MODAL_TO_DELETE_PATIENT",
  CONFIRMATION_MODAL_TO_DELETE_HMD_DEVICE: "CONFIRMATION_MODAL_TO_DELETE_HMD_DEVICE",
  CONFIRMATION_MODAL_TO_DELETE_FAQ: "CONFIRMATION_MODAL_TO_DELETE_FAQ",
  CONFIRMATION_MODAL_TO_RESET_HMD_DEVICE: "CONFIRMATION_MODAL_TO_RESET_HMD_DEVICE",
  BLIND_SPOT_YES: "BLIND_SPOT_YES",
  BLIND_SPOT_NO: "BLIND_SPOT_NO",
  DND_FOLLOW_CHARTS: "DND_FOLLOW_CHARTS",
  DND_SINGLE_VIEW_CHARTS: "DND_SINGLE_VIEW_CHARTS",
  DND_OVERVIEW_VIEW_CHARTS: "DND_OVERVIEW_VIEW_CHARTS",
  CHART_VIEW_MODAL: "CHART_VIEW_MODAL",
  WARNING_MESSAGE_MODAL: "WARNING_MESSAGE_MODAL",
  IMPORT_TESTS_MODAL: "IMPORT_TESTS_MODAL",
  ADD_HMD_MODAL: "ADD_HMD_MODAL",
  DB_BACKUP_MODAL: "DB_BACKUP_MODAL",
  ADD_HELP_QUERY_CONTENT_MODAL: "ADD_HELP_QUERY_CONTENT_MODAL",
  EDIT_HELP_QUERY_CONTENT_MODAL: "EDIT_HELP_QUERY_CONTENT_MODAL",
}

export const UI = {
  SET_DEVICE_SIDEBAR: "SET_DEVICE_SIDEBAR",
  SET_DEVICE_SIDEBAR_DISABLE: "SET_DEVICE_SIDEBAR_DISABLE"
}

export const HMDDEVICES = {
  SELECT_HMD_DEVICE: "SELECT_HMD_DEVICE",
  SET_HMD_DEVICE_BY_ID: "SET_HMD_DEVICE_BY_ID",
  ADD_HMD: "ADD_HMD",
  UPDATE_HMD: "UPDATE_HMD",
  SET_HMD_LIST: "SET_HMD_LIST",
  DELETE_HMD: "DELETE_HMD"
}

export const BACKUP = {
  SET_BACKUP_LIST: "SET_BACKUP_LIST",
  SET_BACKUP_PAGINATION: "SET_BACKUP_PAGINATION",
  SET_SCHEDULE_LIST: "SET_SCHEDULE_LIST",
  ADD_BACKUP: "ADD_BACKUP",
}

export const WORKLISTS = {
  SET_WORKLISTS_OBJ: "SET_WORKLISTS_OBJ",
  SET_ADD_WORKLIST_MODAL: "SET_ADD_WORKLIST_MODAL",
  ADD_WORKLIST_INTO_OBJ: "ADD_WORKLIST_INTO_OBJ",
  ADD_ACTIVE_WORKLIST: "ADD_ACTIVE_WORKLIST",
  DELETE_WORKLIST: "DELETE_WORKLIST",
  SET_WORKLIST_SEARCH_TEXT: "SET_WORKLIST_SEARCH_TEXT",
  SET_WORKLIST_SORT: "SET_WORKLIST_SORT"
}
