import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Scrollbars } from 'react-custom-scrollbars'
import LineChart from "../../../shared/graphs/lineChart";
import FollowChartBlock from "./followChartBlock";
import { MODALS } from "../../../../shared/constants/actions.constants"
import { CHART_NAMES } from "../../../../shared/constants/chart.constants";
import { setModalWithType } from '../../../../actions/modal.action'

export const Progression = (props) => {
    let [ followupCharts, setFollowupCharts ] = useState(null)
    let [ totalCharts ] = useState(Object.keys(CHART_NAMES).map((name, i) => { 
        return {
            id: `t${ i + 1 }`,
            text: (name || "").toLowerCase().split("_").join(" "),
            content: (name || "").toLowerCase().split("_").join(" "),
            key: name
        }
     }))

    useEffect(() => {
        let { followupCharts: userFollowupCharts } = props.loggedInUser.settings || {}

        if (userFollowupCharts && userFollowupCharts.length) {
            let arr = userFollowupCharts.map((chart, i) => { 
                return {
                    id: `f${ i + 1 }`,
                    text: chart,
                    key: (chart || "").split(" ").join("_").toUpperCase(),
                    content: chart
                }
            })
            setFollowupCharts(arr)
        }
    }, [(props.loggedInUser && props.loggedInUser.settings)])

    const editClickHandler = () => {
        let modalState = { 
            followupCharts: followupCharts,
            totalCharts,
            header: "Follow Up Charts",
            size: "lg",
            maxDestinationCount: 4,
            minDestinationCount: 4
          }
    
        props.setModalWithType(true, MODALS.DND_FOLLOW_CHARTS, modalState)
    }

    return (
        <React.Fragment>
        {
            props.patient && (
            <React.Fragment>
                <div className="tab-pane fade show active" id="progression" role="tabpanel">
                    <Scrollbars style={{ position: '' }}>
                    <div className="filter__details">
                        <div className="filter__left position-relative pd-y-0 pd-l-30 pd-r-10">
                            <span className="os-title">OS</span>
                            <div className="progresion-list">
                            <ul>
                                <li><a href="javscript:void(0)"><i className="fabicon-ProgressLoopOuter"></i> Baseline</a></li>
                                <li><a href="javscript:void(0)"><i className="fabicon-Play"></i> Iterate</a></li>
                                <li><a href="javscript:void(0)"><i className="fabicon-CirclePlus"></i> Clinical Info</a></li>
                            </ul>
                            </div>
                        </div>
                        <div className="filter__right position-relative pd-y-0 pd-r-30 pd-l-10">
                            <span className="os-title">OD</span>
                            <div className="progresion-list">
                            <ul>
                                <li><a href="javscript:void(0)"><i className="fabicon-ProgressLoopOuter"></i> Baseline</a></li>
                                <li><a href="javscript:void(0)"><i className="fabicon-Play"></i> Iterate</a></li>
                                <li><a href="javscript:void(0)"><i className="fabicon-CirclePlus"></i> Clinical Info</a></li>
                            </ul>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex">
                    <div className="col-6 eadie-borderright">
                        <div className="progression__means">
                        <div className="progression__rows mg-b-24">
                            <h3 className="semiBold mg-b-10">Mean Deviation</h3>
                            <LineChart />
                            <p className="pd-l75 fontSize-12">MD Slope: 0.0+0.21dB / year (95% confidence)</p>
                        </div>

                        <div className="progression__rows mg-b-24 progression__rows--space">
                            <div className="row">
                            <div className="col-6">
                                <h3 className="semiBold mg-b-10">Follow up: Apr 04, 2019</h3>
                            </div>                                
                            </div>                              
                            <div className="progression__follow">
                            <ul className="progression__follow--list">
                                <li>
                                <i className="fabicon-FlickDown red"></i>
                                P &lt; 5% Deterioration
                                </li>
                                <li>
                                <i className="fabicon-FlickDown green"></i>
                                P &lt; 5% (2 consectutive)
                                </li>
                                <li>
                                <i className="fabicon-FlickDown yellow"></i>
                                P &lt; 5% (3+ consectutive)
                                </li>
                            </ul>
                            <span className="progression__sitatext">SITA Standard 10-2</span>
                            </div>
                            <div className="progression__outers">
                            <div className="progression__outers--action">
                                <a className="actions--text" href = "#!" onClick = { editClickHandler }>
                                <i className="fabicon-Edit"></i>
                                Edit
                                </a>
                                <span className="actions--text">
                                <i className="fabicon-BarChart4"></i>
                                Label
                                </span>
                            </div>
                            <div className="d-flex flex-wrap no-gutters filter__content__box pd-x-3 pd-y-3">
                                {
                                    followupCharts && followupCharts.length && followupCharts.map((chart) => (
                                        <FollowChartBlock 
                                            key = { chart.id } 
                                            id = { `${ chart.id }` }
                                            chartObj = { chart }
                                            OSOD = { 'OD' }
                                        />
                                    ))
                                }
                            </div>      
                            </div>
                                
                            <div className="fliter__imgbox fliter__followup minh-auto">                                
                            <div className="d-flex flex-wrap">
                                <div className="filter-equal">
                                <p>GHT: <span className="green">Within Normal Limits</span></p>
                                </div>
                                <div className="filter-equal">
                                <p>&nbsp;</p>
                                </div>
                                <div className="filter-equal">
                                <p>&nbsp;</p>
                                </div>
                                <div className="filter-equal">
                                <p>&nbsp;</p>
                                </div>                                  
                            </div>
                            </div>
                            <div className="fliter__imgbox fliter__followup minh-auto">                                
                            <div className="d-flex flex-wrap">
                                <div className="filter-equal">
                                <p>FL <span className="blue">5/15 XX</span></p>
                                </div>
                                {/* <div className="filter-equal">
                                <p>VFI <span className="blue">98%</span></p>
                                </div> */}
                                <div className="filter-equal">
                                <p><span className="red">***Low Test Reliability***</span></p>
                                </div>
                                <div className="filter-equal">
                                <p>&nbsp;</p>
                                </div>
                            </div>
                            </div>
                            <div className="fliter__imgbox fliter__followup minh-auto">                                
                            <div className="d-flex flex-wrap">
                                <div className="filter-equal">
                                <p>FP <span className="blue">9%</span></p>
                                </div>
                                <div className="filter-equal">
                                <p>MD <span className="green">+0.66 dB</span></p>
                                </div>
                                <div className="filter-equal">
                                <p>Pupil Diameter <span className="blue">5.9 mm *</span></p>
                                </div>
                                <div className="filter-equal">
                                <p>&nbsp;</p>
                                </div>                                  
                            </div>
                            </div>
                            <div className="fliter__imgbox fliter__followup minh-auto">                                
                            <div className="d-flex flex-wrap">
                                <div className="filter-equal">
                                <p>FN <span className="blue">3%</span></p>
                                </div>                                  
                                <div className="filter-equal">
                                <p>PSD <span className="green">1.97dB P &lt; 10% </span></p>
                                </div>
                                <div className="filter-equal">
                                <p>Visual Acuity: <span className="blue">0.8</span></p>
                                </div>
                                <div className="filter-equal">
                                <p>Fovea: <span className="green">33 dB</span></p>
                                </div>                                  
                            </div>
                            </div>
                        </div>

                        </div>
                    </div>
                    <div className="col-6">
                        <div className="progression__means">
                        <div className="progression__rows mg-b-24">
                            <h3 className="semiBold mg-b-10">Mean Deviation</h3>
                            <img src="/assets/images/mean.jpg" alt="" className="w-100 mg-b-5" />
                            <p className="pd-l75 fontSize-12">MD Slope: 0.0+0.21dB / year (95% confidence)</p>
                        </div>

                        <div className="progression__rows mg-b-24 progression__rows--space">
                            <div className="row">
                            <div className="col-6">
                                <h3 className="semiBold mg-b-10">Follow up: Apr 04, 2019</h3>
                            </div>                                
                            </div>                              
                            <div className="progression__follow">
                            <ul className="progression__follow--list">
                                <li>
                                <i className="fabicon-FlickDown red"></i>
                                P &lt; 5% Deterioration
                                </li>
                                <li>
                                <i className="fabicon-FlickDown green"></i>
                                P &lt; 5% (2 consectutive)
                                </li>
                                <li>
                                <i className="fabicon-FlickDown yellow"></i>
                                P &lt; 5% (3+ consectutive)
                                </li>
                            </ul>
                            <span className="progression__sitatext">SITA Standard 10-2</span>
                            </div>
                            <div className="progression__outers">
                            <div className="progression__outers--action">
                                <a href="#!" className="actions--text">
                                <i className="fabicon-Edit"></i>
                                Edit
                                </a>
                                <span className="actions--text">
                                <i className="fabicon-BarChart4"></i>
                                Label
                                </span>
                            </div>
                            <div className="d-flex flex-wrap no-gutters filter__content__box pd-x-3 pd-y-3">
                                {
                                    followupCharts && followupCharts.length && followupCharts.map((chart) => (
                                        <FollowChartBlock 
                                            key = { chart.id } 
                                            id = { `${ chart.id }` }
                                            chartObj = { chart }
                                            OSOD = { 'OD' }
                                        />
                                    ))
                                }
                            </div>       
                            </div>

                            <div className="fliter__imgbox fliter__followup minh-auto">                                
                            <div className="d-flex flex-wrap">
                                <div className="filter-equal">
                                <p>GHT: <span className="green">Within Normal Limits</span></p>
                                </div>
                                <div className="filter-equal">
                                <p>&nbsp;</p>
                                </div>
                                <div className="filter-equal">
                                <p>&nbsp;</p>
                                </div>
                                <div className="filter-equal">
                                <p>&nbsp;</p>
                                </div>                                  
                            </div>
                            </div>
                            <div className="fliter__imgbox fliter__followup minh-auto">                                
                            <div className="d-flex flex-wrap">
                                <div className="filter-equal">
                                <p>FL <span className="blue">5/15 XX</span></p>
                                </div>
                                {/* <div className="filter-equal">
                                <p>VFI <span className="blue">98%</span></p>
                                </div> */}
                                <div className="filter-equal">
                                <p><span className="red">***Low Test Reliability***</span></p>
                                </div>
                                <div className="filter-equal">
                                <p>&nbsp;</p>
                                </div>                                  
                            </div>
                            </div>
                            <div className="fliter__imgbox fliter__followup minh-auto">                                
                            <div className="d-flex flex-wrap">
                                <div className="filter-equal">
                                <p>FP <span className="blue">9%</span></p>
                                </div>
                                <div className="filter-equal">
                                <p>MD <span className="green">+0.66 dB</span></p>
                                </div>
                                <div className="filter-equal">
                                <p>Pupil Diameter <span className="blue">5.9 mm *</span></p>
                                </div>
                                <div className="filter-equal">
                                <p>&nbsp;</p>
                                </div>                                  
                            </div>
                            </div>
                            <div className="fliter__imgbox fliter__followup minh-auto">                                
                            <div className="d-flex flex-wrap">
                                <div className="filter-equal">
                                <p>FN <span className="blue">3%</span></p>
                                </div>                                  
                                <div className="filter-equal">
                                <p>PSD <span className="green">1.97dB P &lt; 10% </span></p>
                                </div>
                                <div className="filter-equal">
                                <p>Visual Acuity: <span className="blue">0.8</span></p>
                                </div>
                                <div className="filter-equal">
                                <p>Fovea: <span className="green">33 dB</span></p>
                                </div>                                  
                            </div>
                            </div>
                        </div>

                        <div className="progression__rows mg-b-5 progression__rows--space">                              
                            <div className="row">
                            <div className="col-6">
                                <h3 className="semiBold mg-b-10">Baseline: Jan 06, 2011</h3>
                            </div>                                
                            <div className="col-6">
                                <h3 className="semiBold mg-b-10">Baseline: Jul 05, 2011</h3>
                            </div>                                
                            </div>                              
                            <div className="progression__follow">
                            <ul className="progression__follow--list">
                                <li>
                                <i className="fabicon-FlickDown red"></i>
                                P &lt; 5% Deterioration
                                </li>
                                <li>
                                <i className="fabicon-FlickDown green"></i>
                                P &lt; 5% (2 consectutive)
                                </li>
                                <li>
                                <i className="fabicon-FlickDown yellow"></i>
                                P &lt; 5% (3+ consectutive)
                                </li>
                            </ul>
                            <span className="progression__sitatext">SITA Standard 10-2</span>
                            </div>
                            <div className="progression__outers">
                            <div className="progression__outers--action">
                                <a href="#!" className="actions--text">
                                <i className="fabicon-Edit"></i>
                                Edit
                                </a>
                                <span className="actions--text">
                                <i className="fabicon-BarChart4"></i>
                                Label
                                </span>
                            </div>
                            <div className="d-flex flex-wrap no-gutters filter__content__box pd-x-3 pd-y-3">
                                {
                                    followupCharts && followupCharts.length && followupCharts.map((chart) => (
                                        <FollowChartBlock 
                                            key = { chart.id } 
                                            id = { `${ chart.id }` }
                                            chartObj = { chart }
                                            OSOD = { 'OD' }
                                        />
                                    ))
                                }
                            </div>        
                            </div>

                            <div className="fliter__imgbox fliter__followup minh-auto">                                
                            <div className="d-flex flex-wrap">
                                <div className="filter-equal">
                                <p>GHT: <span className="green">Within Normal Limits</span></p>
                                </div>
                                <div className="filter-equal">
                                <p>&nbsp;</p>
                                </div>
                                <div className="filter-equal">
                                <p>&nbsp;</p>
                                </div>
                                <div className="filter-equal">
                                <p>&nbsp;</p>
                                </div>                                  
                            </div>
                            </div>
                            <div className="fliter__imgbox fliter__followup minh-auto">                                
                            <div className="d-flex flex-wrap">
                                <div className="filter-equal">
                                <p>FL <span className="blue">5/15 XX</span></p>
                                </div>
                                {/* <div className="filter-equal">
                                <p>VFI <span className="blue">98%</span></p>
                                </div> */}
                                <div className="filter-equal">
                                <p><span className="red">***Low Test Reliability***</span></p>
                                </div>
                                <div className="filter-equal">
                                <p>&nbsp;</p>
                                </div>                                  
                            </div>
                            </div>
                            <div className="fliter__imgbox fliter__followup minh-auto">                                
                            <div className="d-flex flex-wrap">
                                <div className="filter-equal">
                                <p>FP <span className="blue">9%</span></p>
                                </div>
                                <div className="filter-equal">
                                <p>MD <span className="green">+0.66 dB</span></p>
                                </div>
                                <div className="filter-equal">
                                <p>Pupil Diameter <span className="blue">5.9 mm *</span></p>
                                </div>
                                <div className="filter-equal">
                                <p>&nbsp;</p>
                                </div>                                  
                            </div>
                            </div>
                            <div className="fliter__imgbox fliter__followup minh-auto">                                
                            <div className="d-flex flex-wrap">
                                <div className="filter-equal">
                                <p>FN <span className="blue">3%</span></p>
                                </div>                                  
                                <div className="filter-equal">
                                <p>PSD <span className="green">1.97dB P &lt; 10% </span></p>
                                </div>
                                <div className="filter-equal">
                                <p>Visual Acuity: <span className="blue">0.8</span></p>
                                </div>
                                <div className="filter-equal">
                                <p>Fovea: <span className="green">33 dB</span></p>
                                </div>                                  
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    </Scrollbars>
                </div>
            </React.Fragment>
            )
        }
        </React.Fragment>
    )
}

const mapDispatchToProps = dispatch => (
    bindActionCreators({
      setModalWithType,
    }, dispatch)
  )
  
export default connect(null, mapDispatchToProps)(Progression)
