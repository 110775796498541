import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { setModalWithType } from "../../../actions/modal.action"
import { deletePatient } from "../../../actions/patients.action"
import { deleteHmdDevice, resetSingleDevicesStatus } from "../../../actions/hmdDevices.action";
import { addFaq, deleteFAQ } from '../../../actions/faq.action';
import { restoreBackup } from '../../../actions/cronjobs.action';
import { deleteWorklist, deleteAllPatientsFromWorklist } from "../../../actions/worklist.action"
import { MODALS } from "../../../shared/constants/actions.constants"
import * as Logger from "../../../shared/utils/logger";

export const ConfirmationModal = (props) => {

  const confirmClickHandler = () => {
    switch (props.modalType) {
      case MODALS.CONFIRMATION_MODAL:
        if (props.modalData.worklistId && props.modalData.actionType && props.modalData.actionType === MODALS.CONFIRMATION_MODAL_TO_REMOVE_ALL_PATIENTS_FROM_WORKLIST) {
          props.deleteAllPatientsFromWorklist(props.modalData.worklistId)
        } else if (props.modalData.patientId && props.modalData.actionType && props.modalData.actionType === MODALS.CONFIRMATION_MODAL_TO_DELETE_PATIENT) {
          props.deletePatient(props.modalData.patientId)
        } else if (props.modalData.worklistId && props.modalData.actionType && props.modalData.actionType === MODALS.CONFIRMATION_MODAL_TO_DELETE_WORKLIST) {
          props.deleteWorklist(props.modalData.worklistId)
        } else if (props.modalData.hmdDeviceId && props.modalData.actionType && props.modalData.actionType === MODALS.CONFIRMATION_MODAL_TO_DELETE_HMD_DEVICE) {
          props.deleteHmdDevice(props.modalData.hmdDeviceId)
        } else if (props.modalData.hmdDeviceId && props.modalData.actionType && props.modalData.actionType === MODALS.CONFIRMATION_MODAL_TO_RESET_HMD_DEVICE) {
          props.resetSingleDevicesStatus(props.modalData.hmdDeviceId)
        } else if (props.modalData.faqId && props.modalData.actionType && props.modalData.actionType === MODALS.CONFIRMATION_MODAL_TO_DELETE_FAQ) {
          props.deleteFAQ(props.modalData.faqId)
        } else if (props.modalData.s3Key && props.modalData.actionType && props.modalData.actionType === MODALS.CONFIRMATION_MODAL_TO_RESTORE_BACKUP_FILE) {
          props.restoreBackup(props.modalData.s3Key)
        }
        break

      default:
        Logger.message("CONFIRMATION MODAL DEFAULT CASE.")
    }

    props.setModalWithType(false)
  }

  const cancelClickHandler = () => {
    if (props.modalData.s3Key && props.modalData.actionType && props.modalData.actionType === MODALS.CONFIRMATION_MODAL_TO_RESTORE_BACKUP_FILE) {
      console.log("Cancel Clicked , go back to page no: ", );
      let modalState = {
        backdrop: 'static',
        size: 'lg',
        backupModalPageNo: props.modalData?.backupModalPageNo || 1
      };
      props.setModalWithType(true, MODALS.DB_BACKUP_MODAL, modalState)
    } else {
      props.setModalWithType(false)
    }
  }

  return (
    <React.Fragment>
      <div className="modal-header">
        <h2 className="modal-title" id="patient-modalLabel">{ props.modalData.header }</h2>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick = { () => props.setModalWithType(false) }>
            <span aria-hidden="true"><i className="fabicon-close"></i></span>
        </button>
      </div>
      <div className="modal-body pd-b-30">
        <div className="row">
            <div className="form-group col-md-12">
              <label>{ props.modalData.body }</label>
            </div>
        </div>
        <button type="submit" className="btn btn-red" onClick = { cancelClickHandler }>Cancel</button>&nbsp;&nbsp;
        <button type="submit" className="btn btn-red" onClick = { confirmClickHandler }>Confirm</button>
      </div>
    </React.Fragment>
  )
}

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setModalWithType,
    deletePatient,
    deleteWorklist,
    deleteAllPatientsFromWorklist,
    deleteHmdDevice,
    deleteFAQ,
    restoreBackup,
    resetSingleDevicesStatus
  }, dispatch)
)

export default connect(null, mapDispatchToProps)(ConfirmationModal)
 