import * as Yup from 'yup'

export const WorklistForm = {
    name: "",
    assignedTo: "",
    patients: []
}

export const WorklistSchema = Yup.object().shape({
    name: Yup.string()
      .required('Name is required'),
    assignedTo: Yup.string()
})
