import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { CHART_ORIENTATION, CHART_NAMES, SINGLE_VIEW_CHARTS } from  '../../../../shared/constants/chart.constants'
import Eye from '../../../shared/graphs/eye'
import { toCamelCase } from "../../../../shared/utils/wrappers";
import * as SV from '../../../../shared/utils/graph/sensitivityValues';
import { getChartScaleSize } from './utils';

export const chartNameToCamelCase= (string)=> {
    // Split the string into words.
    // const words = string.split(" ");
  
    // // Capitalize the first letter of each word.
    // for (let i = 1; i < words.length; i++) {
    //   words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    // }
  
    // // Join the words back together.
    // return words.join("");

    if (string === SINGLE_VIEW_CHARTS.SENSITIVITY_VALUES){
        return 'raw'
    }
    else if (string === SINGLE_VIEW_CHARTS.SENSITIVITY_GRAYSCALE){
        return 'raw'
    }
    else if (string === SINGLE_VIEW_CHARTS.CRITERIA_CALCULATOR){
        return 'criteriaCalculator'
    }
    else if (string === SINGLE_VIEW_CHARTS.TOTAL_DEVIATION_VALUES){
        return 'totalDeviation'
    }
    else if (string === SINGLE_VIEW_CHARTS.TOTAL_DEVIATION_PROBABILITIES){
        return 'totalDeviationProbabilities'
    }
    else if (string === SINGLE_VIEW_CHARTS.PATTERN_DEVIATION_VALUES){
        return 'patternDeviation'
    }
    else if (string === SINGLE_VIEW_CHARTS.PATTERN_DEVIATION_PROBABILITIES){
        return 'patternDeviationProbabilities'
    }
}

export const ChartBlock = (props) => {
    const OSOD = props.OSOD || 'OD'

    let eyeProps = {
        chartId: `chart-Single-${ OSOD }-${ props.id }`,
        chartName: props.chartObj.key,
        chartData: props.assessment && props.assessment.data && props.assessment.data.processed && props.assessment.data?.[chartNameToCamelCase(props.chartObj.text)]?.[OSOD] ? props.assessment.data?.[chartNameToCamelCase(props.chartObj.text)]?.[OSOD] : [],
        chartOrientation: CHART_ORIENTATION[OSOD],
        transform: getChartScaleSize((props.assessment.configuration.grid, props.setModal)),
        left: '',
        top: '',
        // top: '20px',
        transformOrigin: 'top left',
        position: 'absolute',
        chartObj: props.chartObj,
        assessment: props.assessment
    }

    let modalData = { 
        chartName: props.chartObj.key , 
        chartData: props.assessment && props.assessment.data && props.assessment.data.processed && props.assessment.data?.[chartNameToCamelCase(props.chartObj.text)]?.[OSOD] ? props.assessment.data?.[chartNameToCamelCase(props.chartObj.text)]?.[OSOD] : [], 
        chartOrientation: CHART_ORIENTATION[OSOD], 
        transform: getChartScaleSize((props.assessment.configuration.grid, props.setModal)),
        left: -175,
        top: -175
    }
    
    if (props.chartObj.key === CHART_NAMES.CLUSTER_MD) {
        eyeProps = {
            ...eyeProps,
            transform: 1.3,
            top: 10,
            left: 25
        }
    }

    return (
        <div className={`col-6 ${[CHART_NAMES.GAZE_TRACKER, CHART_NAMES.CLUSTER_MD, CHART_NAMES.CRITERIA_CALCULATOR].includes(props.chartObj.key)? 'd-print-none': ''}`}>
            <div className="fliter__imgbox" onClick={ () => props.showInModal(modalData) }>
                <p className="mg-b-10">{ toCamelCase(props.chartObj.text) }</p>
                {eyeProps.chartData.length === 0 ? 
                    <div className='img__centered'>
                        <img src="/assets/images/noDataFound.png" alt="" className="w-100 mg-b-5"/>
                    </div> 
                    : 
                    props.assessment.data.processed && props.assessment.data?.[chartNameToCamelCase(props.chartObj.text)]?.[OSOD] && <Eye {...eyeProps} />
                }
            </div>
        </div>
    )
}


const mapStateToProps = ({ assessment, modals, patients }) => ({
    assessment: assessment.activeAssessment,
    setModal: modals.set,
    modalType: modals.type,
    modalData: modals.data,
    activePatient: patients.activePatient,
  })
  
export default connect(mapStateToProps)(ChartBlock)
