import { WORKLISTS } from "../shared/constants/actions.constants"

const initialState = {
  worklistsObj: {},
  activeWorklist: null,
  worklistSearchText: ""
}

export default function(state = initialState, action) {

  switch (action.type) {
    case WORKLISTS.SET_WORKLISTS_OBJ:
      return {
        ...state,
        worklistsObj: { ...action.payload }
      }

    case WORKLISTS.ADD_WORKLIST_INTO_OBJ:
      return {
        ...state,
        worklistsObj: { ...state.worklistsObj, ...action.payload }
      }

    case WORKLISTS.ADD_ACTIVE_WORKLIST:
      return {
        ...state,
        activeWorklist: action.payload
      }

    case WORKLISTS.DELETE_WORKLIST:
      delete state.worklistsObj[action.payload]
      return {
        ...state,
        worklistsObj: { ...state.worklistsObj }
      }

    case WORKLISTS.SET_WORKLIST_SEARCH_TEXT:
      return {
        ...state,
        worklistSearchText: action.payload
      }
    
    case WORKLISTS.SET_WORKLIST_SORT:
      return {
        ...state,
        worklistSort: action.payload
      }

    default:
      return state
  }
}
