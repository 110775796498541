import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { UserForm } from '../../models/users.model';
import { addUserFromAuth0, getUserByEmail } from '../../actions/users.action';
import history from '../../history';
import { setModalWithType } from '../../actions/modal.action';
import { MODALS } from '../../shared/constants/actions.constants';

const Auth0Checker = props => {
  const { isLoading, user: auth0User } = useAuth0();

  useEffect(() => {
    if (auth0User && props.loggedInUser === null) {
      const userInfo = { ...UserForm };
      const userObj = {
        ...userInfo,
        ...auth0User,
        firstName: auth0User.given_name,
        lastName: auth0User.family_name,
        email: auth0User.email,
        password: 'Auth0@123',
        passwordSalt: 'Auth0@123',
        role: 'admin',
        settings: {
          dateFormat: 'dd/mm/yyyy',
        },
        userType: 'Auth0',
      };

      if (!userObj.firstName || !userObj.lastName) {
        const getData = async () => {
          await props.getUserByEmail(userObj.email, userObj);
        };
        getData();
      } else {
        props.addUserFromAuth0(userObj, history);
      }
    }
  }, [auth0User, addUserFromAuth0, props.loggedInUser]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return <div></div>;
};

const mapStateToProps = ({ auth }) => ({
  loggedInUser: auth.loggedInUser,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addUserFromAuth0,
      setModalWithType,
      getUserByEmail,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Auth0Checker);
