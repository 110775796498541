import { combineReducers } from "redux"
import authReducer from "./auth.reducer"
import UsersReducer  from "./users.reducer"
import PatientReducer  from "./patients.reducer"
import AssessmentReducer  from "./assessment.reducer"
import LoaderReducer  from "./loader.reducer"
import ModalReducer  from "./modal.reducer"
import CountryStateCityReducer  from "./countryStateCity.reducer"
import UIReducer from "./ui.reducer"
import HmdDeviceReducer from "./hmdDevices.reducer"
import WorklistReducer from "./worklist.reducer"
import JobsReducer from "./cronJob.reducer"
import NotesReducer from "./notes.reducer"
import FaqReducer from "./faq.reducer"

export default combineReducers({
  loader: LoaderReducer,
  auth: authReducer, 
  users: UsersReducer,
  patients: PatientReducer,
  modals: ModalReducer,
  countryStateCity: CountryStateCityReducer,
  ui: UIReducer,
  hmdDevices: HmdDeviceReducer,
  worklists: WorklistReducer,
  assessment: AssessmentReducer,
  notes: NotesReducer,
  jobs: JobsReducer,
  faq: FaqReducer
})
