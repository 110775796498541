import React from "react"
import { CHART_NAMES, CHART_ORIENTATION } from '../../../shared/constants/chart.constants'
import Eye from '../../shared/graphs/eye'
import OngoingReliabilityMeasures from "../ongoingReliabilityMeasures"

export const EyeGraph = (props) => {

    return (
        <div className = "test__monitor--tracker">
            <div className = "row mt-5 mb-5">
                {props.processedData ? 
                    <>
                        <div className="col-md-1"></div>
                        <div className = "col-md-5 position-relative">
                            {
                                props.processedData && props.processedData.OS ? (
                                    <Eye chartId={`chart-OD-livechart-1`} assessment={props.assessment} chartName={CHART_NAMES.SENSITIVITY_VALUES} chartData={ props.processedData.OS } chartOrientation={CHART_ORIENTATION.OS} transform={props.assessment?.configuration?.grid === '10-2'? .2: 0.08} transformOrigin={'left top'} height={500} width={250} top={0} left={0}/>
                                ) : <OngoingReliabilityMeasures/>
                            }
                        </div>
                        <div className = "col-md-5 position-relative ">
                            {
                                props.processedData && props.processedData.OD ? (
                                    <Eye chartId={`chart-OS-livechart-1`} assessment={props.assessment} chartName={CHART_NAMES.SENSITIVITY_VALUES} chartData={ props.processedData.OD } chartOrientation={CHART_ORIENTATION.OD} transform={props.assessment?.configuration?.grid === '10-2'? .2: 0.08} transformOrigin={'left top'} height={500} width={250} top={0} left={0}/>
                                ) : <OngoingReliabilityMeasures/>
                            }
                        </div>
                    </>
                    :
                    <div className="col-md-12 d-flex justify-content-center align-items-center mt-5">
                        <OngoingReliabilityMeasures/>
                    </div>
                }
            </div>
            <div className="col-md-1"></div>
        </div>
    )
}
  
export default EyeGraph
