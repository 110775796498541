import React from 'react'
import WorklistNonPatientListRow from "./worklistNonPatientListRow"
import NoRecord from "../../shared/noRecord"
import { NO_RECORD_MSG } from "../../../shared/constants/shared.constants"
import { Scrollbars } from 'react-custom-scrollbars'

export const WorklistPatientList = (props) => {

  return (
    <div className="patient__table block__toggle worklist--heightleft">
      <div className="tableCntrl tableCntrl--left">
        <div className="table mg-b-0 table-hover">
          <div className="thead">
            <div className="trow">
              <div className="tcol w-40">Patient Name</div>
              <div className="tcol w-30">DOB</div>
              <div className="tcol w-25">Patient ID</div>
              <div className="tcol w-5"></div>
            </div>
          </div>
          <div className="tbody mcustomscrollbar table-hover noheight" style={{"maxHeight" : "270px !important"}} >
            <Scrollbars style={{ 'position': 'relative', 'minHeight': 230 }}>
              {
                  props.patientSimpleList && props.patientSimpleList.length ? (
                    props.patientSimpleList.map((patient, index) => (
                      <WorklistNonPatientListRow 
                        key = { index }
                        patient = { patient }
                        onRightArrowClick = { props.onRightArrowClick }
                      />
                    ))
                  ) : (
                    <NoRecord msg = { NO_RECORD_MSG.NO_PATIENT_FOUND } />
                  )
              }
            </Scrollbars>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WorklistPatientList
