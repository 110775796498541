import React, { useEffect } from "react"
import { connect } from "react-redux"
import Header from "../components/shared/header"
import Sidebar from "../components/shared/sidebar"
import PatientsAssessmentPanel from "../components/assessment/assessmentPanel"

const AssessmentContainer = (props) => {

  useEffect(() => {
    window.heightcalculation()
  }, [])

  return (
    <div>
      <Header loggedInUser = { props.loggedInUser } />
      <main className="wrapper">
        <Sidebar />
        <div className="content-wrapper">
          <div className="content__inner content__main">
            <div className = { `patient__table content__center w-100 block__toggle ${ props.showDeviceSidebar ? "block__toggle--left" : "" }` }>
              <div className="patient__list">

                <PatientsAssessmentPanel />
                
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

const mapStateToProps = ({ auth, ui }) => ({
  loggedInUser: auth.loggedInUser,
  showDeviceSidebar: ui.showDeviceSidebar
})

export default connect(mapStateToProps)(AssessmentContainer)
