import { PATIENTS } from "../shared/constants/actions.constants"

const initialState = {
  patientsObj: {},
  activePatient: null,
  patientSearch: {
    text: "",
    assessmentFromDate: "",
    assessmentToDate: ""
  },
  patientSimpleList: [],
  patientListSort: "0",
  patientPagination: null
}

export default function(state = initialState, action) {

  switch (action.type) {
    case PATIENTS.SET_PATIENTS_OBJ:
      return {
        ...state,
        patientsObj: { ...action.payload }
      }

    case PATIENTS.ADD_PATIENT_INTO_OBJ:
      return {
        ...state,
        patientsObj: { ...state.patientsObj, ...action.payload }
      }

    case PATIENTS.ADD_ACTIVE_PATIENT:
      return {
        ...state,
        activePatient: action.payload
      }

    case PATIENTS.DELETE_PATIENT:
      delete state.patientsObj[action.payload]
      return {
        ...state,
        patientsObj: { ...state.patientsObj }
      }

    case PATIENTS.SET_PATIENT_SEARCH_TEXT:
      let patientSearchForText = {
        ...state.patientSearch,
        text: action.payload
      }
      
      return {
        ...state,
        patientSearch: patientSearchForText
      }

    case PATIENTS.SET_ASSESSMENT_DATE_RANGE:
      let { fromDate, toDate } = action.payload
      let patientSearchForRange = {
        ...state.patientSearch,
        assessmentFromDate: fromDate, 
        assessmentToDate: toDate
      }
      
      return {
        ...state,
        patientSearch: patientSearchForRange
      }
    
    case PATIENTS.SET_PATIENT_LIST_SORT:
      return {
        ...state,
        patientListSort: action.payload
      }

    case PATIENTS.SET_PATIENT_SIMPLE_LIST:
      return {
        ...state,
        patientSimpleList: action.payload
      }

    case PATIENTS.SET_PATIENT_PAGINATION:
      return {
        ...state,
        patientPagination: action.payload
      }

    case PATIENTS.SET_PATIENTS_IMPORT_TEST_INTO_OBJ:
      let { patientId, importTests } = action.payload
      let activePatient = state.activePatient
      
      if (activePatient && activePatient.id && activePatient.id == patientId) {
        activePatient = {
          ...activePatient, 
          importedTests: importTests
        }
      }

      return {
        ...state,
        activePatient
      }

    default:
      return state
  }
}
