import React from "react"
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withNamespaces } from 'react-i18next'
import { Link, withRouter } from "react-router-dom"
import { clearAuthToken } from "../../services/localstorage/localStorage.service"
import { ROUTES } from '../../shared/constants/routes.constants'
import { resetDevicesStatus } from "../../actions/hmdDevices.action"
import { setModalWithType } from "../../actions/modal.action"
import { MODALS } from "../../shared/constants/actions.constants"
import { useAuth0 } from "@auth0/auth0-react"


export const Header = (props) => {

  const { user, logout } = useAuth0()

  const onLogoutClick = event => {
    if(user) {
      logout()
    }
    event.preventDefault()
    clearAuthToken()
    window.location.href = ROUTES.LOGIN
  }

  const onResetDeviceClick = async event => {
    event.preventDefault()
    await props.resetDevicesStatus()
    window.location.reload()
  }

  const onAddDeviceClick = event => {
    event.preventDefault()
    props.setModalWithType(true, MODALS.ADD_HMD_MODAL, {
      size: 'md',
      backdrop: 'static'
    })
  }

  const onBackupMenuClick = event => {
    event.preventDefault()
    props.setModalWithType(true, MODALS.DB_BACKUP_MODAL, {
      size: 'lg',
      backdrop: 'static',
      backupModalPageNo: 1
    })
  }
  
  return (
    <header className="header d-flex">
      <div className="header-logo d-flex justify-content-center">
        <img className="header-logo-image" src="/assets/images/logowhite.png" alt="Eadietech Logo" />
      </div>
      <div className="header-nav d-flex justify-content-between w-100">
        <div className="header-nav-left">
          <ul>
            <li className = { `nav-item ${props.location.pathname.indexOf(`${ROUTES.PATIENTS}`) >= 0 ? "active" : ""}` }>
              <Link to = { ROUTES.PATIENTS }>
                <i className="fabicon-search"></i>{ props.t('SEARCH_PATIENT') }
              </Link>
            </li>
            <li className = { `nav-item ${props.location.pathname === `${ROUTES.WORKLIST}` ? "active" : ""}` }>
              <Link to = { ROUTES.WORKLIST }>
                <i className="fabicon-worklist"></i>{ props.t('WORKLIST') }
              </Link>
            </li>
            {
              props.loggedInUser && props.loggedInUser.role.toLowerCase() === 'admin' && 
              <li className = { `nav-item ${(props.match.path === `${ROUTES.USERS}` || props.match.path === `${ROUTES.USER_DETAILS}`) ? "active" : ""}` }>
                <Link to = { ROUTES.USERS }>
                  <i className="fabicon-contact"></i>{ props.t('MANAGE_USER') }
                </Link>
              </li>
            }
          </ul>
        </div>
        <div className="header-nav-right">
          <ul>
            <li className="nav-item">
              <Link to="/help">
                <i className="fabicon-help"></i>{ props.t('HELP') }
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/settings">
                <i className="fabicon-setting"></i>{ props.t('SETTINGS') }
              </Link>
            </li>
            <li className = "nav-item dropdown">
              <a className = "header-nav-avatar" id="profileDropdown" data-toggle="dropdown" href = "#!">
                <img className = "rounded-circle" src="/assets/images/profile-placeholder.jpg" alt="profile" />
              </a>
              <div className = "dropdown-menu dropdown-menu-right profile-dropdown">
                <div className = "user-info">
                  <img className = "rounded-circle mx-auto" src="/assets/images/profile-placeholder.jpg" alt="profile" />
                  <p><strong>{ props.loggedInUser.firstName } { props.loggedInUser.lastName }</strong></p>
                  <p>{ props.loggedInUser.email }</p>
                </div>
                <a className = "dropdown-item" href="#!" onClick = { onResetDeviceClick }>Reset Devices</a>
                <a className = "dropdown-item" href="#!" onClick = { onAddDeviceClick }>Add Device</a>
                { props?.loggedInUser?.email === 'admin@email.com' ? <a className = "dropdown-item" href="#!" onClick = { onBackupMenuClick }>Backup Management</a> : null }
                <a className = "dropdown-item" href="#!">Profile</a>
                <a className = "dropdown-item" href="#!" onClick = { onLogoutClick }>Logout</a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </header>
  )

}

const mapDispatchToProps = dispatch => (
  bindActionCreators({ 
    resetDevicesStatus,
    setModalWithType
  }, dispatch)
)

export default connect(null, mapDispatchToProps)(withRouter(withNamespaces()(Header)))
