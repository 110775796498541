import { Formik } from 'formik';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { useCallback, useEffect, useState } from 'react';
import { setModalWithType } from '../../../actions/modal.action';
import { MODALS } from '../../../shared/constants/actions.constants';
import { parseDateAndTime } from '../../../shared/utils/wrappers';
import {
  addMongoDbBackup,
  getDB_BackupList,
  getAllScheduleList,
  addCronJobSchedule,
} from '../../../actions/cronjobs.action';
import Pagination from '../../shared/pagination';

const scheduleInitialValues = {
  command: '0 0 * * *',
  label: 'DAILY',
  timezone: 'America/Toronto',
  isActive: false,
  id: '',
};

export const DbBackupModal = props => {
  const [initialValues, setInitialValues] = useState(scheduleInitialValues);

  const cancelClickHandler = () => {
    props.setModalWithType(false);
  };

  const addBackupToDB = async () => {
    await props.addMongoDbBackup({});
    await props.getDB_BackupList(true, 1);
  };

  const parseSerial = index => {
    if (props.backupPagination)
      return (props.backupPagination.currentPage - 1) * 10 + index + 1;
    return index + 1;
  };

  const selectSchedule = (e, setValues) => {
    const selectedValue = JSON.parse(e.target.value);
    setValues(selectedValue);
  };

  const saveSchedule = async (values, { setSubmitting }) => {
    await props.addCronJobSchedule(values);
  };

  const onPaginationClick = useCallback(pageNo => {
    props.getDB_BackupList(true, pageNo);
  }, []);

  const openBackupConfirmationModal = backupItem => {
    let modalState = {
      actionType: MODALS.CONFIRMATION_MODAL_TO_RESTORE_BACKUP_FILE,
      header: 'Restore Backup',
      body: `Please kindly note that restoring a backup will replace the current database with the selected backup. Are you sure you want to proceed?\n\n Do not Close this window until the process is complete.`,
      backdrop: 'static',
      size: 'lg',
      actionType: MODALS.CONFIRMATION_MODAL_TO_RESTORE_BACKUP_FILE,
      s3Key: backupItem.storageInfo.key,
      backupModalPageNo: props.backupPagination.currentPage,
    };

    props.setModalWithType(true, MODALS.CONFIRMATION_MODAL, modalState);
  };

  useEffect(() => {
    props.getDB_BackupList(true, props?.modalData.backupModalPageNo || 1);
    props.getAllScheduleList();

    return () => {
      props.getDB_BackupList(true, 1);
    };
  }, []);

  useEffect(() => {
    if (props.scheduleList && props.scheduleList.length > 0) {
      setInitialValues(
        props.scheduleList.find(schedule => schedule.isActive === true) ||
          scheduleInitialValues,
      );
    }
  }, [props.scheduleList]);

  return (
    <React.Fragment>
      <div className="modal-header">
        <h2 className="modal-title" id="patient-modalLabel">
          Database Backup
        </h2>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={cancelClickHandler}
        >
          <span aria-hidden="true">
            <i className="fabicon-close"></i>
          </span>
        </button>
      </div>

      <div className="modal-body">
        <div className="test__monitoring mg-b-0 pb-0 border-bottom-0">
          <div className="test__monitoring--inner">
            <ul
              className="nav nav-pills nav__customtab"
              id="pills-tab"
              role="tablist"
            >
              <li className={`nav-item `}>
                <a
                  className={'nav-link active'}
                  href="#pills-backup"
                  role="tab"
                  data-toggle="pill"
                  onClick={() => {}}
                >
                  Backup Details
                </a>
              </li>
              <li className={`nav-item `}>
                <a
                  className={'nav-link'}
                  href="#pills-configure"
                  data-toggle="pill"
                  role="tab"
                  onClick={() => {}}
                >
                  Configure Scheduler
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-3 test__content--scroll mcustomscrollbar">
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-backup"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div className="d-flex justify-content-center align-items-center">
                <button
                  type="button"
                  className={`btn btn-light mr-3 mb-3`}
                  onClick={addBackupToDB}
                >
                  Create Backup Now
                </button>
              </div>

              {props.backupList && props.backupList.length > 0 && (
                <div className="tableCntrl tableCntrl--left">
                  <div className="table mg-b-0 table-hover">
                    <div className="thead">
                      <div className="trow">
                        <div className="tcol w-10">
                          <a href="#!" className="filter">
                            SL
                          </a>
                        </div>
                        <div className="tcol w-25">Type</div>
                        <div className="tcol w-50">Time</div>
                        <div className="tcol w-15">Action</div>
                      </div>
                    </div>
                    <div className="tbody table-hover">
                      {props.backupList.map((backupItem, index) => {
                        return (
                          <div key={backupItem.id} className={`trow active`}>
                            <div className="tcol w-10">
                              {parseSerial(index)}
                            </div>
                            <div className="tcol w-25">{backupItem.type}</div>
                            <div className="tcol w-50">
                              {parseDateAndTime(backupItem.createdAt)}
                            </div>
                            <div className="tcol w-15">
                              <div className="device__delete--btn position-relative">
                                <button
                                  type="button"
                                  className="btn btn-red  ml-2"
                                  onClick={() => openBackupConfirmationModal(backupItem)}
                                >
                                  <i className="fabicon-Restore"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {props.backupPagination && (
                      <Pagination
                        totalPages={props.backupPagination.page}
                        currentPage={props.backupPagination.currentPage}
                        onPageChange={pageNo => onPaginationClick(pageNo)}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
            <div
              className="tab-pane fade"
              id="pills-configure"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={saveSchedule}
              >
                {({ values, handleSubmit, setValues, errors, touched }) => (
                  <React.Fragment>
                    <form onSubmit={handleSubmit}>
                      <label>Run the scheduler</label>
                      <div className="d-flex justify-content-between align-items-center spacing-4">
                        <select
                          className="form-control"
                          value={JSON.stringify(values)}
                          onChange={e => selectSchedule(e, setValues)}
                        >
                          {props.scheduleList.map((scheduleItem, index) => (
                            <option
                              selected={values.id === scheduleItem.id}
                              key={scheduleItem.id}
                              value={JSON.stringify(scheduleItem)}
                            >
                              {scheduleItem.label}
                            </option>
                          ))}
                        </select>
                        <button type="submit" className="btn btn-light ml-3">
                          Save
                        </button>
                      </div>
                    </form>
                  </React.Fragment>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setModalWithType,
      getDB_BackupList,
      addMongoDbBackup,
      getAllScheduleList,
      addCronJobSchedule,
    },
    dispatch,
  );

const mapStateToProps = ({ jobs, modals }) => ({
  backupList: jobs.backupList,
  scheduleList: jobs.scheduleList,
  backupPagination: jobs.backupPagination,
  modalData: modals.data,
});

export default connect(mapStateToProps, mapDispatchToProps)(DbBackupModal);
