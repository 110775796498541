import React, { useEffect } from 'react'
import { withRouter } from "react-router-dom"
import { connect } from 'react-redux'
import { Formik } from 'formik'
import { bindActionCreators } from 'redux'
import { Scrollbars } from 'react-custom-scrollbars'
import { getNotes, addNote } from "../../../actions/notes.action"
import { NoteSchema, NoteForm } from "../../../models/note.model"
import { parseDate } from "../../../shared/utils/wrappers"


export const AssessmentNotes = (props) => {

    useEffect(() => {
        if(props.assessment){
            props.getNotes(props.patient.id, props.assessment.id)
        }
    }, [])

    /**
     * @desc To handle on submission
     * @param {object} event
     * @public
     */
    const noteFormHandler = (event, { resetForm }) => {
        props.addNote(props.patient.id, props.assessment.id, event)
        resetForm(NoteForm)
    }

    return (
        <React.Fragment>
            <Scrollbars>
                <div className="add__notes--inner mcustomscrollbar">
                    { props.notes && props.notes.map((notesObj, i) => (
                        <div className="add__notes--row">
                            {
                                notesObj && notesObj.user && (
                                    <div className="add__notes_namedate">
                                        {
                                            notesObj && notesObj.notes && notesObj.notes.length > 0 && (
                                                <span className="add__notes-text">{ parseDate(notesObj.notes[0].createdAt) } </span>
                                            )
                                        }
                                        <span className="add__notes-text fontSize-14">{ notesObj.user.firstName } { notesObj.user.lastName }</span>
                                    </div>
                                )
                            }
                            <ul>
                                {
                                    notesObj && notesObj.notes && notesObj.notes.map((note, i) => (
                                        <li>
                                            <p>{note.note}</p>
                                            <div className="add__notes--action">
                                                <a href="#!" className="fabicon-Edit" onClick={()=>{}}></a>
                                                <a href="#!" className="fabicon-Delete" onClick={()=>{}}></a>
                                            </div>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                        ))
                    }

                </div>
                <Formik
                    enableReinitialize = { true }
                    initialValues = { NoteForm }
                    validationSchema = { NoteSchema() }
                    onSubmit = { noteFormHandler }
                >
                    {
                        ({ values, handleChange, handleSubmit, setFieldValue, errors, touched }) => (
                            <React.Fragment>
                                <form onSubmit = { handleSubmit }>
                                    <div className="form-group add__notes--area">
                                        <textarea placeholder="Add a new note" className = { `form-control ${ (errors.note && touched.note) ? "is-invalid" : null }` } id = "note" name = "note" onChange = { handleChange } value = { values.note }></textarea>
                                        { errors.note && touched.note ? (
                                            <div className="invalid-feedback">
                                                { errors.note }
                                            </div>
                                        ) : null }
                                    </div>
                                    <div className="col-lg-12 mg-t-15">
                                        <button className="btn btn-red" type="submit">Post</button>
                                    </div>
                                </form>
                            </React.Fragment>
                        )
                    }
                </Formik>
            </Scrollbars>
        </React.Fragment>
    )
}

const mapStateToProps = ({ notes }) => ({
    notes: notes.notes
  })
  
  const mapDispatchToProps = dispatch => (
    bindActionCreators({ 
        getNotes, 
        addNote
    }, dispatch)
  )
  
  export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AssessmentNotes))
