import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { MODALS } from '../../../../shared/constants/actions.constants';
import { CHART_NAMES } from '../../../../shared/constants/chart.constants';
import {
  calculateAge,
  calculateTimeDuration,
  parseDateWithMonth,
  parseTime,
  toCamelCase,
} from '../../../../shared/utils/wrappers';
import {
  getAssessmentById,
  clearStateAfterAssessmentInitiate,
} from '../../../../actions/assessment.action';
import { getPatientById } from '../../../../actions/patients.action';
import ChartBlock from './chartBox';
import InfoBox from './infoBox';
import { bindActionCreators } from 'redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { STRATEGIES_LABELS } from '../../../../models/assessment.model';
import { OS_OD_DIRECTION } from '../../../../shared/constants/shared.constants';

const getOsOdSequence = (direction) => {
  return direction === OS_OD_DIRECTION.OS_ON_LEFT_OD_ON_RIGHT ? ["OS", "OD"] : ["OD", "OS"];
};

export const SingleView = props => {
  let [singleViewCharts, setSingleViewCharts] = useState(null);
  let [assessment, setAssessment] = useState(null);
  let [totalCharts] = useState(
    Object.keys(CHART_NAMES).map((name, i) => {
      return {
        id: `t${i + 1}`,
        text: (name || '')
          .toLowerCase()
          .split('_')
          .join(' '),
        content: (name || '')
          .toLowerCase()
          .split('_')
          .join(' '),
        key: name,
      };
    }),
  );

  useEffect(() => {
    setAssessment(props.activeAssessment);
  }, [props.activeAssessment]);

  useEffect(() => {
    let { singleViewCharts: userSingleViewCharts } =
      props.loggedInUser.settings || {};

    if (userSingleViewCharts && userSingleViewCharts.length) {
      let arr = userSingleViewCharts.map((chart, i) => {
        return {
          id: `s${i + 1}`,
          text: chart,
          key: (chart || '')
            .split(' ')
            .join('_')
            .toUpperCase(),
          content: chart,
        };
      });
      setSingleViewCharts(arr);
    }
  }, [props.loggedInUser && props.loggedInUser.settings]);

  const editClickHandler = () => {
    let modalState = {
      followupCharts: singleViewCharts,
      totalCharts,
      header: 'Single View Charts',
      size: 'lg',
      maxDestinationCount: 9,
      minDestinationCount: 3,
    };

    props.setModalWithType(true, MODALS.DND_SINGLE_VIEW_CHARTS, modalState);
  };

  let location = useLocation();

  useEffect(() => {
    let searchParams = new URLSearchParams(location.search).get('assessmentId');
    props.getAssessmentById(searchParams);
  }, [location]);


  const fetchData = async (patientId ) => {
    await props.getPatientById(patientId);
  };

  useEffect(() => {

    // fetchData(props.patient.id);

    // return () => {
    //   props.clearStateAfterAssessmentInitiate();
    // };
  }, [props.patient.id]);

  return (
    <React.Fragment>
      {props.patient && assessment && (
        <React.Fragment>
          <div
            className="tab-pane fade show active"
            id="singleview"
            role="tabpanel"
            // ref={props.forwardRef}
          >
            <Scrollbars style={{ position: '' }}>
              <div className="filter__details">
                <div className="filter__left position-relative">
                  <span className="os-title d-print-none">{props.loggedInUser.settings.osOdDirection === OS_OD_DIRECTION.OS_ON_LEFT_OD_ON_RIGHT ? "OS": "OD"}</span>
                  <div className="d-flex">
                    <div className="filter-equal">
                      <p className="strong">
                        Date: {parseDateWithMonth(assessment.testStartedAt)}
                      </p>
                      <p>Time: {parseTime(assessment.testStartedAt)}</p>
                      <p>Age: {calculateAge(props.patient.dateOfBirth)}</p>
                    </div>
                    <div className="filter-equal">
                      <p>Grid: {assessment.configuration.grid}</p>
                      <p>Response Type: {toCamelCase(assessment.configuration.responseType)}</p>
                    </div>
                    <div className="filter-equal">
                      <p>
                        Strategy:{' '}
                        {/* {(assessment.configuration.strategy)} */}
                        {(STRATEGIES_LABELS[assessment.configuration.strategy].label )}
                      </p>
                      <p>
                        Eye Sequence:{' '}
                        {toCamelCase(assessment.configuration.eye)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="filter__right position-relative">
                  <span className="os-title d-print-none">{props.loggedInUser.settings.osOdDirection === OS_OD_DIRECTION.OS_ON_LEFT_OD_ON_RIGHT ? "OD": "OS"}</span>
                  <div className="d-flex">
                    <div className="filter-equal">
                      <p>Background Intensity: 10 cd/m<sup>2</sup></p>
                      <p>Normative Values Version: 0.1</p>
                    </div>
                    <div className="filter-equal">
                      <p>Stimuli Duration: 200ms</p>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="filter__details__inner d-flex no-gutters pb-5">
                {getOsOdSequence(props.loggedInUser.settings.osOdDirection).map((OSOD, i) => (
                  <InfoBox 
                    key={i}
                    assessment={assessment} 
                    activeAssessment={props.activeAssessment}
                    calculateTimeDuration={calculateTimeDuration} 
                    editClickHandler={editClickHandler}
                    singleViewCharts={singleViewCharts}
                    OSOD={OSOD}
                    patient={props.patient}
                    showInModal={props.showInModal}
                  />
                ))}
              </div>
            </Scrollbars>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = ({ auth }) => ({
  loggedInUser: auth.loggedInUser
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
      {
        getPatientById,
        getAssessmentById,
        clearStateAfterAssessmentInitiate,
      },
      dispatch,
    );
  
export default connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(SingleView));
